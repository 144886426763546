// API
import { api } from '../configs/api/api';

// Models
import { INotificationsSettings } from '../interfaces/notifications-settings.interfaces';
import { INotificationItem } from '../interfaces/notifications.interfaces';

export const NotificationsService = {
  fetchNotificationsList: async (
    offset: number, limit: number
  ): Promise<{
    notifications?: INotificationItem[],
    total?: number,
    error?: string
  }> => {
    try {
      const axiosResponse = await api.get('/users/notifications', {
        params: {
          offset, limit
        }
      });
      return axiosResponse.data;
    } catch (error: any) {
      const res = error?.response?.data;
      if (error?.response.status === 500) {
        return { error: res?.message };
      }
      return { ...res };
    }
  },
  markAsReadNotifications: async (): Promise<{
    message?: string,
    error?: string
  }> => {
    try {
      const axiosResponse = await api.get('/users/notifications/mark-read', );
      return axiosResponse.data;
    } catch (error: any) {
      const res = error?.response?.data;
      if (error?.response.status === 500) {
        return { error: res?.message };
      }
      return { ...res };
    }
  },
  notificationsUnreadCount: async (): Promise<{
    count?: number,
    message?: string,
    error?: string
  }> => {
    try {
      const axiosResponse = await api.get('/users/notifications/unread-count', );
      return axiosResponse.data;
    } catch (error: any) {
      const res = error?.response?.data;
      if (error?.response.status === 500) {
        return { error: res?.message };
      }
      return { ...res };
    }
  },
  deleteNotifications: async (
    notification_id?: number
  ): Promise<{ error?: string, message?: string }> => {
    try {
      const axiosResponse = await api.delete('/users/delete-notifications', {
        params: {
          ...(notification_id && { notification_id })
        }
      });
      return axiosResponse.data;
    } catch (error: any) {
      const res = error?.response?.data;
      if (error?.response.status === 500) {
        return { error: res?.message };
      }
      return { ...res };
    }
  },
  fetchNotificationsSettings: async (): Promise<{
    notification_settings?: INotificationsSettings,
    error?: string
  }> => {
    try {
      const axiosResponse = await api.get('/users/notification-settings');
      return axiosResponse.data;
    } catch (error: any) {
      const res = error?.response?.data;
      if (error?.response.status === 500) {
        return { error: res?.message };
      }
      return { ...res };
    }
  },
  updateNotificationsSettings: async (
    settings: INotificationsSettings
  ): Promise<{
    notification_settings?: INotificationsSettings,
    error?: string
  }> => {
    try {
      const axiosResponse = await api.post('/users/update-notification-settings', {
        ...settings,
        in_app_notification: true,
        sms_notification: false,
      });
      return axiosResponse.data;
    } catch (error: any) {
      const res = error?.response?.data;
      if (error?.response.status === 500) {
        return { error: res?.message };
      }
      return { ...res };
    }
  },
}
