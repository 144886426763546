import { createAsyncThunk } from '@reduxjs/toolkit';

// Services
import { HistoryService } from '../../services/history.service';

// Slices & Thunks
import { HistorySliceActions } from './history.slice';
import { IHistory } from '../../interfaces/history.interfaces';
import { IAppointmentHistory, IMessageNotification } from '../../interfaces/chat.interfaces';

export enum EHistoryThunks {
  FetchHistoryData = 'HISTORY/fetchHistoryData',
  AddHistoryFromResend = 'HISTORY/addHistoryFromResend',
  AddHistoryFromSocket = 'HISTORY/addHistoryFromSocket',
  AddHistoryNotificationFromSocket = 'HISTORY/addHistoryNotificationFromSocket',
  AddHistoryAppointmentFromSocket = 'HISTORY/addHistoryAppointmentFromSocket',
  ClearHistory = 'HISTORY/clearHistory',
}

export const fetchHistoryData = createAsyncThunk(
  EHistoryThunks.FetchHistoryData,
  async ({ id, worker_code, offset, limit, isLoadMore = false, isFirstLoading = false, temporary_token }: {
    id?: string | number,
    worker_code?: string,
    offset?: number,
    limit?: number,
    temporary_token?: string,
    isLoadMore?: boolean,
    isFirstLoading?: boolean
  }, { dispatch }) => {
    dispatch(HistorySliceActions.loadHistory(isFirstLoading));
    const {
      history,
      total,
      total_all,
      error
    } = await HistoryService.fetchHistory(id, worker_code, offset, limit, temporary_token);
    if (!history) {
      return dispatch(HistorySliceActions.loadHistoryError(error ?? 'Error'));
    }
    return dispatch(HistorySliceActions.loadHistorySuccess({
      history,
      total: total as number, total_all: total_all as number,
      isLoadMore
    }));
  }
);

export const addHistoryFromResend = createAsyncThunk(
  EHistoryThunks.AddHistoryFromResend,
  async ({ history, prevId }: { history: IHistory, prevId: number }, { dispatch }) => {
    return dispatch(HistorySliceActions.addHistoryFromResend({
      history,
      prevId
    }));
  }
);

export const addHistoryFromSocket = createAsyncThunk(
  EHistoryThunks.AddHistoryFromSocket,
  async (history: IHistory, { dispatch }) => {
    const isHistoryPage = window.location.href.includes('history');
    if (!isHistoryPage) return;
    return dispatch(HistorySliceActions.addHistoryFromSocket(history));
  }
);

export const addHistoryNotificationFromSocket = createAsyncThunk(
  EHistoryThunks.AddHistoryNotificationFromSocket,
  async ({ history }: { history: IMessageNotification }, { dispatch }) => {
    if (!window.location.href.includes(`expert/${ history.worker?.id }/history`)) {
      return;
    }
    return dispatch(HistorySliceActions.addHistoryNotificationFromSocket({
      history
    }));
  }
);

export const addHistoryAppointmentFromSocket = createAsyncThunk(
  EHistoryThunks.AddHistoryAppointmentFromSocket,
  async (history: IAppointmentHistory, { dispatch }) => {
    if (!window.location.href.includes(`expert/${ history.worker?.id }/history`)) {
      return;
    }
    return dispatch(HistorySliceActions.AddHistoryAppointmentFromSocket(history));
  }
);

export const clearHistory = createAsyncThunk(
  EHistoryThunks.ClearHistory,
  async (_, { dispatch }) => {
    return dispatch(HistorySliceActions.clearHistory());
  }
);
