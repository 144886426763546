import { FC, HTMLProps } from 'react';
import dayjs from 'dayjs';

// Shared UI Library Components
import { CustomIcon, EIconName, CustomTooltip } from '@ppmcore/seven-ppm-core-shared-components-react';

// Styles
import './history-notification.scss';

// Models
import { IMessageNotification } from '../../../../interfaces/chat.interfaces';

type THistoryNotificationProps = HTMLProps<HTMLDivElement> & {
  messageInfo: IMessageNotification;
  isRedirectItem?: boolean;
  onScrollToMessage?: (messageInfo: IMessageNotification) => void;
}

export const HistoryNotification: FC<THistoryNotificationProps> = (
  { messageInfo, isRedirectItem = false, onScrollToMessage = () => {}, ...props }: THistoryNotificationProps
) => {
  const { created_at, message_pinged } = messageInfo;

  const onScrollClickHandler = (): void => {
    onScrollToMessage(messageInfo);
  }

  return (
    <div className="history-notification" { ...props }>
      <div className="history-notification--body">
        <div className="body-item body-item--time">
          { dayjs(created_at).format('hh:mm A') }
        </div>
        <div className="body-item body-item--icon">
          <CustomIcon name={ EIconName.NotificationsActiveFill } size={'size-md'}/>
        </div>
        <div className="body-item body-item--message">
          I’m ready to respond to your request from { dayjs(message_pinged.created_at).format('DD MMM YYYY [at] hh:mm A') }
        </div>
      </div>
      <CustomTooltip title={ 'Go to Request' }>
        <div className="history-notification--action" onClick={ onScrollClickHandler }>
          <CustomIcon name={ EIconName.ArrowUpwardFill }/>
        </div>
      </CustomTooltip>
    </div>
  );
};

