import { createAsyncThunk } from '@reduxjs/toolkit';

// Store actions
import { openNotification } from '../app-notifications/app-notifications.thunks';

// Services
import { NotificationsService } from '../../services/notifications.service';

// Slices & Thunks
import { NotificationsSliceActions } from './notifications.slice';

// Models
import { INotificationItem } from '../../interfaces/notifications.interfaces';

export enum ENotificationsThunks {
  FetchNotifications = 'NOTIFICATIONS/fetchNotifications',
  MarkAsReadNotifications = 'NOTIFICATIONS/markAsReadNotifications',
  NotificationsUnreadCount = 'NOTIFICATIONS/notificationsUnreadCount',
  DeleteNotification = 'NOTIFICATIONS/deleteNotification',
  ClearAllNotifications = 'NOTIFICATIONS/clearAllNotifications',
  ClearNotificationsState = 'NOTIFICATIONS/clearNotificationsState',
}

export const fetchNotifications = createAsyncThunk(
  ENotificationsThunks.FetchNotifications,
  async ({ offset = 0, limit = 30, isLoadMore = false }: {
    offset?: number,
    limit?: number,
    isLoadMore?: boolean
  }, { dispatch }) => {
    dispatch(NotificationsSliceActions.loadNotifications());
    const { notifications, total, error } = await NotificationsService.fetchNotificationsList(offset, limit);
    if (!notifications) {
      return dispatch(NotificationsSliceActions.loadNotificationsFailure(error ?? 'Error'));
    }
    return dispatch(NotificationsSliceActions.loadNotificationsSuccess({
      notifications, total: total as number, isLoadMore
    }));
  }
);

export const notificationsUnreadCount = createAsyncThunk(
  ENotificationsThunks.NotificationsUnreadCount,
  async (_, { dispatch }) => {
    const temporaryToken = localStorage.getItem('temporary_token');
    const token = localStorage.getItem('token');
    if (!!temporaryToken || !token) return 0;

    const { error, count } = await NotificationsService.notificationsUnreadCount();
    if (error) {
      return 0;
    }
    dispatch(NotificationsSliceActions.setUnreadCount(count || 0));
    return count;
  }
);

export const markAsReadNotifications = createAsyncThunk(
  ENotificationsThunks.MarkAsReadNotifications,
  async (_, { dispatch }) => {
    await new Promise((res) => {
      const timerId = setTimeout(() => {
        res(true);
        clearTimeout(timerId);
      },5000);
    });
    const { message } = await NotificationsService.markAsReadNotifications();
    if (!message) {
      return false;
    }
    dispatch(NotificationsSliceActions.markAsReadNotifications());
    return true;
  }
);

export const deleteNotification = createAsyncThunk(
  ENotificationsThunks.DeleteNotification,
  async (notification_id: number, { dispatch }) => {
    const { error, message } = await NotificationsService.deleteNotifications(notification_id);
    if (error) {
      return dispatch(openNotification({
        type: 'error',
        description: error ?? 'Failed to delete the notification, try again!'
      }));
    }
    dispatch(openNotification({
      type: 'success',
      description: message ?? 'Notification successfully deleted!'
    }))
    return dispatch(NotificationsSliceActions.deleteNotifications(notification_id));
  }
);

export const addNotificationFromSocket = createAsyncThunk(
  ENotificationsThunks.DeleteNotification,
  async (notifications: INotificationItem, { dispatch }) => {
    const isNotificationPage = window.location.href.includes('notifications');
    if (!isNotificationPage) {
      dispatch(notificationsUnreadCount());
      return;
    }
    return dispatch(NotificationsSliceActions.addNotificationFromSocket(notifications));
  }
);

export const clearAllNotifications = createAsyncThunk(
  ENotificationsThunks.ClearAllNotifications,
  async (_, { dispatch }) => {
    const { error } = await NotificationsService.deleteNotifications();
    if (error) {
      return dispatch(openNotification({
        type: 'error',
        description: error ?? 'Failed to delete all notifications, try again!'
      }));
    }
    dispatch(openNotification({
      type: 'success',
      description: error ?? 'All notifications successfully deleted!'
    }))
    return dispatch(NotificationsSliceActions.clearAllNotifications());
  }
);

export const clearNotificationsState = createAsyncThunk(
  ENotificationsThunks.ClearNotificationsState,
  async (_, { dispatch }) => {
    return dispatch(NotificationsSliceActions.clearNotificationsState());
  }
);
