import React, { FC, useMemo } from 'react';
import dayjs from 'dayjs';

// Shared UI Library Components
import {
  CustomButton,
  CustomIcon,
  EIconName,
  EIconColor,
  // Timer
} from '@ppmcore/seven-ppm-core-shared-components-react';

// Styles
import './next-payment.scss';

// Components
import { NextPaymentCountdown } from '../../../../shared/ui-components/next-payment-countdown/next-payment-countdown';

interface INextPaymentProps {
  finished_at: string;
  additionalMinutes: number;
  makePayment?: () => void;
  cancelMakePayment?: () => void;
}

export const NextPayment: FC<INextPaymentProps> = (
  {finished_at, additionalMinutes, makePayment = () => {}, cancelMakePayment = () => {}}: INextPaymentProps
) => {

  const initTimer = useMemo(() => dayjs(finished_at).diff(dayjs(), 'seconds'), [finished_at]);

  return (<div className={'next-payment'}>
    <div className={'next-payment-inside'}>
      <div className={'next-payment-inside-title'}>
        <CustomIcon name={EIconName.Error} color={EIconColor.Error} />
        {/*<div>This consultation will conclude in <Timer initialSeconds={initTimer} onTimerEnd={() => cancelMakePayment()}/></div>*/}
        <div>This consultation will conclude in <NextPaymentCountdown initialSeconds={initTimer} onTimerEnd={() => cancelMakePayment()}/></div>
      </div>
      <div className={'next-payment-inside-desc'}>Would you like to make a payment for an additional {additionalMinutes} minutes to continue the connection?</div>
      <div className={'next-payment-inside-actions'}>
        <CustomButton text="No" mode={ 'dark' } onClick={() => cancelMakePayment()}/>
        <CustomButton text="Make a Payment" onClick={() => makePayment()}/>
      </div>
    </div>
  </div>);
}
