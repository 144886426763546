import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

// Shared UI Library Components
import { CustomButton, Loader } from '@ppmcore/seven-ppm-core-shared-components-react';

// Components
import { Footer } from '../../modules/footer/footer';

// Store entities
import { getUserState } from '../../store/user/user.selectors';

// Styles
import './page-not-found.scss';

export const PageNotFound = () => {

  const navigate = useNavigate();
  const { user, loading } = useSelector(getUserState);

  return (<div className={ 'page-not-found' }>
    <div className={ 'page-not-found-inside' }>
      <div className={ 'logo-section' }>
        <img className={ 'logo' } src={ '/assets/images/logo.svg' } alt={ '' }/>
      </div>
      <div className={ 'page-not-found-content' }>
        <div className={ 'title' }>404</div>
        <div className={ 'subtitle' }>Page not found</div>
        <div className={ 'text' }>The page you are looking for doesn’t exist or another error occurred.</div>
        {
          loading && <Loader size={ 'large' }/>
        }
        {
          !loading && <CustomButton text={ `Back to ${ user ? 'Home' : 'Log In' }` } type={ 'primary' }
                                    onClick={ () => navigate('/home') }/>
        }
      </div>
    </div>
    <Footer/>
  </div>);
}
