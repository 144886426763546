import { StripePaymentElementOptions } from '@stripe/stripe-js/types/stripe-js/elements/payment';

export const CPaymentRequestConfig = {
  country: 'US',
  currency: 'usd',
  total: {
    label: 'Demo total',
    amount: 1099,
  },
  requestPayerName: false,
  requestPayerEmail: false,
}

export const CPaymentButtonConfig = {
  style: {

    borderRadius: '32px',
    paymentRequestButton: {
      type: 'default',
      // One of 'default', 'book', 'buy', or 'donate'
      // Defaults to 'default'

      theme: 'dark',
      // One of 'dark', 'light', or 'light-outline'
      // Defaults to 'dark'

      height: '44px',
      // Defaults to '40px'. The width is always '100%'.
    },
  }
}

export const CPaymentElementOptions: StripePaymentElementOptions = {
  layout: 'tabs',
  wallets: {
    applePay: 'never',
    googlePay: 'never',
  },
  fields: {
    billingDetails: {
      // address: 'never',
    },
  }
};
