import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Form } from 'antd';

// Shared UI Library Components
import {
  CustomButton,
  defaultValidateMessages,
  DiscardModal,
  InputPassword,
  passwordPattern,
  PasswordValidationTooltip, SpinElement
} from '@ppmcore/seven-ppm-core-shared-components-react';

// Components
import { AuthLayout } from '../auth-modules/auth-layout/auth-layout';

// Thunks
import { openNotification } from '../../../store/app-notifications/app-notifications.thunks';
import { checkResetPasswordTokenThunks, clearAuthState, resetPasswordThunks } from '../../../store/auth/auth.thunks';

// Styles
import './reset-password.scss';

export const ResetPassword = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const dispatch = useDispatch<any>();

  const [searchParams] = useSearchParams();
  const reset_token = searchParams.get('reset_token');

  const [btnDisabled, setBtnDisabled] = useState(true);
  const [showPasswordError, setShowPasswordError] = useState(false);
  const [resetToken, setResetToken] = useState<string | null>(null);
  const [discardModalOpen, setDiscardModalOpen] = useState(false);
  const [checkedToken, setCheckedToken] = useState(false);

  useEffect(() => {
    localStorage.removeItem('reset_password_time');
    localStorage.removeItem('reset_password');
    if (reset_token) {
      dispatch(checkResetPasswordTokenThunks({reset_token}))
        .then((res: any) => {
          if (res.payload && res.payload?.error) {
            dispatch(clearAuthState()).then(() => navigate(`/recovery-link-expired`));
            return;
          }
          setResetToken(reset_token);
          setCheckedToken(true);
        });
    }
  }, [reset_token])

  const onFinish = () => {
    form.validateFields().then((values) => {
      const {password, password_confirmation} = values;
      if (resetToken) {
        dispatch(resetPasswordThunks({reset_token: resetToken, password, password_confirmation}))
          .then((res: any) => {
            if (res.payload && res.payload?.error) {
              dispatch(clearAuthState()).then(() => navigate(`/recovery-link-expired`));
              return;
            }
            dispatch(openNotification({
              description: res.payload.message,
              type: 'success'
            }));
            navigate(`/sign-in`);
          });
      }
    }).catch((error) => {
      console.error('Form validation failed', error);
    });
  };

  const cancelAction = (value: boolean) => {
    if (value) {
      localStorage.removeItem('reset_password');
      localStorage.removeItem('reset_password_time');
      dispatch(clearAuthState()).then(() => navigate(`/sign-in`));
    }
    setDiscardModalOpen(false);
  }

  const onValuesChange = (changedValues: { password: string } | { password_confirmation: string },
                          allFormValues: { password: string | undefined, password_confirmation: string | undefined }) => {

    if (allFormValues.password !== undefined
      && allFormValues.password_confirmation !== undefined
      && allFormValues.password !== ''
      && allFormValues.password_confirmation !== ''
      && new RegExp(passwordPattern).test(allFormValues.password)
      && allFormValues.password_confirmation === allFormValues.password) {
      setBtnDisabled(false);
    } else {
      setBtnDisabled(true);
    }
  };

  return (<AuthLayout isLoading={!checkedToken} cardTitle="New Password" rightBarIcon="auth">
    <Form
      form={form}
      className={'reset-password'}
      name="reset-password"
      requiredMark={false}
      onFinish={onFinish}
      onValuesChange={onValuesChange}
      autoComplete="off"
      layout={'vertical'}
      validateMessages={defaultValidateMessages}>

      <Form.Item
        name="password"
        messageVariables={{fieldLabel: 'New password'}}
        dependencies={['password_confirmation']}
        rules={[
          {required: true},
          ({getFieldValue}) => ({
            validator(_, value) {
              if (!value || new RegExp(passwordPattern).test(getFieldValue('password'))) {
                setShowPasswordError(false);
                return Promise.resolve();
              }
              setShowPasswordError(true);
              return Promise.reject(new Error());
            }
          })
        ]}>
        <div>
          {showPasswordError && <PasswordValidationTooltip/>}
          <InputPassword placeholder={'Password*'}/>
        </div>
      </Form.Item>

      <Form.Item
        name="password_confirmation"
        dependencies={['password']}
        messageVariables={{fieldLabel: 'Re-enter new password'}}
        rules={[
          {required: true},
          ({getFieldValue}) => ({
            validator(_, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
              }
              return Promise.reject(new Error(defaultValidateMessages.passwordsDontMatch));
            }
          })]}>
        <InputPassword placeholder={'Confirm Password*'}/>
      </Form.Item>

      <div className="form-bottom">
        <CustomButton text="Cancel" type={'text'} onClick={() => setDiscardModalOpen(true)}/>
        <CustomButton text="Confirm" htmlType={'submit'} disabled={btnDisabled}/>
      </div>
    </Form>

    <DiscardModal open={discardModalOpen}
                  onCancel={(value) => cancelAction(value)}
                  title={'Are you sure you want to discard your Password Recovery?'} />
  </AuthLayout>);
}
