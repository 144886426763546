import React, { FC, Fragment } from 'react';
import { useWindowSize } from "@uidotdev/usehooks";

// Shared UI Library Components
import { CustomIcon } from '@ppmcore/seven-ppm-core-shared-components-react';

// Styles
import './rates-item.scss';

// Models
import { IRateItem } from '../../../../interfaces/experts.interfaces';

type TExpertRatesProps = {
  rateInfo: IRateItem;
  viewType?: 'ppm' | 'pps',
};

export const RatesItem: FC<TExpertRatesProps> = (
  { rateInfo, viewType = 'ppm' }: TExpertRatesProps
) => {
  const { width } = useWindowSize();

  const {
    icon,
    is_enabled,
    name,
    type,
    list,
    rate,
    full_free_service_enabled
  } = rateInfo;

  return (
    <Fragment>
      {
        ((viewType === 'ppm' && (width || 0) > 768) || ((width || 0) <= 768 && (is_enabled || viewType === 'pps'))) && <>
          <div className="item-icon">
            <CustomIcon size={ 'size-xll' } name={ icon }/>
          </div>
          <div className="item-name">
            { name }
          </div>
        </>
      }
      {
        (is_enabled && viewType === 'ppm') && <Fragment>
          <div className="item-type">
            { type }
          </div>
          { !full_free_service_enabled && <div className="item-rate">
            { rate }<span>{ name !== 'Correspondence' ? '/min' : '/rqst' }</span>
          </div> }
          { full_free_service_enabled && <div className="item-rate">
            Free
          </div> }
        </Fragment>
      }
      { (!!list?.length && viewType === 'pps') && <>
        <div className="item-cost">
          Cost per Session
        </div>
        <div className="item-list">
          { list.map((item, idx) =>
            <div key={ idx } className="item-list--item">
              { item }
            </div>
          ) }
        </div>
      </> }
    </Fragment>
  );
};
