// API
import { api } from '../configs/api/api';
import axios, { AxiosError } from 'axios';

// Interfaces
import { IStaticPages } from '../interfaces/static-pages.interface';

export const fetchStaticPages = async (): Promise<{ data: IStaticPages; status: number } | { data: any; status: number }> => {
  return await api.get('/static-pages').then(result => {
    const {data, status} = result;
    return {data, status};
  }).catch((error: Error | AxiosError) => {
    if (axios.isAxiosError(error) && error.response) {
      const {data, status} = error.response;
      return {data, status};
    } else {
      return {
        data: {error: 'An error has occurred with API processing'},
        status: 500
      };
    }
  });
}
