import axios, { AxiosError } from 'axios';
import { api } from '../configs/api/api';

// Interfaces
import { IUserData, IUserSignUpRequest } from '../interfaces/user.interfaces';
import { IAuthData } from '../interfaces/auth.interfaces';

export const AuthService = {
  signIn: async (email: string, password: string, temporary_token: string, device_UDID: string | null, invitation_code: string | null): Promise<{ data: IAuthData; status: number } | { data: any; status: number }> => {
    return await api.post('/users/login', {email, password, temporary_token, device_UDID, invitation_code})
      .then(result => {
        const {data, status} = result;
        return {data, status};
      })
      .catch((error: Error | AxiosError) => {
        if (axios.isAxiosError(error) && error.response) {
          const {data, status} = error.response;
          return {data, status};
        } else {
          return {
            data: {error: 'An error has occurred with API processing'},
            status: 500
          };
        }
      });
  },
  signUp: async (userRequest: IUserSignUpRequest): Promise<{ data: IAuthData; status: number } | { data: any; status: number }> => {
    return await api.post('/users/sign-up', userRequest)
      .then(result => {
        const {data, status} = result;
        return {data, status};
      })
      .catch((error: Error | AxiosError) => {
        if (axios.isAxiosError(error) && error.response) {
          const {data, status} = error.response;
          return {data, status};
        } else {
          return {
            data: {error: 'An error has occurred with API processing'},
            status: 500
          };
        }
      });
  },
  signUpTemp: async ({first_name, last_name, email, company_code}: {first_name: string, last_name: string, email: string, company_code: string}): Promise<{ data: IAuthData; status: number } | { data: any; status: number }> => {
    return await api.post('/temporary/sign-up', {first_name, last_name, email, company_code})
      .then(result => {
        const {data, status} = result;
        return {data, status};
      })
      .catch((error: Error | AxiosError) => {
        if (axios.isAxiosError(error) && error.response) {
          const {data, status} = error.response;
          return {data, status};
        } else {
          return {
            data: {error: 'An error has occurred with API processing'},
            status: 500
          };
        }
      });
  },
  activateCode: async (temporary_token: string, code: string, type: string, device_UDID: string | null, invitation_code: string | null): Promise<{ data: IUserData; status: number } | { data: any; status: number }> => {
    return await api.post('/users/activate-code', {temporary_token, code, type, device_UDID, invitation_code})
      .then(result => {
        const {data, status} = result;
        return {data, status};
      })
      .catch((error: Error | AxiosError) => {
        if (axios.isAxiosError(error) && error.response) {
          const {data, status} = error.response;
          return {data, status};
        } else {
          return {
            data: {error: 'An error has occurred with API processing'},
            status: 500
          };
        }
      });
  },
  activateCodeTempUser: async (temporary_token: string, code: string, type: string): Promise<{ data: IUserData; status: number } | { data: any; status: number }> => {
    return await api.post('/temporary/activate-code', {temporary_token, code, type})
      .then(result => {
        const {data, status} = result;
        return {data, status};
      })
      .catch((error: Error | AxiosError) => {
        if (axios.isAxiosError(error) && error.response) {
          const {data, status} = error.response;
          return {data, status};
        } else {
          return {
            data: {error: 'An error has occurred with API processing'},
            status: 500
          };
        }
      });
  },
  resendCode: async (temporary_token: string, type: string)
    : Promise<{ data: {message: string; resend_access: string; status: string;}; status: number } | { data: any; status: number }> => {
    return await api.post('/users/resend-code', {temporary_token, type})
      .then(result => {
        const {data, status} = result;
        return {data, status};
      })
      .catch((error: Error | AxiosError) => {
        if (axios.isAxiosError(error) && error.response) {
          const {data, status} = error.response;
          return {data, status};
        } else {
          return {
            data: {error: 'An error has occurred with API processing'},
            status: 500
          };
        }
      });
  },
  resendCodeTempUser: async (temporary_token: string, type: string)
    : Promise<{ data: {message: string; resend_access: string; status: string;}; status: number } | { data: any; status: number }> => {
    return await api.post('/temporary/resend-code', {temporary_token, type})
      .then(result => {
        const {data, status} = result;
        return {data, status};
      })
      .catch((error: Error | AxiosError) => {
        if (axios.isAxiosError(error) && error.response) {
          const {data, status} = error.response;
          return {data, status};
        } else {
          return {
            data: {error: 'An error has occurred with API processing'},
            status: 500
          };
        }
      });
  },
  forgotPassword: async (email: string) => {
    return await api.post('/users/forgot-password', {email, type: 'customer'}).then(result => {
      const {data, status} = result;
      return {data, status};
    })
      .catch((error: Error | AxiosError) => {
        if (axios.isAxiosError(error) && error.response) {
          const {data, status} = error.response;
          return {data, status};
        } else {
          return {
            data: {error: 'An error has occurred with API processing'},
            status: 500
          };
        }
      });
  },
  resendResetEmail: async (email: string) => {
    return await api.post('/users/resend-forgot-password-email', {email, type: 'customer'}).then(result => {
      const {data, status} = result;
      return {data, status};
    })
      .catch((error: Error | AxiosError) => {
        if (axios.isAxiosError(error) && error.response) {
          const {data, status} = error.response;
          return {data, status};
        } else {
          return {
            data: {error: 'An error has occurred with API processing'},
            status: 500
          };
        }
      });
  },
  checkResetPasswordToken: async (reset_token: string) => {
    return await api.get(`/reset-password/${reset_token}`).then(result => {
      const {data, status} = result;
      return {data, status};
    })
      .catch((error: Error | AxiosError) => {
        if (axios.isAxiosError(error) && error.response) {
          const {data, status} = error.response;
          return {data, status};
        } else {
          return {
            data: {error: 'An error has occurred with API processing'},
            status: 500
          };
        }
      });
  },
  resetPassword: async (reset_token: string, password: string, password_confirmation: string) => {
    return await api.post('/reset-password', {reset_token, password, password_confirmation}).then(result => {
      const {data, status} = result;
      return {data, status};
    })
      .catch((error: Error | AxiosError) => {
        if (axios.isAxiosError(error) && error.response) {
          const {data, status} = error.response;
          return {data, status};
        } else {
          return {
            data: {error: 'An error has occurred with API processing'},
            status: 500
          };
        }
      });
  }
}
