import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import { clearInterval, setInterval } from 'worker-timers';

// Styles
import './next-payment-countdown.scss';

type TFreeMinutesCountdownProps = {
  initialSeconds: number;
  onTimerEnd?: (state: boolean) => void;
}

export const NextPaymentCountdown: FC<TFreeMinutesCountdownProps> = (
  {
    initialSeconds,
    onTimerEnd = () => {},
  }: TFreeMinutesCountdownProps
): JSX.Element => {

  const [time, setTime] = useState<number>(0);
  const expiryTimer = useRef<number | null>(null);

  const clearIntervalAction = (): void => {
    if (expiryTimer.current === null) return;
    try {
      clearInterval(expiryTimer.current);
    } catch (error) {
      console.log('clearIntervalAction', error);
    }
    expiryTimer.current = null;
  }

  const onTimerEndHandler = (): void => {
    onTimerEnd(true);
  }

  const setUpTimerByDeadline = (deadline: number): void => {
    clearIntervalAction();

    if (deadline <= 0) {
      setTime(0);
      onTimerEndHandler();
      return;
    }

    setTimer(deadline);
    setTime(deadline);
  }

  const transformFormat = useMemo(() => {
    if (time >= 3600) {
      return 'HH:mm:ss';
    }
    return 'mm:ss';
  }, [time])

  useEffect(() => {
    if (time > 0 || expiryTimer.current === null) return;
    clearIntervalAction();
    onTimerEndHandler();
  }, [time]);

  useEffect(() => {
    if (!initialSeconds) return;
    setUpTimerByDeadline(initialSeconds);
  }, [initialSeconds]);

  useEffect(() => {
    return () => {
      clearIntervalAction();
    }
  }, []);

  const setTimer = (time: number) => {
    if (time > 0) {
      clearIntervalAction();
      expiryTimer.current = setInterval(() => setTime(prev => prev - 1), 1000);
    } else {
      clearIntervalAction();
      onTimerEndHandler();
    }
  };

  dayjs.extend(duration);
  dayjs.extend(relativeTime);

  const durationFormat = dayjs.duration(time, "seconds");
  const formattedTime = durationFormat.format(transformFormat);

  const emptyTime = formattedTime === '00:00' || formattedTime === '00:00:00';

  return <div className={ `next-payment-countdown ${ emptyTime ? 'red-time' : '' }` }>
    <div className="next-payment-countdown--text">
      { !emptyTime && formattedTime }
      { emptyTime && <>
        <span>_</span><span>_</span>:<span>_</span><span>_</span>
      </> }
    </div>
  </div>;
};
