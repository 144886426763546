import { createAsyncThunk } from '@reduxjs/toolkit';

// Services
import { CallService } from '../../services/call.service';

// Slices
import { CallFinishedSliceActions } from './call-finished.slice';

export const getCallFinishedInfo = createAsyncThunk(
  'AFTER_CALL/getCallFinishedInfo',
  async ({call_id, temporary_token}: { call_id: number; temporary_token: string; }, {dispatch}: any) => {

      dispatch(CallFinishedSliceActions.getCallFinishedRequest());
      const res = await CallService.getCallInfo(call_id, temporary_token);
      if (res.status !== 200) {
        dispatch(CallFinishedSliceActions.getCallFinishedFailure());
        return res.data;
      }
      dispatch(CallFinishedSliceActions.getCallFinishedSuccess(res.data.call));
  }
);

export const clearCallFinishedStore = createAsyncThunk(
  'AFTER_CALL/clearCallFinishedStore',
  async (_, {dispatch}: any) => {
    dispatch(CallFinishedSliceActions.clearCallFinishedState());
  }
);
