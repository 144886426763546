import React, { useState, useEffect, FC } from 'react';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
// @ts-ignore
import worker_script from './worker-script.js';

// Styles
import './timer.scss';

// Models
import { ICurrency } from '../../../../interfaces/company.interfaces';

interface ITimerProps {
  initialSeconds: number | null;
  callRate: number | null;
  isFullFreeCall?: boolean;
  currency: ICurrency;
}

const timerWorker = new Worker(worker_script);

export const Timer: FC<ITimerProps> = (
  {
    initialSeconds = 0,
    callRate = 0,
    isFullFreeCall = false,
    currency
  }: ITimerProps
): JSX.Element => {

  const [webWorkerTime, setWebWorkerTime] = useState(initialSeconds);
  const [rate, setRate] = useState<string | null>(null);

  useEffect(() => {
    timerWorker.postMessage({ turn: 'on', initialSeconds: initialSeconds });
  }, [initialSeconds]);

  useEffect(() => {
    let rateCalculation = '0.00';
    if (webWorkerTime !== null && webWorkerTime > 0) {
      rateCalculation = ((callRate || 1) * webWorkerTime).toFixed(2);
    }
    setRate(rateCalculation);
  }, [webWorkerTime, callRate]);

  useEffect(() => {
    timerWorker.onmessage = ({ data: { time } }) => {
      setWebWorkerTime(time);
    };
  }, []);

  dayjs.extend(duration);
  dayjs.extend(relativeTime);

  const durationFormat = dayjs.duration(webWorkerTime || 0, 'seconds');
  const formattedTimeHour = parseInt(durationFormat.asHours() + '');
  const formattedTimeMin = durationFormat.format('mm');
  const formattedTimeSec = durationFormat.format('ss');

  return (<div className={ 'timer-block' }>
    <div
      className={ 'timer' }>{ formattedTimeHour > 0 && `${ formattedTimeHour } h ` } { formattedTimeMin } min { formattedTimeSec } sec
    </div>
    <span></span>
    { (rate && !isFullFreeCall) && <div className={ 'price' }>{ currency.symbol }{ parseFloat(rate).toFixed(2) }</div> }
    { (isFullFreeCall) && <div className={ 'price' }>Free</div> }
  </div>);
};
