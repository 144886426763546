import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Interfaces
import { IUserData } from '../../interfaces/user.interfaces';

export interface UserState {
  user: IUserData | null;
  loading: boolean;
  error: boolean;
}

const initialState: UserState = {
  user: null,
  loading: false,
  error: false
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    profileRequest(state) {
      state.loading = true;
      state.error = false;
    },
    profileSuccess: (state, action: PayloadAction<IUserData>) => {
      state.user = action.payload;
      state.loading = false;
      state.error = false;
    },
    profileFailure: (state) => {
      state.loading = false;
      state.error = true;
    },
    profileUpdateRequest(state) {
      state.loading = true;
      state.error = false;
    },
    profileUpdateSuccess: (state, action: PayloadAction<IUserData>) => {
      state.user = action.payload;
      state.loading = false;
      state.error = false;
    },
    profileUpdateFailure: (state) => {
      state.loading = false;
      state.error = true;
    },
    profileDeleteRequest(state) {
      state.loading = true;
      state.error = false;
    },
    profileDeleteSuccess: (state) => {
      state.loading = false;
      state.error = false;
    },
    profileDeleteFailure: (state) => {
      state.loading = false;
      state.error = true;
    },
    updatePhotoRequest(state) {
      state.loading = true;
      state.error = false;
    },
    updatePhotoSuccess: (state, action: PayloadAction<{ photo: string }>) => {
      state.user = { ...state.user, photo: action.payload.photo };
      state.loading = false;
      state.error = false;
    },
    updatePhotoFailure(state) {
      state.loading = false;
      state.error = true;
    },
    changeCompany(state, { payload: { company_id } }: PayloadAction<{ company_id: number }>) {
      state.user = {
        ...state.user,
        last_used_company: company_id
      };
    },
    deletePhotoRequest(state) {
      state.loading = true;
      state.error = false;
    },
    deletePhotoSuccess: (state) => {
      state.user = { ...state.user, photo: null };
      state.loading = false;
      state.error = false;
    },
    deletePhotoFailure(state) {
      state.loading = false;
      state.error = true;
    },
    logoutRequest(state) {
      state.loading = true;
      state.error = false;
    },
    logoutSuccess: (state) => {
      state.user = null;
      state.loading = false;
      state.error = false;
    },
    logoutFailure: (state) => {
      state.loading = false;
      state.error = true;
    },
    deleteLogoutRequest: (state) => {
      state.user = null;
      state.loading = false;
      state.error = false;
    },
  }
});

export const UserSliceActions = userSlice.actions;
