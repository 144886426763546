import { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import weekday from "dayjs/plugin/weekday";
import updateLocale from "dayjs/plugin/updateLocale";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import duration from "dayjs/plugin/duration";

// Store entities
import { getDateState } from "../store/date/date.selectors";
import { setLocalTimeZone } from "../store/date/date.thunks";

// Dayjs plugins setup
dayjs.extend(utc)
dayjs.extend(timezone);
dayjs.extend(duration);
dayjs.extend(weekday);
dayjs.extend(updateLocale)
dayjs.updateLocale('en', {
  weekStart: 1
});

/**
 * Custom React Hook for tracking window height changes.
 * @returns {void}
 */
export const useDate = (): void => {

  const { timezonesOptions, localTimezone, selectedTimezone } = useSelector(getDateState);

  const dispatch = useDispatch<any>();

  /**
   * Change the user's current timezone based on their browser's timezone offset
   */
  const changeUserCurrentTimezone = (timeZone: string): void => {
    dayjs.tz.setDefault(timeZone);
    dispatch(setLocalTimeZone({ timeZone }));
  }

  /**
   * Sets the user's current timezone based on their browser's timezone offset
   */
  const setUserCurrentTimezone = (): void => {
    if (!timezonesOptions.length) return;
    try {
      const userTimezoneOffset = Math.round(-(new Date().getTimezoneOffset()));
      const userTimeZoneName = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const foundTimezones = timezonesOptions?.filter(option => option.offset === userTimezoneOffset) || timezonesOptions?.[0];
      const timezoneOption = foundTimezones?.find(option => option.value.toLowerCase() === userTimeZoneName.toLowerCase())
        || foundTimezones?.[0];
      dispatch(setLocalTimeZone({ timeZone: timezoneOption.value }));
    } catch (error) {
      dispatch(setLocalTimeZone({ timeZone: timezonesOptions?.[0].value }));
    }
  }

  useEffect(() => {
    if (!selectedTimezone || (localTimezone === selectedTimezone)) return;
    changeUserCurrentTimezone(selectedTimezone);
  }, [selectedTimezone]);

  useEffect(() => {
    setUserCurrentTimezone();
  }, [timezonesOptions]);
};
