import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Models
import { IPaymentsTypes } from '../../interfaces/payments-types.interfaces';

export interface IPaymentsTypesState {
  paymentsTypes: IPaymentsTypes | null,
  isLoad: boolean,
  isError: boolean,
}

const initialState: IPaymentsTypesState = {
  paymentsTypes: null,
  isLoad: false,
  isError: false,
};

export const paymentsTypesSlice = createSlice({
  initialState,
  name: 'paymentsTypes',
  reducers: {
    loadPaymentsTypesRequest: (state): void => {
      state.isLoad = true;
      state.isError = false;
    },
    loadPaymentsTypesSuccess: (state, action: PayloadAction<IPaymentsTypes>): void => {
      state.paymentsTypes = action.payload;
      state.isLoad = false;
      state.isError = false;
    },
    loadPaymentsTypesError: (state): void => {
      state.isLoad = false;
      state.isError = true;
    },
    clearPaymentsTypes: (state): void => {
      state.paymentsTypes = null;
      state.isLoad = false;
      state.isError = false;
    }
  }
});

export const PaymentsTypeSliceActions = paymentsTypesSlice.actions;
