import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Components
import { AddPayment, PaymentMethods } from '../../../shared/payment-components';

// Store entities
import { fetchPaymentsCards } from '../../../store/payments/payments.thunks';
import { getCurrencyState } from '../../../store/currency/currency.selectors';

// Styles
import './payments.scss';

interface IPaymentsProps {}

export const Payments: FC<IPaymentsProps> = ({}: IPaymentsProps) => {

  const { currentCurrency, company_account_id } = useSelector(getCurrencyState);

  const dispatch = useDispatch<any>();

  useEffect(() => {
    loadPaymentsData();
  }, []);

  const loadPaymentsData = (): void => {
    dispatch(fetchPaymentsCards());
  }

  return (<div className="payments">
    <div className={'content-title'}>Payment Methods</div>
    <div className={'content-title-line'}></div>
    <div className={'content-inside'}>
      <PaymentMethods/>
      <AddPayment currency={ currentCurrency } company_account_id={ company_account_id }/>
    </div>
  </div>);
}
