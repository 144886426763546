import React, { FC, useState } from 'react';
import { useSelector } from 'react-redux';

// Shared UI Library Components
import {
  CustomIcon,
  EIconName,
  UserAvatar
} from '@ppmcore/seven-ppm-core-shared-components-react';

// Styles
import './expert-auth-widget-navigation.scss';

// Components
import { RightMenu } from '../../../../modules/main-layout/components/right-menu/right-menu';

// Store entities
import { getCoreState } from '../../../../store/core/core.selectors';
import { getUserState } from '../../../../store/user/user.selectors';
import { getWidgetState } from '../../../../store/widget/widget.selectors';

// Utils
import { isWidgetAppType } from '../../../../utils/widget.utils';

type TSidebarFilterProps = {};

export const ExpertAuthWidgetNavigation: FC<TSidebarFilterProps> = ({}: TSidebarFilterProps): JSX.Element => {
  const [mobileMenu, setMobileMenu] = useState<boolean>(false);

  const coreState = useSelector(getCoreState);
  const { type: widgetType } = useSelector(getWidgetState);
  const { user } = useSelector(getUserState);

  const isWidget = isWidgetAppType();
  const token = localStorage.getItem('token');

  const toggleMobileMenu = (): void => {
    setMobileMenu(!mobileMenu);
  }

  if (!isWidget || !token || widgetType !== 'popup') return <></>;

  return (
    <div className="expert-auth-widget-navigation">
      <div className={ `expert-auth-widget-navigation--item item-menu ${ mobileMenu ? 'open-menu' : '' }` }>
        <UserAvatar avatarUrl={ user?.photo } sizeClass={ 'small' }/>
        <CustomIcon name={ EIconName.ArrowDown } onClick={ toggleMobileMenu }/>
      </div>
      <div className="expert-auth-widget-navigation--item menu-list" hidden={ !mobileMenu }>
        <RightMenu withLabels={ true } currentPath={ coreState?.path?.current || '' }
                   onClickMenuItem={ toggleMobileMenu }/>
      </div>
    </div>
  )
};
