import React from 'react';

// Components
import { ConversationNotifications } from '../modules/conversation-notifications/conversation-notifications';

// Styles
import './chat-status-call-missed.scss';

export const ChatStatusCallMissed = () => {

  const workerId = localStorage.getItem('worker_id');
  const returnPathname = localStorage.getItem('return_pathname') ?? `/expert/${workerId}`;

  return (<ConversationNotifications
    title={'Counsellor Unavailable'}
    text={'The Counsellor is not available at the moment. You may try to contact them later. The payment for the Text Chat will be refunded to you.'}
    navigateAction={returnPathname}  />);
}
