import { createAsyncThunk } from '@reduxjs/toolkit';

// Services
import { TransactionsService } from '../../services/transactions.service';

// Store entities
import { openNotification } from '../app-notifications/app-notifications.thunks';

// Slice
import { TransactionsSliceActions } from './transactions.slice';

// Models
import { ITransactionSettings } from '../../interfaces/transactions.interfaces';

export enum ETransactionsThunks {
  GetTransactions = 'TRANSACTIONS/getTransactions',
  RefundTransaction = 'TRANSACTIONS/refundTransaction',
  GetReceiptUrl = 'TRANSACTIONS/getReceiptUrl',
  SaveTransactionsSetting = 'TRANSACTIONS/saveTransactionsSetting',
  ClearTransactions = 'TRANSACTIONS/clearTransactions',
}

export const getTransactions = createAsyncThunk(
  ETransactionsThunks.GetTransactions,
  async ({ settings, isFirstLoad = false }: {
    settings: ITransactionSettings,
    isFirstLoad?: boolean
  }, { dispatch }) => {
    dispatch(TransactionsSliceActions.getTransactionsRequest({ isFirstLoad }));
    const transactions = await TransactionsService.fetchTransactions(settings);
    if (!transactions) {
      return dispatch(TransactionsSliceActions.getTransactionsRequestFailure());
    }
    return dispatch(TransactionsSliceActions.getTransactionsRequestSuccess(transactions));
  }
);

export const refundTransaction = createAsyncThunk(
  ETransactionsThunks.RefundTransaction,
  async ({ payment_id, refund_comment }: {
    payment_id: number,
    refund_comment: string
  }, { dispatch }) => {
    const { error, message } = await TransactionsService.refundTransaction(payment_id, refund_comment);
    if (error) {
      return dispatch(openNotification({
        type: 'error',
        description: error
      }));
    }
    dispatch(TransactionsSliceActions.updateTransactionInfo(payment_id));
    return dispatch(openNotification({
      type: 'success',
      description: message
    }));
  }
);

export const getReceiptUrl = createAsyncThunk(
  ETransactionsThunks.GetReceiptUrl,
  async ({ payment_id }: { payment_id: string | number }, { dispatch }) => {
    const { error, receipt_url } = await TransactionsService.getReceiptUrl(payment_id);
    if (!receipt_url) {
      dispatch(openNotification({
        type: 'error',
        description: error
      }));
      return null;
    }
    return receipt_url;
  }
);

export const saveTransactionsSetting = createAsyncThunk(
  ETransactionsThunks.SaveTransactionsSetting,
  async ({ settings }: { settings: ITransactionSettings }, { dispatch }) => {
    dispatch(TransactionsSliceActions.setSettings(settings));
    return dispatch(getTransactions({ settings }));
  }
);

export const clearTransactions = createAsyncThunk(
  ETransactionsThunks.ClearTransactions,
  async (_, { dispatch }) => {
    return dispatch(TransactionsSliceActions.clearTransactions());
  }
);
