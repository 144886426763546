import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { CustomButton } from '@ppmcore/seven-ppm-core-shared-components-react';

// Styles
import './conversation-notifications.scss';

type IConversationNotificationsProps = {
  title: string;
  text: string;
  navigateAction: string;
  navigateActionText?: string;
  additionAction?: string;
  additionActionText?: string;
  onNavigateAction?: () => void;
  onAdditionAction?: () => void;
}

export const ConversationNotifications: FC<IConversationNotificationsProps> = ({
  title,
  text,
  navigateAction,
  navigateActionText,
  additionAction,
  additionActionText,
  onNavigateAction = () => {},
  onAdditionAction = () => {}
}: IConversationNotificationsProps): JSX.Element => {

  const navigate = useNavigate();

  const navigateBtn = () => {
    localStorage.removeItem('return_pathname');
    onNavigateAction();
    navigate(navigateAction);
  }

  const navigateAdditionBtn = () => {
    localStorage.removeItem('return_pathname');
    onAdditionAction();
    if (additionAction) {
      navigate(additionAction)
    }
  }

  return (<div className={ 'conversation-notifications-page' }>
    <div className={ 'conversation-notifications-page-inside' }>
      <div className={ 'notification-title' }>{ title }</div>
      <div className={ 'notification-text' }>{ text }</div>
      <div className={ 'actions' }>
        { additionAction && <CustomButton mode={ 'dark' } type={ 'link' }
                                          onClick={ navigateAdditionBtn }>{ additionActionText }</CustomButton> }
        <CustomButton mode={ 'dark' } type={ 'primary' }
                      onClick={ navigateBtn }>{ navigateActionText ?? 'OK' }</CustomButton>
      </div>
    </div>
  </div>);
}
