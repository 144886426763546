import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

// Shared UI Library Components
import { CardElement, useWindowHeight } from '@ppmcore/seven-ppm-core-shared-components-react';

// Components
import { Footer } from '../../modules/footer/footer';

// Styles
import './browser-not-supported.scss';

// Store entities
import { getStaticPages } from '../../store/static-pages/static-pages.thunks';

export const BrowserNotSupported = () => {
  // set up window resize handler
  useWindowHeight();

  const dispatch = useDispatch<any>();

  useEffect(() => {
    dispatch(getStaticPages());
  }, []);

  return (<div className={ 'browser-not-supported' }>
    <div className={ 'browser-not-supported-inside' }>
      <div className={ 'logo-section' }>
        <img className={ 'logo' } src={ '/assets/images/logo.svg' } alt={ '' }/>
      </div>
      <CardElement cardTitle={ 'Browser is not supported. Please install a newer version of your browser.' }>
        <div className={ 'page-subtitle' }>Please, use browser versions that are not older than the following:</div>
        <div className={ 'browsers-items' }>
          <div className={ 'browsers-item' }>
            <img src={ '/assets/images/browsers/chrome.png' } alt=''/>
            <div className={ 'item-name' }>Google Chrome</div>
            <div className={ 'item-version' }>Ver. 88</div>
          </div>
          <div className={ 'browsers-item' }>
            <img src={ '/assets/images/browsers/safari.png' } alt=''/>
            <div className={ 'item-name' }>Safari</div>
            <div className={ 'item-version' }>Ver. 14</div>
          </div>
          <div className={ 'browsers-item' }>
            <img src={ '/assets/images/browsers/firefox.png' } alt=''/>
            <div className={ 'item-name' }>Firefox</div>
            <div className={ 'item-version' }>Ver. 78</div>
          </div>
        </div>
        <div className={ 'browsers-items two-items' }>
          <div className={ 'browsers-item' }>
            <img src={ '/assets/images/browsers/opera.png' } alt=''/>
            <div className={ 'item-name' }>Opera</div>
            <div className={ 'item-version' }>Ver. 79</div>
          </div>
          <div className={ 'browsers-item' }>
            <img src={ '/assets/images/browsers/edge.png' } alt=''/>
            <div className={ 'item-name' }>Edge</div>
            <div className={ 'item-version' }>Ver. 88</div>
          </div>
        </div>
      </CardElement>
    </div>
    <Footer/>
  </div>);
}
