import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ICallInfo, ICallProcess, TCallState, TCallStatus } from '../../interfaces/call.interfaces';
import { ICallMessage } from '../../interfaces/chat.interfaces';

export interface ICallState {
  callProcess: ICallProcess | null;
  callState: TCallState;
  callProcessAudio: boolean | null;
  callProcessVideo: boolean | null;
  loading: boolean;
  error: boolean;
  payPerSessionTimeLeftDialog: boolean;
  messages: ICallMessage[];
  hasNewMessage: boolean;
  loadingMessage: boolean;
  errorMessage: string | null;
  callChatOpen: boolean;
}

const initialState: ICallState = {
  callProcess: null,
  callState: 'call',
  callProcessAudio: null,
  callProcessVideo: null,
  loading: false,
  error: false,
  payPerSessionTimeLeftDialog: false,
  messages: [],
  hasNewMessage: false,
  loadingMessage: false,
  errorMessage: null,
  callChatOpen: false
};

export const callSlice = createSlice({
  name: 'callProcessSlice',
  initialState,
  reducers: {
    getCallRequest(state) {
      state.loading = true;
      state.error = false;
    },
    getCallSuccess: (state, action: PayloadAction<ICallInfo>) => {
      state.callProcess = action.payload?.call ?? null;
      state.loading = false;
      state.error = false;
    },
    getCallFailure: (state) => {
      state.loading = false;
      state.error = true;
    },
    createCallRequest(state) {
      state.loading = true;
      state.error = false;
    },
    createCallSuccess: (state, action: PayloadAction<{ call_id: number }>) => {
      state.callProcess = { call_id: action.payload.call_id };
      state.loading = false;
      state.error = false;
    },
    createCallFailure: (state) => {
      state.loading = false;
      state.error = true;
    },
    updateCallRequest(state) {
      state.loading = true;
      state.error = false;
    },
    updateCallSuccess: (state, { payload: callProcess }: PayloadAction<ICallProcess>) => {
      state.callProcess = { ...state?.callProcess, ...callProcess };
      state.loading = false;
      state.error = false;
    },
    updateCallFailure: (state) => {
      state.loading = false;
      state.error = true;
    },
    startCallRequest(state) {
      state.loading = true;
      state.error = false;
    },
    startCallSuccess: (state, action: PayloadAction<ICallProcess>) => {
      state.callProcess = action.payload;
      state.loading = false;
      state.error = false;
    },
    startCallFailure: (state) => {
      state.loading = false;
      state.error = true;
    },
    rejectCallRequest(state) {
      state.loading = true;
      state.error = false;
    },
    rejectCallSuccess: (state, action: PayloadAction<ICallProcess>) => {
      state.callProcess = action.payload;
      state.loading = false;
      state.error = false;
    },
    rejectCallFailure: (state) => {
      state.loading = false;
      state.error = true;
    },
    setCallState: (state, { payload: callState }: PayloadAction<TCallState>) => {
      state.callState = callState;
    },
    changeDeviceStatusRequest(state) {
      state.loading = true;
      state.error = false;
    },
    changeDeviceStatusSuccess: (state, action: PayloadAction<ICallProcess>) => {
      state.callProcess = action.payload;
      state.loading = false;
      state.error = false;
    },
    changeDeviceStatusFailure: (state) => {
      state.loading = false;
      state.error = true;
    },
    changePayPerSessionTimeLeftDialog: (state, action: PayloadAction<boolean>) => {
      state.payPerSessionTimeLeftDialog = action.payload;
    },
    changeCallProcessAudio: (state, action: PayloadAction<boolean>) => {
      state.callProcessAudio = action.payload;
    },
    changeCallProcessVideo: (state, action: PayloadAction<boolean>) => {
      state.callProcessVideo = action.payload;
    },
    changeCallStatus: (state, action: PayloadAction<TCallStatus>) => {
      state.callProcess = state.callProcess ? { ...state.callProcess, call_status: action.payload } : null;
    },
    //... call reducers
    sendCallMessageRequest(state) {
      state.loadingMessage = true;
      state.errorMessage = null;
    },
    sendCallMessageRequestSuccess: (state, { payload: message }: PayloadAction<ICallMessage>) => {
      state.messages = [message, ...state.messages];
      state.loadingMessage = false;
      state.errorMessage = null;
    },
    setMessagesList: (state, { payload: messages }: PayloadAction<ICallMessage[]>) => {
      state.messages = messages;
    },
    sendCallMessageRequestFailure: (state, { payload }: PayloadAction<string>) => {
      state.loadingMessage = false;
      state.errorMessage = payload;
    },
    addCallMessageFromSocket: (state, { payload: message }: PayloadAction<ICallMessage>) => {
      state.messages = [message, ...state.messages];
      state.hasNewMessage = !state.callChatOpen;
    },
    changeChatSectionStatus: (state, action: PayloadAction<boolean>) => {
      state.callChatOpen = action.payload;
      state.hasNewMessage = false;
    },
    clearState: (state) => {
      state.callProcess = null;
      state.callState = 'call';
      state.callProcessAudio = null;
      state.callProcessVideo = null;
      state.loading = false;
      state.error = false;
      state.messages = [];
      state.loadingMessage = false;
      state.errorMessage = null;
      state.callChatOpen = false;
    },
  }
});

export const CallSliceActions = callSlice.actions;
