import { createSelector } from '@reduxjs/toolkit';

// Models
import { INotificationsSettingsState } from './notifications-settings.slice';
import { RootState } from '../index';

/**
 A selector function to retrieve the notifications settings data from the store
 @function
 @param {RootState} state - The root state of the Redux store
 @returns {INotificationsSettingsState} The notifications settings state object from the Redux store
 */
const selectNotificationsSettingsState = (state: RootState): INotificationsSettingsState => state.notificationsSettings;

/**
 A memoized selector function to retrieve the notifications settings data from the store
 @function
 @type {import('@reduxjs/toolkit').OutputSelector<RootState, INotificationsSettingsState, (res: INotificationsSettingsState) => INotificationsSettingsState>}
 */
export const getNotificationsSettings = createSelector(
  [selectNotificationsSettingsState],
  (state) => state
);
