import React, { Dispatch, FC, useEffect, useState } from 'react';

// Styles
import './sidebar-widget.scss';

// Components
import { ExpertList } from '../../../../shared/ui-components/expert';

// Models
import { ICoreState } from '../../../../store/core/core.slice';
import { TExpertsList } from '../../../../store/experts/experts.slice';
import { ISidebarState } from '../../../../store/sidebar/sidebar.slice';
import { SidebarMenu } from '../sidebar-menu/sidebar-menu';

interface ISidebarProps {
  sidebarOpened: boolean;
  hideSidebarActionBtn: boolean;
  setSidebarOpened: Dispatch<boolean>;
  sidebarState: ISidebarState;
  coreState?: ICoreState;
}

export const SidebarWidget: FC<ISidebarProps> = (
  { coreState, sidebarOpened, hideSidebarActionBtn, sidebarState, setSidebarOpened }: ISidebarProps
): JSX.Element => {
  const [type, setType] = useState<TExpertsList | null>(null);
  const currentPath = coreState?.path.current ?? '';

  const toggleSidebar = (): void => {
    setSidebarOpened(!sidebarOpened);
  }

  const navigateToLanding = (): void => {
    window.open('https://paypertok.com', '_blank');
  }

  useEffect(() => {
    if (sidebarState.type === 'history') {
      setType('history');
      return;
    }
    if (sidebarState.type === 'home' || sidebarState.type === 'business-information') {
      setType('all');
      return;
    }
    if (sidebarState.type === 'favorites') {
      setType('favorites');
      return;
    }
  }, [sidebarState.type]);

  return (
    <aside className={ "sidebar-widget" + (sidebarOpened ? '' : ' close-sidebar') }>
      <div className="sidebar-widget-header">

      </div>
      <div className="sidebar-widget-body">
        { !!type &&
          <ExpertList hidden={ !sidebarOpened } type={ type } navSuffix={ type === 'history' ? 'history' : '' }/> }
      </div>
      <div className="sidebar-widget-footer">
        <SidebarMenu sidebarState={ sidebarState } sidebarOpened={ sidebarOpened } currentPath={ currentPath }/>
      </div>
    </aside>
  )
};
