import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';

// Shared UI Library Components
import { SpinElement } from '@ppmcore/seven-ppm-core-shared-components-react';

// Components
import { Subscriber } from '../subscriber/subscriber';
import { Publisher } from '../publisher/publisher';
import { ConsultationInfo } from '../../../../shared/payment-components';
import { NextPayment } from '../next-payment/next-payment';
import { CheckoutModal } from '../../../../modals/checkout-modal/checkout-modal';
import { TimeLeftMessage } from '../time-left-message/time-left-message';
import { ConversationChat } from '../conversation-chat/conversation-chat';

// Thunks
import {
  cancelNextPayment,
  changePayPerSessionTimeLeftDialog,
  chargeChatTimeslot
} from '../../../../store/chat/chat.thunks';

// Selectors
import { getLastMessageData } from '../../../../store/socket/socket.selectors';
import { getCurrencyState } from '../../../../store/currency/currency.selectors';
import { getChatState } from '../../../../store/chat/chat.selectors';

// Interfaces
import { IChatProcess, INextConversationPayment, TChatState } from '../../../../interfaces/text-chat.interfaces';
import { IUserData } from '../../../../interfaces/user.interfaces';

// Enums
import { EWebSocketTypes } from '../../../../enums/web-socket-types.enums';

// Styles
import './chat-content.scss';

interface IVideoContentProps {
  chatData: IChatProcess;
  userData: IUserData;
  chatState: TChatState;
}

export const ChatContent: FC<IVideoContentProps> = (
  { chatData, userData, chatState }: IVideoContentProps
) => {

  const dispatch = useDispatch<any>();
  const temporaryToken = localStorage.getItem('temporary_token');

  const { payPerSessionTimeLeftDialog } = useSelector(getChatState);
  const lastMessage = useSelector(getLastMessageData);
  const { currentCurrency, company_account_id } = useSelector(getCurrencyState);

  const [checkoutOpen, setCheckoutOpen] = useState<boolean>(false);
  const [nextPaymentOpen, setNextPaymentOpen] = useState<boolean>(false);
  const [nextPaymentData, setNextPaymentData] = useState<INextConversationPayment | null>(null);
  const [showTimeLeftMessage, setShowTimeLeftMessage] = useState<boolean>(false);

  useEffect(() => {
    const showPayPerSessionTimeLeftDialog = localStorage.getItem('showPayPerSessionTimeLeftDialog');
    if (showPayPerSessionTimeLeftDialog && showPayPerSessionTimeLeftDialog === 'yes') {
      dispatch(changePayPerSessionTimeLeftDialog(true));
    }

    return () => {
      localStorage.removeItem('showPayPerSessionTimeLeftDialog');
    }
  }, []);

  useEffect(() => {
    if (lastMessage?.type === EWebSocketTypes.NeedConversationUpdatePayment) {
      setNextPaymentData(lastMessage.data);
      setNextPaymentOpen(true);
    }
  }, [lastMessage]);

  useEffect(() => {
    if (payPerSessionTimeLeftDialog) setShowTimeLeftMessage(payPerSessionTimeLeftDialog);
  }, [payPerSessionTimeLeftDialog]);

  useEffect(() => {
    if (chatData.is_socket_next_charge_sent) {
      setNextPaymentData(
        {
          conversation_id: chatData.conversation_id,
          conversation_finished_after: chatData.conversation_finished_after ?? 0,
          additional_minutes: chatData.conversation_prebooked_time ?? 0,
          finished_at: dayjs().add(chatData.conversation_finished_after || 0, 'seconds').toISOString()
        }
      );
      setNextPaymentOpen(true);
    }
  }, [chatData]);

  const onPaid = (payment_method_nonce: string) => {
    if (chatData?.conversation_id) {
      dispatch(chargeChatTimeslot({
        conversation_id: chatData.conversation_id,
        payment_method_nonce,
        temporary_token: temporaryToken ?? ''
      }))
        .then(() => setCheckoutOpen(false));
    }
  }

  const onCheckoutCancel = () => {
    setCheckoutOpen(false);
    setNextPaymentOpen(true);
  }

  const makePayment = () => {
    setNextPaymentOpen(false);
    setCheckoutOpen(true);
  }

  const cancelMakePayment = () => {
    dispatch(cancelNextPayment({
      conversation_id: chatData.conversation_id,
      temporary_token: temporaryToken ?? ''
    })).then(() => setNextPaymentOpen(false));
  }

  const closeTimeLeftMessage = () => {
    dispatch(changePayPerSessionTimeLeftDialog(false))
      .then(() => {
        setShowTimeLeftMessage(false);
        localStorage.setItem('showPayPerSessionTimeLeftDialog', 'no')
      });
  }

  return (<div className={ 'chat-content' }>

    <div className={ 'chat-content--main' }>

      { showTimeLeftMessage &&
        <TimeLeftMessage onCloseDialog={ closeTimeLeftMessage }/> }

      { (chatState === 'internet-connection') && <div className={ 'bad-connection-spinner' }><SpinElement/></div> }

      <Publisher userData={ userData }/>

      <Subscriber workerData={ chatData.worker }/>

    </div>

    <div className={ 'chat-content--chat' }>
      <ConversationChat conversation_id={ chatData.conversation_id }/>
    </div>

    { nextPaymentOpen && nextPaymentData
      && <NextPayment
        finished_at={ nextPaymentData.finished_at }
        additionalMinutes={ nextPaymentData.additional_minutes }
        makePayment={ makePayment }
        cancelMakePayment={ cancelMakePayment }/> }

    { chatData.worker?.id && chatData.conversation_prebooked_time &&
      <CheckoutModal
        open={ checkoutOpen }
        currency={ currentCurrency }
        company_account_id={ company_account_id }
        mode={ 'white' }
        consultation_type={ chatData.conversation_type ?? 'video_call' }
        worker_id={ chatData.worker.id }
        rate={ chatData.worker_rate?.value }
        onCancel={ onCheckoutCancel }
        btn_text={ `Pay ${ currentCurrency.symbol }${ ((chatData.amount || 0) / 100).toFixed(2) }` }
        onPaid={ onPaid }>
        <ConsultationInfo
          mode={ 'white' }
          rate={ `${ chatData.worker_rate?.text }` }
          rate_time={ chatData.worker_rate?.call_duration ?? null }
          name={ `${ chatData.worker.first_name } ${ chatData.worker.last_name }` }
          type={ chatData.conversation_type }
          prebooked_time={ chatData.conversation_prebooked_time }
        />
      </CheckoutModal> }
  </div>);
}
