import React from 'react';
import { NavLink } from 'react-router-dom';
import { useWindowSize } from '@uidotdev/usehooks';

// Shared UI Library Components
import { CustomIcon, CustomTooltip, EIconName } from '@ppmcore/seven-ppm-core-shared-components-react';

// Styles
import './profile-left-menu.scss';

export const ProfileLeftMenu = () => {
  const { width: windowWidth } = useWindowSize();

  const menuItems = [
    {
      link: '/profile-settings',
      title: 'Personal Data',
      icon: EIconName.PersonOff,
      activeIcon: EIconName.PersonOn
    },
    {
      link: '/profile-settings/change-password',
      title: 'Change Password',
      icon: EIconName.Lock,
      activeIcon: EIconName.LockFill,
    },
    {
      link: '/profile-settings/change-email',
      title: 'Change Email',
      icon: EIconName.Mail,
      activeIcon: EIconName.MailFill,
    },
    {
      link: '/profile-settings/notification-settings',
      title: 'Notification Settings',
      icon: EIconName.EditNotificationOff,
      activeIcon: EIconName.EditNotificationOn,
    },
    {
      link: '/profile-settings/payment-methods',
      title: 'Payment Methods',
      icon: EIconName.PaymentMethodsOff,
      activeIcon: EIconName.PaymentMethodsOn,
    },
  ]

  return (<div className="profile-left-menu">
    { menuItems.map((item, index) =>
      <NavLink key={ index } to={ item.link } end>
        <CustomTooltip title={ item.title } placement={ (windowWidth && windowWidth <= 1024) ? 'bottom' : 'right' }>
          <div className={ 'icon-section' }>
            <CustomIcon name={ item.icon }/>
            <CustomIcon name={ item.activeIcon }/>
          </div>
          <span>{ item.title }</span>
        </CustomTooltip>
      </NavLink>
    ) }
  </div>);
}
