import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Models
import { TWidgetType } from '../../types/widget.types';

export interface IWidgetState {
  type: TWidgetType;
  companyHash: string;
}

const initialState: IWidgetState = {
  type: localStorage.getItem('widgetType') as TWidgetType || 'popup',
  companyHash: '',
};

export const widgetSlice = createSlice({
  name: 'widget',
  initialState,
  reducers: {
    setWidgetSettings: (state, { payload: settings }: PayloadAction<{ type: TWidgetType, companyHash: string }>) => {
      state.type = settings.type;
      state.companyHash = settings.companyHash;
    },
  }
});

export const WidgetSliceActions = widgetSlice.actions;
