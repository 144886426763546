import { FC } from 'react';
import dayjs from 'dayjs';
import Linkify from 'linkify-react';

// Styles
import './chat-item.scss';

// Models
import { ICallMessage } from '../../../../interfaces/chat.interfaces';

interface IMessageItemProps {
  message: ICallMessage;
}

export const ChatItem: FC<IMessageItemProps> = (
  { message: { sender_first_name, sender_last_name, message_time, message, sender_type } }: IMessageItemProps
) => {

  const isMy = sender_type === 'user';
  const messageClass = isMy ? 'my-message' : '';

  return (
    <div className={ `chat-item ${ messageClass }` }>
      <div className="chat-item-info">
        <div className="chat-item-info--header">
          <div className="header-item header-item--name">
            { sender_first_name } { sender_last_name }, { dayjs(message_time).format('hh:mm A') }
          </div>
        </div>
        <div className="chat-item-info--body">
          <div className="body-message">
            <Linkify options={ { target: '_blank' } }>{ message }</Linkify>
          </div>

          {/*<div className="body-files"></div> TODO in future versions */ }
        </div>
      </div>
    </div>
  );
};
