import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Interfaces
import { ICurrency } from '../../interfaces/company.interfaces';

export interface ICurrencyState {
  currencies: ICurrency[];
  currentCurrency: ICurrency;
  company_account_id: string | null;
  loadingCurrent: boolean;
  errorCurrent: any | null;
  loadingCurrencies: boolean;
  errorCurrencies: any | null;
}

const initialState: ICurrencyState = {
  currencies: [],
  currentCurrency: {
    name: 'USD',
    symbol: '$'
  },
  company_account_id: null,
  loadingCurrent: true,
  errorCurrent: null,
  loadingCurrencies: true,
  errorCurrencies: null,
};

export const currencySlice = createSlice({
  name: 'currency',
  initialState,
  reducers: {
    currentCurrencyRequest(state) {
      state.loadingCurrent = true;
      state.errorCurrent = null;
    },
    currentCurrencyRequestSuccess: (state, { payload: {
      currentCurrency, company_account_id
    } }: PayloadAction<{
      currentCurrency: ICurrency, company_account_id?: string | null
    }>) => {
      state.currentCurrency = currentCurrency;
      state.company_account_id = company_account_id || null;
      state.loadingCurrent = false;
      state.errorCurrent = null;
    },
    currentCurrencyRequestFailure: (state, { payload: error }: PayloadAction<string>) => {
      state.loadingCurrent = false;
      state.errorCurrent = error;
    },
    currenciesListRequest(state) {
      state.loadingCurrencies = true;
      state.errorCurrencies = null;
    },
    currenciesListRequestSuccess: (state, { payload: currencies }: PayloadAction<ICurrency[]>) => {
      state.currencies = currencies;
      state.loadingCurrencies = false;
      state.errorCurrencies = null;
    },
    currenciesListRequestFailure(state, { payload: error }: PayloadAction<string>) {
      state.loadingCurrencies = false;
      state.errorCurrencies = error;
    },
    updateCurrentCurrencyFromSocket(state, { payload: currentCurrency }: PayloadAction<ICurrency>) {
      if (state.currentCurrency.symbol === currentCurrency.symbol) return;
      state.currentCurrency = currentCurrency;
    },
  }
});

export const CurrencySliceActions = currencySlice.actions;
