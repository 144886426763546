import React, { useState, useEffect, FC } from 'react';
import { useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
// @ts-ignore
import timer_to_down_worker from './timer-to-down-worker.js';

// Thunks
import { changePayPerSessionTimeLeftDialog } from '../../../../store/call/call.thunks';

// Styles
import './timer-to-down.scss';

// Models
import { ICurrency } from '../../../../interfaces/company.interfaces';

interface ITimerProps {
  initialSeconds?: number;
  callRate: number;
  currency: ICurrency;
  call_finished_after?: number;
}

const timerWorker = new Worker(timer_to_down_worker);

export const TimerToDown: FC<ITimerProps> = (
  {
    initialSeconds = 0,
    callRate,
    currency,
    call_finished_after
  }: ITimerProps
): JSX.Element => {

  const dispatch = useDispatch<any>();

  const [webWorkerTime, setWebWorkerTime] = useState(initialSeconds);
  const payPerSessionTimeLeftDialog = 300;

  useEffect(() => {
    timerWorker.postMessage({ turn: 'on', initialSeconds: initialSeconds });
  }, [initialSeconds]);

  useEffect(() => {
    const showPayPerSessionTimeLeftDialog = localStorage.getItem('showPayPerSessionTimeLeftDialog');
    if (webWorkerTime <= payPerSessionTimeLeftDialog && !showPayPerSessionTimeLeftDialog && (call_finished_after && call_finished_after > 299)) {
      localStorage.setItem('showPayPerSessionTimeLeftDialog', 'yes')
      dispatch(changePayPerSessionTimeLeftDialog(true));
    }
    if (webWorkerTime <= 0) {
      timerWorker.postMessage({ turn: 'off', initialSeconds: 0 });
    }
  }, [webWorkerTime]);

  useEffect(() => {
    timerWorker.onmessage = ({ data: { time } }) => {
      setWebWorkerTime(time);
    };
  }, []);

  dayjs.extend(duration);
  dayjs.extend(relativeTime);

  const durationFormat = dayjs.duration(webWorkerTime, 'seconds');
  const formattedTimeHour = parseInt(durationFormat.asHours() + '');
  const formattedTimeMin = durationFormat.format('mm');
  const formattedTimeSec = durationFormat.format('ss');

  return (<div className={'timer-block'}>
    <div className={'timer'}>{formattedTimeHour > 0 && `${formattedTimeHour} h `} {formattedTimeMin} min {formattedTimeSec} sec</div>
    <span></span>
    {callRate && <div className={'price'}>{ currency.symbol }{callRate.toFixed(2)}</div>}
  </div>);
};
