import React, {
  forwardRef,
  HTMLProps,
  useState
} from 'react';
import { useDispatch } from 'react-redux';

// Shared UI Library Components
import {
  CustomIcon,
  EIconName,
  ListEllipsis,
  Loader,
  UserAvatar,
  CustomTooltip
} from '@ppmcore/seven-ppm-core-shared-components-react';

// Styles
import './expert-profile-header.scss';

// Components
import { ExpertProfileMenu } from '../expert-profile-menu/expert-profile-menu';
import { DeleteFavoriteModal } from '../../../../modals/delete-favorite-modal/delete-favorite-modal';
import { ExpertStatusNotifier } from '../expert-status-notifier/expert-status-notifier';

// Store Entities
import { bookmarkWorker } from '../../../../store/currentExpert/currentExpert.thunks';

// Models
import { IExpertProfile } from '../../../../interfaces/experts.interfaces';
import { TSidebarType } from '../../../../types/sidebar.types';

type TExpertProfileHeaderProps = HTMLProps<HTMLDivElement> & {
  expert: IExpertProfile;
  sidebarType: TSidebarType;
}

export const ExpertProfileHeader = forwardRef<HTMLDivElement, TExpertProfileHeaderProps>((
  { expert, sidebarType, ...props }: TExpertProfileHeaderProps, ref
) => {

  const [deleteFavoriteOpen, setDeleteFavoriteOpen] = useState<boolean>(false);
  const [makingFavorite, setMakingFavorite] = useState<boolean>(false);

  const dispatch = useDispatch<any>();

  const token = localStorage.getItem('token');
  const specialities = expert.specialities?.map((s) => s.name) ?? [];

  const toggleDeleteFavoriteModal = (): void => {
    setDeleteFavoriteOpen(!deleteFavoriteOpen);
  }

  const makeFavorite = async (): Promise<void> => {
    const currentStatus = expert.is_bookmarked;
    setMakingFavorite(true);
    if (currentStatus) {
      toggleDeleteFavoriteModal();
    }
    await dispatch(bookmarkWorker({
      id: expert.id,
      bookmark: !currentStatus,
      isFavoritesSidebarState: sidebarType === 'favorites'
    }));
    setMakingFavorite(false);
  }

  const checkFavoriteStatus = (): void => {
    if (expert.is_bookmarked) {
      toggleDeleteFavoriteModal();
      return;
    }
    makeFavorite();
  }

  return (
    <div className="expert-profile-header" ref={ ref } { ...props }>

      <DeleteFavoriteModal open={ deleteFavoriteOpen }
                           onSubmit={ makeFavorite }
                           onCancel={ toggleDeleteFavoriteModal }/>

      <div className="expert-profile-header--item item-card">
        <div className="item-card--avatar">
          <UserAvatar avatarUrl={ expert.photo } sizeClass={ 'medium' }/>
        </div>
        <div className="item-card--info">
          <div className="info-name">
            <div className="info-name--text">
              <CustomTooltip title={ `${ expert.first_name } ${ expert.last_name }` }>
                { expert.first_name } { expert.last_name }
              </CustomTooltip>
            </div>
            {
              (token && (!!expert?.is_active && !expert?.is_blocked)) && <CustomTooltip placement={ 'bottom' }
                                                                                        title={ expert.is_bookmarked ? 'Remove from Favorites' : 'Add to Favorites' }
                                                                                        className="info-name--mark">
                { !makingFavorite && <CustomIcon name={ expert.is_bookmarked ? EIconName.LikeFill : EIconName.Like }
                                                 onClick={ checkFavoriteStatus }/> }
                { makingFavorite && <Loader/> }
              </CustomTooltip>
            }
          </div>
          <div className="info-speciality">
            <ListEllipsis items={ specialities } hasCounter={ true }/>
          </div>
        </div>
      </div>
      { (!expert?.is_active || expert?.is_blocked) && <div className="expert-profile-header--item item-notifier">
        <ExpertStatusNotifier/>
      </div> }
      <div className="expert-profile-header--item item-menu">
        <ExpertProfileMenu expert={ expert }/>
      </div>
    </div>
  );
});
