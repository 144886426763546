// Shared UI Library Components
import { CustomIcon, EIconName } from "@ppmcore/seven-ppm-core-shared-components-react";

export const CCounsellorPageMenuList = [
  {
    label: 'About',
    path: `expert/{id}`,
    icon: <CustomIcon name={ EIconName.PersonOff }/>,
    activeIcon: <CustomIcon name={ EIconName.PersonOn }/>,
    withBg: false,
    disabled: false,
    actionType: 'profile',
    hide: false,
    isSecure: false,
    disabledTooltip: '',
  },
  {
    label: 'Rates',
    path: 'expert/{id}/rates',
    icon: <CustomIcon name={ EIconName.Income }/>,
    activeIcon: <CustomIcon name={ EIconName.IncomeFill }/>,
    withBg: false,
    disabled: false,
    actionType: 'rates',
    hide: false,
    isSecure: false,
    disabledTooltip: '',
  },
  {
    label: 'Book Appointment',
    path: 'expert/{id}/book-appointment',
    icon: <CustomIcon name={ EIconName.Calendar }/>,
    activeIcon: <CustomIcon name={ EIconName.CalendarFill }/>,
    withBg: false,
    disabled: false,
    actionType: 'book-appointment',
    hide: false,
    isSecure: false,
    disabledTooltip: 'Only for registered users',
  },
  {
    label: 'History',
    path: 'expert/{id}/history',
    icon: <CustomIcon name={ EIconName.HistoryOff }/>,
    activeIcon: <CustomIcon name={ EIconName.HistoryOn }/>,
    withBg: false,
    disabled: false,
    actionType: 'history',
    hide: false,
    isSecure: false,
    disabledTooltip: 'Only for registered users',
  },
  {
    label: 'Correspondence',
    path: 'expert/{id}/messages',
    icon: <CustomIcon name={ EIconName.Message }/>,
    activeIcon: <CustomIcon name={ EIconName.MessageFill }/>,
    withBg: false,
    disabled: false,
    actionType: 'messages',
    hide: false,
    isSecure: false,
    disabledTooltip: 'Only for registered users',
  },
  {
    label: 'Transactions',
    path: 'expert/{id}/transactions',
    icon: <CustomIcon name={ EIconName.TransactionsOff }/>,
    activeIcon: <CustomIcon name={ EIconName.TransactionsOn }/>,
    withBg: false,
    disabled: false,
    actionType: 'transactions',
    hide: false,
    isSecure: false,
    disabledTooltip: 'Only for registered users',
  },
];
