import React, { FC } from 'react';

// Shared UI Library Components
import { InputReadOnly, CustomButton } from '@ppmcore/seven-ppm-core-shared-components-react';

// Interfaces
import { IUserData } from '../../../../interfaces/user.interfaces';

// Styles
import './user-view.scss';

interface IUserViewProps {
  userData: IUserData | null;
  changeMode?: () => void;
}

export const UserView: FC<IUserViewProps> = ({userData, changeMode = () => {}}: IUserViewProps) => {
  return (<div className="user-view">
    <div className="form-top-actions">
      <CustomButton text={'Edit'} type="text" size={'small'} onClick={() => changeMode()} />
    </div>

    <div className={'profile-title'}>General Data</div>

    <div className={'profile-row'}>
      <InputReadOnly label={'First Name'} value={userData?.first_name ?? null} />
      <InputReadOnly label={'Last Name'} value={userData?.last_name ?? null} />
    </div>

    <div className={'profile-row'}>
      {/*<InputReadOnly label={'Nickname'} value={userData?.username ?? null} />*/}
      <InputReadOnly label={'Email'} value={userData?.email} />
      <InputReadOnly label={'Phone Number'} value={userData?.phone} />
    </div>
  </div>);
}
