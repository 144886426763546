import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Models
import { ICallMessage } from '../../interfaces/chat.interfaces';
import { IChatInfo, IChatProcess, TChatState, TChatStatus } from '../../interfaces/text-chat.interfaces';

export interface IChatState {
  chatProcess: IChatProcess | null;
  chatState: TChatState;
  loading: boolean;
  error: boolean;
  payPerSessionTimeLeftDialog: boolean;
  messages: ICallMessage[];
  hasNewMessage: boolean;
  loadingMessage: boolean;
  errorMessage: string | null;
}

const initialState: IChatState = {
  chatProcess: null,
  chatState: 'chat',
  loading: false,
  error: false,
  payPerSessionTimeLeftDialog: false,
  messages: [],
  hasNewMessage: false,
  loadingMessage: false,
  errorMessage: null,
};

export const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    getChatRequest(state) {
      state.loading = true;
      state.error = false;
    },
    getChatSuccess: (state, action: PayloadAction<IChatInfo>) => {
      state.chatProcess = { ...action.payload?.conversation } ?? null;
      state.loading = false;
      state.error = false;
    },
    getChatFailure: (state) => {
      state.loading = false;
      state.error = true;
    },
    createChatRequest(state) {
      state.loading = true;
      state.error = false;
    },
    createChatSuccess: (state, action: PayloadAction<{ conversation_id: number }>) => {
      state.chatProcess = { conversation_id: action.payload.conversation_id };
      state.loading = false;
      state.error = false;
    },
    createChatFailure: (state) => {
      state.loading = false;
      state.error = true;
    },
    updateChatRequest(state) {
      state.loading = true;
      state.error = false;
    },
    updateChatSuccess: (state, { payload: chatProcess }: PayloadAction<IChatProcess>) => {
      state.chatProcess = { ...state.chatProcess, ...chatProcess };
      state.loading = false;
      state.error = false;
    },
    updateChatFailure: (state) => {
      state.loading = false;
      state.error = true;
    },
    startChatRequest(state) {
      state.loading = true;
      state.error = false;
    },
    startChatSuccess: (state, action: PayloadAction<IChatProcess>) => {
      state.chatProcess = action.payload;
      state.loading = false;
      state.error = false;
    },
    startChatFailure: (state) => {
      state.loading = false;
      state.error = true;
    },
    rejectChatRequest(state) {
      state.loading = true;
      state.error = false;
    },
    rejectChatSuccess: (state, action: PayloadAction<IChatProcess>) => {
      state.chatProcess = action.payload;
      state.loading = false;
      state.error = false;
    },
    rejectChatFailure: (state) => {
      state.loading = false;
      state.error = true;
    },
    setChatState: (state, { payload: callState }: PayloadAction<TChatState>) => {
      state.chatState = callState;
    },
    changePayPerSessionTimeLeftDialog: (state, action: PayloadAction<boolean>) => {
      state.payPerSessionTimeLeftDialog = action.payload;
    },
    changeChatStatus: (state, action: PayloadAction<TChatStatus>) => {
      state.chatProcess = state.chatProcess ? { ...state.chatProcess, conversation_status: action.payload } : null;
    },
    //... call reducers
    sendChatMessageRequest(state) {
      state.loadingMessage = true;
      state.errorMessage = null;
    },
    sendChatMessageRequestSuccess: (state, { payload: message }: PayloadAction<ICallMessage>) => {
      state.messages = [message, ...state.messages];
      state.loadingMessage = false;
      state.errorMessage = null;
    },
    sendChatMessageRequestFailure: (state, { payload }: PayloadAction<string>) => {
      state.loadingMessage = false;
      state.errorMessage = payload;
    },
    setMessagesList: (state, { payload: messages }: PayloadAction<ICallMessage[]>) => {
      state.messages = messages;
    },
    addChatMessageFromSocket: (state, { payload: message }: PayloadAction<ICallMessage>) => {
      state.messages = [message, ...state.messages];
      state.hasNewMessage = true;
    },
    clearState: (state) => {
      state.chatProcess = null;
      state.chatState = 'chat';
      state.loading = false;
      state.error = false;
      state.messages = [];
      state.loadingMessage = false;
      state.hasNewMessage = false;
      state.errorMessage = null;
    },
  }
});

export const ChatSliceActions = chatSlice.actions;
