import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Models
import { IChatInfo, IMessageItem, IMessageNotification } from '../../interfaces/chat.interfaces';

export interface IMessagesState {
  chat: IChatInfo | null;
  loading: boolean;
  error: string | null;

  total: number,
  messages: Array<IMessageItem | IMessageNotification>;
  loadingMessages: boolean;
  errorMessages: string | null;
}

const initialState: IMessagesState = {
  chat: null,
  loading: true,
  error: null,

  total: 0,
  messages: [],
  loadingMessages: true,
  errorMessages: null,
};

export const messagesSlice = createSlice({
  name: 'messages',
  initialState,
  reducers: {
    getChatInfoRequest(state) {
      state.loading = true;
      state.error = null;
    },
    getChatInfoRequestSuccess: (state, { payload }: PayloadAction<IChatInfo>) => {
      state.chat = payload;
      state.error = null;
      state.loading = false;
    },
    getChatInfoRequestFailure: (state, { payload }: PayloadAction<string>) => {
      state.loading = false;
      state.error = payload;
    },
    getMessagesListRequest(state) {
      state.loadingMessages = true;
      state.errorMessages = null;
    },
    getMessagesListRequestSuccess: (state, { payload: { messages, total, isLoadMore = false } }: PayloadAction<{
      messages: IMessageItem[],
      total: number,
      isLoadMore?: boolean
    }>) => {
      state.messages = isLoadMore ? [...state.messages, ...messages] : messages;
      state.total = total;
      state.loadingMessages = false;
      state.errorMessages = null;
    },
    getMessagesListRequestFailure: (state, { payload }: PayloadAction<string>) => {
      state.loadingMessages = false;
      state.errorMessages = payload;
    },
    sendPaidMessageRequest(state) {
      state.loadingMessages = true;
      state.errorMessages = null;
    },
    sendPaidMessageRequestSuccess: (state, {
      payload: {
        data: { chat, chat_messages },
        resent_message_id
      }
    }: PayloadAction<{
      data: {
        chat: IChatInfo,
        chat_messages: IMessageItem
      },
      resent_message_id?: number
    }>) => {
      state.chat = state.chat ? { ...state.chat, messages_total_cost: chat.messages_total_cost } : chat;
      state.messages = [chat_messages, ...(resent_message_id ? state.messages.map(message => message.id === resent_message_id ? {
        ...message,
        is_resent: true
      } : message) : state.messages)];
      state.total = state.total + 1;
      state.loadingMessages = false;
      state.errorMessages = null;
    },
    sendPaidMessageRequestFailure: (state, { payload }: PayloadAction<string>) => {
      state.loadingMessages = false;
      state.errorMessages = payload;
    },
    addPaidMessageFromSocket: (state, { payload }: PayloadAction<IMessageItem>) => {
      if (payload.chat_id !== state?.chat?.id) return;
      state.messages = [payload, ...state.messages.map(message => payload.replied_message_id?.id === message.id ? {
        ...message,
        is_message_replied: true,
        replied_message_id: payload.replied_message_id,
      } : message)];
      state.total = state.total + 1;
    },
    addPaidMessageNotificationFromSocket: (state, { payload }: PayloadAction<IMessageNotification>) => {
      if (payload.message_pinged.chat_id !== state?.chat?.id) return;
      state.messages = [payload, ...state.messages];
      state.total = state.total + 1;
    },
    clearMessages: (state) => {
      state.chat = null;
      state.loading = true;
      state.total = 0;
      state.error = null;
      state.messages = [];
      state.loadingMessages = true;
      state.errorMessages = null;
    },
  }
});

export const MessagesSliceActions = messagesSlice.actions;
