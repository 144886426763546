import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../index';
import { IChatFinishedState } from './chat-finished.slice';

/**
 A selector function to retrieve the text chat data from the store
 @function
 @param {RootState} state - The root state of the Redux store
 @returns {IChatFinishedState} The text chat finished state object from the Redux store
 */
const selectChatFinishedState = (state: RootState): IChatFinishedState => state.chatFinished

/**
 A memoized selector function to retrieve the text chat data from the store
 @function
 @type {import('@reduxjs/toolkit').OutputSelector<RootState, IChatFinishedState, (res: IChatFinishedState) => IChatFinishedState>}
 */
export const getChatFinishedState = createSelector(
  [selectChatFinishedState],
  (state): IChatFinishedState => state
);
