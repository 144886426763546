import React, { FC, SyntheticEvent, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

// Shared UI Library Components
import { SpinElement } from '@ppmcore/seven-ppm-core-shared-components-react';

// Styles
import './expert-list.scss';

// Components
import { ExpertItem } from '../expert-item/expert-item';
import { ExpertFilters } from '../expert-filters/expert-filters';

// Store Entities
import { getExpertsState } from '../../../../store/experts/experts.selectors';
import { fetchExpertsList, fetchExpertsListTempUser } from '../../../../store/experts/experts.thunks';
import { getCoreState } from '../../../../store/core/core.selectors';
import { getCurrentExpertState } from '../../../../store/currentExpert/currentExpert.selectors';

// Models
type TExpertList = 'all' | 'favorites' | 'history';

interface IExpertListProps {
  type: TExpertList;
  navSuffix?: string;
  hidden?: boolean;
}

let prevType: TExpertList | null = null;

export const ExpertList: FC<IExpertListProps> = (
  { navSuffix = '', hidden = false, type }: IExpertListProps
) => {
  const bodyRef = useRef<HTMLDivElement>(null);

  const params = useParams();
  const companyCode = useMemo(() => params?.companyCode, [params?.companyCode]);
  const personalLink = useMemo(() => params?.personalLink, [params?.personalLink]);

  const [activeExpertId, setActiveExpertId] = useState<number | null>(null);

  const {
    experts: { list, isLoad: loadingList, total, total_all },
    filters: expertFilters
  } = useSelector(getExpertsState);
  const { path } = useSelector(getCoreState);
  const { expert: currentExpertState } = useSelector(getCurrentExpertState);

  const navigate = useNavigate();
  const dispatch = useDispatch<any>();

  const isFavorite = type === 'favorites';

  const getExperts = ({ isLoadMore, offset, isChangeType = false }: {
    isLoadMore: boolean,
    offset: number,
    isChangeType?: boolean,
  }) => {
    if ((total !== null && list?.length >= total) && !isChangeType) return;
    dispatch(fetchExpertsList({
      filters: {
        ...expertFilters,
        offset,
        limit: 20,
        is_bookmarked: Number(isFavorite)
      },
      type,
      isLoadMore
    }));
  }

  const getExpertsTempUser = ({ isLoadMore, company_code, offset }: {
    isLoadMore: boolean,
    company_code: string,
    offset: number,
  }) => {
    if (total !== null && list?.length >= total) return;
    dispatch(fetchExpertsListTempUser({
      filters: { ...expertFilters, offset, limit: 20, is_bookmarked: Number(isFavorite) },
      company_code,
      type,
      isLoadMore
    }));
  }

  const onExpertClickHandler = (id: number, company_code: string, personal_link: string): void => {
    setActiveExpertId(id);
    let path = company_code ? `/${ company_code }/${ personal_link || '' }` : `/expert/${ id }${ type === 'favorites' ? '?type=favorites' : '' }`;
    if (navSuffix) {
      path += '/' + navSuffix;
    }
    navigate(path);
  }

  const onScrollListHandler = (event: SyntheticEvent): void => {
    const { scrollTop, scrollHeight, offsetHeight } = event.target as HTMLDivElement;
    if ((Math.abs(scrollTop) + offsetHeight + 50 < scrollHeight) || loadingList || (total && total <= list.length)) return;

    if (companyCode) {
      getExpertsTempUser({ ...expertFilters, isLoadMore: true, company_code: companyCode, offset: list.length });
      return;
    }
    getExperts({ ...expertFilters, isLoadMore: true, offset: list.length });
  }

  useEffect(() => {
    if (companyCode) {
      getExpertsTempUser({ ...expertFilters, isLoadMore: false, company_code: companyCode, offset: 0 });
      return;
    }
    if (prevType === type) return;
    prevType = type;
    getExperts({ ...expertFilters, isLoadMore: false, offset: 0, isChangeType: true });
  }, [type, companyCode]);

  useEffect(() => {
    return () => {
      prevType = null;
    };
  }, []);

  return (
    <div className="expert-list" hidden={ hidden }>
      <div className="expert-list--header">
        <ExpertFilters sidebarOpened={ true }
                       disabled={ !total_all }
                       expertFilters={ expertFilters }
                       type={ type }
                       loading={ loadingList }
        />

      </div>
      <div className="expert-list--body" ref={ bodyRef } onScroll={ onScrollListHandler }>
        {
          list.map((expert) => {
            const activeLinkValidation = personalLink ?
              path.current.includes(encodeURIComponent(personalLink)) :
              path.current.includes(`expert/${ expert.id }`);
            const isActive = (activeExpertId === expert.id && activeLinkValidation) || (currentExpertState?.id === expert.id && activeLinkValidation);
            return (
              <div key={ expert.id } className={ `body-item ${ isActive ? 'active-expert' : '' }` }
                   onClick={ () => {
                     onExpertClickHandler(expert.id, expert.company_code, expert.personal_link)
                   } }>
                <ExpertItem expertInfo={ expert }/>
              </div>
            )
          })
        }
        { loadingList && <SpinElement/> }
      </div>
      { (!list.length && !loadingList) &&
        <div className="expert-list--empty" ref={ bodyRef } onScroll={ onScrollListHandler }>
          { !!expertFilters?.search ? 'No matching data found' : 'No data to display' }
        </div> }
    </div>
  );
};
