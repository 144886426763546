import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../index';
import { AuthState } from './auth.slice';

/**
 A selector function to retrieve the user profile data from the store
 @function
 @param {RootState} state - The root state of the Redux store
 @returns {AuthState} The user profile state object from the Redux store
 */
const selectAuthState = (state: RootState): AuthState => state.auth;

/**
 A memoized selector function to retrieve the user profile data from the store
 @function
 @type {import('@reduxjs/toolkit').OutputSelector<RootState, UserProfileState, (res: AuthState) => AuthState>}
 */
export const getAuthState = createSelector(
  [selectAuthState],
  (state): AuthState => state
);
