// API
import { api } from '../configs/api/api';

// Models
import {
  ICompany,
  ICountry,
  ILanguage,
  ISingleCompany,
  ISpeciality,
  ISpecialization
} from '../interfaces/core.interfaces';
import { ICallProcess, IOngoingCall } from '../interfaces/call.interfaces';

export const CoreService = {
  fetchLanguagesList: async ({ company_code }: { company_code: string | null }): Promise<{
    languages: ILanguage[],
    status: string
  } | null> => {
    try {
      const axiosResponse = await api.get(company_code ? `/non-registered/${ company_code }/languages` : '/company-languages');
      return axiosResponse.data;
    } catch (error) {
      return null;
    }
  },
  fetchCountriesList: async ({ company_code }: { company_code: string | null }): Promise<{
    countries: ICountry[],
    status: string
  } | null> => {
    try {
      const axiosResponse = await api.get(company_code ? `/non-registered/${ company_code }/countries` : '/company-countries');
      return axiosResponse.data;
    } catch (error) {
      return null;
    }
  },
  fetchSpecialitiesList: async ({ company_code }: { company_code: string | null }): Promise<{
    specialities: ISpeciality[],
    status: string
  } | null> => {
    try {
      const axiosResponse = await api.get(company_code ? `/non-registered/${ company_code }/specialities` : '/specialities');
      return axiosResponse.data;
    } catch (error) {
      return null;
    }
  },
  fetchCompaniesList: async (): Promise<{ companies: ICompany[], status: string } | null> => {
    try {
      const axiosResponse = await api.get('/users/companies');
      return axiosResponse.data;
    } catch (error) {
      return null;
    }
  },
  fetchUserCompany: async (): Promise<ISingleCompany | null> => {
    try {
      const axiosResponse = await api.get('/users/company-profile');
      return axiosResponse.data;
    } catch (error) {
      return null;
    }
  },
  fetchTempUserCompany: async (company_code: string): Promise<ISingleCompany | null> => {
    try {
      const axiosResponse = await api.get(`/non-registered/${ company_code }`);
      return axiosResponse.data;
    } catch (error) {
      return null;
    }
  },
  assignNewCompany: async (invitation_code: string): Promise<any | null> => {
    try {
      const axiosResponse = await api.post(`/users/assign-user`, { invitation_code });
      return axiosResponse.data;
    } catch (error) {
      return null;
    }
  },
  fetchSpecializationsList: async (speciality: ISpeciality, company_code: string | null): Promise<{
    specializations: ISpecialization[],
    status: string
  } | null> => {
    try {
      const axiosResponse = await api.get(company_code ? `/non-registered/${ company_code }/specializations` : '/specializations', {
        params: {
          speciality: [speciality.id]
        }
      });
      return axiosResponse.data;
    } catch (error) {
      return null;
    }
  },
  globalSettings: async (): Promise<{
    call?: IOngoingCall,
    soon_appointment_call?: ICallProcess,
    error?: string
  }> => {
    try {
      const axiosResponse = await api.get('/global-settings');
      return axiosResponse.data;
    } catch (error: any) {
      const res = error?.response?.data;
      if (error?.response.status === 500) {
        return { error: res?.message };
      }
      return { ...res };
    }
  },
  fetchTimezonesList: async (): Promise<{
    timezones?: Array<{ name: string, offset: number, value: string }>,
    error?: string
  }> => {
    try {
      // const axiosResponse = await api.get('/assets/timezones/time-zones-updated.json', { baseURL: '/' }
      const axiosResponse = await api.get('/time-zones', {
        params: {
          offset: 0, limit: 1000
        }
      });
      return { timezones: axiosResponse.data };
    } catch (error: any) {
      const res = error?.response?.data;
      if (error?.response.status === 500) {
        return { error: res?.message };
      }
      return { ...res };
    }
  }
}
