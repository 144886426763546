import { FC, ReactNode } from 'react';
import dayjs from 'dayjs';

import { Dropdown } from 'antd';

// Shared UI Library Components
import { CustomIcon, EIconName, Loader } from '@ppmcore/seven-ppm-core-shared-components-react';

// Styles
import './transactions-item.scss';

// Models
import { ITransactionItem } from '../../../../interfaces/transactions.interfaces';
import { TCallType } from '../../../../interfaces/call.interfaces';

interface ITransactionsItemProps {
  transaction: ITransactionItem;
  activeInvoiceId: string | number | null;
  openRefundModal?: (id: number) => void;
  openInfoModal?: (transaction: ITransactionItem) => void;
  openInvoice?: (id: string | number) => void;
}

export const TransactionsItem: FC<ITransactionsItemProps> = (
  {
    transaction,
    activeInvoiceId,
    openRefundModal = () => {},
    openInfoModal = () => {},
    openInvoice = () => {}
  }: ITransactionsItemProps
): JSX.Element => {

  const onOrderClick = (): void => {
    if (activeInvoiceId === transaction.id) return;
    openInvoice(transaction?.id);
  }

  const getConsultationIconName = (type: TCallType): ReactNode => {
    if (type === 'message') {
      return <CustomIcon size={ 'size-md' } name={ EIconName.Message }/>;
    }
    if (type === 'audio_call') {
      return <CustomIcon size={ 'size-md' } name={ EIconName.MicOn }/>;
    }
    if (type === 'conversation') {
      return <CustomIcon size={ 'size-md' } name={ EIconName.Chat }/>;
    }
    return <CustomIcon size={ 'size-md' } name={ EIconName.CameraOn }/>;
  }

  return (
    <div className="transactions-item">
      <div className="transactions-item--column">
        <div className="column-item column-item--date">
          <span>{ getConsultationIconName(transaction.consultation_type) }</span>
          <span className="bold-text">{ dayjs(transaction.created_at).format('DD MMM YYYY') }</span>
          <span>{ dayjs(transaction.created_at).format('hh:mm A') }</span>
          { transaction.duration?.text && <span>{ transaction.duration?.text }</span> }
          { transaction.total?.text && <span className="bold-text">{ transaction.total?.text }</span> }

          { transaction.free_duration && <div className="bold-text">
            Free: { transaction.free_duration }
          </div> }
        </div>
      </div>
      <div className="transactions-item--column">
        <div className="column-item column-item--actions">
          { activeInvoiceId === transaction.id ? <Loader className={ 'actions-spin' }/> : <Dropdown menu={ {
            items: [
              // {
              //   key: 'refund',
              //   label: (
              //     <div className="refund-menu-item">
              //       <CustomIcon name={ EIconName.Refund } size={'size-md'}/>
              //       Request Refund
              //     </div>),
              //   disabled: transaction.is_refund_requested,
              //   onClick: () => openRefundModal(transaction.id)
              // },
              {
                key: 'invoice',
                label: (
                  <div className="refund-menu-item">
                    <CustomIcon size={ 'size-lg' } name={ EIconName.ReceiptOff }/>
                    See Invoice
                  </div>),
                onClick: () => openInvoice(transaction.id)
              },
            ]
          } } trigger={ ['click'] } placement="bottomRight" arrow>
            <CustomIcon name={ EIconName.BurgerMenu }/>
          </Dropdown> }
        </div>
        <div className="column-item column-item--total">
          { transaction.total?.text || '-' }
        </div>
      </div>

      {/*{ !!transaction.refund_status && <div className="transactions-item--footer">*/}
      {/*  <div className={ `footer-item ${ transaction.refund_status }` }>*/}
      {/*    { transaction.refund_status === ERefundStatus.Declined && 'Refund Declined' }*/}
      {/*    { transaction.refund_status === ERefundStatus.Requested && 'Refund Requested' }*/}
      {/*    { transaction.refund_status === ERefundStatus.Refunded && 'Refunded' }*/}
      {/*  </div>*/}
      {/*  <div className={ `footer-item ${ transaction.refund_status }` }>*/}
      {/*    <CustomIcon name={ EIconName.Info } onClick={ () => openInfoModal(transaction) }/>*/}
      {/*  </div>*/}
      {/*</div> }*/}
    </div>
  );
};
