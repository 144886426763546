import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Shared UI Library Components
import { INotificationItem } from '../../interfaces/notifications.interfaces';

export interface INotificationState {
  notifications: INotificationItem[];
  total: number;
  unread_count: number;
  loading: boolean;
  error: string | null;
}

const initialState: INotificationState = {
  notifications: [],
  total: 0,
  unread_count: 0,
  loading: true,
  error: null,
};

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    loadNotifications(state) {
      state.loading = true;
      state.error = null;
    },
    loadNotificationsSuccess: (state, { payload: { notifications, total, isLoadMore } }: PayloadAction<{
      notifications: INotificationItem[],
      total: number
      isLoadMore: boolean
    }>) => {
      state.notifications = isLoadMore ? [...state.notifications, ...notifications] : notifications;
      state.total = total;
      state.error = null;
      state.loading = false;
    },
    loadNotificationsFailure: (state, { payload }: PayloadAction<string>) => {
      state.loading = false;
      state.error = payload;
    },
    addNotificationFromSocket: (state, { payload: notification }: PayloadAction<INotificationItem>) => {
      state.notifications = [notification, ...state.notifications];
      state.total = state.total +- 1;
    },
    deleteNotifications: (state, { payload: id }: PayloadAction<number>) => {
      state.notifications = state.notifications.filter(notification => notification.id !== id);
    },
    setUnreadCount: (state, { payload: unread_count }: PayloadAction<number>) => {
      state.unread_count = unread_count;
    },
    markAsReadNotifications: (state) => {
      state.unread_count = 0;
      state.notifications = state.notifications.map(notification => ({ ...notification, is_read: true }));
    },
    clearAllNotifications: (state) => {
      state.notifications = [];
      state.total = 0;
      state.loading = false;
      state.error = null;
    },
    clearNotificationsState: (state) => {
      state.notifications = [];
      state.total = 0;
      state.loading = true;
      state.error = null;
    },
  }
});

export const NotificationsSliceActions = notificationsSlice.actions;
