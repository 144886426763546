import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// Components
import {
  CustomIcon,
  CustomIconBig,
  EIconBigName,
  EIconColor,
  EIconName, SpinElement
} from '@ppmcore/seven-ppm-core-shared-components-react';

// Thunks
import { clearChatStore, getChatInfo, rejectChat } from '../../../store/chat/chat.thunks';

// Selectors
import { getChatState } from '../../../store/chat/chat.selectors';
import { getLastMessageData } from '../../../store/socket/socket.selectors';

// Enums
import { EWebSocketTypes } from '../../../enums/web-socket-types.enums';

// Styles
import './chat-outgoing.scss';
import { clearSocketState } from '../../../store/socket/socket.thunks';

export const ChatOutgoing = () => {

  const dispatch = useDispatch<any>();
  const navigate = useNavigate();

  const temporaryToken = localStorage.getItem('temporary_token');

  const { chatProcess } = useSelector(getChatState);
  const lastMessage = useSelector(getLastMessageData);

  const conversation_id = localStorage.getItem('conversation_id');

  useEffect(() => {
    if (!chatProcess && conversation_id) {
      dispatch(getChatInfo({ conversation_id: +conversation_id, temporary_token: temporaryToken ?? '' }));
    }
  }, [chatProcess]);

  useEffect(() => {
    switch (lastMessage?.type) {
      case EWebSocketTypes.ConversationNotAnswered:
        dispatch(clearChatStore(true)).then(() => {
          navigate(`/conversation/call-missed`);
        });
        break;
      case EWebSocketTypes.ConversationRejected:
        dispatch(clearChatStore(true)).then(() => {
          navigate(`/conversation/call-declined`);
        });
        break;
      case EWebSocketTypes.ApproveConversation:
        if (conversation_id) dispatch(getChatInfo({
          conversation_id: +conversation_id,
          temporary_token: temporaryToken ?? ''
        }))
          .then(() => navigate(`/conversation/${ chatProcess?.conversation_id }`));
        break;
      default:
        break;
    }
  }, [lastMessage]);

  const cancelCall = (): void => {
    if (!chatProcess) return;

    dispatch(rejectChat({ conversation_id: chatProcess.conversation_id, temporary_token: temporaryToken ?? '' }))
      .then(() => dispatch(clearChatStore(true)))
      .then(() => {
        navigate(`/conversation/not-answered`);
      })
  }

  useEffect(() => {
    return () => {
      dispatch(clearSocketState());
    }
  }, []);

  return (<div className={ 'chat-outgoing' }>
    { (!chatProcess) && <SpinElement/> }

    { chatProcess && <div className={ 'chat-outgoing-inside' }>
      <div className={ 'photo' }>
        { chatProcess.worker?.photo
          ? <img src={ chatProcess.worker?.photo } alt=""/>
          : <div className={ 'default-image' }>
            <CustomIconBig name={ EIconBigName.NoVideoAvatar }/>
          </div> }
      </div>
      <div className={ 'fio' }>{ chatProcess.worker?.first_name } { chatProcess.worker?.last_name }</div>
      <div className={ 'calling-text' }>Connecting <span className="dot"></span></div>
      <div className={ 'cancel-call' } onClick={ cancelCall }>
        <CustomIcon name={ EIconName.ChatError } color={ EIconColor.White }/>
      </div>
    </div> }
  </div>);
}
