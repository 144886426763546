import axios, { AxiosError } from 'axios';
import { api } from '../configs/api/api';

// Interfaces
import { IUserData } from '../interfaces/user.interfaces';
import { ICompanyProfile } from '../interfaces/core.interfaces';

export const UserService = {
  profile: async (isTemporary: boolean): Promise<IUserData | any> => {
    try {
      return await api.get(`/${isTemporary ? 'temporary' : 'users'}/profile`, {});
    } catch (error: any) {
      return error;
    }
  },
  updateProfile: async (user: IUserData): Promise<IUserData | any> => {
    try {
      return await api.post('/users/update-profile', user);
    } catch (error) {
      return error;
    }
  },
  deleteProfile: async (): Promise<any> => {
    try {
      return await api.delete('/users/delete-profile');
    } catch (error) {
      return error;
    }
  },
  updatePhoto: async (photo: File): Promise<any> => {
    try {
      return await api.post('/users/update-profile-photo', {photo}, {
        headers: {'Content-Type': 'multipart/form-data'}
      });
    } catch (error) {
      return error;
    }
  },
  deletePhoto: async (): Promise<any> => {
    try {
      return await api.delete('/users/delete-profile-photo');
    } catch (error) {
      return error;
    }
  },
  updateLastCompany: async (company_id: number): Promise<{ message?: string, company_profile?: ICompanyProfile, error?: string }> => {
    try {
      const axiosResponse = await api.post('/users/update-last-company', { company_id });
      return { ...axiosResponse.data };
    } catch (error: any) {
      const res = error?.response?.data;
      if (error?.response.status === 500) {
        return { error: res?.message };
      }
      return { ...res };
    }
  },
  logout: async (): Promise<any> => {
    return await api.post('/users/logout', {});
  },
  fetchSocketToken: async (): Promise<{ data: {socket_token: string}; status: number } | { data: any; status: number }> => {
    return await api.get('/socket/auth').then(result => {
      const {data, status} = result;
      return {data, status};
    }).catch((error: Error | AxiosError) => {
      if (axios.isAxiosError(error) && error.response) {
        const {data, status} = error.response;
        return {data, status};
      } else {
        return {
          data: {error: 'An error has occurred with API processing'},
          status: 401
        };
      }
    });
  }
}
