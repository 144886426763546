import React, { FC, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Form, Drawer } from 'antd';

// Shared UI Library Components
import {
  ChipsInput,
  CustomButton,
  CustomCheckbox,
  CustomIcon,
  DropdownElement,
  EIconName,
  NumberInput
} from '@ppmcore/seven-ppm-core-shared-components-react';

// Styles
import './expert-filter-modal.scss';

// Store entities
import { getCoreState } from '../../store/core/core.selectors';
import { clearSpecializations, fetchSpecializations } from '../../store/core/core.thunks';
import { setExpertsFilters } from '../../store/experts/experts.thunks';
import { getExpertsState } from '../../store/experts/experts.selectors';

// Models
import { IExpertFilters } from '../../interfaces/experts.interfaces';
import { ISpeciality } from '../../interfaces/core.interfaces';
import { TExpertsList } from '../../store/experts/experts.slice';

const { Item: FormItem, useForm } = Form;

type TFormValues = {
  speciality: string;
  specialisations: string[];
  languages: string[];
  countries: string[];
  rate_from?: number;
  rate_to?: number;
  is_online?: any;
}

type TExpertFilterModalProps = {
  open: boolean;
  type: TExpertsList;
  onSubmit?: () => void;
  onCancel?: () => void;
}

export const ExpertFilterModal: FC<TExpertFilterModalProps> = (
  { open = false, type, onSubmit = () => {}, onCancel = () => {} }: TExpertFilterModalProps
) => {
  const [form] = useForm<TFormValues>();
  const [currentSpeciality, setCurrentSpeciality] = useState<ISpeciality>({ id: 0, name: '' });
  const [hasChanges, setHasChanges] = useState<boolean>(false);
  const [wasTriggered, setWasTriggered] = useState<boolean>(false);

  const {
    languages: { list: languagesList, isLoad: isLanguagesLoad },
    countries: { list: countriesList, isLoad: isCountriesLoad },
    speciality: { list: specialityList, isLoad: isSpecialityLoad },
    specializations: { list: specializationsList, isLoad: isSpecializationsLoad },
  } = useSelector(getCoreState);
  const { filters: expertFilters } = useSelector(getExpertsState);

  const languagesOptions = languagesList.map(({ name, code }) => ({ label: name, value: code }));
  const countriesOptions = countriesList.map(({ name, code }) => ({ label: name, value: code }));
  const specialityOptions = specialityList.map(({ name, id }) => ({ label: name, value: `${ id }` }));
  const specializationsOptions = specializationsList.map(({ name, id }) => ({ label: name, value: `${ id }` }));

  const dispatch = useDispatch<any>();
  const params = useParams();
  const companyCode = useMemo(() => params?.companyCode || null, [params?.companyCode]);

  const isFavorites = type === 'favorites';

  const getSpecialtyNameById = (id: string): string => {
    return specialityOptions.find(s => s.value === id)?.label ?? '';
  }

  const changeSpecialityHandler = (value: string): void => {
    const specialtyName = getSpecialtyNameById(value);
    setCurrentSpeciality({ id: +value, name: specialtyName });
    form.setFieldValue('specialisations', []);
    if (!value) return;
    dispatch(fetchSpecializations({
      speciality: { id: +value, name: specialtyName },
      company_code: companyCode ?? null
    }));
  }

  const resetFilters = () => {
    form.resetFields();
    dispatch(setExpertsFilters({
      filters: {
        search: '',
        languages: [],
        countries: [],
        specialisations: [],
        speciality: '',
        is_bookmarked: Number(isFavorites),
        offset: 0,
        limit: 20,
        rate_to: undefined,
        rate_from: undefined,
      },
      type,
      company_code: companyCode || null
    }));
    setHasChanges(false);
    setWasTriggered(false);
    setCurrentSpeciality({ id: 0, name: '' });
    dispatch(clearSpecializations());
  };

  const applyFilters = async (): Promise<void> => {
    const formValues = form.getFieldsValue() satisfies IExpertFilters;

    dispatch(setExpertsFilters({
        filters: {
          ...expertFilters,
          ...formValues,
          is_online: Number(formValues.is_online),
          is_bookmarked: Number(isFavorites),
        },
        type,
        company_code: companyCode
      }
    )).then(() => onSubmit());
  }

  const onValuesChange = (_: { [key in keyof TFormValues]: any }, {
    countries,
    specialisations,
    speciality,
    rate_to,
    rate_from,
    languages
  }: TFormValues): void => {
    const isCountriesHasChange = !!countries?.length;
    const isSpecialisationsHasChange = !!specialisations?.length;
    const isLanguagesHasChange = !!languages?.length;
    const isSpecialityHasChange = !!speciality && speciality !== 'all';
    const isRateToHasChange = !!rate_to || Number.isInteger(rate_to);
    const isRateFromHasChange = !!rate_from || Number.isInteger(rate_from);

    setWasTriggered(true);
    setHasChanges(
      isCountriesHasChange ||
      isSpecialisationsHasChange ||
      isSpecialityHasChange ||
      isRateToHasChange ||
      isRateFromHasChange ||
      isLanguagesHasChange
    );
  }

  const handleCancel = (): void => {
    onCancel();
  };

  return (
    <Drawer
      rootClassName="expert-filter-modal"
      open={ open }
      closeIcon={ <CustomIcon name={ EIconName.Close }/> }
      title={ 'Filters' }
      footer={ [
        <CustomButton key="reset" text={ 'Reset' } disabled={ !hasChanges && !wasTriggered } type={ 'default' }
                      onClick={ resetFilters }/>,
        <CustomButton key="apply" text={ 'Apply' } disabled={ !hasChanges && !wasTriggered } onClick={ applyFilters }/>
      ] }
      extra={ null }
      onClose={ handleCancel }
    >
      <Form className="drawer-body" form={ form } onValuesChange={ onValuesChange }>

        <div className="drawer-body--item">
          <FormItem name="is_online"
                    rules={ [{ required: false }] } initialValue={ false } valuePropName={ 'checked' }>
            <CustomCheckbox label={ 'Online Only' }/>
          </FormItem>
        </div>

        <div className="drawer-body--item">
          <FormItem name="speciality"
                    rules={ [{ required: false }] } initialValue={ 'all' }>
            <DropdownElement
              label={ 'Speciality' }
              loading={ isSpecialityLoad }
              options={ specialityOptions }
              onChange={ changeSpecialityHandler }/>
          </FormItem>
        </div>

        <div className="drawer-body--item">
          <div className="modal-body-item">
            <FormItem name="specialisations"
                      rules={ [{ required: false }] }>
              <ChipsInput label={ 'Specialisation(s)' }
                          placeholder={ !currentSpeciality.name ? 'Please select one of the available Specialities' : 'Please select a Specialisation(s)' }
                          loading={ isSpecializationsLoad }
                          disabled={ !currentSpeciality.name }
                          options={ specializationsOptions }/>
            </FormItem>
          </div>
        </div>

        <div className="drawer-body--item">
          <FormItem name="languages"
                    rules={ [{ required: false }] }>
            <ChipsInput label={ 'Language(s)' }
                        placeholder={ 'Please select a Language(s)' }
                        loading={ isLanguagesLoad }
                        options={ languagesOptions }/>
          </FormItem>
        </div>

        <div className="drawer-body--item">
          <FormItem name="countries"
                    rules={ [{ required: false }] }>
            <ChipsInput label={ 'Country(ies)' }
                        placeholder={ 'Please select a Country(ies)' }
                        loading={ isCountriesLoad }
                        options={ countriesOptions }/>
          </FormItem>
        </div>

        <div className="drawer-body--item flex-items">
          <div className="flex-items-label">
            Rate
          </div>
          <FormItem name="rate_from">
            <NumberInput placeholder={ 'Min' } min={ 0 } max={ 1000 } controls={ false }/>
          </FormItem>
          <FormItem name="rate_to">
            <NumberInput placeholder={ 'Max' } min={ 0 } max={ 1000 } controls={ false }/>
          </FormItem>
        </div>
      </Form>
    </Drawer>
  )
};
