import React, { FC, HTMLProps, ReactNode } from 'react';
import dayjs from 'dayjs';

// Shared UI Library Components
import { CustomIcon, EIconName } from '@ppmcore/seven-ppm-core-shared-components-react';

// Styles
import './conversation-consultation.scss';

// Components
import { MessagesList } from '../../chat';

// Models
import { IConversationHistory } from '../../../../interfaces/chat.interfaces';

type TConsultationProps = HTMLProps<HTMLDivElement> & {
  history: IConversationHistory;
}

export const ConversationConsultation: FC<TConsultationProps> = (
  {
    history: {
      conversation_messages,
      created_at,
      total,
      user,
      worker,
      conversation_status,
      conversation_duration,
      free_status,
      free_duration,
    }, ...props
  }: TConsultationProps
) => {

  const isEmptyMessages = !conversation_messages?.length;

  const getStatusField = (): ReactNode => {
    if (conversation_status === 'not_answered') {
      return 'No answer';
    }
    if (conversation_status === 'canceled') {
      return 'Canceled';
    }
    if (conversation_status === 'rejected') {
      return 'Rejected';
    }
    return conversation_duration;
  }

  return (
    <div className={ `conversation-consultation ${ isEmptyMessages ? 'empty-messages' : '' }` } { ...props }>
      <div className="conversation-consultation--header">
        <div className="header-item">
          { dayjs(created_at).format('hh:mm A') }
        </div>
        <div className="header-item has-divider">
          <CustomIcon name={ EIconName.Chat } size={ 'size-md' }/> Text Chat
        </div>
        <div className="header-item has-divider">
          { getStatusField() }
        </div>
        <div className={ `header-item ${ free_status !== 'not_free' ? 'has-divider' : '' }` }>
          { total ?? '-' }
        </div>
        { free_status !== 'not_free' && <div className="header-item">
          Free: { free_duration }
        </div> }
      </div>
      { !isEmptyMessages && <div className="conversation-consultation--chat">
        <MessagesList messages={ conversation_messages } user={ user } worker={ worker }/>
      </div> }
    </div>
  );
};
