import { createSelector } from '@reduxjs/toolkit';

// Models
import { RootState } from '../index';
import { INotificationState } from './notifications.slice';

/**
 A selector function to retrieve the notifications data from the store
 @function
 @param {RootState} state - The root state of the Redux store
 @returns {INotificationState} The notifications state object from the Redux store
 */
const selectNotificationsState = (state: RootState): INotificationState => state.notifications;

/**
 A memoized selector function to retrieve the notifications data from the store
 @function
 @type {import('@reduxjs/toolkit').OutputSelector<RootState, INotificationState, (res: INotificationState) => INotificationState>}
 */
export const getNotificatiosState = createSelector(
  [selectNotificationsState],
  (state): INotificationState => state
);
