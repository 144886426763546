import { createSelector } from '@reduxjs/toolkit';

// Models
import { RootState } from '../index';
import { IActiveAppointmentsState } from './active-appointments.slice';

/**
 A selector function to retrieve the appointments data from the store
 @function
 @param {RootState} state - The root state of the Redux store
 @returns {IActiveAppointmentsState} The appointments state object from the Redux store
 */
const selectActiveAppointmentsState = (state: RootState): IActiveAppointmentsState => state.activeAppointments;

/**
 A memoized selector function to retrieve the appointments data from the store
 @function
 @type {import('@reduxjs/toolkit').OutputSelector<RootState, IActiveAppointmentsState, (res: IActiveAppointmentsState) => IActiveAppointmentsState>}
 */
export const getActiveAppointmentsState = createSelector(
  [selectActiveAppointmentsState],
  (state): IActiveAppointmentsState => state
);
