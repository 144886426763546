import { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { FormInstance } from 'antd';

// Shared UI Library Components
import { CustomIcon, EIconName, Paginator, SpinElement } from '@ppmcore/seven-ppm-core-shared-components-react';

// Styles
import './transactions-list.scss';

// Components
import { TransactionsFiltersModal } from '../../../../modals/transactions-filters-modal/transactions-filters-modal';
import { RefundModal } from '../../../../modals/refund-modal/refund-modal';
import { TransactionsItem } from '../transactions-item/transactions-item';
import { TransactionsInfoModal } from '../../../../modals/transactions-info-modal/transactions-info-modal';

// Store
import {
  getReceiptUrl,
  getTransactions,
  refundTransaction,
} from '../../../../store/transactions/transactions.thunks';
import { getTransactionsState } from '../../../../store/transactions/transactions.selectors';
import { getUserState } from '../../../../store/user/user.selectors';

// Models
import { ITransactionItem } from '../../../../interfaces/transactions.interfaces';
import { ExpertAuthWidgetNavigation } from '../../expert/expert-auth-widget-navigation/expert-auth-widget-navigation';

interface ITransactionsListProps {
  worker_id?: number;
  expertPage?: boolean;
}

export const TransactionsList: FC<ITransactionsListProps> = (
  { worker_id, expertPage }: ITransactionsListProps
): JSX.Element => {
  const [page, setPage] = useState<number>(1);
  const [activeRefundId, setActiveRefundId] = useState<number>(0);
  const [activeTransaction, setActiveTransaction] = useState<ITransactionItem | null>(null);
  const [activeInvoiceId, setActiveInvoiceId] = useState<string | number | null>(null);
  const [openFilters, setOpenFilters] = useState<boolean>(false);
  const [openRefund, setOpenRefund] = useState<boolean>(false);
  const [openInfo, setOpenInfo] = useState<boolean>(false);

  const {
    isLoading,
    isFirstLoad,
    total,
    total_all,
    transactionsList,
    settings: transactionsSettings
  } = useSelector(getTransactionsState);
  const { user } = useSelector(getUserState);

  const transformedList: ITransactionItem[] = transactionsList;

  const {
    date_to,
    date_from,
    show_audio_calls,
    show_calls,
    show_messages
  } = transactionsSettings;
  const hasSettings = !!date_to || !!date_from || show_audio_calls === false || show_calls === false || show_messages === false;

  const dispatch = useDispatch<any>();

  const toggleFiltersModal = (): void => {
    setOpenFilters(!openFilters);
  }

  const toggleRefundModal = (): void => {
    setOpenRefund(!openRefund);
  }

  const toggleInfoModal = (): void => {
    setOpenInfo(!openInfo);
  }

  const openRefundModal = async (id: number): Promise<void> => {
    toggleRefundModal();
    setActiveRefundId(id);
  }

  const openInfoModal = async (transaction: ITransactionItem): Promise<void> => {
    toggleInfoModal();
    setActiveTransaction(transaction);
  }

  const refundHandler = async (reason: string, form: FormInstance): Promise<void> => {
    toggleRefundModal();
    await dispatch(refundTransaction({ payment_id: activeRefundId, refund_comment: reason }));
    form.resetFields();
  }

  const openInvoice = async (payment_id: string | number): Promise<void> => {
    setActiveInvoiceId(payment_id);
    const data = await dispatch(getReceiptUrl({ payment_id }));
    if (!data.payload) return setActiveInvoiceId(null);
    window.open(data.payload, '_blank');
    setActiveInvoiceId(null);
  }

  const onPageChange = (currentPage: number) => {
    if (page !== currentPage) {
      setPage(currentPage);
      dispatch(getTransactions({
        settings: {
          ...transactionsSettings,
          offset: (currentPage - 1) * 10,
          limit: 10,
          ...(worker_id && { worker_id })
        }
      }));
      return;
    }
    setPage(1);
  };

  const onSubmitFiltersHandler = (): void => {
    toggleFiltersModal();
    setPage(1);
  }

  return (
    <div className={ `transactions-list ${ expertPage ? 'transactions-list-expert' : '' }` }>
      <RefundModal open={ openRefund } onCancel={ toggleRefundModal } onSubmit={ refundHandler }/>
      { activeTransaction &&
        <TransactionsInfoModal open={ openInfo } onCancel={ toggleInfoModal } transaction={ activeTransaction }/> }

      <div className="transactions-list--header">
        <div className="header-title">
          Transactions
        </div>
        <div className={ `header-filters ${ hasSettings ? 'has-filters' : '' }` } hidden={ !total_all }>
          { !!user?.created_at && <TransactionsFiltersModal userCreatedAt={ user?.created_at } open={ openFilters }
                                                            onCancel={ toggleFiltersModal }
                                                            onSubmit={ onSubmitFiltersHandler }
                                                            settings={ transactionsSettings }
                                                            worker_id={ worker_id }/> }
          <CustomIcon name={ EIconName.Filters } onClick={ toggleFiltersModal }/>
        </div>
        { !expertPage && <ExpertAuthWidgetNavigation/> }
      </div>

      { isFirstLoad && <SpinElement fullHeight={ true }/> }

      { !isFirstLoad && <>

        { !total_all && <>
          <div className="transactions-list--subtitle">
            You don’t have any transactions yet. Start communicating!
          </div>
          <div className="transactions-list--img">
            <img src={ '/assets/icons/big/no-transactions.svg' } alt={ 'no-transactions-icon' }/>
          </div>
        </> }

        { total > 10 && <div className="transactions-list--pagination">
          <Paginator current={ page } totalItems={ total } pageChange={ onPageChange }/>
        </div>
        }

        { !total && <div className="transactions-list--empty">
          No matching items
        </div>
        }

        <div className="transactions-list--body">
          { isLoading && <SpinElement fullHeight={ true }/> }
          {
            transformedList
              .map(transaction =>
                <TransactionsItem key={ transaction.id }
                                  transaction={ transaction }
                                  activeInvoiceId={ activeInvoiceId }
                                  openInvoice={ openInvoice }
                                  openRefundModal={ openRefundModal }
                                  openInfoModal={ openInfoModal }/>
              )
          }
        </div>
      </> }
    </div>
  );
};
