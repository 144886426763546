import { FC } from 'react';

// Shared UI Library Components
import { CustomIcon, EIconName } from '@ppmcore/seven-ppm-core-shared-components-react';

// Styles
import './sidebar-header.scss';

// Components
import { LogoBlock } from '../logo-block/logo-block';
import { SingleLogoBlock } from '../single-logo-block/single-logo-block';

interface ISidebarHeaderProps {
  sidebarOpened: boolean;
  hideSidebarActionBtn: boolean;
  toggleSidebar?: () => void;
}

export const SidebarHeader: FC<ISidebarHeaderProps> = (
  {
    sidebarOpened,
    hideSidebarActionBtn,
    toggleSidebar = () => {
    },
  }: ISidebarHeaderProps
): JSX.Element => {

  const token = localStorage.getItem('token');

  return (
    <div className="sidebar-header">
      <div className={ `header-item ${ !sidebarOpened && 'hide-info' }` }>
        {token ? <LogoBlock sidebarOpened={sidebarOpened}/> : <SingleLogoBlock sidebarOpened={sidebarOpened}/>}
      </div>
      <div className="header-item">
        {!hideSidebarActionBtn && <CustomIcon
          onClick={ toggleSidebar }
          name={ sidebarOpened ? EIconName.MenuClose : EIconName.MenuOpen }/>}
      </div>
    </div>
  )
};
