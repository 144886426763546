import axios, { AxiosError } from 'axios';

// API
import { api } from '../configs/api/api';

// Models
import {
  IChatInfo, IChatProcess,
  IChatProcessCreateRequest, IChatProcessStartRequest, IChatProcessUpdateRequest,
  ICreateChatResponse,
  IUpdateChatResponse
} from '../interfaces/text-chat.interfaces';

export const ChatConsultationService = {
  getChatInfo: async (conversation_id: number, temporary_token: string): Promise<{ data: IChatInfo; status: number } | {
    data: any;
    status: number
  }> => {
    return await api.get(`/${ temporary_token ? 'temporary' : 'users' }/conversation/info`, { params: { conversation_id } })
      .then(result => {
        const { data, status } = result;
        return { data, status };
      })
      .catch((error: Error | AxiosError) => {
        if (axios.isAxiosError(error) && error.response) {
          const { data, status } = error.response;
          return { data, status };
        } else {
          return {
            data: { error: 'An error has occurred with API processing' },
            status: 500
          };
        }
      });
  },
  createChat: async (createCallData: IChatProcessCreateRequest): Promise<{
    data: ICreateChatResponse;
    status: number
  } | { data: any; status: number }> => {
    return await api.post<ICreateChatResponse>(`/${ createCallData.temporary_token ? 'temporary' : 'users' }/create-conversation`, createCallData)
      .then(result => {
        const { data, status } = result;
        return { data, status };
      })
      .catch((error: Error | AxiosError) => {
        if (axios.isAxiosError(error) && error.response) {
          const { data, status } = error.response;
          return { data, status };
        } else {
          return {
            data: { error: 'An error has occurred with API processing' },
            status: 500
          };
        }
      });
  },
  updateChat: async (updateCallData: IChatProcessUpdateRequest): Promise<{
    data: IUpdateChatResponse;
    status: number
  } | { data: any; status: number }> => {
    return await api.post<IUpdateChatResponse>(`/${ updateCallData.temporary_token ? 'temporary' : 'users' }/update-conversation`, updateCallData)
      .then(result => {
        const { data, status } = result;
        return { data, status };
      })
      .catch((error: Error | AxiosError) => {
        if (axios.isAxiosError(error) && error.response) {
          const { data, status } = error.response;
          return { data, status };
        } else {
          return {
            data: { error: 'An error has occurred with API processing' },
            status: 500
          };
        }
      });
  },
  startChat: async (updateCallData: IChatProcessStartRequest): Promise<{ data: IChatInfo; status: number } | {
    data: any;
    status: number
  }> => {
    return await api.post<IChatInfo>(`/${ updateCallData.temporary_token ? 'temporary' : 'users' }/start-conversation`, updateCallData)
      .then(result => {
        const { data, status } = result;
        return { data, status };
      })
      .catch((error: Error | AxiosError) => {
        if (axios.isAxiosError(error) && error.response) {
          const { data, status } = error.response;
          return { data, status };
        } else {
          return {
            data: { error: 'An error has occurred with API processing' },
            status: 500
          };
        }
      });
  },
  rejectChat: async (conversation_id: number, temporary_token: string): Promise<{ data: IChatInfo; status: number } | {
    data: any;
    status: number
  }> => {
    return await api.post<IChatInfo>(`/${ temporary_token ? 'temporary' : 'users' }/reject-conversation`, { conversation_id })
      .then(result => {
        const { data, status } = result;
        return { data, status };
      })
      .catch((error: Error | AxiosError) => {
        if (axios.isAxiosError(error) && error.response) {
          const { data, status } = error.response;
          return { data, status };
        } else {
          return {
            data: { error: 'An error has occurred with API processing' },
            status: 500
          };
        }
      });
  },
  chargeChatTimeslot: async (chargeTimeslotData: IChatProcessStartRequest): Promise<{
    data: IChatProcess;
    status: number
  } | { data: any; status: number }> => {
    return await api.post<IChatProcess>(`/${ chargeTimeslotData.temporary_token ? 'temporary' : 'users' }/conversation/charge-conversation-timeslot`, chargeTimeslotData)
      .then(result => {
        const { data, status } = result;
        return { data, status };
      })
      .catch((error: Error | AxiosError) => {
        if (axios.isAxiosError(error) && error.response) {
          const { data, status } = error.response;
          return { data, status };
        } else {
          return {
            data: { error: 'An error has occurred with API processing' },
            status: 500
          };
        }
      });
  },
  cancelNextPayment: async (conversation_id: number, temporary_token: string): Promise<{
    data: IChatProcess;
    status: number
  } | { data: any; status: number }> => {
    return await api.post<IChatProcess>(`/${ temporary_token ? 'temporary' : 'users' }/conversation/cancel-charge`, { conversation_id })
      .then(result => {
        const { data, status } = result;
        return { data, status };
      })
      .catch((error: Error | AxiosError) => {
        if (axios.isAxiosError(error) && error.response) {
          const { data, status } = error.response;
          return { data, status };
        } else {
          return {
            data: { error: 'An error has occurred with API processing' },
            status: 500
          };
        }
      });
  },
}
