import React, { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// Components
import { StaticTextModal } from '../../modals/static-text-modal/static-text-modal';
import { StaticPages } from '../static-pages/static-pages';

// Styles
import './footer.scss';

interface IFooterProps {
  hideLinks?: boolean;
}

export const Footer: FC<IFooterProps> = ({hideLinks = false}: IFooterProps) => {

  const navigate = useNavigate();

  const [staticTermsModalOpen, setStaticTermsModalOpen] = useState(false);
  const [staticPrivacyModalOpen, setStaticPrivacyModalOpen] = useState(false);

  useEffect(() => {
    const urlSplit = window.location.href.split('#');
    if (urlSplit[1]) {
      setStaticTermsModalOpen(urlSplit[1] === 'terms-of-service');
      setStaticPrivacyModalOpen(urlSplit[1] === 'privacy-policy');
      navigate('sign-in')
    }
  }, []);

  return (<div className="footer">
    <span className="footer--copyright">&copy; PayPerTok {(new Date().getFullYear())}. All Rights Reserved</span>
    {!hideLinks && <div className={'dialog-link'} onClick={() => setStaticTermsModalOpen(true)}>Terms of Service</div>}
    {!hideLinks && <div className={'dialog-link'} onClick={() => setStaticPrivacyModalOpen(true)}>Privacy Policy</div>}

    {!hideLinks && <StaticTextModal
      modalTitle="Terms of Service"
      open={staticTermsModalOpen}
      showFooter={false}
      onCancel={() => setStaticTermsModalOpen(false)}>
      <StaticPages staticPageId={2} isDialog={true}/>
    </StaticTextModal>}

  {!hideLinks && <StaticTextModal
      modalTitle="Privacy Policy"
      open={staticPrivacyModalOpen}
      showFooter={false}
      onCancel={() => setStaticPrivacyModalOpen(false)}>
      <StaticPages staticPageId={1} isDialog={true}/>
    </StaticTextModal>}
  </div>);
}
