import { createAsyncThunk } from '@reduxjs/toolkit';

// Slices
import { LoaderSliceActions } from './loader.slice';

export enum ELoaderActions {
  SetLoaderState = 'Loader/setLoaderState',
}

export const setLoaderState = createAsyncThunk(
    ELoaderActions.SetLoaderState,
    async ({ show, text }: { show: boolean, text?: string }, { dispatch }: any) => {
      return dispatch(LoaderSliceActions.setLoaderState({ show, text }));
    }
  )
;
