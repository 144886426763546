import { createAsyncThunk } from '@reduxjs/toolkit';

// Services
import { AppointmentsService } from '../../services/appointments.service';

// Slices
import { AppointmentsSliceActions } from './appointments.slice';

// Store actions
import { openNotification } from '../app-notifications/app-notifications.thunks';

// Models
import { IAppointment, IAppointmentsSettings, ICreateAppointment } from "../../interfaces/appointments.interfaces";
import { INotificationItem } from "../../interfaces/notifications.interfaces";

export enum EBookAppointmentsThunks {
  FetchAppointmentsConfig = 'APPOINTMENTS/fetchAppointmentsConfig',
  FetchAppointments = 'APPOINTMENTS/fetchAppointments',
  CancelAppointment = 'APPOINTMENTS/cancelAppointment',
  UpdateAppointmentFromSocket = 'APPOINTMENTS/updateAppointmentFromSocket',
  BookAppointment = 'APPOINTMENTS/bookAppointment',
  SetAppointmentsSettings = 'APPOINTMENTS/setAppointmentsSettings',
  ClearBookAppointmentState = 'APPOINTMENTS/clearBookAppointmentState',
}

export const bookAppointment = createAsyncThunk(
  EBookAppointmentsThunks.BookAppointment,
  async (payload: ICreateAppointment, { dispatch }: any) => {
    dispatch(AppointmentsSliceActions.bookAppointmentRequest());
    const { appointment, error } = await AppointmentsService.bookAppointment(payload);

    if (error) {
      dispatch(AppointmentsSliceActions.bookAppointmentRequestError());
      dispatch(openNotification({
        type: 'error',
        description: error.error || 'Something went wrong, please try again',
      }));
      return null;
    }
    dispatch(AppointmentsSliceActions.bookAppointmentRequestSuccess(appointment));
    dispatch(openNotification({
      type: 'success',
      description: 'Appointment successfully booked',
    }));
    return appointment;
  }
);

export const fetchAppointmentsConfig = createAsyncThunk(
  EBookAppointmentsThunks.FetchAppointmentsConfig,
  async (payload: {
    worker_id: number | string,
    appointment_day: string,
    call_type?: string,
    call_duration?: string
  }, { dispatch }: any) => {
    dispatch(AppointmentsSliceActions.fetchAppointmentsConfig());
    const { data, error } = await AppointmentsService.fetchAppointmentsConfig(payload);

    if (!data && !error) {
      return null;
    }

    if (!data || error) {
      dispatch(AppointmentsSliceActions.fetchAppointmentsConfigError());
      dispatch(openNotification({
        type: 'error',
        description: error.message || 'Default error message',
      }));
      return null;
    }

    dispatch(AppointmentsSliceActions.fetchAppointmentsConfigSuccess(data));
    return data;
  }
);

export const fetchAppointments = createAsyncThunk(
  EBookAppointmentsThunks.FetchAppointments,
  async ({ offset = 0, limit = 30, isLoadMore = false, settings = {} }: {
    offset?: number,
    limit?: number,
    isLoadMore?: boolean,
    settings?: IAppointmentsSettings;
  }, { dispatch }) => {
    dispatch(AppointmentsSliceActions.loadAppointments());
    const {
      appointments,
      total,
      totalAll,
      error
    } = await AppointmentsService.fetchAppointmentsList(offset, limit, settings);
    if (!appointments) {
      return dispatch(AppointmentsSliceActions.loadAppointmentsFailure(error ?? 'Error'));
    }
    return dispatch(AppointmentsSliceActions.loadAppointmentsSuccess({
      appointments, total: total as number, total_all: totalAll as number, isLoadMore
    }));
  }
);

export const cancelAppointment = createAsyncThunk(
  EBookAppointmentsThunks.CancelAppointment,
  async ({ appointment_id, cancel_notes = '' }: { appointment_id: number, cancel_notes?: string }, { dispatch }) => {
    dispatch(AppointmentsSliceActions.makeAction(true));
    const { appointment, error, message } = await AppointmentsService.cancelAppointment(appointment_id, cancel_notes);
    if (!appointment) {
      dispatch(AppointmentsSliceActions.makeAction(false));
      return dispatch(openNotification({
        type: 'error',
        description: error ?? 'Failed to cancel the appointment, try again!'
      }));
    }
    dispatch(openNotification({
      type: 'success',
      description: message ?? 'Appointment successfully canceled!'
    }));
    return dispatch(AppointmentsSliceActions.cancelAppointment(appointment));
  }
);

export const updateAppointmentFromSocket = createAsyncThunk(
  EBookAppointmentsThunks.UpdateAppointmentFromSocket,
  async (appointment: INotificationItem, { dispatch }) => {
    const isAppointmentsPage = window.location.pathname === '/appointments';
    if (!isAppointmentsPage) return;
    return dispatch(AppointmentsSliceActions.updateAppointmentFromSocket(appointment));
  }
);

export const setAppointmentsSettings = createAsyncThunk(
  EBookAppointmentsThunks.SetAppointmentsSettings,
  async (settings: IAppointmentsSettings, { dispatch }: any) => {
    dispatch(AppointmentsSliceActions.setSettings(settings));
  }
);

export const clearBookAppointmentState = createAsyncThunk(
  EBookAppointmentsThunks.ClearBookAppointmentState,
  async (_, { dispatch }: any) => {
    dispatch(AppointmentsSliceActions.clearState());
  }
);
