import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Form } from 'antd';
import dayjs from "dayjs";
import durationPlugin from "dayjs/plugin/duration";
import utc from "dayjs/plugin/utc";

// Shared UI Library Components
import {
  CustomButton,
  CustomIcon,
  defaultValidateMessages,
  DiscardModal,
  CustomInput,
  EIconName,
  ServerErrors,
  ServerErrorsTimer,
  emailPattern
} from '@ppmcore/seven-ppm-core-shared-components-react';

// Components
import { AuthLayout } from '../auth-modules/auth-layout/auth-layout';
import { FormBottomActions } from '../auth-modules/form-bottom-actions/form-bottom-actions';

// Thunks
import { forgotPasswordThunks, clearAuthState } from '../../../store/auth/auth.thunks';

// Styles
import './forgot-password.scss';

export const ForgotPassword = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const dispatch = useDispatch<any>();

  dayjs.extend(durationPlugin);
  dayjs.extend(utc);
  const currentData = dayjs().utc();

  const [btnDisabled, setBtnDisabled] = useState(true);
  const [formDisabled, setFormDisabled] = useState(false);
  const [discardModalOpen, setDiscardModalOpen] = useState(false);
  const [serverError, setServerError] = useState<string | null>(null);

  const [errorTimerTime, setErrorTimerTime] = useState<string>("");
  const [errorTimerText, setErrorTimerText] = useState<string | null>(null);

  useEffect(() => {
    localStorage.removeItem('signUp');
    localStorage.removeItem('reset_password_time');
    localStorage.removeItem('reset_password');
    const forgotPasswordAccessTime = localStorage.getItem('forgotPasswordAccessTime');
    const forgotPasswordErrorMessage = localStorage.getItem('forgotPasswordErrorMessage');
    if (forgotPasswordAccessTime && forgotPasswordErrorMessage) {
      const startedData = dayjs(forgotPasswordAccessTime).utc();
      const totalSeconds = startedData.diff(currentData, "seconds");
      if (totalSeconds > 0) {
        form.resetFields();
        setBtnDisabled(true);
        setFormDisabled(true);
        setErrorTimerTime(forgotPasswordAccessTime);
        setErrorTimerText(forgotPasswordErrorMessage);
        return;
      }
      onErrorTimerEnd();
    }
  }, []);

  const confirmAction = () => {
    form.validateFields().then((values) => {
      const {email} = values;
      dispatch(forgotPasswordThunks({email})).then((res: any) => {

        if (res.payload?.error && res.payload.access_after) {
          setErrorTimerTime(res.payload.access_after);
          setErrorTimerText(res.payload?.error);
          localStorage.setItem('forgotPasswordAccessTime', res.payload.access_after);
          localStorage.setItem('forgotPasswordErrorMessage', res.payload?.error);
          form.resetFields();
          setBtnDisabled(true);
          setFormDisabled(true);
          setServerError(null);
          return;
        }

        if (res.payload && res.payload?.error) {
          setBtnDisabled(true);
          setServerError(res.payload?.error);
          return;
        }

        setServerError(null);
        localStorage.removeItem('forgotPasswordAccessTime');
        localStorage.removeItem('forgotPasswordErrorMessage');
        localStorage.setItem('reset_password', email);
        navigate('/forgot-password-resend');
      });
    }).catch((error) => {
      console.error('Form validation failed', error);
    });
  }

  const cancelAction = (value: boolean) => {
    if (value) {
      localStorage.removeItem('reset_password');
      dispatch(clearAuthState()).then(() => navigate(`/sign-in`));
    }
    setServerError(null);
    setDiscardModalOpen(false);
  }

  const onErrorTimerEnd = () => {
    localStorage.removeItem('forgotPasswordAccessTime');
    localStorage.removeItem('forgotPasswordErrorMessage');
    setBtnDisabled(false);
    setFormDisabled(false);
    setServerError(null);
    setErrorTimerTime("");
    setErrorTimerText(null);
  };

  const onValuesChange = (changedValues: { email: string },
                          allFormValues: { email: string | undefined }) => {
    if (allFormValues.email !== undefined
      && allFormValues.email !== ''
      && new RegExp(emailPattern).test(allFormValues.email)) {
      setBtnDisabled(false);
    } else {
      setBtnDisabled(true);
    }
  };

  return (<AuthLayout cardTitle="Forgot Password" rightBarIcon="auth">
    <Form
      form={form}
      disabled={formDisabled}
      className={'forgot-password'}
      name="forgot-password"
      requiredMark={false}
      autoComplete="off"
      onValuesChange={onValuesChange}
      layout={'vertical'}
      validateMessages={defaultValidateMessages}>

      {errorTimerText && errorTimerTime && (
        <ServerErrorsTimer
          serverError={errorTimerText}
          errorTime={errorTimerTime}
          onTimeEnd={onErrorTimerEnd}
        />
      )}

      {serverError && <ServerErrors serverError={serverError} />}

      <Form.Item
        name="email"
        messageVariables={{fieldLabel: 'Email'}}
        rules={[
          {required: true},
          {max: 60},
          ({getFieldValue}) => ({
            validator(_, value) {
              if (!value || new RegExp(emailPattern).test(getFieldValue('email'))) {
                return Promise.resolve();
              }
              if (value.length > 60) {
                return Promise.resolve();
              }
              return Promise.reject(new Error(defaultValidateMessages.invalidEmailFormat));
            }
          })]}>
        <CustomInput disabled={formDisabled} shouldTrim={true} type={'text'} placeholder={'Email*'} suffix={<CustomIcon name={EIconName.Mail}/>} />
      </Form.Item>

      {serverError && <FormBottomActions showForgotPasswordLink={false} infoText={'Don’t have an account?'} isSignInLink={false}/>}

      <div className="form-bottom" style={{marginTop: serverError ? '24px' : '48px'}}>
        <CustomButton text="Cancel" type={'text'} onClick={() => setDiscardModalOpen(true)} disabled={false} />
        <CustomButton text="Confirm" htmlType={'submit'} onClick={confirmAction} disabled={btnDisabled} />
      </div>
    </Form>

    <DiscardModal open={discardModalOpen}
                  onCancel={(value) => cancelAction(value)}
                  title={'Are you sure you want to discard your Password Recovery?'} />
  </AuthLayout>);
}
