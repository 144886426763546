import { createSelector } from '@reduxjs/toolkit';

// Models
import { ILoaderState } from './loader.slice';
import { RootState } from '../index';

/**
 A selector function to retrieve the loader data from the store
 @function
 @param {RootState} state - The root state of the Redux store
 @returns {ILoaderState} The loader state object from the Redux store
 */
const selectLoaderState = (state: RootState): ILoaderState => state.loader;

/**
 A memoized selector function to retrieve the loader data from the store
 @function
 @type {import('@reduxjs/toolkit').OutputSelector<RootState, ILoaderState, (res: ILoaderState) => ILoaderState>}
 */
export const getLoaderState = createSelector(
  [selectLoaderState],
  (state) => state
);
