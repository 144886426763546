import { createSelector } from '@reduxjs/toolkit';

// Models
import { RootState } from '../index';
import { IMessagesState } from './messages.slice';

/**
 A selector function to retrieve the messages data from the store
 @function
 @param {RootState} state - The root state of the Redux store
 @returns {IMessagesState} The messages state object from the Redux store
 */
const selectMessagesState = (state: RootState): IMessagesState => state.messages;

/**
 A memoized selector function to retrieve the messages data from the store
 @function
 @type {import('@reduxjs/toolkit').OutputSelector<RootState, IMessagesState, (res: IMessagesState) => IMessagesState>}
 */
export const getMessagesState = createSelector(
  [selectMessagesState],
  (state): IMessagesState => state
);
