import { createSelector } from '@reduxjs/toolkit';

// Models
import { ISocketState } from './socket.slice';
import { RootState } from '../index';
import { IWebSocketDataEvent } from '../../interfaces/web-socket.interfaces';

/**
 A selector function to retrieve the socket data from the store
 @function
 @param {RootState} state - The root state of the Redux store
 @returns {ISocketState} The socket state object from the Redux store
 */
const selectSocketState = (state: RootState): ISocketState => state.socket;

/**
 A memoized selector function to retrieve the socket last message data from the store
 @function
 @type {import('@reduxjs/toolkit').OutputSelector<RootState, ISocketState, (res: ISocketState) => IWebSocketDataEvent<any> | null>}
 */
export const getLastMessageData = createSelector(
  [selectSocketState],
  (state): IWebSocketDataEvent<any> | null => state.lastMessage
);

/**
 A memoized selector function to retrieve the socket token from the store
 @function
 @type {import('@reduxjs/toolkit').OutputSelector<RootState, ISocketState, (res: ISocketState) => string | null>}
 */
export const getSocketToken = createSelector(
  [selectSocketState],
  (state): string | null => state.socketToken
);
