import React, { FC } from 'react';

// Styles
import './privacy-policy.scss';

// Components
import { StaticPages } from '../../modules/static-pages/static-pages';
import {
  ExpertAuthWidgetNavigation
} from '../../shared/ui-components/expert/expert-auth-widget-navigation/expert-auth-widget-navigation';

type TPrivacyPolicyProps = {};

export const PrivacyPolicy: FC<TPrivacyPolicyProps> = (
  {}: TPrivacyPolicyProps
) => {

  return (
    <div className="privacy-policy">
      <div className="privacy-policy--header">
        <div className="header-title">
          Privacy Policy
        </div>
        <ExpertAuthWidgetNavigation/>
      </div>
      <div className="privacy-policy--body">
        <StaticPages staticPageId={ 1 } isDialog={ true }/>
      </div>
    </div>
  );
}
