import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import durationPlugin from 'dayjs/plugin/duration';
import utc from 'dayjs/plugin/utc';

// Shared UI Library Components
import { Timer, CustomButton } from '@ppmcore/seven-ppm-core-shared-components-react';

// Components
import { AuthLayout } from '../auth-modules/auth-layout/auth-layout';

// Actions
import { openNotification } from '../../../store/app-notifications/app-notifications.thunks';

// Thunks
import { resendEmailThunks, clearAuthState } from '../../../store/auth/auth.thunks';

// Styles
import './reset-password-resend.scss';

export const ResetPasswordResend = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch<any>();

  dayjs.extend(durationPlugin);
  dayjs.extend(utc);
  const currentData = dayjs().utc();
  const timerAccess = dayjs().add(1, 'minute').utc();
  const initialTime = 60;

  const [resetEmail, setResetEmail] = useState<string>('');
  const [sendAgainClass, setSendAgainClass] = useState(false);

  const [sendAgainTimer, setSendAgainTimer] = useState(0);

  useEffect(() => {
    const email = localStorage.getItem('reset_password');
    if (email) {
      setResetEmail(email);
    } else {
      cancelAction();
    }
  }, []);

  useEffect(() => {
    const resetPasswordTime = localStorage.getItem('reset_password_time');
    if (resetPasswordTime) {
      const totalSeconds = dayjs(resetPasswordTime).utc().diff(currentData, 'seconds');
      if (totalSeconds > 0) {
        setSendAgainTimer(totalSeconds);
        setSendAgainClass(false);
      }
    } else {
      localStorage.setItem('reset_password_time', timerAccess.format());
      setSendAgainTimer(initialTime);
      setSendAgainClass(false);
    }
  }, []);

  const onSendAgain = () => {
    if (sendAgainClass && resetEmail) {
      dispatch(resendEmailThunks({email: resetEmail}))
        .then((res: { payload: any }) => {
          if (res.payload?.error) {
            dispatch(openNotification({
              description: res.payload?.error,
              type: 'error'
            }));
            return;
          }

          localStorage.setItem('reset_password_time', timerAccess.format());
          setSendAgainTimer(initialTime);
          setSendAgainClass(false);

          dispatch(openNotification({
            description: res.payload.message,
            type: 'success'
          }));
        });
    }
  }

  const onSendAgainTimerEnd = () => {
    setSendAgainClass(true);
  }

  const cancelAction = () => {
    dispatch(clearAuthState()).then(() => navigate(`/sign-in`));
  }

  return (<AuthLayout cardTitle="Email Sent!" rightBarIcon="auth">
    <div className={'reset-password-resend'}>
      <div className={'reset-password-resend-text'}>Please, check your email for a link to reset your password.</div>

      <div className="text-after">Didn't receive an email? <span
        className={sendAgainClass ? 'send-again send-again-active' : 'send-again'}
        onClick={onSendAgain}>Send Again</span> {!sendAgainClass && <span>in <Timer
        initialSeconds={sendAgainTimer} onTimerEnd={onSendAgainTimerEnd}/> seconds.</span>}
      </div>

      <div className="form-bottom">
        <CustomButton text={'Back to Log In'} type="text" size={'medium'} onClick={() => cancelAction()} />
      </div>
    </div>
  </AuthLayout>);
}
