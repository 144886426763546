import { FC, HTMLProps, useState } from 'react';
import dayjs from 'dayjs';
import Linkify from 'linkify-react';

// Shared UI Library Components
import { CustomIcon, EIconName, Loader, UserAvatar, CustomTooltip } from '@ppmcore/seven-ppm-core-shared-components-react';

// Styles
import './message-item.scss';

// Models
import { IMessageItem } from '../../../../interfaces/chat.interfaces';

type TMessageItemProps = HTMLProps<HTMLDivElement> & {
  messageInfo: IMessageItem;
  isActiveCounsellor: boolean;
  rate?: string;
  onResend?: (message: IMessageItem) => void;
}

export const MessageItem: FC<TMessageItemProps> = (
  {
    messageInfo,
    isActiveCounsellor,
    rate = '',
    onResend = () => {},
    ...props
  }: TMessageItemProps
) => {

  const [savingChanges, setSavingChanges] = useState<boolean>(false);

  const {
    user,
    worker,
    created_at,
    message,
    message_price,
    sender_type,
    replied_message_id,
    is_message_replied,
    expired_at,
    is_resent,
  } = messageInfo;
  const isMy = sender_type === 'user';
  const avatarSrc = isMy ? user?.photo : worker?.photo;
  const isExpired = dayjs().isAfter(expired_at);
  const messageClass = isMy ? 'my-message' : '';

  const onResendHandler = async (): Promise<void> => {
    setSavingChanges(true);
    await onResend(messageInfo);
    setSavingChanges(false);
  }

  return (
    <div className={ `message-item ${ messageClass }` } { ...props }>
      <div className="item-avatar">
        <UserAvatar sizeClass={ 'small' } avatarUrl={ avatarSrc } hasVideo={ false }/>
      </div>
      <div className="item-info">
        <div className="item-info--header">

          { (isMy && !savingChanges && isExpired && !is_message_replied && !is_resent && isActiveCounsellor) &&
            <div className="header-actions">
              <CustomTooltip title={ 'Resend Request' }>
                <div className="header-actions--item" onClick={ onResendHandler }>
                  <CustomIcon name={ EIconName.Refresh_fill }/>
                </div>
              </CustomTooltip>
            </div> }

          { savingChanges && <div className="header-actions">
            <div className="header-actions--item loader-item">
              <Loader size={ 'small' }/>
            </div>
          </div> }

          <div className="header-date">
            { dayjs(created_at).format('hh:mm A') }
          </div>
          <div className="header-type">
            <CustomIcon name={ EIconName.Message } size={ 'size-md' }/>{ isMy ?
            <span><b>Request</b></span> :
            <span><b>Response</b> to Request from { dayjs(replied_message_id?.created_at).format('DD MMM YYYY hh:mm A') }</span> }
          </div>
          { (isMy && message_price) && <div className="header-price">
            { message_price }
          </div> }
        </div>
        <div className="item-info--body">
          <div className="body-message">
            <Linkify options={ { target: '_blank' } }>{ message }</Linkify>
          </div>
        </div>
      </div>
    </div>
  );
};
