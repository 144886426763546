import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Models
import { IExpertItem, IExpertProfile } from '../../interfaces/experts.interfaces';

export interface ICurrentExpertState {
  expert: IExpertProfile | null;
  loading: boolean;
  error: string | null;
}

const initialState: ICurrentExpertState = {
  expert: null,
  loading: true,
  error: null,
};

export const currentExpertSlice = createSlice({
  name: 'current-expert',
  initialState,
  reducers: {
    loadExpertData: (state) => {
      state.loading = true;
      state.error = null;
    },
    loadExpertDataSuccess: (state, { payload }: PayloadAction<IExpertProfile>) => {
      state.expert = payload;
      state.loading = false;
      state.error = null;
    },
    loadExpertDataError: (state, { payload: error }: PayloadAction<string>) => {
      state.loading = false;
      state.error = error;
    },
    updateExpertStatusFromSocket: (state, { payload: expert }: PayloadAction<IExpertProfile>) => {
      if (state?.expert?.id !== expert.id) return;
      state.expert = expert;
    },
    deleteExpertStatusFromSocket: (state) => {
      state.expert = null;
      state.loading = true;
      state.error = null;
    },
    toggleBookmarkStatus: (state, { payload: status }: PayloadAction<boolean>) => {
      if (!state.expert) return;
      state.expert = {
        ...state.expert,
        is_bookmarked: status
      };
    },
    clearExpert: (state) => {
      state.expert = null;
      state.loading = true;
      state.error = null;
    },
  }
});

export const CurrentExpertSliceActions = currentExpertSlice.actions;
