// API
import { api } from '../configs/api/api';

// Models
import { ICurrency } from '../interfaces/company.interfaces';

export const CurrencyService = {
  fetchCurrentCurrency: async (): Promise<{ currency?: ICurrency, company_account_id?: string | null, error?: string }> => {
    try {
      const axiosResponse = await api.get('/company-currency', {});
      return axiosResponse.data;
    } catch (error: any) {
      const res = error?.response?.data;
      if (error?.response.status === 500) {
        return { error: res?.message };
      }
      return { ...res };
    }
  },
  fetchCompanyCurrencies: async (): Promise<{
    currency?: ICurrency[],
    error?: string
  }> => {
    try {
      const response = await api.get('/currencies');
      return response.data;
    } catch (error: any) {
      const res = error?.response?.data;
      if (error?.response.status === 500) {
        return { error: res?.message };
      }
      return { ...res };
    }
  },
  updateCompanyCurrency: async (currency: string): Promise<{
    currency?: ICurrency,
    error?: string
  }> => {
    try {
      const response = await api.post('/company/update-company-currency', { currency });
      return response.data;
    } catch (error: any) {
      const res = error?.response?.data || { error: error?.message };
      if (error?.response?.status === 500) {
        return { error: res?.message };
      }
      return { ...res };
    }
  },
}
