import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// Components
import {
  CustomIcon,
  CustomIconBig,
  EIconBigName,
  EIconColor,
  EIconName, SpinElement
} from '@ppmcore/seven-ppm-core-shared-components-react';

// Thunks
import { clearCallStore, getCallInfo, rejectCall } from '../../../store/call/call.thunks';

// Selectors
import { getCallState } from '../../../store/call/call.selectors';
import { getLastMessageData } from '../../../store/socket/socket.selectors';

// Enums
import { EWebSocketTypes } from '../../../enums/web-socket-types.enums';

// Styles
import './call-outgoing.scss';

export const CallOutgoing = () => {

  const dispatch = useDispatch<any>();
  const navigate = useNavigate();

  const temporaryToken = localStorage.getItem('temporary_token');

  const {callProcess} = useSelector(getCallState);
  const lastMessage = useSelector(getLastMessageData);

  const callId = localStorage.getItem('call_id');

  useEffect(() => {
    if (!callProcess && callId) {
      dispatch(getCallInfo({call_id: +callId, temporary_token: temporaryToken ?? ''}));
    }
  }, [callProcess]);

  useEffect(() => {
    switch (lastMessage?.type) {
      case EWebSocketTypes.CallNotAnswered:
        dispatch(clearCallStore(true)).then(() => {
          navigate(`/call/call-missed`);
        });
        break;
      case EWebSocketTypes.CallRejected:
        dispatch(clearCallStore(true)).then(() => {
          navigate(`/call/call-declined`);
        });
        break;
      case EWebSocketTypes.ApproveCall:
        if (callId) dispatch(getCallInfo({call_id: +callId, temporary_token: temporaryToken ?? ''}))
          .then(() => navigate(`/call/${callProcess?.call_id}`));
        break;
      default:
        break;
    }
  }, [lastMessage]);

  const cancelCall = () => {
    if (callProcess) dispatch(rejectCall({call_id: callProcess.call_id, temporary_token: temporaryToken ?? ''}))
      .then(() => dispatch(clearCallStore(true)))
      .then(() => {
        navigate(`/call/not-answered`);
      })
  }

  return (<div className={'call-outgoing'}>
    {(!callProcess) && <SpinElement/>}

    {callProcess && <div className={'call-outgoing-inside'}>
      <div className={'photo'}>
        {callProcess.worker?.photo
        ? <img src={callProcess.worker?.photo} alt="" />
        : <div className={'default-image'}>
          <CustomIconBig name={EIconBigName.NoVideoAvatar}/>
        </div>}
      </div>
      <div className={'fio'}>{callProcess.worker?.first_name} {callProcess.worker?.last_name}</div>
      <div className={'calling-text'}> Calling <span className="dot"></span></div>
      <div className={'cancel-call'} onClick={cancelCall}>
        <CustomIcon name={EIconName.CallEnd} color={EIconColor.White} />
      </div>
    </div>}
  </div>);
}
