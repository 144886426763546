import { createSelector } from '@reduxjs/toolkit';

// Models
import { IAppNotificationsState } from './app-notifications.slice';
import { RootState } from '../index';

/**
 A selector function to retrieve the app notifications data from the store
 @function
 @param {RootState} state - The root state of the Redux store
 @returns {IAppNotificationsState} The app notifications state object from the Redux store
 */
const selectNotificationsData = (state: RootState): IAppNotificationsState => state.appNotifications;

/**
 A memoized selector function to retrieve the app notifications data from the store
 @function
 @type {import('@reduxjs/toolkit').OutputSelector<RootState, IAppNotificationsState, (res: IAppNotificationsState) => IAppNotificationsState>}
 */
export const getNotificationsData = createSelector(
  [selectNotificationsData],
  (state): IAppNotificationsState => state
);
