import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Models
import { IExpertFilters, IExpertItem } from '../../interfaces/experts.interfaces';

export type TExpertsList = 'all' | 'favorites' | 'history';

export interface IExpertsState {
  experts: {
    list: IExpertItem[];
    total: number | null;
    total_all: number | null;
    isLoad: boolean;
    isError: boolean;
  },
  filters: IExpertFilters,
}

const initialState: IExpertsState = {
  experts: {
    list: [],
    total: null,
    total_all: 0,
    isLoad: false,
    isError: false,
  },
  filters: {
    offset: 0,
    limit: 20,
  }
};

export const expertsSlice = createSlice({
  name: 'experts',
  initialState,
  reducers: {
    loadExpertsList: (state, { payload }: PayloadAction<{ type: TExpertsList }>) => {
      state.experts = {
        ...state.experts,
        isLoad: true,
        isError: false
      }
    },
    loadExpertsListSuccess: (state, { payload }: PayloadAction<{
      list: IExpertItem[],
      total: number,
      total_all: number,
      type: TExpertsList,
      isLoadMore?: boolean
    }>) => {
      state.experts = {
        list: payload.isLoadMore ? [...state.experts.list, ...payload.list] : payload.list,
        total: payload.total,
        total_all: payload.total_all,
        isError: false,
        isLoad: false
      };
    },
    loadExpertsListError: (state, { payload }: PayloadAction<{ type: TExpertsList }>) => {
      state.experts = {
        ...state.experts,
        isError: true,
        isLoad: false
      };
    },
    updateExpertStatusFromSocket: (state, { payload }: PayloadAction<IExpertItem>) => {
      const isNewItem = state.experts.list.find(expert => expert.id === payload.id);
      if (!isNewItem && !payload.is_blocked && payload.is_active) {
        state.experts.list = [...state.experts.list, payload];
        return;
      }
      if (payload?.is_blocked || !payload.is_active) {
        state.experts.list = state.experts.list
          .filter(counsellor => counsellor.id !== payload.id);
        return;
      }
      state.experts.list = state.experts.list
        .map(counsellor => counsellor.id === payload.id ? { ...counsellor, ...payload } : counsellor);
    },
    deleteExpertStatusFromSocket: (state, { payload }: PayloadAction<IExpertItem>) => {
      const isNewItem = state.experts.list.find(expert => expert.id === payload.id);
      if (!isNewItem) {
        return;
      }
      state.experts.list = state.experts.list.filter(counsellor => counsellor.id !== payload.id);
    },
    updateExpertMessagesHasUnreadState: (state, { payload: { expertId, has_unread_messages } }: PayloadAction<{
      expertId: number | string,
      has_unread_messages: boolean
    }>) => {
      state.experts.list = state.experts.list.map(counsellor => counsellor.id === expertId ? {
        ...counsellor,
        has_unread_messages
      } : counsellor);
    },
    setExpertsFilters: (state, { payload }: PayloadAction<IExpertFilters>) => {
      state.filters = payload;
    },
    changeBookmarkTotal: (state, { payload: bookmarked }: PayloadAction<boolean>) => {
      state.experts = {
        ...state.experts,
        total: bookmarked ? (state.experts.total ?? 0) + 1 : (state.experts.total ?? 0) - 1
      };
    },
  }
});

export const ExpertsSliceActions = expertsSlice.actions;
