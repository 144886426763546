import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Models
import { IHistory } from '../../interfaces/history.interfaces';
import {
  IAppointmentHistory,
  ICallNotification,
  IConversationHistory,
  IMessageNotification
} from '../../interfaces/chat.interfaces';

export interface IHistoryState {
  history: Array<IHistory | IConversationHistory | IMessageNotification | ICallNotification | IAppointmentHistory>;
  total: number;
  total_all: number;
  isFirstLoading: boolean;
  loading: boolean;
  error: string | null;
}

const initialState: IHistoryState = {
  history: [],
  total: 0,
  total_all: 0,
  isFirstLoading: false,
  loading: false,
  error: null,
};

export const historySlice = createSlice({
  name: 'history',
  initialState,
  reducers: {
    loadHistory: (state, { payload: isFirstLoading }: PayloadAction<boolean>) => {
      state.isFirstLoading = isFirstLoading;
      state.loading = true;
      state.error = null;
    },
    loadHistorySuccess: (state, { payload: { history, isLoadMore, total, total_all } }: PayloadAction<{
      history: Array<IHistory | IConversationHistory | IMessageNotification | ICallNotification>,
      total: number,
      total_all: number,
      isLoadMore: boolean,
    }>) => {
      state.history = isLoadMore ? [...state.history, ...history] : history;
      state.total = total;
      state.total_all = total_all;
      state.isFirstLoading = false;
      state.loading = false;
      state.error = null;
    },
    loadHistoryError: (state, { payload: error }: PayloadAction<string>) => {
      state.loading = false;
      state.error = error;
    },
    addHistoryNotificationFromSocket: (state, {
      payload: { history }
    }: PayloadAction<{ history: IMessageNotification }>) => {
      state.history = [history, ...state.history];
      state.total = state.total + 1;
      state.total_all = state.total_all + 1;
    },
    AddHistoryAppointmentFromSocket: (state, {
      payload: history
    }: PayloadAction<IAppointmentHistory>) => {
      state.history = [history, ...state.history];
      state.total = state.total + 1;
      state.total_all = state.total_all + 1;
    },
    addHistoryFromResend: (state, {
      payload: {
        history, prevId
      }
    }: PayloadAction<{ history: IHistory, prevId: number }>) => {
      state.history = [
        history,
        ...state.history.map(item => item.id === prevId ? { ...item, is_resent: true } : item)
      ];
      state.total = state.total + 1;
      state.total_all = state.total_all + 1;
    },
    addHistoryFromSocket: (state, { payload: history }: PayloadAction<IHistory>) => {
      state.history = [history, ...state.history];
      state.total = state.total + 1;
    },
    clearHistory: (state) => {
      state.history = [];
      state.total = 0;
      state.total_all = 0;
      state.isFirstLoading = false;
      state.loading = false;
      state.error = null;
    },
  }
});

export const HistorySliceActions = historySlice.actions;
