import { FC, HTMLProps, useMemo } from 'react';
import dayjs from 'dayjs';

// Shared UI Library Components
import { CustomIcon, EIconName, tzDayjs } from '@ppmcore/seven-ppm-core-shared-components-react';

// Styles
import './appointment-notification.scss';

// Models
import { IAppointmentHistory } from '../../../../interfaces/chat.interfaces';

type TAppointmentNotificationProps = HTMLProps<HTMLDivElement> & {
  history: IAppointmentHistory;
}

export const AppointmentNotification: FC<TAppointmentNotificationProps> = (
  { history, ...props }: TAppointmentNotificationProps
) => {
  const { created_at } = history;

  const notificationMessage = useMemo(() => {
    const workerName = `${ history.worker.first_name } ${ history.worker.last_name }`;
    const date = `${ tzDayjs(history.started_at).format('dddd, MMM D [at] hh:mm A') } - ${ tzDayjs(history.finished_at).format('hh:mm A') }`;

    return `Appointment with ${ workerName } for ${ date } has been ${ history.status }`;
  }, [history]);

  return (
    <div className="appointment-notification" { ...props }>
      <div className="appointment-notification--body">
        <div className="body-item body-item--time">
          { dayjs(created_at).format('hh:mm A') }
        </div>
        <div className="body-item body-item--icon">
          <CustomIcon name={ EIconName.NotificationsActiveFill } size={ 'size-md' }/>
        </div>
        <div className="body-item body-item--message">
          { notificationMessage }
        </div>
      </div>
    </div>
  );
};

