import { FC, HTMLProps, useState } from 'react';
import dayjs from 'dayjs';
import Linkify from 'linkify-react';

// Shared UI Library Components
import {
  CustomIcon,
  CustomTooltip,
  EIconName,
  Loader,
  UserAvatar
} from '@ppmcore/seven-ppm-core-shared-components-react';

// Styles
import './request.scss';

// Models
import { IHistory } from '../../../../interfaces/history.interfaces';

type TRequestProps = HTMLProps<HTMLDivElement> & {
  history: IHistory;
  isActiveCounsellor: boolean;
  onResend?: (history: IHistory) => void;
}

export const Request: FC<TRequestProps> = (
  {
    history,
    isActiveCounsellor,
    onResend = () => {},
    ...props
  }: TRequestProps
) => {

  const [savingChanges, setSavingChanges] = useState<boolean>(false);

  const {
    sender_type,
    user,
    worker,
    created_at,
    finished_at,
    total,
    message,
    is_replied,
    is_resent
  } = history;
  const isMy = sender_type === 'user';
  const avatarSrc = isMy ? user?.photo : worker?.photo;
  const isExpired = dayjs().isAfter(finished_at);

  const onResendHandler = async (): Promise<void> => {
    setSavingChanges(true);
    await onResend(history);
    setSavingChanges(false);
  }

  return (
    <div className={ `request ${ isMy ? 'my-request' : '' }` } { ...props }>
      <div className="item-avatar">
        <UserAvatar sizeClass={ 'small' } avatarUrl={ avatarSrc } hasVideo={ false }/>
      </div>
      <div className="item-info">
        <div className="item-info--header">

          { (isMy && !is_replied && !savingChanges && !is_resent && isExpired && isActiveCounsellor) &&
            <div className="header-actions">
              <CustomTooltip title={ 'Resend Request' }>
              <div className="header-actions--item" onClick={ onResendHandler }>
                <CustomIcon name={ EIconName.Refresh_fill }/>
              </div>
              </CustomTooltip>
            </div> }

          { savingChanges && <div className="header-actions">
            <div className="header-actions--item loader-item">
              <Loader size={ 'small' }/>
            </div>
          </div> }

          <div className="header-date">
            { dayjs(created_at).format('hh:mm A') }
          </div>
          <div className="header-type">
            <CustomIcon name={ EIconName.Message } size={ 'size-md' }/>{ isMy ?
            <span><b>Request</b></span> :
            <span><b>Response</b> to Request from { dayjs(created_at).format('DD MMM YYYY hh:mm A') }</span> }
          </div>
          { isMy && <div className="header-price">
            { total ?? '-' }
          </div> }
        </div>
        <div className="item-info--body">
          <div className="body-message">
            <Linkify options={ { target: '_blank' } }>{ message }</Linkify>
          </div>
        </div>
      </div>
    </div>
  );
};
