import { AnyAction, combineReducers, configureStore} from '@reduxjs/toolkit';

// Reducers & Slices
import { coreSlice } from './core/core.slice';
import { userSlice } from './user/user.slice';
import { appNotificationsSlice } from './app-notifications/app-notifications.slice';
import { notificationsSettingsSlice } from './notifications-settings/notifications-settings.slice';
import { notificationsSlice } from './notifications/notifications.slice';
import { paymentsSlice } from './payments/payments.slice';
import { loaderSlice } from './loader/loader.slice';
import { socketSlice } from './socket/socket.slice';
import { expertsSlice } from './experts/experts.slice';
import { currentExpertSlice } from './currentExpert/currentExpert.slice';
import { historySlice } from './history/history.slice';
import { transactionsSlice } from './transactions/transactions.slice';
import { authSlice } from './auth/auth.slice';
import { profileSettingsSlice } from './profile-settings/profile-settings.slice';
import { messagesSlice } from './messages/messages.slice';
import { paymentsTypesSlice } from './payments-types/payments-types.slice';
import { callSlice } from './call/call.slice';
import { callFinishedSlice } from './call-finished/call-finished.slice';
import { staticPagesSlice } from './static-pages/static-pages.slice';
import { currencySlice } from './currency/currency.slice';
import { sidebarSlice } from './sidebar/sidebar.slice';
import { ongoingConsultationSlice } from './ongoing-consultation/ongoing-consultation.slice';
import { widgetSlice } from './widget/widget.slice';
import { chatSlice } from './chat/chat.slice';
import { chatFinishedSlice } from './chat-finished/chat-finished.slice';
import { dateSlice } from "./date/date.slice";
import { appointmentsSlice } from "./appointments/appointments.slice";
import { activeAppointmentsSlice } from "./active-appointments/active-appointments.slice";

const reducers = {
  core: coreSlice.reducer,
  history: historySlice.reducer,
  transactions: transactionsSlice.reducer,
  auth: authSlice.reducer,
  user: userSlice.reducer,
  profileSettings: profileSettingsSlice.reducer,
  appNotifications: appNotificationsSlice.reducer,
  notifications: notificationsSlice.reducer,
  notificationsSettings: notificationsSettingsSlice.reducer,
  payments: paymentsSlice.reducer,
  paymentsTypes: paymentsTypesSlice.reducer,
  messages: messagesSlice.reducer,
  loader: loaderSlice.reducer,
  socket: socketSlice.reducer,
  experts: expertsSlice.reducer,
  currentExpert: currentExpertSlice.reducer,
  callProcess: callSlice.reducer,
  callFinished: callFinishedSlice.reducer,
  chatProcess: chatSlice.reducer,
  chatFinished: chatFinishedSlice.reducer,
  staticPages: staticPagesSlice.reducer,
  currency: currencySlice.reducer,
  sidebar: sidebarSlice.reducer,
  ongoingConsultation: ongoingConsultationSlice.reducer,
  widget: widgetSlice.reducer,
  date: dateSlice.reducer,
  appointments: appointmentsSlice.reducer,
  activeAppointments: activeAppointmentsSlice.reducer,
}

const rootReducer = combineReducers(reducers);

const resetRootReducer = (state: any, action: AnyAction) => {
  if (action.type === 'user/logoutSuccess' || action.type === 'user/deleteLogoutRequest') {
    return rootReducer(undefined, action);
  }
  return rootReducer(state, action);
};

export const store = configureStore({
  reducer: resetRootReducer,
  devTools: process.env.NODE_ENV !== 'production',
});

export type RootState = ReturnType<typeof store.getState>;
