import { createAsyncThunk } from '@reduxjs/toolkit';

// Services
import { CallService } from '../../services/call.service';
import { ChatsService } from '../../services/chats.service';

// Slices
import { CallSliceActions } from './call.slice';

// Store actions
import { openNotification } from '../app-notifications/app-notifications.thunks';

// Thunks
import { clearSocketState } from '../socket/socket.thunks';

// Interfaces
import {
  ICallProcessCreateRequest, ICallProcessJoinRequest,
  ICallProcessStartRequest,
  ICallProcessUpdateRequest, TCallState, TCallStatus
} from '../../interfaces/call.interfaces';
import { ICallMessage } from '../../interfaces/chat.interfaces';

export enum ECallProcessThunks {
  CallInfo = 'CALL_PROCESS/callInfo',
  CallInfoClearRequest = 'CALL_PROCESS/callInfoClearRequest',
  CreateCall = 'CALL_PROCESS/createCall',
  UpdateCall = 'CALL_PROCESS/updateCall',
  StartCall = 'CALL_PROCESS/startCall',
  JoinToCall = 'CALL_PROCESS/joinToCall',
  RejectCall = 'CALL_PROCESS/rejectCall',
  ChangeDeviceStatus = 'CALL_PROCESS/changeDeviceStatus',
  ChargeCallTimeslot = 'CALL_PROCESS/chargeCallTimeslot',
  ChangeAudioVideoStatus = 'CALL_PROCESS/changeAudioVideoStatus',
  ChangeCallStatus = 'CALL_PROCESS/changeCallStatus',
  CallReconnectedStatus = 'CALL_PROCESS/callReconnectedStatus',
  ChangeTimeLeftDialog = 'CALL_PROCESS/changeTimeLeftDialog',
  SendCallMessage = 'CALL_PROCESS/sendCallMessage',
  AddCallMessageFromSocket = 'CALL_PROCESS/addCallMessageFromSocket',
  ChangeChatSectionStatus = 'CALL_PROCESS/changeChatSectionStatus',
  SetMessagesList = 'CALL_PROCESS/setMessagesList',
  ClearCallStore = 'CALL_PROCESS/clearCallStore',
  SetCallState = 'CALL_PROCESS/setCallState',
}

export const getCallInfo = createAsyncThunk(
  ECallProcessThunks.CallInfo,
  async ({ call_id, temporary_token }: { call_id: number; temporary_token: string }, { dispatch }: any) => {
    dispatch(CallSliceActions.getCallRequest());
    const res = await CallService.getCallInfo(call_id, temporary_token);
    if (res.status !== 200) {
      dispatch(CallSliceActions.getCallFailure());
      return res.data;
    }

    const audio_status = localStorage.getItem('audio_status');
    if (audio_status) {
      dispatch(changeAudioVideoStatus({ device_status: JSON.parse(audio_status), device_type: 'audio' }));
    } else {
      const microphoneSwitcherStatus = res.data.call.user_device_settings?.is_user_microphone_enabled === 1 ?? false;
      dispatch(changeAudioVideoStatus({ device_status: microphoneSwitcherStatus, device_type: 'audio' }));
    }

    if (res.data.call.call_type === 'video_call') {
      const video_status = localStorage.getItem('video_status');
      if (video_status) {
        dispatch(changeAudioVideoStatus({ device_status: JSON.parse(video_status), device_type: 'video' }));
      } else {
        const cameraSwitcherStatus = res.data.call.user_device_settings?.is_user_camera_enabled === 1 ?? false;
        dispatch(changeAudioVideoStatus({ device_status: cameraSwitcherStatus, device_type: 'video' }));
      }
    }

    dispatch(setMessagesList(res.data.call?.chat_history ?? []));
    dispatch(CallSliceActions.getCallSuccess(res.data));

    return res.data;
  }
);

export const getCallInfoClearRequest = createAsyncThunk(
  ECallProcessThunks.CallInfoClearRequest,
  async ({ call_id, temporary_token }: { call_id: number; temporary_token: string }, _) => {
    const res = await CallService.getCallInfo(call_id, temporary_token);
    return res.data;
  }
);

export const createCall = createAsyncThunk(
  ECallProcessThunks.CreateCall,
  async (createCallData: ICallProcessCreateRequest, { dispatch }: any) => {
    dispatch(CallSliceActions.createCallRequest());
    const res = await CallService.createCall(createCallData);
    if (res.status === 425) {
      dispatch(clearCallStore(true)).then(() => {
        window.location.href = `/call/counsellor-unavailable-no-payment`;
      });
    }
    if (res.status === 426) {
      dispatch(clearCallStore(true)).then(() => {
        window.location.href = `/call/counsellor-in-call-no-payment`;
      });
    }
    if (res.status !== 200) {
      dispatch(CallSliceActions.createCallFailure());
      return res.data;
    }
    dispatch(CallSliceActions.createCallSuccess(res.data));
    return res.data;
  }
);

export const updateCall = createAsyncThunk(
  ECallProcessThunks.UpdateCall,
  async (updateCallData: ICallProcessUpdateRequest, { dispatch }: any) => {
    dispatch(CallSliceActions.updateCallRequest());
    const res = await CallService.updateCall(updateCallData);
    if (res.status === 425) {
      dispatch(clearCallStore(true)).then(() => {
        window.location.href = `/call/counsellor-unavailable-no-payment`;
      });
    }
    if (res.status === 426) {
      dispatch(clearCallStore(true)).then(() => {
        window.location.href = `/call/counsellor-in-call-no-payment`;
      });
    }
    if (res.status !== 200) {
      dispatch(CallSliceActions.updateCallFailure());
      return res.data;
    }
    dispatch(CallSliceActions.updateCallSuccess(res.data));
    return res.data;
  }
);

export const joinToCall = createAsyncThunk(
  ECallProcessThunks.JoinToCall,
  async (startCallData: ICallProcessJoinRequest, { dispatch }: any) => {
    dispatch(CallSliceActions.startCallRequest());
    const res = await CallService.joinToCall(startCallData);
    if (res.status === 425) {
      dispatch(clearCallStore(true)).then(() => {
        window.location.href = `/call/counsellor-unavailable`;
      });
    }
    if (res.status === 426) {
      dispatch(clearCallStore(true)).then(() => {
        window.location.href = `/call/counsellor-in-call`;
      });
    }
    if (res.status !== 200) {
      dispatch(CallSliceActions.startCallFailure());
      return res.data;
    }
    dispatch(CallSliceActions.startCallSuccess(res.data.call));
    return res.data.call;
  }
);

export const startCall = createAsyncThunk(
  ECallProcessThunks.StartCall,
  async (startCallData: ICallProcessStartRequest, { dispatch }: any) => {
    dispatch(CallSliceActions.startCallRequest());
    const res = await CallService.startCall(startCallData);
    if (res.status === 425) {
      dispatch(clearCallStore(true)).then(() => {
        window.location.href = `/call/counsellor-unavailable`;
      });
    }
    if (res.status === 426) {
      dispatch(clearCallStore(true)).then(() => {
        window.location.href = `/call/counsellor-in-call`;
      });
    }
    if (res.status !== 200) {
      dispatch(CallSliceActions.startCallFailure());
      return res.data;
    }
    dispatch(CallSliceActions.startCallSuccess(res.data.call));
    return res.data.call;
  }
);

export const rejectCall = createAsyncThunk(
  ECallProcessThunks.RejectCall,
  async ({ call_id, temporary_token }: { call_id: number, temporary_token: string }, { dispatch }: any) => {
    dispatch(CallSliceActions.rejectCallRequest());
    const res = await CallService.rejectCall(call_id, temporary_token);
    if (res.status !== 200) {
      dispatch(CallSliceActions.rejectCallFailure());
    }
    dispatch(CallSliceActions.rejectCallSuccess(res.data.call));
  }
);

export const setCallState = createAsyncThunk(
  ECallProcessThunks.SetCallState,
  async (callState: TCallState, { dispatch }: any) => {
    dispatch(CallSliceActions.setCallState(callState));
  }
);

export const chargeCallTimeslot = createAsyncThunk(
  ECallProcessThunks.ChargeCallTimeslot,
  async (chargeTimeslotData: ICallProcessStartRequest, { dispatch }: any) => {
    const { data, status } = await CallService.chargeCallTimeslot(chargeTimeslotData);
    if (status !== 200) {
      return data;
    }

    if (data?.call?.call_session_type === 'pay_per_session') {
      dispatch(CallSliceActions.updateCallSuccess(data.call));
    }

    return data;
  }
);

export const cancelNextPayment = createAsyncThunk(
  ECallProcessThunks.ChargeCallTimeslot,
  async ({ call_id, temporary_token }: { call_id: number, temporary_token: string }, _) => {
    const res = await CallService.cancelNextPayment(call_id, temporary_token);
    if (res.status !== 200) {
      return res.data;
    }
    return res.data;
  }
);

export const changeDeviceStatus = createAsyncThunk(
  ECallProcessThunks.ChangeDeviceStatus,
  async ({ call_id, user_device_settings, temporary_token }
      : {
      call_id: number,
      user_device_settings: { is_user_microphone_enabled: number, is_user_camera_enabled: number },
      temporary_token: string
    },
    { dispatch }: any) => {
    dispatch(CallSliceActions.changeDeviceStatusRequest());
    const res = await CallService.changeDeviceStatus(call_id, user_device_settings, temporary_token);
    if (res.status !== 200) {
      dispatch(CallSliceActions.changeDeviceStatusFailure());
      return res.data;
    }
    dispatch(CallSliceActions.changeDeviceStatusSuccess(res.data.user_profile));
    return res.data;
  }
);

export const callReconnected = createAsyncThunk(
  ECallProcessThunks.CallReconnectedStatus,
  async ({ call_id }: { call_id: number }, _: any) => {
    return await CallService.callReconnected(call_id);
  }
);

export const changeAudioVideoStatus = createAsyncThunk(
  ECallProcessThunks.ChangeAudioVideoStatus,
  async ({ device_status, device_type }: {
    device_status: boolean,
    device_type: 'audio' | 'video'
  }, { dispatch }: any) => {
    if (device_type === 'audio') {
      localStorage.setItem('audio_status', device_status + '');
      dispatch(CallSliceActions.changeCallProcessAudio(device_status));
    }
    if (device_type === 'video') {
      localStorage.setItem('video_status', device_status + '');
      dispatch(CallSliceActions.changeCallProcessVideo(device_status));
    }
  }
);

export const changeCallStatus = createAsyncThunk(
  ECallProcessThunks.ChangeCallStatus,
  async (newStatus: TCallStatus, { dispatch }: any) => {
    dispatch(CallSliceActions.changeCallStatus(newStatus));
  }
);

export const changePayPerSessionTimeLeftDialog = createAsyncThunk(
  ECallProcessThunks.ChangeTimeLeftDialog,
  async (status: boolean, { dispatch }: any) => {
    dispatch(CallSliceActions.changePayPerSessionTimeLeftDialog(status));
  }
);

export const sendCallMessage = createAsyncThunk(
  ECallProcessThunks.SendCallMessage,
  async ({ call_id, message, files, temporary_token }: {
    call_id: number | string,
    message: string,
    files: File[],
    temporary_token: string
  }, { dispatch }) => {
    dispatch(CallSliceActions.sendCallMessageRequest());
    const { error, data } = await ChatsService.sendCallMessage(call_id, message, files, temporary_token);
    if (error) {
      dispatch(openNotification({
        type: 'error',
        description: error?.error ?? 'Failed to send message, try again!'
      }));
      return dispatch(CallSliceActions.sendCallMessageRequestFailure(error));
    }
    return dispatch(CallSliceActions.sendCallMessageRequestSuccess(data as ICallMessage));
  }
);

export const addCallMessageFromSocket = createAsyncThunk(
  ECallProcessThunks.AddCallMessageFromSocket,
  async ({ message }: { message: ICallMessage }, { dispatch }) => {
    return dispatch(CallSliceActions.addCallMessageFromSocket(message));
  }
);

export const changeChatSectionStatus = createAsyncThunk(
  ECallProcessThunks.ChangeChatSectionStatus,
  async (status: boolean, { dispatch }) => {
    return dispatch(CallSliceActions.changeChatSectionStatus(status));
  }
);

export const setMessagesList = createAsyncThunk(
  ECallProcessThunks.SetMessagesList,
  async (messages: ICallMessage[], { dispatch }) => {
    return dispatch(CallSliceActions.setMessagesList(messages));
  }
);

export const clearCallStore = createAsyncThunk(
  ECallProcessThunks.ClearCallStore,
  async (clearLocalStorage: boolean, { dispatch }: any) => {
    if (clearLocalStorage) {
      localStorage.removeItem('call_type');
      localStorage.removeItem('call_id');
      localStorage.removeItem('audio_status');
      localStorage.removeItem('video_status');
    }
    dispatch(clearSocketState());
    dispatch(CallSliceActions.clearState());
  }
);
