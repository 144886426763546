import { FC } from 'react';

// Styles
import './messages-list.scss';

// Components
import { Message } from '../message/message';

// Models
import { IHistoryMessage } from '../../../../interfaces/history.interfaces';
import { IMessageUser, IMessageWorker } from '../../../../interfaces/chat.interfaces';

interface IMessageProps {
  messages: IHistoryMessage[];
  user?: IMessageUser;
  worker?: IMessageWorker;
  isReverse?: boolean;
}

export const MessagesList: FC<IMessageProps> = (
  { messages, user,  worker, isReverse = false }: IMessageProps
) => {

  return (
    <div className={ `message-list ${ isReverse ? 'reverse-chat' : '' }` }>
      { messages.map(({ sender_id, message, message_time, message_id }) => {
        const isMy = sender_id === user?.id;
        return <Message key={ message_id } isMy={ isMy }
                        avatarUrl={ isMy ? user?.photo : worker?.photo }
                        name={ isMy ? `${ user?.first_name } ${ user?.last_name }` : `${ worker?.first_name } ${ worker?.last_name }` }
                        message={ message }
                        message_time={ message_time }/>
      }) }
    </div>
  );
};
