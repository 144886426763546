import { FC } from 'react';

// Shared UI Library Components
import {
  CustomIcon,
  EIconName,
  ListEllipsis,
  UserAvatar,
  CustomTooltip,
  EUserAccountStatus
} from '@ppmcore/seven-ppm-core-shared-components-react';

// Styles
import './expert-item.scss';

// Models
import { IExpertItem } from '../../../../interfaces/experts.interfaces';

export interface IExpertItemProps {
  expertInfo: IExpertItem;
}

export const ExpertItem: FC<IExpertItemProps> = (
  {
    expertInfo,
  }: IExpertItemProps
) => {

  const fullName = expertInfo.first_name + ' ' + expertInfo.last_name;
  const specialities = expertInfo?.specialities?.map(_ => _.name);

  const isVideoEnabled = expertInfo.is_video_call_enabled || !!expertInfo.session_rates.call_per_session_rate.length;
  const isAudioEnabled = expertInfo.is_audio_call_enabled || !!expertInfo.session_rates.audio_call_per_session_rate.length;
  const isTextChatEnabled = expertInfo.is_conversation_enabled;

  const getUserStatusIconName = (status: EUserAccountStatus): string => {
    if (status === EUserAccountStatus.Online) {
      return EIconName.OnlineStatus;
    }
    if (status === EUserAccountStatus.Offline) {
      return EIconName.OfflineStatus;
    }
    if (status === EUserAccountStatus.Deactivated) {
      return EIconName.OfflineStatus;
    }
    if (status === EUserAccountStatus.Busy) {
      return EIconName.OnTheCallStatus;
    }
    return EIconName.OfflineStatus;
  };

  const getUserStatusTooltipTitle = (status: EUserAccountStatus): string => {
    if (status === EUserAccountStatus.Online) {
      return 'Online';
    }
    if (status === EUserAccountStatus.Offline) {
      return 'Offline';
    }
    if (status === EUserAccountStatus.Deactivated) {
      return 'Offline';
    }
    if (status === EUserAccountStatus.Busy) {
      return 'On the Call';
    }
    return 'Offline';
  };

  return (
    <div className="expert-item">
      <div className="expert-item-avatar">
        <UserAvatar hasVideo={ !!expertInfo.video } avatarUrl={ expertInfo?.photo } sizeClass={ 'large' }/>

        <div className={ `avatar-badge avatar-badge-${ expertInfo?.worker_status }` }>
          <CustomTooltip placement={ 'bottom' } title={ getUserStatusTooltipTitle(expertInfo?.worker_status) }>
            <CustomIcon size={ 'size-md' } name={ getUserStatusIconName(expertInfo?.worker_status) }/>
          </CustomTooltip>
        </div>
      </div>
      <div className="expert-item-info">
        <div className="info-title">
          <CustomTooltip title={ fullName }>{ fullName }</CustomTooltip>
        </div>
        <div className="info-name">
          <ListEllipsis items={ specialities } hasCounter={ true }/>
        </div>

        <div className="info-prices">
          { isVideoEnabled &&
            <CustomTooltip placement={ 'bottom' } title={ 'Video cost per minute' } className="prices-item">
              <CustomIcon size={ 'size-md' } name={ EIconName.CameraOn }/>
              { !expertInfo?.full_free_service_enabled && <>
                <span className={ 'bold-text' }>{ expertInfo?.call_rate?.text }</span> /min
              </> }
              { !!expertInfo?.full_free_service_enabled && <span className={ 'bold-text' }>Free</span> }
            </CustomTooltip> }
          { isAudioEnabled &&
            <CustomTooltip placement={ 'bottom' } title={ 'Voice cost per minute' } className="prices-item">
              <CustomIcon size={ 'size-md' } name={ EIconName.MicOn }/>
              { !expertInfo?.full_free_service_enabled && <>
                <span className={ 'bold-text' }>{ expertInfo?.audio_call_rate?.text }</span> /min
              </> }
              { !!expertInfo?.full_free_service_enabled && <span className={ 'bold-text' }>Free</span> }
            </CustomTooltip> }
          { isTextChatEnabled &&
            <CustomTooltip placement={ 'bottom' } title={ 'Text Chat cost per minute' } className="prices-item">
              <CustomIcon size={ 'size-md' } name={ EIconName.Chat }/>
              { !expertInfo?.full_free_service_enabled && <>
                <span className={ 'bold-text' }>{ expertInfo?.conversation_rate?.text }</span> /min
              </> }
              { !!expertInfo?.full_free_service_enabled && <span className={ 'bold-text' }>Free</span> }
            </CustomTooltip> }
          { expertInfo?.is_messages_enabled &&
            <CustomTooltip placement={ 'bottom' } title={ 'Correspondence cost per request' } className="prices-item">
              <CustomIcon size={ 'size-md' } name={ EIconName.Message }/>
              { !expertInfo?.full_free_service_enabled && <>
                <span className={ 'bold-text' }>{ expertInfo?.message_rate?.text }</span> /rqst
              </> }
              { !!expertInfo?.full_free_service_enabled && <span className={ 'bold-text' }>Free</span> }
            </CustomTooltip> }
        </div>
      </div>
    </div>
  );
};
