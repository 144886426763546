import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Models
import { IAppointmentCallStarted } from "../../interfaces/appointments.interfaces";

export interface IActiveAppointmentsState {
  appointments: IAppointmentCallStarted[];
  loading: boolean;
  error: null | string;
}

const initialState: IActiveAppointmentsState = {
  appointments: [],
  loading: false,
  error: null,
};

export const activeAppointmentsSlice = createSlice({
  name: 'active-appointments',
  initialState,
  reducers: {
    fetchActiveAppointments(state) {
      state.loading = true;
      state.error = null;
    },
    fetchActiveAppointmentsSuccess: (state, { payload: appointments }: PayloadAction<IAppointmentCallStarted[]>) => {
      state.appointments = appointments;
      state.loading = false;
      state.error = null;
    },
    fetchActiveAppointmentsError: (state) => {
      state.loading = false;
      state.error = null;
    },
    updateActiveAppointmentFromSocket: (state, { payload: appointment }: PayloadAction<IAppointmentCallStarted>) => {
      state.appointments = [...state.appointments, appointment];
    },
    clearState: (state) => {
      state.appointments = [];
      state.loading = true;
      state.error = null;
    },
  }
});

export const ActiveAppointmentsSliceActions = activeAppointmentsSlice.actions;
