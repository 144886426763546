// Shared UI Library Components
import { CustomIcon, EIconName } from '@ppmcore/seven-ppm-core-shared-components-react';

export const CRightTopMenu = [
  {
    label: 'Notifications',
    path: 'notifications',
    icon: <CustomIcon name={ EIconName.NotificationOff }/>,
    activeIcon: <CustomIcon name={ EIconName.NotificationOn }/>
  },
  {
    label: 'Transactions',
    path: 'transactions',
    icon: <CustomIcon name={ EIconName.TransactionsOff }/>,
    activeIcon: <CustomIcon name={ EIconName.TransactionsOn }/>
  },
  {
    label: 'Profile Settings',
    path: 'profile-settings',
    icon: <CustomIcon name={ EIconName.Settings }/>,
    activeIcon: <CustomIcon name={ EIconName.SettingsFill }/>
  },
  {
    label: 'My Appointments',
    path: 'appointments',
    icon: <CustomIcon name={ EIconName.Calendar }/>,
    activeIcon: <CustomIcon name={ EIconName.CalendarFill }/>
  }
];

export const CRightCenterMenu = [
  // {
  //   label: 'FAQ',
  //   path: 'faq',
  //   icon: <CustomIcon name={ EIconName.QuestionMark }/>,
  //   activeIcon: <CustomIcon name={ EIconName.QuestionMarkFill }/>,
  //   section: 'center'
  // },
  {
    label: 'Terms of Service',
    path: 'terms-of-service',
    icon: <CustomIcon name={ EIconName.TermsOfServiceOff }/>,
    activeIcon: <CustomIcon name={ EIconName.TermsOfServiceOn }/>,
    section: 'center'
  },
  {
    label: 'Privacy Policy',
    path: 'privacy-policy',
    icon: <CustomIcon name={ EIconName.PrivacyPolicyOff }/>,
    activeIcon: <CustomIcon name={ EIconName.PrivacyPolicyOn }/>,
    section: 'center'
  },
];

export const CRightBottomMenu = [
  {
    label: 'Logout',
    path: 'logout',
    icon: <CustomIcon name={ EIconName.Logout }/>,
    activeIcon: <CustomIcon name={ EIconName.Logout }/>,
    section: 'bottom'
  }
];
