import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage, Messaging, isSupported } from 'firebase/messaging';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

initializeApp(firebaseConfig);

// const messaging = getMessaging();

export const requestForToken = async () => {
  const supported = await isSupported();
  if (!supported) {
    return null;
  }
  const messaging: Messaging = getMessaging();
  return getToken(messaging, { vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY })
    .then((currentToken) => {
      if (currentToken) {
        localStorage.setItem('device_UDID', currentToken);
        return {
          token: currentToken,
          message: '',
        };
      } else {
        // Show permission request UI
        return {
          token: null,
          message: 'No registration token available. Request permission to generate one.',
        };
      }
    })
    .catch((error) => {
      console.log('error', error)

      if (error?.message.includes('Failed to execute \'subscribe\' on \'PushManager\'')) {
        window.location.reload();
      }

      return {
        token: null,
        message: 'An error occurred while retrieving token. ' + error,
      };
    });
}

// Handle incoming messages. Called when:
// - a message is received while the app has focus
// - the user clicks on an app notification created by a service worker `messaging.onBackgroundMessage` handler.
export const onMessageListener = () =>
  new Promise(async (resolve) => {
    const supported = await isSupported();
    if (!supported) {
      resolve(null);
      return;
    }
    const messaging: Messaging = getMessaging();
    onMessage(messaging, (payload) => {
      try {
        const payloadItems = payload.data?.items;
        if (!payloadItems) return;
        const parsedData = JSON.parse(payloadItems);

        navigator.serviceWorker.getRegistrations().then((registrations) => {
          if (!registrations.length) return;

          if (parsedData.data?.type === 'stopShowingNotifications') {
            return;
          }

          registrations[0]?.showNotification(parsedData.data?.title ?? 'Paypertok - Consumer', {
            body: parsedData?.data?.body,
            icon: './logo192.png',
            actions: [
              { action: 'openApp', title: 'Open Paypertok - Counsellor' }
            ],
            data: parsedData
          });
        });

        resolve(parsedData);
      } catch (error) {
        console.log('onForegroundMessage', error);
        resolve(payload);
      }
    });
  });
