import { createAsyncThunk } from '@reduxjs/toolkit';

// Slices & Thunks
import { WidgetSliceActions } from './widget.slice';

// Models
import { TWidgetType } from '../../types/widget.types';

export enum EWidgetThunks {
  SetWidgetSettings = 'WIDGET/setWidgetSettings',
}

export const setWidgetSettings = createAsyncThunk(
  EWidgetThunks.SetWidgetSettings,
  async (settings: { type: TWidgetType, companyHash: string }, { dispatch }) => {
    return dispatch(WidgetSliceActions.setWidgetSettings(settings));
  }
);
