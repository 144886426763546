import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Models
import { INotificationsSettings } from '../../interfaces/notifications-settings.interfaces';

export interface INotificationsSettingsState {
  settings: INotificationsSettings | null;
  loading: boolean;
  error: string | null;
}

const initialState: INotificationsSettingsState = {
  settings: null,
  loading: true,
  error: null
};

export const notificationsSettingsSlice = createSlice({
  name: 'notifications-settings',
  initialState,
  reducers: {
    loadNotificationsSettings: (state) => {
      state.loading = true;
      state.error = null;
    },
    loadNotificationsSettingsSuccess: (state, { payload: settings }: PayloadAction<INotificationsSettings>) => {
      state.settings = settings;
      state.loading = false;
      state.error = null;
    },
    loadNotificationsSettingsError: (state, { payload: error }: PayloadAction<string>) => {
      state.loading = false;
      state.error = error;
    },
    updateNotificationsSettings: (state) => {
      state.loading = true;
      state.error = null;
    },
    updateNotificationsSettingsSuccess: (state, { payload: settings }: PayloadAction<INotificationsSettings>) => {
      state.settings = settings;
      state.loading = false;
      state.error = null;
    },
    updateNotificationsSettingsError: (state, { payload: error }: PayloadAction<string>) => {
      state.loading = false;
      state.error = error;
    },
  }
});

export const NotificationsSettingsSliceActions = notificationsSettingsSlice.actions;
