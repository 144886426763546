import React, { FC } from 'react';

// Shared UI Library Components
import { CustomIcon, EIconName } from '@ppmcore/seven-ppm-core-shared-components-react';

// Styles
import './expert-status-notifier.scss';

type TExpertStatusNotifierProps = {};

export const ExpertStatusNotifier: FC<TExpertStatusNotifierProps> = ({}: TExpertStatusNotifierProps) => {

  return (
    <div className="expert-status-notifier">
      <div className="expert-status-notifier--icon">
        <CustomIcon name={ EIconName.Info } />
      </div>
      <div className="expert-status-notifier--text">
        The Counsellor is temporarily unavailable
      </div>
    </div>
  );
};
