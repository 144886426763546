// API
import { api } from '../configs/api/api';

// Models
import {
  ICreateDefaultPayment, ICreatedPaymentCard,
  ICreatePayment,
  IPaymentsInfo,
  IPaymentToken
} from '../interfaces/payments.interfaces';

export const PaymentsService = {
  fetchPaymentCards: async (): Promise<{ data?: IPaymentsInfo, error?: string }> => {
    try {
      const axiosResponse = await api.get('/users/stripe-cards');
      return { data: axiosResponse.data };
    } catch (error: any) {
      const res = error?.response?.data;
      if (error?.response.status === 500) {
        return { error: res?.message };
      }
      return { ...res };
    }
  },
  selectPaymentCard: async (card_id: string): Promise<{
    selected_card?: string,
    message?: string,
    error?: string,
    status: string
  }> => {
    try {
      const axiosResponse = await api.post('/users/select-stripe-card', { card_id });
      return axiosResponse.data;
    } catch (error: any) {
      const res = error?.response?.data;
      if (error?.response.status === 500) {
        return { error: res?.message, status: 'error' };
      }
      return { ...res };
    }
  },
  createPaymentCard: async (token: string): Promise<{
    card?: ICreatedPaymentCard,
    error?: string
  }> => {
    try {
      const axiosResponse = await api.post('/users/create-stripe-card', { token });
      return { card: axiosResponse.data };
    } catch (error: any) {
      const res = error?.response?.data;
      if (error?.response.status === 500) {
        return { error: res?.message };
      }
      return { ...res };
    }
  },
  deletePaymentCard: async (card_id: string): Promise<{
    selected_card?: string,
    message?: string,
    error?: string,
  }> => {
    try {
      const axiosResponse = await api.delete('/users/delete-stripe-card', {
        params: { card_id }
      });
      return axiosResponse.data;
    } catch (error: any) {
      const res = error?.response?.data;
      if (error?.response.status === 500) {
        return { error: res?.message };
      }
      return { ...res };
    }
  },
  getClientSecret: async (paymentInfo: ICreatePayment): Promise<{ data?: IPaymentToken, error?: string }> => {
    try {
      const axiosResponse = await api.get(`/${paymentInfo.temporary_token ? 'temporary' : 'users'}/payment-token`, {
        params: {
          ...paymentInfo,
          is_remember_me: Number(paymentInfo.is_remember_me),
          is_wallet: Number(paymentInfo?.is_wallet ?? false)
        }
      });
      return { data: axiosResponse.data };
    } catch (error: any) {
      const res = error?.response?.data;
      if (error?.response.status === 500) {
        return { error: res?.message };
      }
      return { ...res };
    }
  },
  payExistingPaymentCard: async (paymentInfo: ICreateDefaultPayment): Promise<{
    data?: IPaymentToken,
    error?: string
  }> => {
    try {
      const axiosResponse = await api.get('/users/default-payment-token', {
        params: paymentInfo
      });
      return { data: axiosResponse.data };
    } catch (error: any) {
      const res = error?.response?.data;
      if (error?.response.status === 500) {
        return { error: res?.message };
      }
      return { ...res };
    }
  }
}
