import React, { FC, useState } from 'react';
import { useDispatch } from "react-redux";
import { Dropdown, Table, TableProps } from "antd";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";

// Styles
import './appointments-desktop.scss';
import Column from "antd/es/table/Column";
import {
  CustomButton,
  CustomIcon,
  CustomTooltip,
  EIconName,
  tzDayjs,
  UserAvatar
} from "@ppmcore/seven-ppm-core-shared-components-react";

// Components
import { CancelingAppointmentModal } from "../../../../modals/canceling-appointment-modal/canceling-appointment-modal";
import { ViewElementByTime } from "../view-element-by-time/view-element-by-time";

// Store entities
import {
  cancelAppointment,
  fetchAppointments,
  setAppointmentsSettings
} from "../../../../store/appointments/appointments.thunks";
import { getCallInfo } from "../../../../store/call/call.thunks";

// Models
import { ISorter } from "../../../../interfaces/transactions.interfaces";
import { IAppointment, IAppointmentsSettings } from "../../../../interfaces/appointments.interfaces";
import { ELocalStorageKeys } from "../../../../enums/storage.enums";

interface IAppointmentsDesktopProps {
  appointments: IAppointment[];
  appointmentsSettings: IAppointmentsSettings;
  loading?: boolean;
  total?: number;
  total_all?: number;
}

export const AppointmentsDesktop: FC<IAppointmentsDesktopProps> = (
  { appointments, appointmentsSettings, total = 0, total_all = 0, loading = false }: IAppointmentsDesktopProps
): JSX.Element => {
  const [cancelAppointmentModalOpen, setCancelAppointmentModalOpen] = useState<boolean>(false);
  const [actionAppointment, setActionAppointment] = useState<IAppointment | null>(null);
  const [page, setPage] = useState<number>(1);

  const dispatch = useDispatch<any>();
  const navigate = useNavigate();

  const getPageSize = (): number => {
    const windowHeight = window.innerHeight;
    return Math.round((windowHeight - 80) / 100); // 100 - height of column, 80 - another block height & header of table
  }

  const jointAppointmentHandler = (call_id: number, call_type: string, workerId: number): void => {
    localStorage.setItem('call_type', call_type);
    localStorage.setItem('call_id', `${ call_id }`);
    localStorage.setItem('worker_id', `${ workerId }`);

    const temporary_token = localStorage.getItem(ELocalStorageKeys.TemporaryToken) || '';

    dispatch(getCallInfo({ call_id: +call_id, temporary_token }));

    navigate(`/call/devices-settings/${ call_id }`);
  }

  const getSortParams = ({ column, order, columnKey }: ISorter): {
    sort_field: string,
    sort_direction: string
  } => {
    if (!column) return {
      sort_field: '',
      sort_direction: '',
    };
    return {
      sort_field: columnKey as string,
      sort_direction: order ? order.slice(0, -3) : '', // 'ascend' | 'descend'
    };
  }

  const toggleCancelAppointmentModal = (): void => {
    setCancelAppointmentModalOpen((prev) => !prev);
  }

  const cancelAppointmentHandler = (appointment: IAppointment | null): void => {
    setActionAppointment(appointment);
    toggleCancelAppointmentModal();
  }

  const submitCancelAppointmentHandler = async (appointment: IAppointment | null, cancel_notes: string): Promise<void> => {
    const appointment_id = appointment?.id;
    if (!appointment_id) return;

    setActionAppointment(appointment);
    const { payload } = await dispatch(cancelAppointment({
      appointment_id,
      cancel_notes
    }));
    if (!payload) return;
    setActionAppointment(null);
    toggleCancelAppointmentModal();
  }

  const sortTableByField = (sorter: ISorter): void => {
    if (!Object.keys(sorter).length) return;
    const sortParams = getSortParams(sorter);
    const settings = { ...appointmentsSettings, ...sortParams } as IAppointmentsSettings;
    dispatch(setAppointmentsSettings(settings));
    dispatch(fetchAppointments({ offset: 0, limit: getPageSize(), settings }));
  }

  const getAppointmentsStatus = (appointment: IAppointment): {
    status: 'canceled' | 'awaiting' | 'approved' | 'past',
    text: string,
    canCancel?: boolean,
    canApprove?: boolean,
  } => {
    if (appointment.status === 'canceled' || appointment.status === 'rejected') {
      return { status: 'canceled', text: 'Canceled' };
    }

    if (appointment.status === 'finished' ) {
      return { status: 'past', text: 'Past' };
    }

    if (!appointment.approved_at && (dayjs().isBefore(dayjs(appointment.max_cancellation_at)) || dayjs().isBefore(dayjs(appointment.max_approval_at)))) {
      return {
        status: 'awaiting',
        text: 'Awaiting',
        canCancel: dayjs().isBefore(dayjs(appointment.max_cancellation_at)) && appointment.status === 'created',
        canApprove: dayjs().isBefore(dayjs(appointment.max_approval_at))
      };
    }

    if (!!appointment.approved_at && dayjs().isAfter(dayjs(appointment.started_at)) && dayjs().isBefore(dayjs(appointment.finished_at))) {
      return {
        status: 'approved',
        text: 'In progress',
        canCancel: dayjs().isBefore(dayjs(appointment.max_cancellation_at)) && appointment.status === 'created',
        canApprove: dayjs().isBefore(dayjs(appointment.max_approval_at))
      };
    }

    if (!!appointment.approved_at && dayjs().isBefore(appointment.finished_at)) {
      return {
        status: 'approved',
        text: 'Future',
        canCancel: dayjs().isBefore(dayjs(appointment.max_cancellation_at)) && appointment.status === 'created',
        canApprove: dayjs().isBefore(dayjs(appointment.max_approval_at))
      };
    }

    return { status: 'past', text: 'Past' };
  }

  const onChange: TableProps<any>['onChange'] = (
    pagination,
    _filters,
    sorter: any,
    _extra
  ) => {
    const currentPage = pagination.current ? pagination.current : 1;
    if (page !== currentPage) {
      setPage(currentPage);
      dispatch(fetchAppointments({
        offset: (currentPage - 1) * getPageSize(),
        limit: getPageSize(),
        settings: {
          ...appointmentsSettings,
        }
      }));
      return;
    }
    setPage(1);
    sortTableByField(sorter);
  };

  return (
    <div className="appointments-desktop">

      <CancelingAppointmentModal open={ cancelAppointmentModalOpen }
                                 payload={ actionAppointment }
                                 loading={ loading }
                                 onCancel={ toggleCancelAppointmentModal }
                                 onSubmit={ submitCancelAppointmentHandler }
      />

      <Table
        rootClassName={ 'appointments-desktop--table' }
        rowClassName={ (record) => {
          const { status } = getAppointmentsStatus(record);
          return status;
        } }
        loading={ loading }
        locale={ { emptyText: 'No matching items' } }
        dataSource={ appointments }
        rowKey="id"
        pagination={ total > getPageSize() ? {
          current: page,
          pageSize: getPageSize(),
          total,
          showSizeChanger: false,
          position: ['bottomRight']
        } : false }
        onChange={ onChange }>

        <Column
          className={ 'date' }
          title="Date"
          key={ 'created_at' }
          width="20%"
          align={ 'center' }
          sorter={ true }
          render={ (data) => (<div
            dangerouslySetInnerHTML={ {
              __html: `
                  ${ tzDayjs(data.started_at).format('[<b>]DD MMM YYYY,[</b>] hh:mm A') }
                   -
                  ${ tzDayjs(data.finished_at).format('hh:mm A') }
              `
            } }/>) }
        />

        <Column
          className={ 'client' }
          title="Counsellor"
          key={ 'first_name' }
          sorter={ true }
          render={ (data) => <div className="client-wrapper">
            <UserAvatar sizeClass={ 'small' } avatarUrl={ data.worker?.photo || '' }/>
            <div className="client-wrapper--name">
              <CustomTooltip title={ `${ data.worker.first_name } ${ data.worker.last_name }` } className="name-field">
                { data.worker.first_name } { data.worker.last_name }
              </CustomTooltip>
              { data.additional_notes && <CustomTooltip title={ data.additional_notes } placement={ 'bottom' }>
                <CustomIcon name={ EIconName.Note }/>
              </CustomTooltip> }
            </div>
          </div> }
        />

        <Column
          title="Type"
          className={ 'type' }
          key={ 'consultation_type' }
          dataIndex={ 'consultation_type' }
          width="10%"
          sorter={ true }
          align={ 'center' }
          render={ (consultation_type) => <div className="type-wrapper">
            <div className="type-wrapper--icon">
              { consultation_type === 'video_call' && <CustomIcon size={ 'size-md' } name={ EIconName.CameraOn }/> }
              { consultation_type === 'audio_call' && <CustomIcon size={ 'size-md' } name={ EIconName.MicOn }/> }
              { consultation_type === 'conversation' && <CustomIcon size={ 'size-md' } name={ EIconName.Chat }/> }
            </div>
            <div className="type-wrapper--name">
              { consultation_type === 'video_call' && 'Video Call' }
              { consultation_type === 'audio_call' && 'Audio Call' }
              { consultation_type === 'conversation' && 'Text Chat' }
            </div>
          </div> }
        />

        <Column
          title="Duration"
          className={ 'duration' }
          key={ 'max_duration' }
          dataIndex={ 'max_duration' }
          width="10%"
          sorter={ true }
          align={ 'center' }
          render={ (max_duration) => {
            const format = max_duration >= 3600 ? 'H [h] m [min]' : 'm [min]';
            return (dayjs.duration(max_duration, "seconds").format(format));
          } }
        />

        <Column
          title="Cost"
          className={ 'cost' }
          key={ 'price' }
          dataIndex={ 'price' }
          width="10%"
          sorter={ true }
          align={ 'center' }
          render={ (price) => price }
        />

        <Column
          title="Status"
          className={ 'status' }
          key={ 'status' }
          width="10%"
          align={ 'center' }
          render={ (data) => {
            const payload = getAppointmentsStatus(data);

            return (<div className="status-wrapper">
              <div className={ `status-wrapper--point ${ payload.status }` }/>
              <div className="status-wrapper--name">
                { payload.text }
              </div>
            </div>)
          } }
        />

        <Column
          title="Actions"
          className={ 'actions' }
          key={ 'actions' }
          width="10%"
          align={ 'center' }
          render={ (data) => {
            const { status, canCancel } = getAppointmentsStatus(data);
            return (<div className="actions-wrapper">
              { (status === 'approved') && <ViewElementByTime from={ data.started_at } to={ data.finished_at }>
                <div className="actions-wrapper--item">
                  <CustomButton
                    onClick={ () => jointAppointmentHandler(data.consultation_id, data.consultation_type, data.worker.id) }>Join</CustomButton>
                </div>
              </ViewElementByTime> }
              { (status === 'awaiting') && <div className="actions-wrapper--item">
                <Dropdown menu={ {
                  items: [
                    {
                      key: '1',
                      label: (
                        <div className={ `cancel-appointment-item ${ !canCancel ? 'disabled' : '' }` }>
                          <CustomIcon name={ EIconName.Cancel } size={ 'size-md' }/>
                          Cancel Appointment
                        </div>),
                      disabled: !canCancel,
                      onClick: () => cancelAppointmentHandler(data)
                    },
                  ]
                } } trigger={ ['click'] } placement="bottomRight" arrow>
                  <CustomIcon name={ EIconName.BurgerMenu }/>
                </Dropdown>
              </div> }
            </div>)
          } }
        />

      </Table>
    </div>
  );
};
