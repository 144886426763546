import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../index';
import { IOngoingConsultationState } from './ongoing-consultation.slice';

/**
 A selector function to retrieve the ongoing consultation data from the store
 @function
 @param {RootState} state - The root state of the Redux store
 @returns {IOngoingConsultationState} The ongoing consultation state object from the Redux store
 */
const selectOngoingConsultationState = (state: RootState): IOngoingConsultationState => state.ongoingConsultation;

/**
 A memoized selector function to retrieve the ongoing consultation data from the store
 @function
 @type {import('@reduxjs/toolkit').OutputSelector<RootState, IOngoingConsultationState, (res: IOngoingConsultationState) => IOngoingConsultationState>}
 */
export const getOngoingConsultationState = createSelector(
  [selectOngoingConsultationState],
  (state): IOngoingConsultationState => state
);
