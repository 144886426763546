import { createSelector } from '@reduxjs/toolkit';

// Models
import { ICoreState } from './core.slice';
import { RootState } from '../index';

/**
 A selector function to retrieve the core data from the store
 @function
 @param {RootState} state - The root state of the Redux store
 @returns {ICoreState} The core state object from the Redux store
 */
const selectCoreState = (state: RootState): ICoreState => state.core;

/**
 A memoized selector function to retrieve the core data from the store
 @function
 @type {import('@reduxjs/toolkit').OutputSelector<RootState, ICoreState, (res: ICoreState) => ICoreState>}
 */
export const getCoreState = createSelector(
  [selectCoreState],
  (state) => state
);
