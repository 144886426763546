import { createAsyncThunk } from '@reduxjs/toolkit';

// Services
import { CurrencyService } from '../../services/currency.service';

// Thunks
import { openNotification } from '../app-notifications/app-notifications.thunks';

// Slices
import { CurrencySliceActions } from './currency.slice';

// Models
import { ICurrency } from '../../interfaces/company.interfaces';

export enum ECurrencyThunks {
  FetchCompanyCurrencies = 'CURRENCY/fetchCompanyCurrencies',
  FetchCurrentCurrency = 'CURRENCY/fetchCurrentCurrency',
  UpdateCurrentCurrencyFromSocket = 'CURRENCY/updateCurrentCurrencyFromSocket',
  UpdateCompanyCurrency = 'CURRENCY/updateCompanyCurrency',
}

export const fetchCurrentCurrency = createAsyncThunk(
  ECurrencyThunks.FetchCurrentCurrency,
  async (_, { dispatch }) => {
    dispatch(CurrencySliceActions.currentCurrencyRequest());
    const { currency, company_account_id, error } = await CurrencyService.fetchCurrentCurrency();
    if (!currency) {
      dispatch(CurrencySliceActions.currentCurrencyRequestFailure(error ?? 'Error loading current currency'));
      return null;
    }
    dispatch(CurrencySliceActions.currentCurrencyRequestSuccess({
      currentCurrency: currency,
      company_account_id
    }));
    return currency;
  }
);

export const fetchCompanyCurrencies = createAsyncThunk(
  ECurrencyThunks.FetchCompanyCurrencies,
  async (_, { dispatch }) => {
    dispatch(CurrencySliceActions.currenciesListRequest());
    const { currency, error } = await CurrencyService.fetchCompanyCurrencies();
    if (!currency) {
      dispatch(CurrencySliceActions.currenciesListRequestFailure(error ?? 'Couldn\'t get currencies, try again'));
      return null;
    }

    dispatch(CurrencySliceActions.currenciesListRequestSuccess(currency));
    return currency;
  }
);

export const updateCompanyCurrency = createAsyncThunk(
  ECurrencyThunks.UpdateCompanyCurrency,
  async (currencyName: string, { dispatch }) => {
    dispatch(CurrencySliceActions.currentCurrencyRequest());
    const { currency, error } = await CurrencyService.updateCompanyCurrency(currencyName);
    if (!currency) {
      dispatch(CurrencySliceActions.currentCurrencyRequestFailure(error ?? 'Couldn\'t update currency, try again'));
      dispatch(openNotification({
        description: error ?? 'Couldn\'t update currency, try again',
        type: 'error'
      }));
      return null;
    }
    dispatch(openNotification({
      description: 'Changes successfully saved!',
      type: 'success'
    }));

    dispatch(CurrencySliceActions.currentCurrencyRequestSuccess({ currentCurrency: currency }));
    return currency;
  }
);

export const updateCurrentCurrencyFromSocket = createAsyncThunk(
  ECurrencyThunks.UpdateCurrentCurrencyFromSocket,
  async (currency: ICurrency, { dispatch }) => {
    dispatch(CurrencySliceActions.updateCurrentCurrencyFromSocket(currency));
    return currency;
  }
);
