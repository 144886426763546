import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

// Components
import { CpStep1 } from './cp-step-1/cp-step-1';
import { CpStep2 } from './cp-step-2/cp-step-2';

// Thunks
import { setInProgressState } from '../../../store/profile-settings/profile-settings.thunks';

// Styles
import './change-password.scss';

export const ChangePassword = () => {

  const dispatch = useDispatch<any>();
  const [activeStep, setActiveStep] = useState(1);

  const onSetStep = (value: number) => {
    setActiveStep(value);
    if (value === 1) {
      dispatch(setInProgressState(false));
    } else {
      dispatch(setInProgressState(true));
    }
  }

  return (<div className="change-password">
    <div className={'content-title'}>Change Password</div>
    <div className={'content-title-line'}></div>
    <div className={'content-inside'}>
      {activeStep === 1 && <CpStep1 setStep={onSetStep}/>}
      {activeStep === 2 && <CpStep2 setStep={onSetStep} />}
    </div>
  </div>);
}
