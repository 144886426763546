import { FC, ReactNode } from 'react';
import dayjs from 'dayjs';

import { Modal } from 'antd';

// Shared UI Library Components
import { CustomIcon, EIconName } from '@ppmcore/seven-ppm-core-shared-components-react';

// Styles
import './transactions-info-modal.scss';

// Models
import { ITransactionItem } from '../../interfaces/transactions.interfaces';

interface ITransactionsFiltersModalProps {
  open: boolean;
  transaction: ITransactionItem;
  onCancel?: () => void;
}

export const TransactionsInfoModal: FC<ITransactionsFiltersModalProps> = (
  {
    open,
    transaction: { created_at, consultation_type, duration, total, details },
    onCancel = () => {}
  }: ITransactionsFiltersModalProps
): JSX.Element => {

  const getTitleOfType = (): ReactNode => {
    if (consultation_type === 'video_call') {
      return <>
        <CustomIcon name={ EIconName.CameraOff }
                    size={'size-md'}/>Video Consultation
      </>;
    }
    if (consultation_type === 'audio_call') {
      return <>
        <CustomIcon name={ EIconName.MicOn }
                    size={'size-md'}/>Voice Consultation
      </>;
    }
    return <>
      <CustomIcon name={ EIconName.Message }
                  size={'size-md'}/>Correspondence Consultation
    </>;
  }

  return (
    <Modal wrapClassName="transactions-info-modal"
           title="Refund Details"
           footer={ null }
           closeIcon={ <CustomIcon name={ EIconName.Close }/> }
           onCancel={ onCancel }
           open={ open }>
      <div className="transactions-info-modal--body">
        <div className="body-item body-item--transaction">
          <div className="transaction-item has-divider">
            { getTitleOfType() }
          </div>
          <div className="transaction-item has-divider">
            { dayjs(created_at).format('DD MMM YYYY, hh:mm A') }
          </div>
          <div className="transaction-item has-divider">
            { duration?.text ?? '-' }
          </div>
          <div className="transaction-item">
            { total?.text }
          </div>
        </div>
        { details.map((detail) => <div className="body-item">
          <div className="body-item--label">
            { detail.status } <span
            className="label-date">{ dayjs(detail.created_at).format('DD MMM YYYY, hh:mm A') }</span>
          </div>
          { detail.reason && <div className="body-item--content">
            { detail.reason }
          </div> }
        </div>) }
      </div>
    </Modal>
  );
};
