// Shared UI Library Components
import { CustomIcon, EIconName } from '@ppmcore/seven-ppm-core-shared-components-react';

export const CSidebarFooterMenuList = [
  {
    label: 'Home',
    path: 'home',
    icon: <CustomIcon name={ EIconName.Home }/>,
    activeIcon: <CustomIcon name={ EIconName.HomeFill }/>,
    secureLink: false,
    isDisabled: false
  },
  {
    label: 'History',
    path: 'history',
    icon: <CustomIcon name={ EIconName.HistoryOff }/>,
    activeIcon: <CustomIcon name={ EIconName.HistoryOn }/>,
    secureLink: true,
    isDisabled: false
  },
  {
    label: 'Favorites',
    path: 'favorites',
    icon: <CustomIcon name={ EIconName.Like }/>,
    activeIcon: <CustomIcon name={ EIconName.LikeFill }/>,
    secureLink: true,
    isDisabled: false
  },
  {
    label: 'Business Information',
    path: 'business-information',
    icon: <CustomIcon name={ EIconName.CompanyInfoOff }/>,
    activeIcon: <CustomIcon name={ EIconName.CompanyInfoOn }/>,
    secureLink: false,
    isDisabled: false
  },
];
