import React, { FC, MouseEvent, useState } from 'react';
import { Form, FormInstance, Modal } from 'antd';

// Shared UI Library Components
import { CustomButton, CustomIcon, CustomTextArea, EIconName } from '@ppmcore/seven-ppm-core-shared-components-react';

// Styles
import './refund-modal.scss';

interface IRefundModalProps {
  open: boolean;
  onSubmit?: (value: any, form: FormInstance) => void;
  onCancel?: () => void;
}

export const RefundModal: FC<IRefundModalProps> = (
  {
    open = false,
    onSubmit = () => {
    },
    onCancel = () => {
    }
  }: IRefundModalProps
) => {
  const [form] = Form.useForm();

  const [reasonValue, setReasonValue] = useState<string>('');

  const handleCancel = (e: MouseEvent<HTMLButtonElement>): void => {
    onCancel();
  };

  const handleSubmit = async (): Promise<void> => {
    const formValue = await form.getFieldsValue();
    onSubmit(formValue.reason, form);
  }

  return (
    <Modal
      rootClassName="refund-modal"
      width={ '604px' }
      open={ open }
      closeIcon={ <CustomIcon name={ EIconName.Close }/> }
      title={ 'Please state the reason for the requested refund' }
      footer={ [
        <CustomButton key="cancel" text={ 'Cancel' } type={ 'text' } onClick={ handleCancel }/>,
        <CustomButton key="submit" text={ 'Agree' } disabled={ !reasonValue.length } onClick={ handleSubmit }/>
      ] }
      onCancel={ handleCancel }
    >
      <Form form={ form } className="refund-modal--body">
        <div className="body-item">
          <Form.Item name="reason" rules={ [{ required: true }] }>
            <CustomTextArea placeholder={ 'Write your reason here' } onChange={ setReasonValue } showCount={ true }
                            maxLength={ 500 }/>
          </Form.Item>
        </div>
      </Form>
    </Modal>
  )
};
