import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IAuthData } from '../../interfaces/auth.interfaces';

export interface AuthState {
  auth: IAuthData | null;
  loading: boolean;
  error: boolean;
  errorMessage: string | null;
}

const initialState: AuthState = {
  auth: null,
  loading: false,
  error: false,
  errorMessage: null
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    signInRequest(state) {
      state.loading = true;
      state.error = false;
      state.errorMessage = null;
    },
    signInSuccess: (state, action: PayloadAction<IAuthData>) => {
      state.auth = action.payload;
      state.loading = false;
      state.error = false;
      state.errorMessage = null;
    },
    signInFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = true;
      state.errorMessage = action.payload;
    },
    signUpRequest(state) {
      state.loading = true;
      state.error = false;
      state.errorMessage = null;
    },
    signUpSuccess: (state) => {
      state.loading = false;
      state.error = false;
      state.errorMessage = null;
    },
    signUpFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = true;
      state.errorMessage = action.payload;
    },
    activateCodeRequest(state) {
      state.loading = true;
      state.error = false;
      state.errorMessage = null;
    },
    activateCodeSuccess: (state) => {
      state.auth = null;
      state.loading = false;
      state.error = false;
      state.errorMessage = null;
    },
    activateCodeFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = true;
      state.errorMessage = action.payload;
    },
    resendCodeRequest(state) {
      state.loading = true;
      state.error = false;
      state.errorMessage = null;
    },
    resendCodeSuccess: (state, action: PayloadAction<IAuthData>) => {
      state.auth = action.payload;
      state.loading = false;
      state.error = false;
      state.errorMessage = null;
    },
    resendCodeFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = true;
      state.errorMessage = action.payload;
    },
    forgotPasswordRequest(state) {
      state.auth = null;
      state.loading = true;
      state.error = false;
    },
    forgotPasswordSuccess: (state, action: PayloadAction<IAuthData | null>) => {
      state.auth = action.payload;
      state.loading = false;
      state.error = false;
    },
    forgotPasswordFailure: (state, action: PayloadAction<string>) => {
      state.auth = null;
      state.loading = false;
      state.error = true;
      state.errorMessage = action.payload;
    },
    resendEmailRequest(state) {
      state.auth = null;
      state.loading = true;
      state.error = false;
    },
    resendEmailSuccess: (state, action: PayloadAction<IAuthData | null>) => {
      state.auth = action.payload;
      state.loading = false;
      state.error = false;
    },
    resendEmailFailure: (state, action: PayloadAction<string>) => {
      state.auth = null;
      state.loading = false;
      state.error = true;
      state.errorMessage = action.payload;
    },
    resetPasswordRequest(state) {
      state.auth = null;
      state.loading = true;
      state.error = false;
    },
    resetPasswordFailure: (state, action: PayloadAction<string>) => {
      state.auth = null;
      state.loading = false;
      state.error = true;
      state.errorMessage = action.payload;
    },
    clearAuthState: (state) => {
      state.auth = null;
      state.loading = false;
      state.error = false;
      state.errorMessage = null;
    },
  }
});

export const AuthSliceActions = authSlice.actions;
