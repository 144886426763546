import React, { FC, HTMLProps } from 'react';
import { useSelector } from 'react-redux';

// Shared UI Library Components
import { CustomIcon, EIconName } from '@ppmcore/seven-ppm-core-shared-components-react';

// Styles
import './widget-wrapper.scss';

// Store entities
import { getCoreState } from '../../store/core/core.selectors'

// Models
import { getWidgetState } from '../../store/widget/widget.selectors';
import { getUserState } from '../../store/user/user.selectors';

type TWidgetWrapperProps = HTMLProps<HTMLDivElement> & {};

export const WidgetWrapper: FC<TWidgetWrapperProps> = ({ ...props }: TWidgetWrapperProps) => {

  const { path, companyInfo } = useSelector(getCoreState);
  const { type: widgetType } = useSelector(getWidgetState);
  const { user } = useSelector(getUserState);

  const isCallState = path.current.includes('call') || path.current.includes('conversation');

  const closeWidget = (): void => {
    window.parent.postMessage({
      type: 'close-widget',
      data: null
    }, '*');
  }

  const openFullScreen = (): void => {
    window.parent.postMessage({
      type: 'toggle-fullscreen',
      data: null
    }, '*');
  }

  const navigateToLanding = (): void => {
    window.open('https://paypertok.com', '_blank');
  }

  return (<div
    className={ `widget-wrapper ${ (user?.user_type !== 'temporary' && !!user) ? 'auth-user' : 'no-auth-user' } widget-wrapper-${ widgetType }` }{ ...props }>
    <div className="widget-wrapper--header widget-header">
      <div className="widget-header--title">
        { companyInfo?.company?.company_profile?.widget_settings?.widget_title_text }
      </div>
      <div className="widget-header--actions">
        { (!isCallState && widgetType === 'popup') && <div className="actions-item" onClick={ closeWidget }>
          <CustomIcon name={ EIconName.ArrowDown }/>
        </div> }
        {
          isCallState && <>
            <div className="actions-item" onClick={ openFullScreen }>
              <CustomIcon name={ EIconName.Fullscreen }/>
            </div>
            {/*<div className="actions-item" onClick={ openFullScreen }>*/ }
            {/*  <CustomIcon name={ EIconName.NorthEast }/>*/ }
            {/*</div>*/ }
          </>
        }
      </div>
    </div>
    <div className={ `widget-wrapper--body widget-body widget-body-${ widgetType }` }>
      { props.children }
    </div>
    <div className={ `widget-wrapper--footer widget-footer ${ isCallState ? 'call-state' : '' }` }>
      {/*<CustomIconBig pathPrefix={ '/assets/icons/small/' } name={ 'powered-by-PPT-widget' }/>*/ }
      { !isCallState && <img onClick={ navigateToLanding } src={ '/assets/icons/small/powered-by-PPT-widget.svg' }
                             alt={ 'powered-by-PPT-widget' }/> }
      { isCallState &&
        <img onClick={ navigateToLanding } src={ '/assets/icons/small/powered-by-PPT-widget-white.svg' }
             alt={ 'powered-by-PPT-widget-white' }/> }
    </div>
  </div>);
}
