import { FC, Fragment, ReactNode, SyntheticEvent, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import { useSearchParams } from 'react-router-dom';

// Shared UI Library Components
import { InfoMessage } from '@ppmcore/seven-ppm-core-shared-components-react';

// Styles
import './messages-list.scss';

// Components
import { MessageItem } from '../message-item/message-item';
import { HistoryNotification } from '../../history/history-notification/history-notification';

// Thunks
import { fetchMessagesList } from '../../../../store/messages/messages.thunks';

// Models
import { IMessageItem, IMessageNotification } from '../../../../interfaces/chat.interfaces';

// Utils
import { instanceOfMessage } from '../../../../utils/history.utils';

type TMessageItemProps = {
  chat_id: string | number;
  messages: Array<IMessageItem | IMessageNotification>;
  total: number,
  isActiveCounsellor: boolean,
  loading?: boolean,
  rate?: string;
  resendMessage?: (message: IMessageItem, files: Array<string | File>) => void;
}

export const MessagesList: FC<TMessageItemProps> = (
  { chat_id, messages, total, isActiveCounsellor, loading = false, rate = '', resendMessage = () => {} }: TMessageItemProps
) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch<any>();

  const transformDatesObject: { [key: string]: Array<IMessageItem | IMessageNotification> } = useMemo(() => {
    const uniqueDates = Array.from(new Set(messages.map((message) => dayjs(message.created_at).format('DD MMM YYYY'))));
    return uniqueDates.reduce((acc, date) => {
      acc[date] = messages.filter(item => dayjs(item.created_at).isSame(date, 'day'));
      return acc;
    }, {} as { [key: string]: Array<IMessageItem | IMessageNotification> });
  }, [messages]);

  const getDateTitle = (date: string): string => {
    if (dayjs(date).isSame(dayjs(), 'day')) {
      return 'Today';
    }
    if (dayjs(date).isSame(dayjs().subtract(1, 'day'), 'day')) {
      return 'Yesterday';
    }
    return date;
  }

  const loadMoreMessages = (limit?: number): void => {
    dispatch(fetchMessagesList({
      chat_id,
      temporary_token: localStorage.getItem('temporary_token') ?? '',
      isLoadMore: true,
      offset: messages.length,
      ...(limit && { limit })
    }));
  }

  const getInfoMessage = (message: IMessageItem): ReactNode => {
    if (message.is_message_replied) return '';

    const expiredDays = dayjs(message.expired_at).diff(message.created_at, 'hours');
    const expiredString = `${ expiredDays } ${ expiredDays !== 1 ? 'hours' : 'hour' }`;
    const isExpired = dayjs().isAfter(message.expired_at);
    let infoMessage = '';
    if (isExpired) {
      infoMessage = `The Counsellor did not respond to your message within <b>${ expiredString }</b>, so the refund has been processed. If you would like, you may try contacting the Counsellor again.`;
    } else {
      infoMessage = `The Counsellor has a period of <b>${ expiredString }</b> to provide you with an answer. If the answer is not provided within this timeframe, the full amount of money you spent on the message will be refunded to you.`;
    }
    return <InfoMessage infoMessage={ infoMessage }/>;
  }

  const scrollToMessageById = (id: number, duration: number = 2000): void => {
    const element = document.getElementById(`${ id }`);
    if (!element) return;

    element.scrollIntoView({ behavior: "smooth", block: 'center' });
    element.classList.add('show-active');

    const timerId = setTimeout(() => {
      element.classList.remove('show-active');
      clearTimeout(timerId);
    }, duration);

    if (!searchParams.has('id')) return;
    setSearchParams({});
  }

  const onScrollToMessageHandler = async ({ message_pinged }: IMessageNotification): Promise<void> => {
    if (messages.length < message_pinged.messages_after) {
      await loadMoreMessages((message_pinged.messages_after - messages.length) + 5);
    }
    await new Promise((res) => setTimeout(res, 300));
    scrollToMessageById(message_pinged.id);
  }

  const onScrollListHandler = (event: SyntheticEvent): void => {
    const { scrollTop, scrollHeight, offsetHeight } = event.target as HTMLDivElement;
    if ((Math.abs(scrollTop) + offsetHeight + 50 < scrollHeight) || loading || total <= messages.length) return;
    loadMoreMessages();
  }

  const onResendMessageHandler = (message: IMessageItem): void => {
    resendMessage(message, []);
  }

  const getComponentByType = (item: IMessageItem | IMessageNotification): ReactNode => {
    if (!instanceOfMessage(item)) {
      return <HistoryNotification messageInfo={ item } onScrollToMessage={ onScrollToMessageHandler }/>
    }
    return <>
      { (!item.is_message_replied && item.sender_type === 'user') && getInfoMessage(item) }
      <MessageItem id={ `${ item.id }` } key={ item.id } isActiveCounsellor={ isActiveCounsellor }
                   messageInfo={ item as IMessageItem } rate={ rate }
                   onResend={ onResendMessageHandler }/>
    </>
  }

  useEffect(() => {
    if (!searchParams.has('id') || loading || !messages.length) return;
    const id = Number(searchParams.get('id'));
    scrollToMessageById(id, 3000);
  }, [searchParams, loading, messages]);

  return (<div className="messages--body-list" onScroll={ onScrollListHandler }>
    {
      Object.keys(transformDatesObject).map((date) => {
        return <Fragment key={ date }>
          {
            transformDatesObject[date]
              .map((message) =>
                <Fragment key={ message.id } children={ getComponentByType(message) }/>
              )
          }
          <div className="body-list-item body-list-item--today">{ getDateTitle(date) }</div>
        </Fragment>
      })
    }
  </div>);
}
