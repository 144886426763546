import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Form } from 'antd';

// Shared UI Library Components
import { CustomIcon, SwitchElement, EIconName, CustomTooltip } from '@ppmcore/seven-ppm-core-shared-components-react';

// Styles
import './notifications-settings.scss';

// Store entities
import { getNotificationsSettings } from '../../../store/notifications-settings/notifications-settings.selectors';
import {
  fetchNotificationsSettings,
  updateNotificationsSettings
} from '../../../store/notifications-settings/notifications-settings.thunks';

// Models
import { INotificationsSettings } from '../../../interfaces/notifications-settings.interfaces';

type TFormValue = {
  // in_app_notification: boolean,
  email_notification: boolean,
  // new_call_notification: boolean,
  // sms_notification: boolean,
}

type TNotificationsSettingsProps = {};

export const NotificationsSettings: FC<TNotificationsSettingsProps> = (
  {}: TNotificationsSettingsProps
) => {
  const [form] = Form.useForm<TFormValue>();

  // const { user } = useSelector(getUserState);
  const { settings } = useSelector(getNotificationsSettings);
  const dispatch = useDispatch<any>();

  // const isDisabledSMS = !user?.phone && !!user;

  const loadNotificationsSettings = (): void => {
    dispatch(fetchNotificationsSettings());
  }

  const updateFormValues = (): void => {
    form.setFieldsValue({
      // in_app_notification: settings?.in_app_notification,
      email_notification: settings?.email_notification,
      // new_call_notification: settings?.new_call_notification,
    });
  }

  const formItemValueChangeHandler = async (value: { [key in keyof INotificationsSettings]: boolean }): Promise<void> => {
    await dispatch(updateNotificationsSettings({ ...settings, ...value }));
  }

  useEffect(() => {
    updateFormValues();
  }, [settings]);

  useEffect(() => {
    loadNotificationsSettings();
  }, []);

  return (<div className="notification-settings">
      <div className={ 'content-title' }>Notification Settings</div>
      <div className={ 'content-title-line' }></div>
      {/*<div className={ 'content-description' }></div>*/}
      <Form className={ 'content-inside' } form={ form } onValuesChange={ formItemValueChangeHandler }>
        {/*<div className="notification-item">*/ }
        {/*  <div className="notification-item--name">*/ }
        {/*    In App Notifications <CustomTooltip title={ 'In App Notifications' }><CustomIcon size={ 'size-md' }*/ }
        {/*                                                                               name={ EIconName.Info }/></CustomTooltip>*/ }
        {/*  </div>*/ }
        {/*  <div className="notification-item--action">*/ }
        {/*    <Form.Item name="in_app_notification" valuePropName="checked">*/ }
        {/*      <SwitchElement disabled={ !settings }/>*/ }
        {/*    </Form.Item>*/ }
        {/*  </div>*/ }
        {/*</div>*/ }
        <div className="notification-item">
          <div className="notification-item--name">
            Email Notifications
            <CustomTooltip title={ 'Email Notifications' }>
              <CustomIcon size={ 'size-md' } name={ EIconName.Info }/>
            </CustomTooltip>
          </div>
          <div className="notification-item--action">
            <Form.Item name="email_notification" valuePropName="checked">
              <SwitchElement disabled={ !settings }/>
            </Form.Item>
          </div>
        </div>
        {/*<div className="notification-item">*/}
        {/*  <div className="notification-item--name">*/}
        {/*    Call Notifications*/}
        {/*    <CustomTooltip title={ 'Call Notifications' }>*/}
        {/*      <CustomIcon size={ 'size-md' } name={ EIconName.Info }/>*/}
        {/*    </CustomTooltip>*/}
        {/*  </div>*/}
        {/*  <div className="notification-item--action">*/}
        {/*    <Form.Item name="new_call_notification" valuePropName="checked">*/}
        {/*      <SwitchElement disabled={ !settings }/>*/}
        {/*    </Form.Item>*/}
        {/*  </div>*/}
        {/*</div>*/}
        {/*<div className="notification-item">*/ }
        {/*  <div className="notification-item--name">*/ }
        {/*    SMS Notifications <CustomTooltip title={ 'SMS Notifications' }><CustomIcon size={ 'size-md' }*/ }
        {/*                                                                         name={ EIconName.Info }/></CustomTooltip>*/ }
        {/*  </div>*/ }
        {/*  <div className="notification-item--action">*/ }
        {/*    <Form.Item name="sms_notification" valuePropName="checked">*/ }
        {/*      <SwitchElement disabled={ isDisabledSMS || !settings }/>*/ }
        {/*    </Form.Item>*/ }
        {/*  </div>*/ }
        {/*</div>*/ }
      </Form>

      {/*{ isDisabledSMS && <div className={ 'notifications-error' }>*/ }
      {/*  <CustomIcon size={ 'size-lg' } name={ EIconName.Warning } color={ EIconColor.Error }/>*/ }
      {/*  <span>Add Phone Number to your Profile to be able to switch on SMS Notifications.</span>*/ }
      {/*</div> }*/ }
    </div>
  );
}
