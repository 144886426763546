import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../index';
import { ICallFinishedState } from './call-finished.slice';

/**
 A selector function to retrieve the call finished data from the store
 @function
 @param {RootState} state - The root state of the Redux store
 @returns {ICallFinishedState} The call finished state object from the Redux store
 */
const selectCallFinishedState = (state: RootState): ICallFinishedState => state.callFinished

/**
 A memoized selector function to retrieve the call finished data from the store
 @function
 @type {import('@reduxjs/toolkit').OutputSelector<RootState, ICallFinishedState, (res: ICallFinishedState) => ICallFinishedState>}
 */
export const getCallFinishedState = createSelector(
  [selectCallFinishedState],
  (state): ICallFinishedState => state
);
