import React, { FC, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";

import { Statistic } from 'antd';

// Styles
import './ongoing-consultation.scss';

// Shared UI Library Components
import { CustomButton } from '@ppmcore/seven-ppm-core-shared-components-react';

// Store entities
import { fetchOngoingCalls } from '../../../../store/ongoing-consultation/ongoing-consultation.thunks';
import { getOngoingConsultationState } from '../../../../store/ongoing-consultation/ongoing-consultation.selectors';
import { getLastMessageData } from '../../../../store/socket/socket.selectors';
import { getCallInfo } from "../../../../store/call/call.thunks";

// Enums
import { EWebSocketTypes } from '../../../../enums/web-socket-types.enums';
import { ELocalStorageKeys } from "../../../../enums/storage.enums";

type TOngoingConsultationProps = {};

const { Countdown } = Statistic;

export const OngoingConsultation: FC<TOngoingConsultationProps> = ({}: TOngoingConsultationProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();

  const [startFinished, setStartFinished] = useState<boolean>(false);

  const containerRef = useRef<HTMLDivElement>(null);

  const lastMessage = useSelector(getLastMessageData);
  const { call, soon_appointment_call } = useSelector(getOngoingConsultationState);

  const loadOutgoingCalls = (): void => {
    dispatch(fetchOngoingCalls());
  }

  const rejoinActionHandler = (): void => {
    if (!call?.id) return;
    window.location.assign(`/call/${ call?.id }`);
  }

  const joinActionHandler = (): void => {
    if (!soon_appointment_call) return;
    localStorage.setItem('call_type', `${ soon_appointment_call.call_type }`);
    localStorage.setItem('call_id', `${ soon_appointment_call.call_id }`);
    localStorage.setItem('worker_id', `${ soon_appointment_call.worker?.id }`);

    const temporary_token = localStorage.getItem(ELocalStorageKeys.TemporaryToken) || '';

    dispatch(getCallInfo({ call_id: +soon_appointment_call.call_id, temporary_token }));

    navigate(`/call/devices-settings/${ soon_appointment_call.call_id }`);
  }

  const pageResizeHandler = () => {
    if (!containerRef.current) return;
    document.documentElement.style.setProperty("--outgoingHeight", `${ containerRef.current.offsetHeight }px`);
  }

  const timerFinished = () => {
    dispatch(fetchOngoingCalls()).then(() => {
      if (!!call?.id) window.location.href = `/call-finished/${ call?.id }`
    });
  }

  const timerStartFinished = () => {
    setStartFinished(() => true);
  }

  useEffect(() => {
    const token = localStorage.getItem('token');
    const temporaryToken = localStorage.getItem('temporary_token');
    if (!token && !temporaryToken) return;

    loadOutgoingCalls();
    window.addEventListener('resize', pageResizeHandler);

    return () => {
      window.removeEventListener('resize', pageResizeHandler);
      document.documentElement.style.setProperty("--outgoingHeight", '0px');
    }
  }, []);

  useEffect(() => {
    pageResizeHandler();
  }, [containerRef.current]);

  useEffect(() => {
    if (lastMessage?.type === EWebSocketTypes.EndCall) {
      timerFinished();
    }
  }, [lastMessage]);

  useEffect(() => {
    if (!soon_appointment_call) return;
    setStartFinished(dayjs().isAfter(dayjs(soon_appointment_call.call_start)));
  }, [soon_appointment_call]);

  return (
    <>
      { (!!call?.id || !!soon_appointment_call?.call_id) &&
        <div className={ `ongoing-consultation ${ (soon_appointment_call && !call) ? 'appointment-soon' : '' }` }
             ref={ containerRef } onLoad={ pageResizeHandler }>
          <div className="ongoing-consultation--message">
            { call && <> The ongoing Appointment
              with <b>{ call?.worker?.first_name } { call?.worker?.last_name }</b> will be over
              in <Countdown value={ call?.finished_after } format="HH:mm:ss" onFinish={ timerFinished }/>
            </> }

            { (soon_appointment_call && startFinished && !call) && <>
              You can join the Appointment with <b>{ soon_appointment_call?.worker?.first_name } { soon_appointment_call?.worker?.last_name }</b></> }

            { (soon_appointment_call && !startFinished && !call) && <>
              The Appointment
              with <b>{ soon_appointment_call?.worker?.first_name } { soon_appointment_call?.worker?.last_name }</b> will
              start
              in <Countdown value={ soon_appointment_call?.call_start } format="HH:mm:ss"
                            onFinish={ timerStartFinished }/>
            </> }
          </div>
          <div className="ongoing-consultation--action">
            { call &&
              <CustomButton mode={ 'dark' } size={ 'small' } onClick={ rejoinActionHandler }>Rejoin</CustomButton> }
            { (soon_appointment_call && startFinished && !call) &&
              <CustomButton mode={ 'dark' } size={ 'small' } onClick={ joinActionHandler }>Join</CustomButton> }
          </div>
        </div> }
    </>
  );
};
