import React, { FC } from 'react';

// Shared UI Library Components
import { CustomTooltip } from '@ppmcore/seven-ppm-core-shared-components-react';


// Styles
import './menu-item.scss';

interface IMenuItemProps {
  icon: any,
  path: string,
  activeIcon: any,
  label: string,
  isActive: boolean,
  withLabels?: boolean,
  onClickMenuItem?: (path: string, label: string) => void
}

export const MenuItem: FC<IMenuItemProps> = ({ icon, path, activeIcon, label, isActive, withLabels = false, onClickMenuItem = () => {} }: IMenuItemProps
): JSX.Element => {
  return (
    <CustomTooltip placement={ 'left' } title={ label }>
      <div
        className={ `menu-list-item ${ withLabels ? 'mobile-item' : '' } ${ !path ? 'logout-action' : '' } ${ isActive ? ' active-item' : '' }` }
        onClick={ () => onClickMenuItem(path, label) }>
        <span className="icon-item">{ isActive ? activeIcon : icon }</span>
        { withLabels ? label : '' }
      </div>
    </CustomTooltip>
  )
};
