import React, { FC, useState } from 'react';
import { Button, Form } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

// Shared UI Library Components
import { defaultValidateMessages, DiscardModal, InputPassword, passwordPattern, PasswordValidationTooltip, ServerErrors } from '@ppmcore/seven-ppm-core-shared-components-react';

// Thunks
import { changePassword } from '../../../../store/profile-settings/profile-settings.thunks';

// Selectors
import { getProfileSettings } from '../../../../store/profile-settings/profile-settings.selectors';

// Actions
import { openNotification } from '../../../../store/app-notifications/app-notifications.thunks';

// Styles
import './cp-step-2.scss';

interface ICpStep2Props {
  setStep?: (value: number) => void;
}

export const CpStep2: FC<ICpStep2Props> = ({setStep = () => {}}: ICpStep2Props) => {

  const [cpStep2] = Form.useForm();
  const dispatch = useDispatch<any>();

  const [btnNextDisabled, setBtnNextDisabled] = useState(true);
  const [showPasswordError, setShowPasswordError] = useState(false);
  const [discardModalOpen, setDiscardModalOpen] = useState(false);
  const [serverError, setServerError] = useState<string | null>(null);

  const { loading } = useSelector(getProfileSettings);

  const onFinish = () => {
    cpStep2.validateFields().then((values) => {
      const {new_password, new_password_confirmation} = values;
      dispatch(changePassword({new_password, new_password_confirmation}))
        .then((res: any) => {
          if (res.payload && res.payload.error) {
            setBtnNextDisabled(true);
            setServerError(res.payload.error);
            return;
          }
          dispatch(openNotification({
            description: res.payload.message,
            type: 'success'
          }));
          setStep(1);
        });
    }).catch((error) => {
      console.error('Form validation failed', error);
    });
  };

  const onCancel = (value: boolean) => {
    if (value) {
      cpStep2.resetFields();
      setServerError(null);
      setBtnNextDisabled(true);
      setStep(1);
    }
    setDiscardModalOpen(false);
  }

  const onValuesChange = (changedValues: { new_password: string } | { new_password_confirmation: string },
                          allFormValues: { new_password: string | undefined, new_password_confirmation: string | undefined }) => {
    if (allFormValues.new_password !== undefined
      && allFormValues.new_password_confirmation !== undefined
      && allFormValues.new_password !== ''
      && allFormValues.new_password_confirmation !== ''
      && new RegExp(passwordPattern).test(allFormValues.new_password)
      && allFormValues.new_password_confirmation === allFormValues.new_password) {
      setBtnNextDisabled(false);
    } else {
      setBtnNextDisabled(true);
    }
  };

  return (<div className="cp-step-2">
    {serverError && <ServerErrors serverError={serverError}/>}

    <Form
      form={cpStep2}
      name="form-cp-step-2"
      requiredMark={false}
      onFinish={onFinish}
      onValuesChange={onValuesChange}
      autoComplete="off"
      layout={'vertical'}
      validateMessages={defaultValidateMessages}>

      <Form.Item
        name="new_password"
        messageVariables={{fieldLabel: 'New password'}}
        dependencies={['new_password_confirmation']}
        rules={[
          {required: true},
          ({getFieldValue}) => ({
            validator(_, value) {
              if (!value || new RegExp(passwordPattern).test(getFieldValue('new_password'))) {
                setShowPasswordError(false);
                return Promise.resolve();
              }
              setShowPasswordError(true);
              return Promise.reject(new Error());
            }
          })
        ]}>
        <div>
          {showPasswordError && <PasswordValidationTooltip/>}
          <InputPassword placeholder={'New Password*'}/>
        </div>
      </Form.Item>

      <Form.Item
        name="new_password_confirmation"
        messageVariables={{fieldLabel: 'Confirm new password'}}
        dependencies={['new_password']}
        rules={[
          {required: true},
          ({getFieldValue}) => ({
            validator(_, value) {
              if (!value || getFieldValue('new_password') === value) {
                return Promise.resolve();
              }
              return Promise.reject(new Error(defaultValidateMessages.passwordsDontMatch));
            }
          })]}>
        <InputPassword placeholder={'Confirm New Password*'}/>
      </Form.Item>

      <div className="form-bottom">
        <Button type="default" size={'large'} onClick={() => setDiscardModalOpen(true)} disabled={loading}>Cancel</Button>
        <Button type="primary" size={'large'} htmlType={'submit'} disabled={btnNextDisabled || loading}>Save</Button>
      </div>
    </Form>

    <DiscardModal open={discardModalOpen}
                  onCancel={(value) => onCancel(value)}
                  title={'Are you sure you want to discard your Change Password?'} />
  </div>);
}
