import { createSelector } from '@reduxjs/toolkit';

// Models
import { RootState } from '../index';
import { IBookAppointmentsState } from './appointments.slice';

/**
 A selector function to retrieve the appointments data from the store
 @function
 @param {RootState} state - The root state of the Redux store
 @returns {ICallState} The appointments state object from the Redux store
 */
const selectBookAppointmentsState = (state: RootState): IBookAppointmentsState => state.appointments;

/**
 A memoized selector function to retrieve the appointments data from the store
 @function
 @type {import('@reduxjs/toolkit').OutputSelector<RootState, IBookAppointmentsState, (res: IBookAppointmentsState) => IBookAppointmentsState>}
 */
export const getBookAppointmentsState = createSelector(
  [selectBookAppointmentsState],
  (state): IBookAppointmentsState => state
);
