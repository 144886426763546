import { createAsyncThunk } from '@reduxjs/toolkit';

import { PaymentsTypeSliceActions } from './payments-types.slice';
import { PaymentsTypesService } from '../../services/payments-types.service';

// Create the middleware instance and methods
export enum EPaymentsTypesThunks {
  FetchPaymentsTypes = 'PAYMENTS_TYPE/fetchPaymentsTypes',
  FetchConversationsPaymentsTypes = 'PAYMENTS_TYPE/fetchConversationsPaymentsTypes',
  ClearPaymentsTypes = 'PAYMENTS_TYPE/clearPaymentsTypes'
}

export const fetchPaymentsTypes = createAsyncThunk(
  EPaymentsTypesThunks.FetchPaymentsTypes,
  async ({ workerId, temporary_token }: { workerId: number, temporary_token: string }, { dispatch }) => {
    dispatch(PaymentsTypeSliceActions.loadPaymentsTypesRequest());
    const data = await PaymentsTypesService.fetchPaymentsTypes(workerId, temporary_token);
    if (!data) {
      return dispatch(PaymentsTypeSliceActions.loadPaymentsTypesError());
    }
    return dispatch(PaymentsTypeSliceActions.loadPaymentsTypesSuccess(data));
  }
);

export const fetchConversationsPaymentsTypes = createAsyncThunk(
  EPaymentsTypesThunks.FetchConversationsPaymentsTypes,
  async ({ workerId, temporary_token }: { workerId: number, temporary_token: string }, { dispatch }) => {
    dispatch(PaymentsTypeSliceActions.loadPaymentsTypesRequest());
    const data = await PaymentsTypesService.fetchConversationsPaymentsTypes(workerId, temporary_token);
    if (!data) {
      return dispatch(PaymentsTypeSliceActions.loadPaymentsTypesError());
    }
    return dispatch(PaymentsTypeSliceActions.loadPaymentsTypesSuccess(data));
  }
);

export const clearPaymentsTypes = createAsyncThunk(
  EPaymentsTypesThunks.ClearPaymentsTypes,
  (_, { dispatch }) => {
    return dispatch(PaymentsTypeSliceActions.clearPaymentsTypes());
  }
);
