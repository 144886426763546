import { FC } from 'react';

// Styles
import './transactions-empty.scss';

type TTransactionsEmptyProps = {
  expertPage?: boolean;
};

export const TransactionsEmpty: FC<TTransactionsEmptyProps> = ({ expertPage = false }: TTransactionsEmptyProps): JSX.Element => {
  return (
    <div className="transactions-empty--inside">
      <div className={ `inside--title ${ expertPage ? 'small-title' : '' }` }>
        Transactions
      </div>
      <div className="inside--subtitle">
        You don’t have any transactions yet. Start communicating!
      </div>
      <div className="inside--img">
        <img src={ '/assets/icons/big/no-transactions.svg' } alt={ 'no-transactions-icon' }/>
      </div>
    </div>
  );
};
