import { createAsyncThunk } from '@reduxjs/toolkit';

// Services
import { ChatConsultationService } from '../../services/chat-consultation.service';
import { ChatsService } from '../../services/chats.service';

// Slices
import { ChatSliceActions } from './chat.slice';

// Store actions
import { openNotification } from '../app-notifications/app-notifications.thunks';

// Interfaces
import {
  IChatProcessCreateRequest,
  IChatProcessStartRequest,
  IChatProcessUpdateRequest, TChatState
} from '../../interfaces/text-chat.interfaces';
import { ICallMessage } from '../../interfaces/chat.interfaces';

export enum EChatProcessThunks {
  ChatInfo = 'CHAT_PROCESS/chatInfo',
  CreateChat = 'CHAT_PROCESS/createChat',
  UpdateChat = 'CHAT_PROCESS/updateChat',
  StartChat = 'CHAT_PROCESS/startChat',
  RejectChat = 'CHAT_PROCESS/rejectChat',
  ChargeChatTimeslot = 'CHAT_PROCESS/chargeChatTimeslot',
  ChangeTimeLeftDialog = 'CHAT_PROCESS/changeTimeLeftDialog',
  SendConversationMessage = 'CHAT_PROCESS/sendConversationMessage',
  AddChatMessageFromSocket = 'CHAT_PROCESS/addChatMessageFromSocket',
  SetMessagesList = 'CHAT_PROCESS/setMessagesList',
  ClearChatStore = 'CHAT_PROCESS/clearChatStore',
  SetChatState = 'CHAT_PROCESS/setChatState',
}

export const getChatInfo = createAsyncThunk(
  EChatProcessThunks.ChatInfo,
  async ({ conversation_id, temporary_token }: { conversation_id: number; temporary_token: string }, { dispatch }: any) => {
    dispatch(ChatSliceActions.getChatRequest());
    const res = await ChatConsultationService.getChatInfo(conversation_id, temporary_token);
    if (res.status !== 200) {
      dispatch(ChatSliceActions.getChatFailure());
      return res.data;
    }
    dispatch(setMessagesList(res.data.conversation?.conversation_history ?? []));
    dispatch(ChatSliceActions.getChatSuccess(res.data));
  }
);

export const createChat = createAsyncThunk(
  EChatProcessThunks.CreateChat,
  async (createCallData: IChatProcessCreateRequest, { dispatch }: any) => {
    dispatch(ChatSliceActions.createChatRequest());
    const res = await ChatConsultationService.createChat(createCallData);
    if (res.status === 425) {
      dispatch(clearChatStore(true)).then(() => {
        window.location.href = `/conversation/counsellor-unavailable-no-payment`;
      });
    }
    if (res.status === 426) {
      dispatch(clearChatStore(true)).then(() => {
        window.location.href = `/conversation/counsellor-in-call-no-payment`;
      });
    }
    if (res.status !== 200) {
      dispatch(ChatSliceActions.createChatFailure());
      return res.data;
    }
    dispatch(ChatSliceActions.createChatSuccess(res.data));
    return res.data;
  }
);

export const updateChat = createAsyncThunk(
  EChatProcessThunks.UpdateChat,
  async (updateCallData: IChatProcessUpdateRequest, { dispatch }: any) => {
    dispatch(ChatSliceActions.updateChatRequest());
    const res = await ChatConsultationService.updateChat(updateCallData);
    if (res.status === 425) {
      dispatch(clearChatStore(true)).then(() => {
        window.location.href = `/conversation/counsellor-unavailable-no-payment`;
      });
    }
    if (res.status === 426) {
      dispatch(clearChatStore(true)).then(() => {
        window.location.href = `/conversation/counsellor-in-call-no-payment`;
      });
    }
    if (res.status !== 200) {
      dispatch(ChatSliceActions.updateChatFailure());
      return { error: res.data };
    }
    dispatch(ChatSliceActions.updateChatSuccess(res.data));
    return res.data;
  }
);

export const startChat = createAsyncThunk(
  EChatProcessThunks.StartChat,
  async (startCallData: IChatProcessStartRequest, { dispatch }: any) => {
    dispatch(ChatSliceActions.startChatRequest());
    const res = await ChatConsultationService.startChat(startCallData);
    if (res.status === 425) {
      dispatch(clearChatStore(true)).then(() => {
        window.location.href = `/conversation/counsellor-unavailable`;
      });
    }
    if (res.status === 426) {
      dispatch(clearChatStore(true)).then(() => {
        window.location.href = `/conversation/counsellor-in-call`;
      });
    }
    if (res.status !== 200) {
      dispatch(ChatSliceActions.startChatFailure());
      return res.data;
    }
    dispatch(ChatSliceActions.startChatSuccess(res.data.conversation));
    return res.data.call;
  }
);

export const rejectChat = createAsyncThunk(
  EChatProcessThunks.RejectChat,
  async ({conversation_id, temporary_token}: {conversation_id: number, temporary_token: string}, { dispatch }: any) => {
    dispatch(ChatSliceActions.rejectChatRequest());
    const res = await ChatConsultationService.rejectChat(conversation_id, temporary_token);
    if (res.status !== 200) {
      dispatch(ChatSliceActions.rejectChatFailure());
    }
    dispatch(ChatSliceActions.rejectChatSuccess(res.data.conversation));
  }
);

export const setChatState = createAsyncThunk(
  EChatProcessThunks.SetChatState,
  async (chatState: TChatState, { dispatch }: any) => {
    dispatch(ChatSliceActions.setChatState(chatState));
  }
);

export const chargeChatTimeslot = createAsyncThunk(
  EChatProcessThunks.ChargeChatTimeslot,
  async (chargeTimeslotData: IChatProcessStartRequest, { dispatch }: any) => {
    const res = await ChatConsultationService.chargeChatTimeslot(chargeTimeslotData);
    if (res.status !== 200) {
      return res.data;
    }
    return res.data;
  }
);

export const cancelNextPayment = createAsyncThunk(
  EChatProcessThunks.ChargeChatTimeslot,
  async ({conversation_id, temporary_token}: {conversation_id: number, temporary_token: string}, _) => {
    const res = await ChatConsultationService.cancelNextPayment(conversation_id, temporary_token);
    if (res.status !== 200) {
      return res.data;
    }
    return res.data;
  }
);

export const changePayPerSessionTimeLeftDialog = createAsyncThunk(
  EChatProcessThunks.ChangeTimeLeftDialog,
  async (status: boolean, {dispatch}: any) => {
    dispatch(ChatSliceActions.changePayPerSessionTimeLeftDialog(status));
  }
);

export const sendConversationMessage = createAsyncThunk(
  EChatProcessThunks.SendConversationMessage,
  async ({ conversation_id, message, files, temporary_token }: { conversation_id: number | string, message: string, files: File[], temporary_token: string }, { dispatch }) => {
    dispatch(ChatSliceActions.sendChatMessageRequest());
    const { error, data } = await ChatsService.sendConversationMessage(conversation_id, message, files, temporary_token);
    if (error) {
      dispatch(openNotification({
        type: 'error',
        description: error?.error ?? 'Failed to send message, try again!'
      }));
      return dispatch(ChatSliceActions.sendChatMessageRequestFailure(error));
    }
    return dispatch(ChatSliceActions.sendChatMessageRequestSuccess(data as ICallMessage));
  }
);

export const addChatMessageFromSocket = createAsyncThunk(
  EChatProcessThunks.AddChatMessageFromSocket,
  async ({ message }: { message: ICallMessage }, { dispatch }) => {
    return dispatch(ChatSliceActions.addChatMessageFromSocket(message));
  }
);

export const setMessagesList = createAsyncThunk(
  EChatProcessThunks.SetMessagesList,
  async (messages: ICallMessage[], { dispatch }) => {
    return dispatch(ChatSliceActions.setMessagesList(messages));
  }
);

export const clearChatStore = createAsyncThunk(
  EChatProcessThunks.ClearChatStore,
  async (clearLocalStorage: boolean, {dispatch}: any) => {
    if (clearLocalStorage) {
      localStorage.removeItem('conversation_id');
    }
    dispatch(ChatSliceActions.clearState());
  }
);
