// API
import { api } from '../configs/api/api';

// Models
import { IPaymentsTypes } from '../interfaces/payments-types.interfaces';;

export const PaymentsTypesService = {
  fetchPaymentsTypes: async (workerId: number, temporary_token: string): Promise<IPaymentsTypes | null> => {
    try {
      const axiosResponse = await api.get(`/${temporary_token ? 'temporary' : 'users'}/calls/payment-types`, { params: { worker_id: workerId }});
      return axiosResponse.data;
    } catch (error) {
      return null;
    }
  },
  fetchConversationsPaymentsTypes: async (workerId: number, temporary_token: string): Promise<IPaymentsTypes | null> => {
    try {
      const axiosResponse = await api.get(`/${temporary_token ? 'temporary' : 'users'}/conversation/payment-types`, { params: { worker_id: workerId }});
      return axiosResponse.data;
    } catch (error) {
      return null;
    }
  }
}
