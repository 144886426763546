import React from 'react';

// Components
import { CallNotifications } from '../modules/call-notifications/call-notifications';

// Styles
import './status-declined.scss';

export const StatusDeclined = () => {

  const workerId = localStorage.getItem('worker_id');

  return (<CallNotifications
    title={'Counsellor Unavailable'}
    text={'The Counsellor declined the call. You may try to contact them later. The payment for the Consultation will be refunded to you.'}
    navigateAction={`/expert/${workerId}/history`}  />);
}
