import { api } from '../configs/api/api';
import { IUserData } from '../interfaces/user.interfaces';

export const ProfileSettingsService = {
  verifyOldPassword: async (old_password: string): Promise<{ message: string, status: string } | any> => {
    try {
      return await api.post('/users/verify-old-password', {old_password});
    } catch (error: any) {
      return error;
    }
  },
  updatePassword: async (new_password: string, new_password_confirmation: string, is_password_validated = true): Promise<{ message: string, status: string } | any> => {
    try {
      return await api.post('/users/update-password', {new_password, new_password_confirmation, is_password_validated});
    } catch (error: any) {
      return error;
    }
  },
  checkCurrentEmail: async (email: string): Promise<{ message: string, status: string } | any> => {
    try {
      return await api.post('/users/check-email', {email});
    } catch (error: any) {
      return error;
    }
  },
  checkVerificationCode: async (code: string, type: string): Promise<IUserData | any> => {
    try {
      return await api.post('/users/check-verification-code', {code, type});
    } catch (error: any) {
      return error;
    }
  },
  resendVerificationCode: async (email: string, type: string): Promise<any> => {
    try {
      return await api.post('/users/resend-verification-code', {email, type});
    } catch (error: any) {
      return error;
    }
  },
  updateEmail: async (email: string, type: string): Promise<any> => {
    try {
      return await api.post('/users/update-email', {email, type});
    } catch (error: any) {
      return error;
    }
  },
}
