import React, { FC, HTMLProps, useEffect, useMemo, useState } from 'react';
import dayjs from "dayjs";
import { clearInterval, setInterval } from 'worker-timers';

// Styles
import './view-element-by-time.scss';

type TViewElementByTimeProps = HTMLProps<HTMLElement> & {
  from?: string,
  to?: string,
}

export const ViewElementByTime: FC<TViewElementByTimeProps> = (
  { from = '', to = '', children }: TViewElementByTimeProps
) => {
  const [time, setTime] = useState<number>(0);

  const canView = useMemo(() => {
    if (!from) return false;

    if (from && !to) {
      return dayjs().isAfter(dayjs(from), 'seconds');
    }

    return dayjs().isAfter(dayjs(from), 'seconds') && dayjs().isBefore(dayjs(to), 'seconds');
  }, [from, to, time]);

  useEffect(() => {
    // Set up an interval to update view every 20 seconds
    const intervalId = setInterval(() => {
      setTime((prevTime) => prevTime + 1);
    }, 10000);

    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  return (<React.Fragment>
    { canView && children }
  </React.Fragment>);
};
