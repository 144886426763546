import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Components
import { UserAvatarSection } from './user-avatar-section/user-avatar-section';
import { UserEdit } from './user-edit/user-edit';
import { UserView } from './user-view/user-view';

// Thunks
import { setInProgressState } from '../../../store/profile-settings/profile-settings.thunks';

// Selectors
import { getUserState } from '../../../store/user/user.selectors';

// Styles
import './personal-data.scss';
import { SpinElement } from '@ppmcore/seven-ppm-core-shared-components-react';

export const PersonalData = () => {

  const dispatch = useDispatch<any>();
  const [editMode, setEditMode] = useState<boolean>(false);

  const {user, loading} = useSelector(getUserState);

  const onChangeMode = () => {
    setEditMode(!editMode);
    if (editMode) {
      dispatch(setInProgressState(true));
    } else {
      dispatch(setInProgressState(false));
    }
  }

  return (<div className="personal-data-page">
    <div className={'content-title'}>Personal Data</div>
    <div className={'content-title-line'}></div>
    {!user
      ? <SpinElement fullHeight={false}/>
      : <div className={'content-inside'}>
          <UserAvatarSection userPhoto={user?.photo ?? null} />
          <div className={'personal-data'}>
            {!editMode && <UserView userData={user} changeMode={onChangeMode} />}
            {editMode && <UserEdit userData={user} isLoading={loading} changeMode={onChangeMode} />}
          </div>
        </div>}
  </div>);
}
