import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Models
import { TSidebarType } from '../../types/sidebar.types';

export interface ISidebarState {
  type: TSidebarType,
}

const initialState: ISidebarState = {
  type: localStorage.getItem('sidebarState') ? localStorage.getItem('sidebarState') as TSidebarType : 'home',
};

export const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState,
  reducers: {
    setSidebarType: (state, { payload: type }: PayloadAction<TSidebarType>) => {
      state.type = type;
    },
  },
});

export const SidebarSliceActions = sidebarSlice.actions;
