import axios from 'axios';

/**
 axios instance for making API requests
 @type {import('axios').AxiosInstance}
 */
export const api = axios.create({
  /**
   Base URL of the API
   @type {string}
   */
  baseURL: process.env.REACT_APP_API_URL,
  /**
   Timeout for the API requests
   @type {number}
   */
  timeout: 20000,
  /**
   Headers to be sent with the API requests
   @type {Object.<string, string>}
   */
  headers: {
    'Access-Control-Allow-Origin' : '*',
    'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS'
  },
});
