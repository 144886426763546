import React, { FC, useState } from 'react';
import { Button, Form } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

// Shared UI Library Components
import { defaultValidateMessages, InputPassword, ServerErrors, ServerErrorsTimer } from '@ppmcore/seven-ppm-core-shared-components-react';

// Selectors
import {
  getProfileSettings
} from '../../../../store/profile-settings/profile-settings.selectors';

// Thunks
import { checkOldPassword } from '../../../../store/profile-settings/profile-settings.thunks';

// Styles
import './cp-step-1.scss';

interface ICpStep1Props {
  setStep?: (value: number) => void;
}

export const CpStep1: FC<ICpStep1Props> = ({setStep = () => {}}: ICpStep1Props) => {

  const dispatch = useDispatch<any>();
  const [cpStep1] = Form.useForm();

  const [btnPrevDisabled, setBtnPrevDisabled] = useState(true);
  const [btnNextDisabled, setBtnNextDisabled] = useState(true);
  const [formDisabled, setFormDisabled] = useState(false);

  const [serverError, setServerError] = useState<string | null>(null);
  const [errorTime, setErrorTime] = useState<string | null>(null);
  const [errorTimeMessage, setErrorTimeMessage] = useState<string | null>(null);

  const {loading} = useSelector(getProfileSettings);

  const onFinish = () => {
    if (btnNextDisabled) return;
    cpStep1.validateFields().then((values) => {
      const {old_password} = values;
      dispatch(checkOldPassword(old_password)).then((res: { payload: any }) => {
        if (res.payload.error && res.payload.access_after) {
          cpStep1.resetFields();
          setBtnPrevDisabled(true);
          setBtnNextDisabled(true);
          setFormDisabled(true);
          setServerError(null);

          setErrorTime(res.payload.access_after);
          setErrorTimeMessage(res.payload.error);
          return;
        }
        if (res.payload && res.payload.error) {
          setBtnNextDisabled(true);
          setServerError(res.payload.error);
          return;
        }
        setStep(2);
      });
    }).catch((error) => {
      console.error('Form validation failed', error);
    });
  };

  const cancelAction = () => {
    cpStep1.resetFields();
    setServerError(null);
    setBtnPrevDisabled(true);
    setBtnNextDisabled(true);
  }

  const onValuesChange = (changedValues: { old_password: string },
                          allFormValues: { old_password: string | undefined }) => {
    if (allFormValues.old_password !== undefined
      && allFormValues.old_password !== '') {
      setBtnPrevDisabled(false);
      setBtnNextDisabled(false);
    } else {
      setBtnPrevDisabled(true);
      setBtnNextDisabled(true);
    }
  };

  const onTimeEnd = () => {
    setErrorTimeMessage(null);
    setErrorTime(null);
    setFormDisabled(false);
  }

  return (<div className="cp-step-1">
    {serverError && <ServerErrors serverError={serverError}/>}
    {errorTimeMessage && errorTime &&
      <ServerErrorsTimer serverError={errorTimeMessage} errorTime={errorTime} onTimeEnd={onTimeEnd}/>}

    <Form
      form={cpStep1}
      disabled={formDisabled}
      name="form-cp-step-1"
      onFinish={onFinish}
      autoComplete="off"
      onValuesChange={onValuesChange}
      validateMessages={defaultValidateMessages}>
      <Form.Item
        name="old_password"
        messageVariables={{fieldLabel: 'Password'}}
        rules={[{required: true}]}>
        <InputPassword disabled={formDisabled} placeholder={'Password*'}/>
      </Form.Item>

      <div className="form-bottom">
        <Button type="default" size={'large'} onClick={cancelAction}
                disabled={btnPrevDisabled || loading}>Cancel</Button>
        <Button type="primary" size={'large'} htmlType={'submit'}
                disabled={btnNextDisabled || loading}>Continue</Button>
      </div>
    </Form>
  </div>);
}
