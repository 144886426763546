import React, { FC, useEffect, useState } from 'react';
import { Button, Form } from 'antd';
import { useDispatch } from 'react-redux';
import PhoneInput from 'react-phone-input-2';

// Shared UI Library Components
import {
  defaultValidateMessages,
  DiscardModal,
  CustomInput,
  CustomPhoneNumber, stringPattern, CustomIcon, EIconName
} from '@ppmcore/seven-ppm-core-shared-components-react';

// Components
import { openNotification } from '../../../../store/app-notifications/app-notifications.thunks';

// Thunks
import { updateProfile } from '../../../../store/user/user.thunks';

// Interfaces
import { IUserData } from '../../../../interfaces/user.interfaces';

// Styles
import './user-edit.scss';

interface IUserEditProps {
  userData: IUserData | null;
  isLoading?: boolean;
  changeMode?: () => void;
}

export const UserEdit: FC<IUserEditProps> = ({ userData, isLoading, changeMode = () => {} }: IUserEditProps) => {

  const dispatch = useDispatch<any>();
  const [form] = Form.useForm();

  const [hasChanges, setHasChanges] = useState(false);
  const [discardModalOpen, setDiscardModalOpen] = useState(false);

  const [formattedPhone, setFormattedPhone] = useState('');
  const [isPhoneValueValid, setIsPhoneValueValid] = useState<boolean | null>(null);

  useEffect(() => {
    if (userData) {
      form.setFieldsValue({
        first_name: userData.first_name,
        last_name: userData.last_name,
        // username: userData.username,
        is_anonymous: userData.is_anonymous
      });

      setFormattedPhone(userData.phone ?? '');
      if (userData.phone) setIsPhoneValueValid(true);
    }
  }, [userData]);

  const onFinish = () => {
    if (!hasChanges) return;
    form.validateFields().then((values) => {
      let userData: IUserData = {
        first_name: values.first_name,
        last_name: values.last_name,
        // username: values.username,
        phone: formattedPhone,
        is_anonymous: false
      };

      dispatch(updateProfile(userData)).then((res: any) => {
        if (res.payload.error) {
          dispatch(openNotification({
            description: res.payload.error,
            type: 'error'
          }));
          return;
        }

        dispatch(openNotification({
          description: 'Changes have successfully been saved.',
          type: 'success'
        }));
        changeMode();
      });
    }).catch((error) => {
      console.error('Form validation failed', error);
    });
  };

  const onCancel = (value: boolean) => {
    if (value) changeMode();
    setDiscardModalOpen(false);
  }

  const onCancelAction = () => {
    if (hasChanges) {
      setDiscardModalOpen(true);
      return;
    }
    changeMode();
  }

  const onValuesChange = (changedValues: { first_name: string } | { last_name: string } | { username: string },
    allFormValues: { first_name: string | undefined, last_name: string | undefined, username: string | undefined }) => {

    if (allFormValues.first_name !== undefined
      && allFormValues.last_name !== undefined
      // && allFormValues.username !== undefined
      && allFormValues.first_name !== ''
      && allFormValues.last_name !== ''
      // && allFormValues.username !== ''
      && allFormValues.first_name.length <= 30
      && allFormValues.last_name.length <= 30) {
      const isFirstNameChanged = allFormValues.first_name !== userData?.first_name;
      const isLastNameChanged = allFormValues.last_name !== userData?.last_name;
      // const isUsernameChanged = allFormValues.username !== userData?.username;
      const isPhoneChanged = formattedPhone !== userData?.phone && !!formattedPhone;
      const isHasChanges = isFirstNameChanged || isLastNameChanged || isPhoneChanged;
      setHasChanges(isHasChanges);
    } else {
      setHasChanges(false);
    }
  };

  useEffect(() => {
    const { first_name, last_name } = form.getFieldsValue();
    const isHasFirstNameChanges = typeof first_name !== 'undefined' ? userData?.first_name !== first_name : false;
    const isHasLastNameChanges = typeof last_name !== 'undefined' ? userData?.last_name !== last_name : false;
    const isHasPhoneChanges = userData?.phone !== formattedPhone;
    const isHasChanges = isHasFirstNameChanges || isHasLastNameChanges || isHasPhoneChanges;

    setHasChanges(isHasChanges &&
      (!!isPhoneValueValid) &&
      first_name?.length <= 30 &&
      first_name?.length >= 1 &&
      last_name?.length <= 30 &&
      last_name?.length >= 1
    );
  }, [formattedPhone]);

  return (<div className="user-edit">
    { userData && <Form
      form={ form }
      className={ 'user-edit-form' }
      name="sign-up"
      onFinish={ onFinish }
      onValuesChange={ onValuesChange }
      autoComplete="off"
      validateMessages={ defaultValidateMessages }>

      <div className="form-top-actions">
        { hasChanges &&
          <Button type="primary" size={ 'small' } htmlType={ 'submit' } disabled={ isLoading }>Save</Button> }
        <Button type="default" size={ 'small' } onClick={ onCancelAction } disabled={ isLoading }>Cancel</Button>
      </div>

      <div className={ 'profile-title' }>General Data</div>

      <div className={ 'profile-row' }>
        <Form.Item
          name="first_name"
          messageVariables={ { fieldLabel: 'First Name' } }
          rules={ [
            { required: true, max: 30 },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || new RegExp(stringPattern).test(getFieldValue('first_name'))) {
                  return Promise.resolve();
                }
                if (value.length > 30) {
                  return Promise.resolve();
                }
                setHasChanges(false);
                return Promise.reject(new Error(defaultValidateMessages.invalidFirstNameFormat));
              }
            })
          ] }>
          <CustomInput type={ 'text' } placeholder={ 'First Name*' } />
        </Form.Item>
        <Form.Item
          name="last_name"
          messageVariables={ { fieldLabel: 'Last Name' } }
          rules={ [
            { required: true, max: 30 },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || new RegExp(stringPattern).test(getFieldValue('last_name'))) {
                  return Promise.resolve();
                }
                if (value.length > 30) {
                  return Promise.resolve();
                }
                setHasChanges(false);
                return Promise.reject(new Error(defaultValidateMessages.invalidLastNameFormat));
              }
            })
          ] }>
          <CustomInput type={ 'text' } placeholder={ 'Last Name*' } />
        </Form.Item>
      </div>

      <div className={ 'profile-row' }>
        {/*<Form.Item*/ }
        {/*  name="username"*/ }
        {/*  messageVariables={{fieldLabel: 'Nickname'}}*/ }
        {/*  rules={[{required: true, max: 60}]}>*/ }
        {/*  <CustomInput type={'text'} placeholder={'Nickname*'} suffix={<CustomIcon name={EIconName.PersonOff}/>} />*/ }
        {/*</Form.Item>*/ }

        <Form.Item
          name="email"
          messageVariables={ { fieldLabel: 'Email' } } initialValue={ userData?.email }>
          <CustomInput type={ 'text' } shouldTrim={true} placeholder={ 'Email*' } disabled={ true } />
        </Form.Item>

        <Form.Item>
          <CustomPhoneNumber
            PhoneInput={ PhoneInput }
            phone={ userData.phone ?? '' }
            isPhoneNumberValid={ (value) => {
              // checkFormValid(value);
              setIsPhoneValueValid(value);
            } }
            onChangeFormattedPhone={ (value) => {
              setFormattedPhone(value);
            } }/>
        </Form.Item>

      </div>
    </Form> }

    <DiscardModal open={ discardModalOpen }
                  onCancel={ (value) => onCancel(value) }
                  title={ 'Are you sure you want to discard changes?' }/>
  </div>);
}
