import { createAsyncThunk } from '@reduxjs/toolkit';

// Store entities & slices
import { openNotification } from '../app-notifications/app-notifications.thunks';
import { PaymentsSliceActions } from './payments.slice';

// Services
import { PaymentsService } from '../../services/payments.service';

// Models
import { ICreateDefaultPayment } from '../../interfaces/payments.interfaces';

// Create the middleware instance and methods
export enum EPaymentsThunks {
  FetchPaymentsCards = 'PAYMENTS/fetchPaymentsCards',
  DeletePaymentCard = 'PAYMENTS/deletePaymentCard',
  SelectPaymentCard = 'PAYMENTS/selectPaymentCard',
  CreatePaymentCard = 'PAYMENTS/createPaymentCard',
  PayExistingPaymentCard = 'PAYMENTS/payExistingPaymentCard',
}

export const fetchPaymentsCards = createAsyncThunk(
  EPaymentsThunks.FetchPaymentsCards,
  async (_, { dispatch }) => {
    dispatch(PaymentsSliceActions.loadPaymentsRequest());
    const { error, data } = await PaymentsService.fetchPaymentCards();
    if (!data) {
      dispatch(openNotification({ type: 'error', description: error }))
      return dispatch(PaymentsSliceActions.loadPaymentsRequestError());
    }
    return dispatch(PaymentsSliceActions.loadPaymentsRequestSuccess(data));
  }
);

export const deletePaymentCard = createAsyncThunk(
  EPaymentsThunks.DeletePaymentCard,
  async (card_id: string, { dispatch }) => {
    const { error, selected_card, message } = await PaymentsService.deletePaymentCard(card_id);
    if (error) {
      return dispatch(openNotification({ type: 'error', description: error }));
    }
    dispatch(openNotification({ type: 'success', description: message }));
    return dispatch(PaymentsSliceActions.deleteCard({ selected_card, deleted_card: card_id }));
  }
);

export const selectPaymentCard = createAsyncThunk(
  EPaymentsThunks.SelectPaymentCard,
  async (card_id: string, { dispatch }) => {
    const { error, selected_card, message } = await PaymentsService.selectPaymentCard(card_id);
    if (!selected_card) {
      return dispatch(openNotification({ type: 'error', description: error }));
    }
    dispatch(openNotification({ type: 'success', description: message }));
    return dispatch(PaymentsSliceActions.markDefault({ selected_card }));
  }
);

export const createPaymentCard = createAsyncThunk(
  EPaymentsThunks.CreatePaymentCard,
  async (token: string, { dispatch }) => {
    const { error, card } = await PaymentsService.createPaymentCard(token);
    if (!card) {
      dispatch(openNotification({ type: 'error', description: error }));
      return null;
    }
    dispatch(openNotification({ type: 'success', description: 'Payment method successfully added!' }));
    dispatch(PaymentsSliceActions.addNewCard({ card }));
    return card;
  }
);

export const payExistingPaymentCard = createAsyncThunk(
  EPaymentsThunks.PayExistingPaymentCard,
  async (createPayment: ICreateDefaultPayment, { dispatch }) => {
    const { data, error } = await PaymentsService.payExistingPaymentCard(createPayment);
    if (!data) {
      return { error };
    }
    return { data };
  }
);

