import React, { FC, useState } from 'react';
import { Modal } from 'antd';

// Components
import { TuFirstStep } from './tu-first-step/tu-first-step';
import { TuSecondStep } from './tu-second-step/tu-second-step';

// Styles
import './temp-user-sign-up-modal.scss';

interface ITempUserSignUpModalProps {
  open: boolean;
  companyCode: string;
  isMessagesAction: boolean;
  onSubmit?: () => void;
  onCancel?: () => void;
}

export const TempUserSignUpModal: FC<ITempUserSignUpModalProps> = (
  {
    open = false,
    companyCode,
    isMessagesAction,
    onSubmit = () => {},
    onCancel = () => {}
  }: ITempUserSignUpModalProps
) => {

  const [completeFirstStep, setCompleteFirstStep] = useState<boolean>(false);

  const completeFirstStepAction = () => {
    setCompleteFirstStep(true);
  }

  const onCancelAction = () => {
    setCompleteFirstStep(false);
    onCancel();
  }

  return (
    <Modal
      rootClassName="temp-user-sign-up-modal"
      width={'394px'}
      open={open}
      centered
      footer={[]}
      onCancel={onCancelAction}
    >
      <div className="modal-body">
        {!completeFirstStep
          && <TuFirstStep
            companyCode={companyCode}
            isMessagesAction={isMessagesAction}
            onSubmit={completeFirstStepAction}
            onCancel={onCancelAction} />}

        {completeFirstStep && <TuSecondStep onSubmit={onSubmit} onCancel={onCancelAction} />}
      </div>
    </Modal>
  )
};
