import React, { FC } from 'react';
import { Modal } from 'antd';

// Shared UI Library Components
import { CustomButton } from '@ppmcore/seven-ppm-core-shared-components-react';

// Styles
import './company-added-modal.scss';

type TCompanyAddedModalProps = {
  open: boolean;
  onClose?: () => void;
}

export const CompanyAddedModal: FC<TCompanyAddedModalProps> = (
  { open = false, onClose = () => {} }: TCompanyAddedModalProps
) => {

  return (
    <Modal
      rootClassName="company-added-modal"
      width={ '604px' }
      open={ open }
      centered
      title={ null }
      closeIcon={ null }
      footer={ [
        <CustomButton key="ok" text={ 'OK' } onClick={ onClose }/>
      ] }
      onCancel={ onClose }
    >
      <div className="modal-body">
        <div className="modal-body-item modal-body-item--description">
          This Company has been added to your Company list.
        </div>
      </div>
    </Modal>
  )
};
