import React, { FC } from 'react';

// Shared UI Library Components
import { CustomIcon, EIconName, EIconColor } from '@ppmcore/seven-ppm-core-shared-components-react';

// Styles
import './time-left-message.scss';

interface ITimeLeftMessageProps {
  onCloseDialog?: () => void;
}

export const TimeLeftMessage: FC<ITimeLeftMessageProps> = ({onCloseDialog = () => {}}: ITimeLeftMessageProps) => {
  return (<div className={'time-left-message'}>
    <div className={'time-left-message-inside'}>
      <CustomIcon name={EIconName.Schedule} color={EIconColor.Default} />5 minutes left in your connection
      <CustomIcon name={EIconName.Close} color={EIconColor.Default} onClick={() => onCloseDialog()} />
    </div>
  </div>);
};
