import { createAsyncThunk } from '@reduxjs/toolkit';

// Services
import { ChatConsultationService } from '../../services/chat-consultation.service';

// Slices
import { ChatFinishedSliceActions } from './chat-finished.slice';

export enum EChatFinishedThunks {
  GetChatFinishedInfo = 'AFTER_CHAT/getChatFinishedInfo',
  ClearChatFinishedStore = 'AFTER_CHAT/clearChatFinishedStore',
}

export const getChatFinishedInfo = createAsyncThunk(
  EChatFinishedThunks.GetChatFinishedInfo,
  async ({ conversation_id, temporary_token }: { conversation_id: number; temporary_token: string; }, { dispatch }: any) => {

    dispatch(ChatFinishedSliceActions.getChatFinishedRequest());
    const res = await ChatConsultationService.getChatInfo(conversation_id, temporary_token);
    if (res.status !== 200) {
      dispatch(ChatFinishedSliceActions.getChatFinishedFailure());
      return res.data;
    }
    dispatch(ChatFinishedSliceActions.getChatFinishedSuccess(res.data.conversation));
  }
);

export const clearChatFinishedStore = createAsyncThunk(
  EChatFinishedThunks.ClearChatFinishedStore,
  async (_, { dispatch }: any) => {
    dispatch(ChatFinishedSliceActions.clearChatFinishedState());
  }
);
