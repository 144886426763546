import React from 'react';
import { useNavigate } from 'react-router-dom';

// Shared UI Library Components
import { CustomButton } from '@ppmcore/seven-ppm-core-shared-components-react';

// Components
import { AuthLayout } from '../auth-modules/auth-layout/auth-layout';

// Styles
import './recovery-link-expired.scss';

export const RecoveryLinkExpired = () => {

  const navigate = useNavigate();

  return (<AuthLayout cardTitle="Your recovery link has expired." rightBarIcon="auth">
    <div className={'recovery-link-expired'}>
      <CustomButton onClick={() => navigate('/sign-in')} text="Log In"/>
    </div>
  </AuthLayout>);
}
