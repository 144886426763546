import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../index';
import { IStaticPagesState } from './static-pages.slice';

/**
 A selector function to retrieve the user profile data from the store
 @function
 @param {RootState} state - The root state of the Redux store
 @returns {UserProfileState} The user profile state object from the Redux store
 */
const selectStaticPagesState = (state: RootState): IStaticPagesState => state.staticPages;

/**
 A memoized selector function to retrieve the user profile data from the store
 @function
 @type {import('@reduxjs/toolkit').OutputSelector<RootState, UserProfileState, (res: UserProfileState) => UserProfileState>}
 */
export const getStaticPagesState = createSelector(
  [selectStaticPagesState],
  (state): IStaticPagesState => state
);
