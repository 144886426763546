/* eslint-disable no-restricted-globals */
const timerToDownWorker = () => {
    let timerInterval;
    self.onmessage = function ({ data: { turn, initialSeconds } }) {
        let time = initialSeconds;
        if (turn === "off" || timerInterval) {
            clearInterval(timerInterval);
            time = 0;
        }
        if (turn === "on") {
            timerInterval = setInterval(() => {
                time -= 1;
                self.postMessage({ time });
            }, 1000);
        }
    };
};

let workerCode = timerToDownWorker.toString();
workerCode = workerCode.substring(workerCode.indexOf("{") + 1, workerCode.lastIndexOf("}"));

const blob = new Blob([workerCode], { type: "application/javascript" });
const timer_to_down_worker = URL.createObjectURL(blob);

module.exports = timer_to_down_worker;
