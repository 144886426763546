import { useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

// Store Entities
import { setCurrentPath } from '../store/core/core.thunks';
import { getSidebarState } from '../store/sidebar/sidebar.selectors';

// Models
import { TSidebarType } from '../types/sidebar.types';
import { setSidebarType } from '../store/sidebar/sidebar.thunks';

/**
 * Custom React Hook for tracking route changes.
 * @returns {void}
 */
export const useRouterHistory = (): void => {

  const { pathname } = useLocation();
  const dispatch = useDispatch<any>();

  const { type } = useSelector(getSidebarState);

  /**
   * Saves the current location in store state.
   * @returns {void}
   */
  const saveCurrentLocation = (): void => {
    const replacedValue = pathname.replace('/', '');

    dispatch(setCurrentPath(replacedValue));

    const sidebarType: TSidebarType = (() => {
      if (replacedValue === 'home') {
        return 'home'
      }
      if (replacedValue === 'history') {
        return 'history'
      }
      if (replacedValue === 'business-information') {
        return 'business-information'
      }
      if (replacedValue === 'favorites') {
        return 'favorites'
      }
      return type;
    })();

    if (sidebarType === type) return;

    dispatch(setSidebarType({ type: sidebarType }));
  }

  /**
   * Use the useLayoutEffect hook to run the saveCurrentLocation function whenever the pathname changes.
   */
  useLayoutEffect(() => {
    saveCurrentLocation();
  }, [pathname]);
};
