import React from 'react';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';

// Store entities
import { getUserState } from '../../store/user/user.selectors';
import { getCoreState } from '../../store/core/core.selectors';

// Components
import { ExpertList } from '../../shared/ui-components/expert';

// Styles
import './welcome.scss';

export const Welcome = () => {

  const token = localStorage.getItem('token');

  const { user } = useSelector(getUserState);
  const { companyInfo: { company } } = useSelector(getCoreState);

  return (
    <div className="welcome">
      <div className="welcome--desktop">
        <div className="desktop--header">
          {company?.company_profile.is_blocked
            ? <div className="header-title"><Trans i18nKey="welcome.temporarilyUnavailable"/></div>
            : <div className="header-title">
                {token ? <Trans i18nKey="welcome.title" values={ { name: user?.first_name ?? '' } }/> : <Trans i18nKey="welcome.titleNotRegistered"/>}
              </div>}
          {company?.company_profile.is_blocked
            ? <div className="header-subtitle"><Trans i18nKey="welcome.comeBackLater"/></div>
            : <div className="header-subtitle">
              {token ? <Trans i18nKey="welcome.subtitle"/> : <Trans i18nKey="welcome.subtitleNotRegistered"/>}
            </div>}
        </div>
        <div className="desktop--img">
          {company?.company_profile.is_blocked
            ? <img src="/assets/icons/big/welcome-unavailable.svg" alt="welcome-unavailable-icon"/>
            : <img src="/assets/icons/big/welcome-body.svg" alt="welcome-icon"/>}
        </div>
      </div>
      <div className="welcome--mobile">
        <div className="mobile-body">
          <ExpertList hidden={ false } type={ 'all' }/>
        </div>
      </div>
    </div>
  );
}
