import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { useDispatch } from 'react-redux';

// Store actions
import { clearPaymentsTypes } from '../../store/payments-types/payments-types.thunks';
import { fetchActiveAppointments } from "../../store/active-appointments/active-appointments.thunks";

// Styles
import './call.scss';

export const Call = () => {
  const dispatch = useDispatch<any>();
  const exitFullscreen = (): void => {
    window.parent.postMessage({
      type: 'exit-fullscreen',
      data: null
    }, '*');
  }

  useEffect(() => {
    return () => {
      exitFullscreen();
      dispatch(fetchActiveAppointments());
      dispatch(clearPaymentsTypes());
    }
  }, []);
  return (<div className={ `call-section` }>
    <Outlet/>
  </div>);
}
