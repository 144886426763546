import React from 'react';
import { useNavigate } from 'react-router-dom';

// Components
import { CustomButton } from '@ppmcore/seven-ppm-core-shared-components-react';

// Styles
import './stay-with-us.scss';

export const StayWithUs = () => {

  const navigate = useNavigate();
  const returnPathname = localStorage.getItem('return_pathname') || `/home`;

  return (<div className={ 'stay-with-us-page' }>
    <div className={ 'stay-with-us-page-inside' }>
      <div className={ 'stay-with-us-title' }>Stay with Us!</div>
      <div className={ 'stay-with-us-text' }>Create an account to stay on top of your history and make the most of our user-friendly features.</div>
      <div className={ 'stay-with-us-btn-line' }>
        {/*<CustomButton type="text" size={'medium'} text={'Log In'} mode={'dark'} onClick={() => navigate('/sign-in')} />*/ }
        {/*<CustomButton type="primary" size={'medium'} text={'Sign Up'} mode={'dark'} onClick={() => navigate('/sign-up')} />*/ }
        <CustomButton type={ 'primary' } size={ 'medium' } text={ 'Set Up Profile' } mode={ 'dark' }
                      onClick={ () => navigate('/sign-up') }/>
      </div>
      <CustomButton type="link" size={ 'medium' } text={ 'Skip' } mode={ 'dark' }
                    onClick={ () => window.location.assign(`${returnPathname}`) }/>
    </div>
  </div>);
}
