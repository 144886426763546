import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

// Components
import {
  SpinElement,
  CustomIconBig,
  CustomIcon,
  EIconBigName,
  EIconName,
  EIconColor,
  CustomButton
} from '@ppmcore/seven-ppm-core-shared-components-react';

// Selectors
import { getCallState } from '../../../store/call/call.selectors';

// Styles
import './rejoin-call.scss';

interface IIncomingCallProps {
  onClose?: () => void;
}

export const RejoinCall: FC<IIncomingCallProps> = ({ onClose = () => {} }: IIncomingCallProps) => {

  const navigate = useNavigate();

  const callId = localStorage.getItem('call_id');

  const { callProcess } = useSelector(getCallState);

  const goToCall = () => {
    onClose();
    navigate(`/call/${callId}`);
  }

  const closeAction = () => {
    onClose();
  }

  return (<div className={'rejoin-call'}>
    {(!callProcess) && <SpinElement/>}

    {callProcess && <div className={'incoming-call-inside'}>
      <CustomIcon className={'close-rejoin-call'} size={'size-xl-30'} name={ EIconName.Close } onClick={ closeAction } />
      <div className={'photo'}>
        {callProcess?.worker?.photo
          ? <img src={callProcess?.worker?.photo} alt=""/>
          : <div className={'default-image'}>
            <CustomIconBig name={EIconBigName.NoVideoAvatar}/>
          </div>}
      </div>
      <div className={'fio'}>{callProcess?.worker?.first_name} {callProcess?.worker?.last_name} has connected to the call. Do you want to connect too?
      </div>

      <div className={'call-type'}>
        {callProcess.call_type === 'video_call'
          ? <CustomIcon name={EIconName.CameraOn} color={EIconColor.White}/>
          : <CustomIcon name={EIconName.MicOn} color={EIconColor.White}/>}
      </div>

      <div className={'actions'}>
        <CustomButton mode={ 'dark' } size={ 'medium' } onClick={ goToCall }>Rejoin</CustomButton>
      </div>
    </div>}
  </div>);
}
