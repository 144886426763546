import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ILoaderState {
  show: boolean;
  text: string;
}

const initialState: ILoaderState = {
  show: false,
  text: ''
};

export const loaderSlice = createSlice({
  initialState,
  name: 'loader',
  reducers: {
    setLoaderState: (state, { payload: { show, text } }: PayloadAction<{ show: boolean, text?: string }>): void => {
      state.show = show;
      state.text = text ?? '';
    }
  }
});

export const LoaderSliceActions = loaderSlice.actions;
