import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

// Service Worker
import * as serviceWorkerRegistration from '../serviceWorkerRegistration';

// Store actions
import { openNotification } from '../store/app-notifications/app-notifications.thunks';

/**
 * A custom React hook to register a Service Worker and handle updates.
 */
export const useServiceWorker = () => {
  const dispatch = useDispatch<any>();

  /**
   * The handler function to handle updates to the Service Worker.
   * @param {ServiceWorkerRegistration} registration - The Service Worker registration.
   */
  const onUpdateHandler = (registration: ServiceWorkerRegistration) => {
    /**
     * Checks if the current route includes 'call'.
     */
    const isCallRoute = window.location.href.includes('call');
    /**
     * The waiting service worker.
     */
    const waitingServiceWorker = registration?.waiting;

    // If there is no waiting service worker or if the route is 'call', do nothing.
    if (!waitingServiceWorker || isCallRoute) return;

    /**
     * Opens an info notification to inform the user about the upcoming reload.
     */
    dispatch(openNotification({
      type: 'info',
      description: 'Please note that the new functionality is available and will be used, the page will reload automatically in a few seconds',
    }));

    /**
     * Event listener for the state change of the waiting service worker.
     */
    waitingServiceWorker.addEventListener("statechange", () => {
      /**
       * Timer to delay the page reload after a state change.
       */
      const timerId = setTimeout(() => {
        clearTimeout(timerId);
        window.location.reload();
      }, 5000);
    });

    // Sends a message to the waiting service worker to skip waiting.
    waitingServiceWorker.postMessage({ type: "SKIP_WAITING" });
  }

  /**
   * The handler function to handle successful Service Worker registration.
   * @param {ServiceWorkerRegistration} registration - The Service Worker registration.
   */
  const onSuccessHandler = (registration: ServiceWorkerRegistration) => {}

  useEffect(() => {
    // If you want your app to work offline and load faster, you can change
    // unregister() to register() below. Note this comes with some pitfalls.
    // Learn more about service workers: https://cra.link/PWA
    // serviceWorkerRegistration.unregister();
    serviceWorkerRegistration.register({
      onUpdate: onUpdateHandler,
      onSuccess: onSuccessHandler,
    });

    // The cleanup function is not used in this hook, so an empty function is returned.
    return () => {
    };
  }, []);
};
