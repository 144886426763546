import React, { FC } from 'react';

// Styles
import './terms-of-service.scss';

// Components
import { StaticPages } from '../../modules/static-pages/static-pages';
import {
  ExpertAuthWidgetNavigation
} from '../../shared/ui-components/expert/expert-auth-widget-navigation/expert-auth-widget-navigation';

type TTermsOfServiceProps = {};

export const TermsOfService: FC<TTermsOfServiceProps> = (
  {}: TTermsOfServiceProps
) => {

  return (
    <div className="terms-of-service">
      <div className="terms-of-service--header">
        <div className="header-title">
          Terms of Service
        </div>
        <ExpertAuthWidgetNavigation/>
      </div>
      <div className="terms-of-service--body">
        <StaticPages staticPageId={ 2 } isDialog={ true }/>
      </div>
    </div>
  );
}
