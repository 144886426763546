import { createAsyncThunk } from '@reduxjs/toolkit';

// Services
import { AppointmentsService } from '../../services/appointments.service';

// Slices
import { ActiveAppointmentsSliceActions } from './active-appointments.slice';

// Models
import { IAppointmentCallStarted } from "../../interfaces/appointments.interfaces";

export enum EActiveAppointmentsThunks {
  FetchActiveAppointments = 'ACTIVE_APPOINTMENTS/fetchActiveAppointments',
  UpdateActiveAppointmentFromSocket = 'ACTIVE_APPOINTMENTS/updateActiveAppointmentFromSocket',
  ClearActiveAppointmentState = 'ACTIVE_APPOINTMENTS/clearActiveAppointmentState',
}

export const fetchActiveAppointments = createAsyncThunk(
  EActiveAppointmentsThunks.FetchActiveAppointments,
  async (_, { dispatch }: any) => {
    dispatch(ActiveAppointmentsSliceActions.fetchActiveAppointments());
    const { data, error } = await AppointmentsService.fetchActiveAppointments();
    if (!data || error) {
      dispatch(ActiveAppointmentsSliceActions.fetchActiveAppointmentsError());
      return null;
    }
    return dispatch(ActiveAppointmentsSliceActions.fetchActiveAppointmentsSuccess(data));
  }
);

export const updateActiveAppointmentFromSocket = createAsyncThunk(
  EActiveAppointmentsThunks.UpdateActiveAppointmentFromSocket,
  async (appointment: IAppointmentCallStarted, { dispatch }) => {
    return dispatch(ActiveAppointmentsSliceActions.updateActiveAppointmentFromSocket(appointment));
  }
);

export const clearActiveAppointmentState = createAsyncThunk(
  EActiveAppointmentsThunks.ClearActiveAppointmentState,
  async (_, { dispatch }: any) => {
    dispatch(ActiveAppointmentsSliceActions.clearState());
  }
);
