import axios, { AxiosError } from 'axios';

// API
import { api } from '../configs/api/api';

// Models
import {
  ICallProcessCreateRequest, ICallProcessStartRequest,
  ICallProcessUpdateRequest, ICallProcess, ICreateCallResponse, IUpdateCallResponse, ICallInfo, ICallProcessJoinRequest
} from '../interfaces/call.interfaces';

export const CallService = {
  getCallInfo: async (call_id: number, temporary_token: string): Promise<{ data: ICallInfo; status: number } | {
    data: any;
    status: number
  }> => {
    return await api.get(`/${ temporary_token ? 'temporary' : 'users' }/calls/info`, { params: { call_id } })
      .then(result => {
        const { data, status } = result;
        return { data, status };
      })
      .catch((error: Error | AxiosError) => {
        if (axios.isAxiosError(error) && error.response) {
          const { data, status } = error.response;
          return { data, status };
        } else {
          return {
            data: { error: 'An error has occurred with API processing' },
            status: 500
          };
        }
      });
  },
  createCall: async (createCallData: ICallProcessCreateRequest): Promise<{
    data: ICreateCallResponse;
    status: number
  } | { data: any; status: number }> => {
    return await api.post<ICreateCallResponse>(`/${ createCallData.temporary_token ? 'temporary' : 'users' }/create-call`, createCallData)
      .then(result => {
        const { data, status } = result;
        return { data, status };
      })
      .catch((error: Error | AxiosError) => {
        if (axios.isAxiosError(error) && error.response) {
          const { data, status } = error.response;
          return { data, status };
        } else {
          return {
            data: { error: 'An error has occurred with API processing' },
            status: 500
          };
        }
      });
  },
  updateCall: async (updateCallData: ICallProcessUpdateRequest): Promise<{
    data: IUpdateCallResponse;
    status: number
  } | { data: any; status: number }> => {
    return await api.post<IUpdateCallResponse>(`/${ updateCallData.temporary_token ? 'temporary' : 'users' }/update-call`, updateCallData)
      .then(result => {
        const { data, status } = result;
        return { data, status };
      })
      .catch((error: Error | AxiosError) => {
        if (axios.isAxiosError(error) && error.response) {
          const { data, status } = error.response;
          return { data, status };
        } else {
          return {
            data: { error: 'An error has occurred with API processing' },
            status: 500
          };
        }
      });
  },
  startCall: async (updateCallData: ICallProcessStartRequest): Promise<{ data: ICallInfo; status: number } | {
    data: any;
    status: number
  }> => {
    return await api.post<ICallInfo>(`/${ updateCallData.temporary_token ? 'temporary' : 'users' }/start-call`, updateCallData)
      .then(result => {
        const { data, status } = result;
        return { data, status };
      })
      .catch((error: Error | AxiosError) => {
        if (axios.isAxiosError(error) && error.response) {
          const { data, status } = error.response;
          return { data, status };
        } else {
          return {
            data: { error: 'An error has occurred with API processing' },
            status: 500
          };
        }
      });
  },
  joinToCall: async (callData: ICallProcessJoinRequest): Promise<{ data: ICallInfo; status: number } | {
    data: any;
    status: number
  }> => {
    return await api.post<ICallInfo>(`/${ callData.temporary_token ? 'temporary' : 'users' }/join-call`, callData)
      .then(result => {
        const { data, status } = result;
        return { data, status };
      })
      .catch((error: Error | AxiosError) => {
        if (axios.isAxiosError(error) && error.response) {
          const { data, status } = error.response;
          return { data, status };
        } else {
          return {
            data: { error: 'An error has occurred with API processing' },
            status: 500
          };
        }
      });
  },
  rejectCall: async (call_id: number, temporary_token: string): Promise<{ data: ICallInfo; status: number } | {
    data: any;
    status: number
  }> => {
    return await api.post<ICallInfo>(`/${ temporary_token ? 'temporary' : 'users' }/reject-call`, { call_id })
      .then(result => {
        const { data, status } = result;
        return { data, status };
      })
      .catch((error: Error | AxiosError) => {
        if (axios.isAxiosError(error) && error.response) {
          const { data, status } = error.response;
          return { data, status };
        } else {
          return {
            data: { error: 'An error has occurred with API processing' },
            status: 500
          };
        }
      });
  },
  chargeCallTimeslot: async (chargeTimeslotData: ICallProcessStartRequest): Promise<{
    data: ICallProcess;
    status: number
  } | { data: any; status: number }> => {
    return await api.post<ICallProcess>(`/${ chargeTimeslotData.temporary_token ? 'temporary' : 'users' }/charge-call-timeslot`, chargeTimeslotData)
      .then(result => {
        const { data, status } = result;
        return { data, status };
      })
      .catch((error: Error | AxiosError) => {
        if (axios.isAxiosError(error) && error.response) {
          const { data, status } = error.response;
          return { data, status };
        } else {
          return {
            data: { error: 'An error has occurred with API processing' },
            status: 500
          };
        }
      });
  },
  cancelNextPayment: async (call_id: number, temporary_token: string): Promise<{
    data: ICallProcess;
    status: number
  } | { data: any; status: number }> => {
    return await api.post<ICallProcess>(`/${ temporary_token ? 'temporary' : 'users' }/cancel-charge`, { call_id })
      .then(result => {
        const { data, status } = result;
        return { data, status };
      })
      .catch((error: Error | AxiosError) => {
        if (axios.isAxiosError(error) && error.response) {
          const { data, status } = error.response;
          return { data, status };
        } else {
          return {
            data: { error: 'An error has occurred with API processing' },
            status: 500
          };
        }
      });
  },
  changeDeviceStatus: async (call_id: number, user_device_settings: {
    is_user_microphone_enabled: number,
    is_user_camera_enabled: number
  }, temporary_token: string)
    : Promise<{ data: ICallProcess; status: number } | { data: any; status: number }> => {
    return await api.post<ICallProcess>(`/${ temporary_token ? 'temporary' : 'users' }/calls/update-user-device-settings`, {
      call_id,
      user_device_settings
    })
      .then(result => {
        const { data, status } = result;
        return { data, status };
      })
      .catch((error: Error | AxiosError) => {
        if (axios.isAxiosError(error) && error.response) {
          const { data, status } = error.response;
          return { data, status };
        } else {
          return {
            data: { error: 'An error has occurred with API processing' },
            status: 500
          };
        }
      });
  },
  callReconnected: async (call_id: number): Promise<{ data: ICallProcess; status: number } | {
    data: any;
    status: number
  }> => {
    return await api.post<ICallProcess>('/call-reconnected', { call_id })
      .then(result => {
        const { data, status } = result;
        return { data, status };
      })
      .catch((error: Error | AxiosError) => {
        if (axios.isAxiosError(error) && error.response) {
          const { data, status } = error.response;
          return { data, status };
        } else {
          return {
            data: { error: 'An error has occurred with API processing' },
            status: 500
          };
        }
      });
  },
}
