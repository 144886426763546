import { createSelector } from '@reduxjs/toolkit';

// Models
import { IExpertProfile } from '../../interfaces/experts.interfaces';
import { ICurrentExpertState } from './currentExpert.slice';
import { RootState } from '../index';

/**
 A selector function to retrieve the expert data from the store
 @function
 @param {RootState} state - The root state of the Redux store
 @returns {ICurrentExpertState} The expert state object from the Redux store
 */
const selectCurrentExpertState = (state: RootState): ICurrentExpertState => state.currentExpert;

/**
 A memoized selector function to retrieve the expert data from the store
 @function
 @type {import('@reduxjs/toolkit').OutputSelector<RootState, ICurrentExpertState, (res: ICurrentExpertState) => IExpertProfile>}
 */
export const getCurrentExpertState = createSelector(
  [selectCurrentExpertState],
  (state): ICurrentExpertState => state
);
