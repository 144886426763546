import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Shared UI Library Components
import {
  InputSearch,
} from '@ppmcore/seven-ppm-core-shared-components-react';

// Styles
import './appointments.scss';

// Components
import { AppointmentsDesktop, AppointmentsMobile } from "../../shared/ui-components/appointments";
import {
  ExpertAuthWidgetNavigation
} from "../../shared/ui-components/expert/expert-auth-widget-navigation/expert-auth-widget-navigation";

// Store entities
import {
  clearBookAppointmentState,
  fetchAppointments,
  setAppointmentsSettings
} from "../../store/appointments/appointments.thunks";
import { getBookAppointmentsState } from "../../store/appointments/appointments.selectors";

// Models
import { IAppointmentsSettings } from "../../interfaces/appointments.interfaces";

export const Appointments = () => {
  const {
    appointments,
    settings: appointmentsSettings,
    total,
    total_all,
    loading
  } = useSelector(getBookAppointmentsState);

  const dispatch = useDispatch<any>();

  const loadAppointments = async (isLoadMore: boolean = false, settings: IAppointmentsSettings = appointmentsSettings): Promise<void> => {
    await dispatch(fetchAppointments({
      offset: isLoadMore ? appointments.length : 0,
      limit: 10,
      isLoadMore,
      settings
    }));
  }

  const onSearchHandler = (search: string): void => {
    dispatch(setAppointmentsSettings({ ...appointmentsSettings, search }));
    loadAppointments(false, { ...appointmentsSettings, search });
  }

  useEffect(() => {
    loadAppointments();

    return () => {
      dispatch(clearBookAppointmentState());
    }
  }, []);

  return (<div className="appointments">
    <div className="appointments--header">
      <div className="header-title">
        My Appointments
      </div>
      <div className="header-search" hidden={ !total_all }>
        <InputSearch placeholder={ 'Search by Client' } loading={ loading } onDebounceChange={ onSearchHandler }/>
      </div>

      <ExpertAuthWidgetNavigation/>
    </div>

    <div className="appointments--body">
      <AppointmentsDesktop appointments={ appointments }
                           appointmentsSettings={ appointmentsSettings }
                           total={ total }
                           total_all={ total_all || 0 }
                           loading={ loading }
      />
      <AppointmentsMobile appointments={ appointments }
                          appointmentsSettings={ appointmentsSettings }
                          total={ total }
                          total_all={ total_all || 0 }
                          loading={ loading }/>
    </div>
  </div>);
}
