import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ICallProcess } from '../../interfaces/call.interfaces';

export interface ICallFinishedState {
  callFinished: ICallProcess | null;
  loading: boolean;
  error: boolean;
}

const initialState: ICallFinishedState = {
  callFinished: null,
  loading: false,
  error: false
};

export const callFinishedSlice = createSlice({
  name: 'CallFinished',
  initialState,
  reducers: {
    getCallFinishedRequest(state) {
      state.loading = true;
      state.error = false;
    },
    getCallFinishedSuccess: (state, action: PayloadAction<ICallProcess>) => {
      state.callFinished = action.payload ?? null;
      state.loading = false;
      state.error = false;
    },
    getCallFinishedFailure: (state) => {
      state.loading = false;
      state.error = true;
    },
    clearCallFinishedState: (state) => {
      state.callFinished = null;
      state.loading = false;
      state.error = false;
    },
  }
});

export const CallFinishedSliceActions = callFinishedSlice.actions;
