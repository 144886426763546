import { createSelector } from '@reduxjs/toolkit';

// Models
import { RootState } from '../index';
import { IChatState } from './chat.slice';

/**
 A selector function to retrieve the chat data from the store
 @function
 @param {RootState} state - The root state of the Redux store
 @returns {ICallState} The chat state object from the Redux store
 */
const selectChatState = (state: RootState): IChatState => state.chatProcess;

/**
 A memoized selector function to retrieve the chat data from the store
 @function
 @type {import('@reduxjs/toolkit').OutputSelector<RootState, IChatState, (res: IChatState) => IChatState>}
 */
export const getChatState = createSelector(
  [selectChatState],
  (state): IChatState => state
);
