import React, { FC, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Dropdown } from 'antd';

// Shared UI Library Components
import { CustomIcon, EIconName, Loader } from '@ppmcore/seven-ppm-core-shared-components-react';

// Styles
import './card-item.scss';

// Store entities
import { deletePaymentCard, selectPaymentCard } from '../../../store/payments/payments.thunks';

// Store entities
import { ICardItem } from '../../../interfaces/payments.interfaces';

interface IPaymentMethodsProps {
  card: ICardItem;
  isSelected: boolean;
}

export const CardItem: FC<IPaymentMethodsProps> = (
  { card: { id, card: { brand, exp_year, exp_month, last4 } }, isSelected }: IPaymentMethodsProps
) => {

  const [isAction, setIsAction] = useState<boolean>(false);

  const dispatch = useDispatch<any>();

  const menuItems = [
    {
      key: 'markDefaultHandler',
      disabled: isSelected,
      label: (
        <div className="actions-item">
          <CustomIcon name={ EIconName.PaymentMethodsOff } size={ 'size-md' }/>
          Set Up as Default
        </div>),
    },
    {
      key: 'deleteCardHandler',
      label: (
        <div className="actions-item">
          <CustomIcon name={ EIconName.Delete } size={ 'size-md' }/>
          Delete
        </div>),
    },
  ]

  const dropdownMenuClickHandler = ({ key }: { key: string, domEvent: any, keyPath: string[] }): void => {
    if (key === 'markDefaultHandler') {
      markDefaultHandler();
      return;
    }
    deleteCardHandler();
  }

  const markDefaultHandler = async (): Promise<void> => {
    setIsAction(true);
    await dispatch(selectPaymentCard(id));
    setIsAction(false);
  }

  const deleteCardHandler = async (): Promise<void> => {
    setIsAction(true);
    await dispatch(deletePaymentCard(id));
    setIsAction(false);
  }

  return (
    <div className="card-item">
      <div className="card-item--info">
        <div className="info-item">
          <div className="info-item-logo">
            { brand &&
              <img className="card-icon" src={ `/assets/icons/cards/${ brand ?? 'stripe' }.svg` } alt={ `${ brand }-icon` }/> }
          </div>
          <div className="info-item-number">
            **** { last4 } { isSelected && <div className="info-item">
            <div className="default-mark">Default</div>
          </div> }
          </div>
        </div>
        <div className="info-item">
          <div className="info-item-date">
            Expiration Date: { exp_month }/{ exp_year }
          </div>
        </div>
      </div>
      <div className="card-item--actions">
        {
          isAction && <Loader/>
        }
        {
          !isAction && <Dropdown rootClassName={ 'card-actions' } menu={ {
            items: menuItems,
            onClick: dropdownMenuClickHandler
          } } trigger={ ['click'] } placement="bottomRight" arrow>
            <CustomIcon name={ EIconName.BurgerMenu }/>
          </Dropdown>
        }
      </div>
    </div>
  );
}
