import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

// Thunks
import { openNotification } from '../store/app-notifications/app-notifications.thunks';

// Services
import { onMessageListener } from '../services/firebase.service';

export const useFirebaseNotifications = () => {
  const dispatch = useDispatch<any>();
  const [notification, setNotification] = useState({title: '', body: ''});

  useEffect(() => {
    if (notification?.title ){
      dispatch(openNotification({
        description: notification?.body,
        type: 'success'
      }));
    }
  }, [notification]);

  useEffect(() => {
    onMessageListener()
      .then((payload: any) => {
        setNotification({title: payload?.data?.title, body: payload?.data?.body});
      })
      .catch((err) => console.log('failed: ', err));
  }, []);
}
