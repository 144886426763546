import React from 'react';
import { useSelector } from 'react-redux';

// Shared UI Library Components
import { InputReadOnly, SpinElement } from '@ppmcore/seven-ppm-core-shared-components-react';

// Selectors
import { getCoreState } from '../../store/core/core.selectors';

// Styles
import './business-information.scss';

export const BusinessInformation = () => {

  const { companyInfo: { company, isLoad } } = useSelector(getCoreState);

  const company_profile = company?.company_profile;
  const isEmptyAddress = !company_profile?.address && !company_profile?.city && !company_profile?.state && !company_profile?.zip_code && !company_profile?.country?.name;
  const isEmptyDetails = !company_profile?.company_email && !company_profile?.landline && !company_profile?.fax && !company_profile?.phone;
  const isEmpty = !company?.company_profile?.has_active_counsellors || (isEmptyAddress && isEmptyDetails && !company_profile?.about);

  return (
    <div className={ `business-information ${ isEmpty ? 'full-height-page' : '' }` }>
      <div className="business-information-title">Business Information</div>
      { isEmpty && <div className="business-information-subtitle">
        No info yet.
      </div> }

      { (isEmpty && !isLoad) && <div className="business-information--empty">
        <div className="empty-img">
          <img src="/assets/icons/big/business-info-empty.svg" alt="empty-rates-icon"/>
        </div>
      </div> }

      { isLoad && <SpinElement fullHeight={ true }/> }

      { (company && !isEmpty && !isLoad) && <div className="business-information-content">
        <div className={ 'content-item' }>
          <div className={ 'content-item-title' }>Business Name</div>
          <div className={ 'content-item-value' }>{ company_profile?.company_name }</div>
        </div>
        { company_profile?.about && <div className={ 'content-item' }>
          <div className={ 'content-item-title' }>About Us</div>
          <div className={ 'content-item-value' }>{ company_profile?.about }</div>
        </div> }
        { !isEmptyDetails && <div className={ 'content-item' }>
          <div className={ 'content-item-title' }>Contact Details</div>
          <div className={ 'content-item-value-row' }>
            { company_profile?.company_email && <InputReadOnly label={ 'Company Email' } clickableEmail={ true }
                                                               value={ company_profile?.company_email || null }/> }
            { company_profile?.landline &&
              <InputReadOnly label={ 'Company Landline' } value={ company_profile?.landline || null }/> }
          </div>
          <div className={ 'content-item-value-row' }>
            { company_profile?.phone &&
              <InputReadOnly label={ 'Company Mobile Number' } value={ company_profile?.phone || null }/> }
            { company_profile?.fax && <InputReadOnly label={ 'Company Fax' } value={ company_profile?.fax || null }/> }
          </div>
        </div> }
        { !isEmptyAddress && <div className={ 'content-item' }>
          <div className={ 'content-item-title' }>Address</div>
          <div className={ 'content-item-value-row' }>
            { company_profile?.address &&
              <InputReadOnly label={ 'Street Address' } value={ company_profile?.address || null }/> }
            { company_profile?.city && <InputReadOnly label={ 'City' } value={ company_profile?.city || null }/> }
          </div>
          <div className={ 'content-item-value-row' }>
            { company_profile?.state && <InputReadOnly label={ 'State' } value={ company_profile?.state || null }/> }
            { company_profile?.zip_code &&
              <InputReadOnly label={ 'Zip Code' } value={ company_profile?.zip_code || null }/> }
            { company_profile?.country?.name &&
              <InputReadOnly label={ 'Country' } value={ company_profile?.country?.name || null }/> }
          </div>
        </div> }
      </div> }
    </div>
  );
}
