import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Models
import { IAppointmentsConfig, IAppointment, IAppointmentsSettings } from "../../interfaces/appointments.interfaces";
import { INotificationItem } from "../../interfaces/notifications.interfaces";

export interface IBookAppointmentsState {
  appointmentsConfig: IAppointmentsConfig | null;
  appointments: IAppointment[];
  total: number;
  total_all: number | null;
  settings: IAppointmentsSettings;
  loading: boolean;
  error: null | string;
}

const initialState: IBookAppointmentsState = {
  appointmentsConfig: null,
  appointments: [],
  total: 0,
  total_all: null,
  settings: {},
  loading: false,
  error: null,
};

export const appointmentsSlice = createSlice({
  name: 'appointments',
  initialState,
  reducers: {
    fetchAppointmentsConfig(state) {
      state.loading = true;
      state.error = null;
    },
    fetchAppointmentsConfigSuccess: (state, { payload: appointmentsConfig }: PayloadAction<IAppointmentsConfig>) => {
      state.appointmentsConfig = appointmentsConfig;
      state.loading = false;
      state.error = null;
    },
    fetchAppointmentsConfigError: (state) => {
      state.loading = false;
      state.error = null;
    },
    bookAppointmentRequest(state) {
      state.loading = true;
      state.error = null;
    },
    bookAppointmentRequestSuccess: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.error = null;
    },
    bookAppointmentRequestError: (state) => {
      state.loading = false;
      state.error = null;
    },
    loadAppointments(state) {
      state.loading = true;
      state.error = null;
    },
    loadAppointmentsSuccess: (state, { payload: { appointments, total, total_all, isLoadMore } }: PayloadAction<{
      appointments: IAppointment[],
      total: number
      total_all: number
      isLoadMore: boolean
    }>) => {
      state.appointments = isLoadMore ? [...state.appointments, ...appointments] : appointments;
      state.total = total;
      state.total_all = total_all;
      state.error = null;
      state.loading = false;
    },
    loadAppointmentsFailure: (state, { payload }: PayloadAction<string>) => {
      state.loading = false;
      state.error = payload;
    },
    updateAppointmentFromSocket: (state, { payload: { appointment_notified } }: PayloadAction<INotificationItem>) => {
      state.appointments = state.appointments.map(appointment => appointment.id === appointment_notified?.appointment_id ? {
        ...appointment,
        status: appointment_notified.status,
        approved_at: appointment_notified.approved_at,
        cancel_notes: appointment_notified.cancel_notes,
        canceled_by: appointment_notified.canceled_by,
        canceled_at: appointment_notified.canceled_at,
      } : appointment);
      state.total = state.total + 1;
      state.total_all = (state.total_all || 0) + 1;
    },
    makeAction: (state, { payload }: PayloadAction<boolean>) => {
      state.loading = payload;
      state.error = null;
    },
    cancelAppointment: (state, { payload }: PayloadAction<IAppointment>) => {
      state.appointments = state.appointments.map(appointment => appointment.id !== payload.id ? appointment : payload);
      state.error = null;
      state.loading = false;
    },
    setSettings: (state, { payload: settings }: PayloadAction<IAppointmentsSettings>) => {
      state.settings = settings;
    },
    clearState: (state) => {
      state.appointmentsConfig = null;
      state.appointments = [];
      state.total = 0;
      state.total_all = null;
      state.settings = {
        search: '',
      };
      state.loading = true;
      state.error = null;
    },
  }
});

export const AppointmentsSliceActions = appointmentsSlice.actions;
