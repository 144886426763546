import React, { FC, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

// Shared UI Library Components
import {
  CustomIcon,
  CustomTooltip,
  EIconName,
  InputSearch,
} from '@ppmcore/seven-ppm-core-shared-components-react';

// Styles
import './expert-filters.scss';

// Components
import { ExpertFilterModal } from '../../../../modals/expert-filter-modal/expert-filter-modal';
import { ExpertAuthWidgetNavigation } from '../expert-auth-widget-navigation/expert-auth-widget-navigation';

// Store entities
import { setExpertsFilters } from '../../../../store/experts/experts.thunks';

// Models
import { IExpertFilters } from '../../../../interfaces/experts.interfaces';
import { TExpertsList } from '../../../../store/experts/experts.slice';

// Utils
import { isWidgetAppType } from '../../../../utils/widget.utils';

interface ISidebarFilterProps {
  sidebarOpened: boolean;
  expertFilters: IExpertFilters;
  type: TExpertsList;
  loading: boolean;
  disabled?: boolean;
}

export const ExpertFilters: FC<ISidebarFilterProps> = (
  {
    sidebarOpened, expertFilters, type, loading, disabled = false,
  }: ISidebarFilterProps
): JSX.Element => {
  const [filterModalOpened, setFilterModalOpened] = useState<boolean>(false);

  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  const params = useParams<{ companyCode?: string }>();
  const companyCode = useMemo(() => params?.companyCode, [params?.companyCode]);

  const isWidget = isWidgetAppType();

  const {
    languages,
    speciality,
    specialisations,
    countries,
    rate_to,
    rate_from,
    is_online
  } = expertFilters;

  const hasSettings = (!!speciality && speciality !== 'all') ||
    !!languages?.length ||
    !!specialisations?.length ||
    !!countries?.length ||
    !!rate_to ||
    !!rate_from ||
    !!is_online;

  const openFilterModal = (): void => {
    if (disabled && !hasSettings) return;
    setFilterModalOpened(true);
  }

  const openSignIn = (): void => {
    navigate('/sign-in');
  }

  const changeSearchHandler = (search: string): void => {
    dispatch(setExpertsFilters({
      filters: {
        ...expertFilters, search: search || '', is_bookmarked: Number(type === 'favorites')
      },
      type,
      company_code: companyCode ?? null
    }));
  }

  return (
    <div className="sidebar-filter" hidden={ !sidebarOpened }>
      <ExpertFilterModal
        open={ filterModalOpened }
        type={ type }
        onCancel={ () => setFilterModalOpened(false) }
        onSubmit={ () => setFilterModalOpened(false) }
      />
      <div className="sidebar-filter-item">
        <InputSearch placeholder="Search by Name" disabled={ disabled && !hasSettings && !expertFilters.search?.length }
                     loading={ loading }
                     onDebounceChange={ changeSearchHandler }/>
      </div>
      <div
        className={ `sidebar-filter-item ${ hasSettings ? 'has-settings' : '' } ${ (disabled && !hasSettings) ? 'disabled-icon' : '' }` }>
        <CustomTooltip title={ 'Filters' } placement={ 'bottom' }>
          <CustomIcon name={ EIconName.Tune } onClick={ openFilterModal }/>
        </CustomTooltip>
      </div>
      { isWidget && <div className="sidebar-filter-item sign-in">
        <CustomTooltip title={ 'Sign In / Sign Up' } placement={ 'bottom' }>
          <CustomIcon name={ EIconName.Login } onClick={ openSignIn }/>
        </CustomTooltip>
      </div> }
      <ExpertAuthWidgetNavigation />
    </div>
  )
};
