import { FC, HTMLProps } from 'react';
import dayjs from 'dayjs';

// Shared UI Library Components
import { CustomIcon, EIconName } from '@ppmcore/seven-ppm-core-shared-components-react';

// Styles
import './text-chat-notification.scss';

// Models
import { IConversationNotification } from '../../../../interfaces/chat.interfaces';

type TTextChatNotificationProps = HTMLProps<HTMLDivElement> & {
  conversationInfo: IConversationNotification;
}

export const TextChatNotification: FC<TTextChatNotificationProps> = (
  { conversationInfo, ...props }: TTextChatNotificationProps
) => {
  const { created_at } = conversationInfo;

  return (
    <div className="text-chat-notification" { ...props }>
      <div className="text-chat-notification--body">
        <div className="body-item body-item--time">
          { dayjs(created_at).format('hh:mm A') }
        </div>
        <div className="body-item body-item--icon">
          <CustomIcon name={ EIconName.NotificationsActiveFill } size={'size-md'}/>
        </div>
        <div className="body-item body-item--message">
          I’m back, you may connect me again
        </div>
      </div>
    </div>
  );
};

