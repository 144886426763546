import { createAsyncThunk } from '@reduxjs/toolkit';

// Store actions
import { openNotification } from '../app-notifications/app-notifications.thunks';

// Services
import { UserService } from '../../services/user.service';

// Slices & Thunks
import { SocketSliceActions } from './socket.slice';

// Models
import { IWebSocketDataEvent } from '../../interfaces/web-socket.interfaces';

export enum ESocketThunks {
  SetSocketLastMessage = 'SOCKET/setSocketLastMessage',
  FetchSocketToken = 'SOCKET/fetchSocketToken',
  ClearSocketState = 'SOCKET/clearSocketState',
}

export const setSocketLastMessage = createAsyncThunk(
  ESocketThunks.SetSocketLastMessage,
  async (message: IWebSocketDataEvent<any>, { dispatch }) => {
    return dispatch(SocketSliceActions.setSocketLastMessage({ message }));
  }
);

export const fetchSocketToken = createAsyncThunk(
  ESocketThunks.FetchSocketToken,
  async (_, { dispatch }) => {
    dispatch(SocketSliceActions.loadSocketToken());
    const res = await UserService.fetchSocketToken();
    if (res.status !== 200) {
      if (res.status !== 401) {
        dispatch(openNotification({
          type: 'error',
          description: res.data.error ?? 'Failed to send message, try again!'
        }));
        return dispatch(SocketSliceActions.loadSocketTokenFailure(res.data.error ?? 'Failed to send message, try again!' ));
      }
      return dispatch(SocketSliceActions.loadSocketTokenFailure(''));
    }
    return dispatch(SocketSliceActions.loadSocketTokenSuccess({ token: res.data.socket_token }));
  }
);

export const clearSocketState = createAsyncThunk(
  ESocketThunks.ClearSocketState,
  async (_, { dispatch }) => {
    return dispatch(SocketSliceActions.clearSocketState());
  }
);
