import { Modal, Form } from 'antd';

// Shared UI Library Components
import {
  CustomButton, CustomTextArea, InfoMessage
} from '@ppmcore/seven-ppm-core-shared-components-react';

// Styles
import './canceling-appointment-modal.scss';

type TFormValue = {
  reason: string,
};

type TCancelingAppointmentModalProps<T> = {
  open: boolean;
  payload: T;
  loading?: boolean;
  title?: string;
  placeholder?: string;
  textAreaRows?: number;
  onSubmit?: (payload: T, cancel_notes: string) => void;
  onCancel?: (payload: T) => void;
}

export const CancelingAppointmentModal = <T extends object | null>(
  {
    open = false,
    payload,
    loading = false,
    title = 'Please state the reason for cancelling the Appointment',
    placeholder = 'Write your reason here (min 3 symbols)',
    textAreaRows = 3,
    onSubmit = () => {
    },
    onCancel = () => {
    }
  }: TCancelingAppointmentModalProps<T>
) => {
  const [form] = Form.useForm<TFormValue>();
  const reasonValue = Form.useWatch('reason', form);

  const handleCancel = (): void => {
    onCancel(payload);
  };

  const handleSubmit = (): void => {
    const { reason } = form.getFieldsValue();
    onSubmit(payload, reason);
  }

  return (
    <Modal
      rootClassName="canceling-appointment-modal"
      width={ '604px' }
      open={ open }
      destroyOnClose={ true }
      closeIcon={ null }
      centered={ true }
      title={ title }
      footer={ [
        <CustomButton key="cancel"
                      text={ 'Cancel' }
                      type={ 'text' }
                      onClick={ handleCancel }
        />,
        <CustomButton key="submit"
                      text={ 'Confirm' }
                      disabled={ reasonValue?.length < 3 }
                      loading={ loading }
                      onClick={ handleSubmit }
        />
      ] }
      onCancel={ handleCancel }
    >
      <Form preserve={ false } form={ form } className="canceling-appointment-modal--body modal-body">
        <div className="modal-body--item item-notifier">
          <InfoMessage infoMessage={ 'Please Note: We will make a full refund to you' }/>
        </div>
        <div className="modal-body--item">
          <Form.Item name={ 'reason' } initialValue={ '' }>
            <CustomTextArea showCount={ true }
                            maxLength={ 500 }
                            autoSize={ {
                              minRows: textAreaRows,
                              maxRows: textAreaRows
                            } }
                            placeholder={ placeholder }/>
          </Form.Item>
        </div>
      </Form>
    </Modal>
  )
};
