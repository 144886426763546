import React from 'react';

// Components
import { NetworkDisconnected } from '../modules/network-disconnected/network-disconnected';

// Styles
import './status-network-disconnected.scss';

export const StatusNetworkDisconnected = () => {
  return (<NetworkDisconnected title={'Oops, something went wrong on your side'} />);
}
