import React from 'react';
import { useNavigate } from 'react-router-dom';

// Components
import { CustomButton } from '@ppmcore/seven-ppm-core-shared-components-react';

// Styles
import './chat-stay-with-us.scss';

export const ChatStayWithUs = () => {

  const navigate = useNavigate();
  const returnPathname = localStorage.getItem('return_pathname') || `/home`;

  return (<div className={ 'chat-stay-with-us-page' }>
    <div className={ 'chat-stay-with-us-page-inside' }>
      <div className={ 'chat-stay-with-us-title' }>Stay with Us!</div>
      <div className={ 'chat-stay-with-us-text' }>Create an account to stay on top of your history and make the most of
        our user-friendly features.
      </div>
      <div className={ 'chat-stay-with-us-btn-line' }>
        <CustomButton type={ 'primary' } size={ 'medium' } text={ 'Set Up Profile' } mode={ 'dark' }
                      onClick={ () => navigate('/sign-up') }/>
      </div>
      <CustomButton type="link" size={ 'medium' } text={ 'Skip' } mode={ 'dark' }
                    onClick={ () => window.location.assign(`${ returnPathname }`) }/>
    </div>
  </div>);
}
