import { createAsyncThunk } from '@reduxjs/toolkit';

// Services
import { CoreService } from '../../services/core.service';

// Slices
import { OngoingConsultationSliceActions } from './ongoing-consultation.slice';

// Models
import { ICallProcess } from "../../interfaces/call.interfaces";

export enum EOngoingConsultationThunks {
  FetchOngoingCalls = 'ONGOING_CONSULTATIONS/fetchOngoingCalls',
  UpdateSoonAppointmentCall = 'ONGOING_CONSULTATIONS/updateSoonAppointmentCall',
}

export const fetchOngoingCalls = createAsyncThunk(
  EOngoingConsultationThunks.FetchOngoingCalls,
  async (_, { dispatch }) => {
    dispatch(OngoingConsultationSliceActions.loadOngoingConsultationRequest());
    const { call, soon_appointment_call, error } = await CoreService.globalSettings();
    if (!call && !soon_appointment_call) {
      dispatch(OngoingConsultationSliceActions.loadOngoingConsultationRequestFailure(error ?? 'Error loading current currency'));
      return null;
    }
    dispatch(OngoingConsultationSliceActions.loadOngoingConsultationRequestSuccess({ call, soon_appointment_call }));
    return call;
  }
);

export const updateSoonAppointmentCall = createAsyncThunk(
  EOngoingConsultationThunks.UpdateSoonAppointmentCall,
  async (data: ICallProcess, { dispatch }) => {
    dispatch(OngoingConsultationSliceActions.updateSoonAppointmentCall(data));
  }
);
