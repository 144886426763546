import React, { FC } from 'react';
import { useSelector } from 'react-redux';

// Styles
import './history-mobile.scss';

// Components
import { ExpertList } from '../../expert';
import { HistoryBase } from '../history-base/history-base';

// Store entities
import { getExpertsState } from '../../../../store/experts/experts.selectors';

type THistoryMobileProps = {};

export const HistoryMobile: FC<THistoryMobileProps> = (
  {}: THistoryMobileProps
) => {
  const { experts: { total_all, isLoad } } = useSelector(getExpertsState);

  return (
    <div className="history--mobile">
      { (!total_all && !isLoad) && <HistoryBase isExistHistory={ false }/> }
      {
        !!total_all && <div className="mobile-body">
          <ExpertList hidden={ false } type={ 'history' } navSuffix={ 'history' }/>
        </div>
      }
    </div>
  );
};
