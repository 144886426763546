import React, { FC, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import durationPlugin from 'dayjs/plugin/duration';
import utc from 'dayjs/plugin/utc';

// Shared UI Library Components
import { CustomIcon, EIconName, EIconColor } from '@ppmcore/seven-ppm-core-shared-components-react';

// Components
import { Timer } from '../timer/timer';
import { TimerToDown } from '../timer-to-down/timer-to-down';
import { FreeMinutesCountdown } from '../../../../shared/ui-components/free-minutes-countdown/free-minutes-countdown';

// Store entities
import { rejectChat } from '../../../../store/chat/chat.thunks';
import { getLastMessageData } from '../../../../store/socket/socket.selectors';

// Enums
import { ECallStatus } from '../../../../enums/call-status.enums';

// Styles
import './chat-header.scss';

// Models
import { ICurrency } from '../../../../interfaces/company.interfaces';
import { IChatProcess, INextConversationPayment } from '../../../../interfaces/text-chat.interfaces';
import { EWebSocketTypes } from '../../../../enums/web-socket-types.enums';

interface IVideoHeaderProps {
  chatData: IChatProcess;
  currency: ICurrency;
  onEndChat?: () => void;
}

export const ChatHeader: FC<IVideoHeaderProps> = ({
  chatData,
  currency,
  onEndChat = () => {}
}: IVideoHeaderProps) => {

  const dispatch = useDispatch<any>();
  const temporaryToken = localStorage.getItem('temporary_token');

  const isFreeCallTimer = chatData.free_status === 'partly_free' && chatData.conversation_session_type === 'pay_per_minute';
  const isFullFreeCall = chatData.free_status === 'full_free';

  const [currentTimerTime, setCurrentTimerTime] = useState<number | null>(null);
  const [currentCallRate, setCurrentCallRate] = useState<number | null>(null);

  const [freeCallTimerEnd, setFreeCallTimerEnd] = useState<boolean>(!isFreeCallTimer);
  const [nextPayment, setNextPayment] = useState<INextConversationPayment | null>(null);

  const lastMessage = useSelector(getLastMessageData);

  const freeCallDuration = useMemo(() => {
    // if (nextPayment) {
    //   const date = new Date();
    //   return date.setSeconds(date.getSeconds() + nextPayment.call_finished_after);
    // }
    return new Date(chatData?.free_conversation_duration as string).getTime()
  }, [chatData?.free_conversation_duration]);

  const handleBeforeUnload = (event: any) => {
    // Perform actions before the component unloads
    event.preventDefault();
    event.returnValue = '';
  };

  const callEnd = (): void => {
    dispatch(rejectChat({ conversation_id: chatData.conversation_id, temporary_token: temporaryToken ?? '' }))
      .then(() => {
        if (chatData.conversation_status !== ECallStatus.Answered) onEndChat();
      });
  }

  const successChargeHandler = (): void => {
    if (
      dayjs(chatData.created_at)
        .add(chatData.worker?.free_service_minutes || 0, 'minutes')
        .isAfter(dayjs(), 'seconds')
    ) {
      setNextPayment(null);
      return;
    }
    setFreeCallTimerEnd(true);
  }

  useEffect(() => {
    if (lastMessage?.type === EWebSocketTypes.ConversationSuccessCharge && lastMessage.data?.conversation_id === chatData.conversation_id) {
      successChargeHandler();
    }
    if (lastMessage?.type === EWebSocketTypes.NeedConversationUpdatePayment && lastMessage.data?.conversation_id === chatData.conversation_id) {
      setNextPayment(lastMessage.data);
    }
  }, [lastMessage]);

  useEffect(() => {
    if (!chatData || chatData.conversation_status !== ECallStatus.Answered) return;
    dayjs.extend(durationPlugin);
    dayjs.extend(utc);

    const currentData = dayjs().utc();
    const startedData = dayjs(chatData.conversation_start).utc();
    const endCallData = chatData.conversation_session_type ? dayjs(chatData.conversation_session_type).utc() : startedData;
    const totalSeconds = chatData.conversation_session_type === 'pay_per_session'
      ? endCallData.diff(currentData, 'seconds')
      : currentData.diff(startedData, 'seconds');

    if (totalSeconds > 0) {
      setCurrentTimerTime(totalSeconds);
    }
    if (totalSeconds <= 0) {
      setCurrentTimerTime(1);
    }
    setCurrentCallRate(chatData.conversation_rate ?? null);
  }, [chatData]);

  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  return (<div className={ 'chat-header' }>
    <div className={ 'chat-header-inside' }>
      <div className={ 'chat-header-item call-type' }>
        <div className={ 'call-type-inside' }>
          <div className="call-type-inside--name">
            Text Chat with { chatData.worker?.first_name } { chatData.worker?.last_name }
          </div>
        </div>
      </div>
      <div className={ 'chat-header-item' }>
        <div className={ 'timer-section' }>
          <CustomIcon name={ EIconName.Chat } color={ EIconColor.White }/>
          { !currentTimerTime && !currentCallRate
            && <div className={ 'connecting-process' }>
              <div className={ 'connecting-process-text' }>Connecting</div>
              <span></span>
              <div className={ 'connecting-process-text' }>
                { chatData.conversation_rate && chatData.conversation_session_type === 'pay_per_session' ? `${ currency.symbol }${ chatData.conversation_rate }` : `${ currency.symbol }00.00` }
              </div>
            </div> }

          {
            (isFreeCallTimer && !freeCallTimerEnd) &&
            <FreeMinutesCountdown currency={ currency } deadline={ freeCallDuration } nextPayment={ nextPayment }
                                  onTimerEnd={ setFreeCallTimerEnd }/>
          }

          { ((!!currentTimerTime && !!currentCallRate && chatData.conversation_session_type === 'pay_per_minute' && freeCallTimerEnd) || isFullFreeCall)
            && <Timer
              currency={ currency }
              initialSeconds={ currentTimerTime }
              callRate={ currentCallRate }
              isFullFreeCall={ isFullFreeCall }
            /> }

          { (!!currentTimerTime && !!currentCallRate && chatData.conversation_session_type === 'pay_per_session')
            && <TimerToDown
              currency={ currency }
              initialSeconds={ currentTimerTime }
              callRate={ currentCallRate }
              call_finished_after={ chatData.conversation_finished_after }
            /> }
        </div>

        <div className={ 'chat-end' } onClick={ callEnd }>
          <CustomIcon name={ EIconName.ChatError } color={ EIconColor.White }/>
        </div>
      </div>
    </div>
  </div>);
}
