/**
 * An enumeration that defines WebSocket events.
 *
 * @readonly
 * @enum {string}
 */
export enum EWebSocketEvent {
  /**
   * The "connect" event.
   */
  Connect = 'connect',

  /**
   * The "ping" event.
   */
  Ping = 'ping',

  /**
   * The "pong" event.
   */
  Pong = 'pong',

  /**
   * The "connectToRoom" event.
   */
  ConnectToRoom = 'connectToRoom'
}

/**
 * An enumeration that defines WebSocket package types.
 *
 * @readonly
 * @enum {string}
 */
export enum EWebSocketPackageType {
  /**
   * The "paid-message" package data.
   */
  PaidMessage = 'paid-message',
  MessageReplied = 'message-replied',
  ChatMessage = 'chat-message',
  ConversationMessage = 'conversation-message',
  WorkerStatusChanged = 'worker-status-changed',
  WorkerRateChanged = 'worker-rate-changed',
  NewMessagePing = 'new-message-ping',
  NewCallPing = 'new-call-ping',
  NewConversationPing = 'new-conversation-ping',
  WorkerDeactivated = 'worker-deactivated',
  WorkerActivated = 'worker-activated',
  WorkerBlocked = 'worker-blocked',
  WorkerUnblocked = 'worker-unblocked',
  WorkerDeleted = 'worker-deleted',
  CorrespondenceTimeChanged = 'correspondence-time-changed',
  CompanyCurrencyUpdated = 'company-currency-updated',
  CompanyChanged = 'company-changed',
  FavoriteDeactivated = 'favorite-deactivated',
  FavoriteBlocked = 'favorite-blocked',
  MessageNotification = 'message-notification',
  HistoryMessage = 'history-message',
  AppointmentStartSoon = 'appointment-start-soon',
  AppointmentCanceled = 'appointment-canceled',
  AppointmentApproved = 'appointment-approved',

  AppointmentCallStarted = 'appointment-call-started',
  AppointmentHistory = 'appointment-history',
  AppointmentCallSoon = 'appointment-call-soon',
  AppointmentCallApproved = 'appointment-call-approved',
}
