import { StripeElementsOptions } from '@stripe/stripe-js';

export const CStripeOptions: StripeElementsOptions = {
  currency: 'usd',
  amount: 1000,
  mode: 'payment',
  loader: 'always',
  capture_method: 'manual',
  paymentMethodCreation: 'manual',
  appearance: {
    theme: 'stripe',
    variables: {
      borderRadius: '10px',
    },
    rules: {
      '.Label': {
        fontSize: '0px'
      },
      '.Input': {
        padding: '15px 16px'
      }
    }
  }
};

export const minBrowsersSupport = {
  chrome: 88,
  firefox: 78,
  safari: 14,
  opera: 79,
  edge: 88,
}
