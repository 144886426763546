// Shared UI Library Components
import { CustomIcon, EIconName } from '@ppmcore/seven-ppm-core-shared-components-react';

export const CExpertMenuList = [
  {
    label: 'Video Consultation',
    path: 'call/devices-settings',
    icon: <CustomIcon name={ EIconName.CameraOn }/>,
    activeIcon: <CustomIcon name={ EIconName.CameraOn }/>,
    withBg: true,
    disabled: false,
    actionType: 'video_call',
    hide: false,
    isSecure: false,
    enabledTooltip: 'Start Video Consultation',
    disabledTooltip: 'Video Consultation is unavailable as the counsellor is offline',
    noBalanceTooltip: 'Video Consultation is unavailable at this moment',
  },
  {
    label: 'Voice Consultation',
    path: 'call/devices-settings',
    icon: <CustomIcon name={ EIconName.MicOn }/>,
    activeIcon: <CustomIcon name={ EIconName.MicOn }/>,
    withBg: true,
    disabled: false,
    actionType: 'audio_call',
    hide: false,
    isSecure: false,
    enabledTooltip: 'Start Voice Consultation',
    disabledTooltip: 'Voice Consultation is unavailable as the counsellor is offline',
    noBalanceTooltip: 'Voice Consultation is unavailable at this moment',
  },
  {
    label: 'Text Chat',
    path: 'conversation/chat-types',
    icon: <CustomIcon name={ EIconName.Chat }/>,
    activeIcon: <CustomIcon name={ EIconName.ChatFill }/>,
    withBg: true,
    disabled: false,
    actionType: 'conversation',
    hide: false,
    isSecure: false,
    enabledTooltip: 'Start Text Chat Consultation',
    disabledTooltip: 'Text Chat Consultation is unavailable as the counsellor is offline',
    noBalanceTooltip: 'Text Chat Consultation is unavailable at this moment',
  },
  {
    label: 'Correspondence',
    path: 'expert/$ID/messages',
    icon: <CustomIcon name={ EIconName.Message }/>,
    activeIcon: <CustomIcon name={ EIconName.MessageFill }/>,
    withBg: true,
    disabled: false,
    actionType: 'messages',
    hide: false,
    isSecure: false,
    enabledTooltip: 'Send Request',
    disabledTooltip: 'Send Request is unavailable as the counsellor is offline',
    noBalanceTooltip: 'Send Request is unavailable at this moment',
  },
  {
    label: 'Book a Call',
    path: 'expert/$ID/book-appointment',
    icon: <CustomIcon name={ EIconName.CalendarAdd }/>,
    activeIcon: <CustomIcon name={ EIconName.CalendarAdd }/>,
    withBg: true,
    disabled: false,
    actionType: 'book-a-call',
    hide: false,
    isSecure: false,
    enabledTooltip: 'Book a Call',
    disabledTooltip: 'Book a Call is unavailable as the counsellor is offline',
    noBalanceTooltip: 'Book a Call is unavailable at this moment',
  },
];
