import React, { FC, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';

// Shared UI Library Components
import { SpinElement } from '@ppmcore/seven-ppm-core-shared-components-react';

// Styles
import './history.scss';

// Store
import { getHistoryState } from '../../store/history/history.selectors';
import { getTransactionsState } from '../../store/transactions/transactions.selectors';
import { clearHistory, fetchHistoryData } from '../../store/history/history.thunks';
import { getTransactions } from '../../store/transactions/transactions.thunks';
import { getCurrencyState } from '../../store/currency/currency.selectors';
import { getCurrentExpertState } from '../../store/currentExpert/currentExpert.selectors';
import { getCoreState } from '../../store/core/core.selectors';

// Components
import { HistoryBase, HistoryList, HistoryMobile } from '../../shared/ui-components/history';
import { ConsultationInfo } from '../../shared/payment-components';
import { CheckoutModal } from '../../modals/checkout-modal/checkout-modal';
import { sendPaidMessage } from '../../store/messages/messages.thunks';

// Models
import { IHistory } from '../../interfaces/history.interfaces';

interface IHistoryProps {
  expertPage?: boolean;
}

export const History: FC<IHistoryProps> = (
  { expertPage = false }: IHistoryProps
) => {
  const [currentId, setCurrentId] = useState<string | undefined>();
  const [checkoutOpen, setCheckoutOpen] = useState<boolean>(false);
  const [messageHistory, setMessageHistory] = useState<IHistory | null>(null);
  const [files, setFiles] = useState<Array<File | string>>([]);

  const { history, total_all, total, loading, isFirstLoading } = useSelector(getHistoryState);
  const { transactionsList, isLoading: isLoadTransactions } = useSelector(getTransactionsState);
  const { currentCurrency, company_account_id } = useSelector(getCurrencyState);
  const { expert } = useSelector(getCurrentExpertState);
  const { companyInfo } = useSelector(getCoreState);

  const params = useParams<{ id?: string, personalLink?: string }>();
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch<any>();

  const personalLink = useMemo(() => params?.personalLink, [params]);
  const full_free_service_enabled = !!expert?.full_free_service_enabled;

  const toggleCheckoutModal = (): void => {
    setCheckoutOpen(!checkoutOpen);
  }

  const loadHistoryData = (id: string | number | undefined, isLoadMore: boolean = false, isFirstLoading: boolean = false): void => {
    if (!id) return;
    if (isLoadMore && history.length >= total_all) return;
    const temporary_token = localStorage.getItem('temporary_token');
    dispatch(fetchHistoryData({
      id,
      isLoadMore,
      offset: history.length,
      isFirstLoading, ...(temporary_token && { temporary_token }),
      ...(searchParams.has('after') && { limit: (Number(searchParams.get('after')) || 15) + 10 }),
    }));
  }

  const loadHistoryDataByPersonalLink = (worker_code: string | undefined, isLoadMore: boolean = false, isFirstLoading: boolean = false): void => {
    if (!worker_code) return;
    if (isLoadMore && history.length >= total_all) return;
    const temporary_token = localStorage.getItem('temporary_token');
    dispatch(fetchHistoryData({
      worker_code,
      isLoadMore,
      offset: history.length,
      isFirstLoading, ...(temporary_token && { temporary_token })
    }));
  }

  const loadLastTransactions = (): void => {
    if (expertPage) return;
    dispatch(getTransactions({
      settings: { offset: 0, limit: 10 },
      isFirstLoad: true
    }));
  }

  const loadMoreHandler = (): void => {
    if (currentId) {
      return loadHistoryData(currentId, true);
    }
    loadHistoryDataByPersonalLink(personalLink, true);
  }

  const sendMessage = async (pi: string): Promise<void> => {
    if (!messageHistory?.chat_id) return;

    toggleCheckoutModal();
    await dispatch(sendPaidMessage({
      id: messageHistory.chat_id,
      message: messageHistory.message,
      files,
      pi,
      temporary_token: localStorage.getItem('temporary_token') ?? '',
      route_type: 'history',
      resent_message_id: messageHistory.id
    }));

    setMessageHistory(null);
    setFiles([]);
  }

  const sendFreeMessage = async (history: IHistory, files: Array<File | string>): Promise<void> => {
    if (!history.chat_id) return;

    await dispatch(sendPaidMessage({
      id: history.chat_id,
      message: history.message,
      files,
      temporary_token: localStorage.getItem('temporary_token') ?? '',
      route_type: 'history',
      resent_message_id: history.id
    }));

    setMessageHistory(null);
    setFiles([]);
  }

  const payMessage = (history: IHistory): void => {
    if (full_free_service_enabled) {
      setMessageHistory(history);
      setFiles(files);
      sendFreeMessage(history, files);
      return;
    }
    toggleCheckoutModal();
    setMessageHistory(history);
    setFiles(files);
  }

  useEffect(() => {
    setCurrentId(params?.id);
  }, [params]);

  useEffect(() => {
    if (params?.id === currentId) return;
    loadHistoryData(currentId, false, true);
  }, [currentId]);

  useEffect(() => {
    if (!personalLink) return;
    loadHistoryDataByPersonalLink(personalLink);
  }, [personalLink]);

  useEffect(() => {
    loadHistoryData(params?.id, false, true);
    loadLastTransactions();

    return () => {
      dispatch(clearHistory());
    };
  }, []);

  return (
    <div className={ `history ${ expertPage ? 'history-expert' : '' }` }>

      { messageHistory?.worker && <CheckoutModal open={ checkoutOpen }
                                          currency={ currentCurrency }
                                          company_account_id={ company_account_id }
                                          mode={ 'white' }
                                          consultation_type={ 'message' }
                                          worker_id={ messageHistory?.worker?.id }
                                          rate={ messageHistory?.message_rate?.value }
                                          btn_text={ `Pay ${ messageHistory?.total_message_price?.text } and Send Request` }
                                          onCancel={ toggleCheckoutModal }
                                          onPaid={ sendMessage }>
        <ConsultationInfo type={ 'message' } mode={ 'white' }
                          name={ messageHistory?.worker?.first_name + ' ' + messageHistory?.worker?.last_name }
                          rate={ messageHistory?.message_rate?.text ?? '' }
                          correspondence_time={ companyInfo?.company?.company_profile.correspondence_time } />
      </CheckoutModal> }

      <div className={ `history--loader history--loader-${ (loading || isLoadTransactions) ? 'show' : 'hide' }` }>
        <SpinElement fullHeight={ true }/>
      </div>

      {
        (!expertPage && !isFirstLoading && !isLoadTransactions) && <>
          <div className="history--desktop">
            <HistoryBase isExistHistory={ !!transactionsList.length }/>
          </div>
          <HistoryMobile/>
        </>
      }

      {
        (expertPage && !isFirstLoading) && <>
          <HistoryList history={ history } total={ total }
                       isActiveCounsellor={ (!!expert?.is_active && !expert?.is_blocked) }
                       loading={ loading }
                       loadMore={ loadMoreHandler }
                       resendMessage={ payMessage }/>
        </>
      }

    </div>
  );
}
