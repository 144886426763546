import { createSelector } from '@reduxjs/toolkit';

// Models
import { RootState } from '../index';
import { ICallState } from './call.slice';

/**
 A selector function to retrieve the call data from the store
 @function
 @param {RootState} state - The root state of the Redux store
 @returns {ICallState} The call state object from the Redux store
 */
const selectCallState = (state: RootState): ICallState => state.callProcess;

/**
 A memoized selector function to retrieve the call data from the store
 @function
 @type {import('@reduxjs/toolkit').OutputSelector<RootState, ICallState, (res: ICallState) => ICallState>}
 */
export const getCallState = createSelector(
  [selectCallState],
  (state): ICallState => state
);
