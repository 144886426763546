import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../index';
import { UserState } from './user.slice';

/**
 A selector function to retrieve the user profile data from the store
 @function
 @param {RootState} state - The root state of the Redux store
 @returns {AuthState} The user profile state object from the Redux store
 */
const selectUserState = (state: RootState): UserState => state.user;

/**
 A memoized selector function to retrieve the user profile data from the store
 @function
 @type {import('@reduxjs/toolkit').OutputSelector<RootState, UserProfileState, (res: AuthState) => AuthState>}
 */
export const getUserState = createSelector(
  [selectUserState],
  (state): UserState => state
);
