import React, { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// Components
import {
  CustomButton,
  CustomIcon,
  EIconColor,
  EIconName,
  SpinElement
} from '@ppmcore/seven-ppm-core-shared-components-react';

// Selectors
import { getCallFinishedState } from '../../../../store/call-finished/call-finished.selectors';

// Thunks
import { getCallFinishedInfo } from '../../../../store/call-finished/call-finished.thunks';

// Styles
import './network-disconnected.scss';

interface INetworkDisconnectedProps {
  title: string;
}

export const NetworkDisconnected: FC<INetworkDisconnectedProps> = ({ title }: INetworkDisconnectedProps) => {

  const dispatch = useDispatch<any>();
  const navigate = useNavigate();

  const callId = localStorage.getItem('call_id');

  const { callFinished, error } = useSelector(getCallFinishedState);

  useEffect(() => {
    if (!callFinished && callId) {
      setTimeout(() => {
        dispatch(getCallFinishedInfo({ call_id: +callId, temporary_token: localStorage.getItem('temporary_token') ?? '' }));
      }, 1500);
    }
  }, [callFinished]);

  return (<div className={'network-disconnected'}>
    {!callFinished ? <SpinElement/>
      : <div className={'network-disconnected-inside'}>
      <div className={'title'}>{title}</div>
      <div className={'call-desc'}>The connection was lost. Please try to call the Counsellor again.</div>
      <div className={'call-result-info'}>
        <CustomIcon name={callFinished.call_type === 'video_call' ? EIconName.CameraOn : EIconName.MicOn} color={EIconColor.White}/>
        <div className={'call-result-text'}>{callFinished.call_duration}</div>
        <div className={'call-divider'}></div>
        <div className={'call-result-text'}>{callFinished.total}</div>
      </div>
      <div className={'actions-section'}>
        <CustomButton text="OK" mode={'dark'} onClick={() => navigate(`/expert/${callFinished?.worker?.id}/history`)}/>
      </div>
    </div>}
  </div>);
}
