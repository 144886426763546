import { createSelector } from '@reduxjs/toolkit';

// Models
import { ISidebarState } from './sidebar.slice';
import { RootState } from '../index';

/**
 A selector function to retrieve the sidebar data from the store
 @function
 @param {RootState} state - The root state of the Redux store
 @returns {ISidebarState} The sidebar state object from the Redux store
 */
const selectSidebarState = (state: RootState): ISidebarState => state.sidebar;

/**
 A memoized selector function to retrieve the sidebar data from the store
 @function
 @type {import('@reduxjs/toolkit').OutputSelector<RootState, ISidebarState, (res: ISidebarState) => ISidebarState>}
 */
export const getSidebarState = createSelector(
  [selectSidebarState],
  (state): ISidebarState => state
);
