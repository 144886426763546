import React from 'react';
import { useDispatch } from 'react-redux';

// Components
import { ConversationNotifications } from '../modules/conversation-notifications/conversation-notifications';

// Thunks
import { fetchOngoingCalls } from '../../../store/ongoing-consultation/ongoing-consultation.thunks';

// Styles
import './chat-status-rejoin.scss';

export const ChatStatusRejoin = () => {

  const dispatch = useDispatch<any>();

  const workerId = localStorage.getItem('worker_id');
  const conversation_id = localStorage.getItem('conversation_id');

  const skipRejoin = () => {
    dispatch(fetchOngoingCalls());
  }

  return (<ConversationNotifications
    title={'Oops, something went wrong'}
    text={'The connection was lost. Please use the button below to try to rejoin the Text Chat. You will be connected to the same Text Chat connection.'}
    navigateAction={`/conversation/${conversation_id}`}
    navigateActionText={'Rejoin'}
    additionAction={localStorage.getItem('return_pathname') ?? `/expert/${workerId}`}
    additionActionText={'Skip'}
    onAdditionAction={skipRejoin} />);
}
