import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Models
import { ITransactionItem, ITransactions, ITransactionSettings } from '../../interfaces/transactions.interfaces';

export interface ITransactionsState {
  transactionsList: ITransactionItem[],
  settings: ITransactionSettings,
  total: number;
  total_all: number | null;
  isFirstLoad: boolean;
  isLoading: boolean;
  isError: boolean;
}

const initialState: ITransactionsState = {
  transactionsList: [],
  settings: {
    limit: 10,
    offset: 0,
    worker_id: '',
  },
  total: 0,
  total_all: null,
  isFirstLoad: true,
  isLoading: false,
  isError: false
};

export const transactionsSlice = createSlice({
  name: 'transactions',
  initialState,
  reducers: {
    getTransactionsRequest(state, { payload: { isFirstLoad } }: PayloadAction<{ isFirstLoad: boolean }>) {
      state.isLoading = true;
      state.isFirstLoad = isFirstLoad;
      state.isError = false;
    },
    getTransactionsRequestSuccess: (state, { payload }: PayloadAction<ITransactions>) => {
      state.transactionsList = payload.transactions ?? [];
      state.total = payload.total ?? 0;
      state.total_all = payload.total_all ?? 0;
      state.isLoading = false;
      state.isFirstLoad = false;
      state.isError = false;
    },
    getTransactionsRequestFailure: (state) => {
      state.isLoading = false;
      state.isFirstLoad = false;
      state.isError = true;
    },
    setSettings(state, { payload }: PayloadAction<ITransactionSettings>) {
      state.settings = payload;
    },
    setIsFirstLoadTransactions: (state, { payload: { isFirstLoad } }: PayloadAction<{ isFirstLoad: boolean }>) => {
      state.isFirstLoad = isFirstLoad;
    },
    updateTransactionInfo: (state, { payload: id }: PayloadAction<number>) => {
      state.transactionsList = state.transactionsList.map(transaction => {
        if (transaction.id === id) {
          return { ...transaction, refund_status: 'requested', is_refund_requested: true };
        }
        return transaction;
      })
    },
    clearTransactions: (state) => {
      state.transactionsList = [];
      state.settings = {
        limit: 10,
        offset: 0,
        worker_id: '',
      };
      state.isFirstLoad = true;
      state.isLoading = false;
    }
  }
});

export const TransactionsSliceActions = transactionsSlice.actions;
