import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Checkbox, Form } from 'antd';

// Shared UI Library Components
import {
  CustomButton,
  CustomIcon,
  CustomInput,
  defaultValidateMessages,
  EIconName,
  emailPattern,
  ServerErrors,
  stringPattern
} from '@ppmcore/seven-ppm-core-shared-components-react';

// Components
import { AuthLayout } from '../auth-modules/auth-layout/auth-layout';
import { FormBottomActions } from '../auth-modules/form-bottom-actions/form-bottom-actions';
import { StaticTextModal } from '../../../modals/static-text-modal/static-text-modal';
import { StaticPages } from '../../../modules/static-pages/static-pages';

// Thunks
import { signUpUser } from '../../../store/auth/auth.thunks';

// Selectors
import { getAuthState } from '../../../store/auth/auth.selectors';

// Styles
import './sign-up.scss';

type TFormValues = {
  first_name: string;
  last_name: string;
  email: string;
  termsService: boolean;
  privacyPolicy: boolean;
};

export const SignUp = () => {

  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const [form] = Form.useForm<TFormValues>();

  const [staticTermsModalOpen, setStaticTermsModalOpen] = useState(false);
  const [staticPrivacyModalOpen, setStaticPrivacyModalOpen] = useState(false);
  const [checkedTerms, setCheckedTerms] = useState(false);
  const [checkedPrivacy, setCheckedPrivacy] = useState(false);

  const [hasTemporaryData, setHasTemporaryData] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(true);
  const [serverError, setServerError] = useState<string | null>(null);

  const { loading } = useSelector(getAuthState);

  const checkTemporaryData = (): void => {
    const data = localStorage.getItem('temporaryData');
    if (!data) return;

    const parseData = JSON.parse(data);
    form.setFields([
      {
        name: 'first_name',
        value: parseData?.first_name || '',
      },
      {
        name: 'last_name',
        value: parseData?.last_name || '',
      },
      {
        name: 'email',
        value: parseData?.email || '',
      },
      {
        name: 'termsService',
        value: true,
      },
      {
        name: 'privacyPolicy',
        value: true,
      },
    ]);
    setCheckedPrivacy(true);
    setCheckedTerms(true);
    setHasTemporaryData(true);
    setBtnDisabled(false);
  }

  useEffect(() => {
    localStorage.removeItem('reset_password_time');
    localStorage.removeItem('reset_password');

    checkTemporaryData();
  }, []);

  const agreeTerms = () => {
    setStaticTermsModalOpen(false);
    setCheckedTerms(true);
    form.setFieldsValue({ termsService: true });

    const { first_name, last_name, email } = form.getFieldsValue();
    if (first_name !== undefined
      && last_name !== undefined
      && email !== undefined
      && first_name !== ''
      && last_name !== ''
      && email !== ''
      && first_name.length <= 30
      && last_name.length <= 30
      && email.length <= 60
      && new RegExp(emailPattern).test(email)
      && checkedPrivacy) {
      setBtnDisabled(false);
    } else {
      setBtnDisabled(true);
    }
  };

  const agreePrivacy = () => {
    setStaticPrivacyModalOpen(false);
    setCheckedPrivacy(true);
    form.setFieldsValue({ privacyPolicy: true });

    const { first_name, last_name, email } = form.getFieldsValue();
    if (first_name !== undefined
      && last_name !== undefined
      && email !== undefined
      && first_name !== ''
      && last_name !== ''
      && email !== ''
      && first_name.length <= 30
      && last_name.length <= 30
      && email.length <= 60
      && new RegExp(emailPattern).test(email)
      && checkedTerms) {
      setBtnDisabled(false);
    } else {
      setBtnDisabled(true);
    }
  };

  const onValuesChange = (changedValues: { first_name: string } | { last_name: string } | { email: string },
    allFormValues: { first_name: string | undefined, last_name: string | undefined, email: string | undefined }) => {
    if (allFormValues.first_name !== undefined
      && allFormValues.last_name !== undefined
      && allFormValues.email !== undefined
      && allFormValues.first_name !== ''
      && allFormValues.last_name !== ''
      && allFormValues.email !== ''
      && allFormValues.first_name.length <= 30
      && allFormValues.last_name.length <= 30
      && allFormValues.email.length <= 60
      && new RegExp(emailPattern).test(allFormValues.email)
      && checkedTerms
      && checkedPrivacy) {
      setBtnDisabled(false);
    } else {
      setBtnDisabled(true);
    }
  };

  const onFinish = () => {
    if (btnDisabled) return;
    form.validateFields().then((values) => {
      const userData = {
        first_name: values.first_name,
        last_name: values.last_name,
        email: values.email
      };
      localStorage.setItem('signUp', JSON.stringify(userData));
      setServerError(null);

      dispatch(signUpUser(userData)).then((res: any) => {
        // if (res.payload?.error && res.payload.error === 'The username cannot be empty.') {
        if (res.payload?.error && res.payload.error === 'The password cannot be empty.') {
          navigate(`/sign-up-confirm`);
          return;
        }

        if (res.payload?.error && res.payload.error === 'The account with this email already exists.') {
          setBtnDisabled(true);
          form.setFields([
            {
              name: 'email',
              value: values.email,
              errors: ['The account with this email already exists.']
            }
          ]);
          return;
        }

        if (res.payload?.error) {
          setBtnDisabled(true);
          setServerError(res.payload.error);
        }
      });
    }).catch((error) => {
      console.error('Form validation failed', error);
    });
  };

  return (<AuthLayout cardTitle="Sign Up" rightBarIcon='auth' cardCurrentStep={ '1' } cardTotalSteps={ '2' }>
    { serverError && <ServerErrors serverError={ serverError }/> }
    <Form
      form={ form }
      className={ 'sign-up-form' }
      name="sign-up"
      onFinish={ onFinish }
      onValuesChange={ onValuesChange }
      autoComplete="off"
      validateMessages={ defaultValidateMessages }>
      <Form.Item
        name="first_name"
        messageVariables={ { fieldLabel: 'First Name' } }
        rules={ [
          { required: true, max: 30 },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || new RegExp(stringPattern).test(getFieldValue('first_name'))) {
                return Promise.resolve();
              }
              if (value.length > 30) {
                return Promise.resolve();
              }
              setBtnDisabled(true);
              return Promise.reject(new Error(defaultValidateMessages.invalidFirstNameFormat));
            }
          })
        ] }>
        <CustomInput type={ 'text' } placeholder={ 'First Name*' }
                     suffix={ <CustomIcon name={ EIconName.PersonOff }/> }/>
      </Form.Item>
      <Form.Item
        name="last_name"
        messageVariables={ { fieldLabel: 'Last Name' } }
        rules={ [
          { required: true, max: 30 },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || new RegExp(stringPattern).test(getFieldValue('last_name'))) {
                return Promise.resolve();
              }
              if (value.length > 30) {
                return Promise.resolve();
              }
              setBtnDisabled(true);
              return Promise.reject(new Error(defaultValidateMessages.invalidLastNameFormat));
            }
          })
        ] }>
        <CustomInput type={ 'text' } placeholder={ 'Last Name*' }
                     suffix={ <CustomIcon name={ EIconName.PersonOff }/> }/>
      </Form.Item>

      <Form.Item
        name="email"
        messageVariables={ { fieldLabel: 'Email' } }
        rules={ [
          { required: true },
          { max: 60 },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || new RegExp(emailPattern).test(getFieldValue('email'))) {
                return Promise.resolve();
              }
              if (value.length > 60) {
                return Promise.resolve();
              }
              setBtnDisabled(true);
              return Promise.reject(new Error(defaultValidateMessages.invalidEmailFormat));
            }
          })] }>
        <CustomInput type={ 'text' } shouldTrim={true} placeholder={ 'Email*' } disabled={ hasTemporaryData }
                     suffix={ <CustomIcon name={ EIconName.Mail }/> }/>
      </Form.Item>

      <StaticTextModal modalTitle="Terms of Service"
                       open={ staticTermsModalOpen }
                       onSubmit={ agreeTerms }
                       onCancel={ () => setStaticTermsModalOpen(false) }>
        <StaticPages staticPageId={ 2 } isDialog={ true }/>
      </StaticTextModal>
      <Form.Item
        className="checkbox-item checkbox-item-first"
        name="termsService"
        initialValue={ checkedTerms }
        valuePropName="checked"
        rules={ [
          {
            validator: (_, value) =>
              value ? Promise.resolve() : Promise.reject(new Error(defaultValidateMessages.termsServiceAgreed))
          }
        ] }>
        <Checkbox disabled={ true }>
          <div>
            <div className="hidden-clickable-space"
                 onClick={ () => setStaticTermsModalOpen(!staticTermsModalOpen) }></div>
            <div className={ 'check-box-text' }>
              I agree to the <span className="modal-action"
                                   onClick={ () => setStaticTermsModalOpen(!staticTermsModalOpen) }>Terms of Service</span>
            </div>
          </div>
        </Checkbox>
      </Form.Item>

      <StaticTextModal modalTitle="Privacy Policy"
                       open={ staticPrivacyModalOpen }
                       onSubmit={ agreePrivacy }
                       onCancel={ () => setStaticPrivacyModalOpen(false) }>
        <StaticPages staticPageId={ 1 } isDialog={ true }/>
      </StaticTextModal>
      <Form.Item
        className="checkbox-item"
        name="privacyPolicy"
        initialValue={ checkedPrivacy }
        valuePropName="checked"
        rules={ [
          {
            validator: (_, value) =>
              value ? Promise.resolve() : Promise.reject(new Error(defaultValidateMessages.privacyPolicyAgreed))
          }
        ] }>
        <Checkbox disabled={ true }>
          <div>
            <div className="hidden-clickable-space"
                 onClick={ () => setStaticPrivacyModalOpen(!staticPrivacyModalOpen) }></div>
            <div className={ 'check-box-text' }>
              I agree to the <span className="modal-action"
                                   onClick={ () => setStaticPrivacyModalOpen(!staticPrivacyModalOpen) }>Privacy Policy</span>
            </div>
          </div>
        </Checkbox>
      </Form.Item>

      <div className="form-bottom form-bottom-block">
        <FormBottomActions showForgotPasswordLink={ false } infoText={ 'Already have an account?' }
                           isSignInLink={ true }/>
        <div className={ 'actions' }>
          <CustomButton htmlType={ 'submit' } disabled={ loading || btnDisabled } text="Continue"/>
        </div>
      </div>
    </Form>
  </AuthLayout>);
}
