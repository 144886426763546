import React, { FC } from 'react';

// Shared UI Library Components
import {
  CustomIconBig,
  EIconBigName,
} from '@ppmcore/seven-ppm-core-shared-components-react';

// Interfaces
import { IUserData } from '../../../../interfaces/user.interfaces';

// Styles
import './publisher.scss';

interface IPublisherProps {
  userData: IUserData;
}

export const Publisher: FC<IPublisherProps> = (
  { userData }: IPublisherProps
) => {

  return (<div className={ 'publisher-audio' }>
    <div className={ 'publisher-inside' }>
      <div className={ 'publisher-section' }/>
      <div
        className={ 'publisher-image' }>
        { userData.photo ? <img src={ userData.photo } alt={ '' }/> :
          <CustomIconBig name={ EIconBigName.NoVideoAvatar }/> }
      </div>
      <div className={ 'publisher-fio' }>
        { userData.first_name } { userData.last_name }
      </div>
    </div>
  </div>);
}
