import React from 'react';

// Components
import { ConversationNotifications } from '../modules/conversation-notifications/conversation-notifications';

// Styles
import './chat-status-network-disconnected.scss';

export const ChatStatusNetworkDisconnected = () => {

  const workerId = localStorage.getItem('worker_id');

  return (<ConversationNotifications
    title={'Oops, something went wrong'}
    text={'The connection was lost. Please try to connect the Counsellor again.'}
    navigateAction={`/expert/${workerId}/history`}  />);
}
