import React, { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';

// Shared UI Library Components
import { EIconName, InputSearch } from '@ppmcore/seven-ppm-core-shared-components-react';

// Styles
import './expert-rates.scss';

// Components
import { RatesItem } from '../rates-item/rates-item';

// Store entities
import { getCurrentExpertState } from '../../../../store/currentExpert/currentExpert.selectors';

// Models
import { IRateItem } from '../../../../interfaces/experts.interfaces';

type TExpertRatesProps = {};

type TRatesKeys = 'video' | 'voice' | 'chat' | 'correspondence';

export const ExpertRates: FC<TExpertRatesProps> = ({}: TExpertRatesProps) => {
  const { expert } = useSelector(getCurrentExpertState);

  const getRatesObject = (): { [key in TRatesKeys]?: IRateItem } => {
    if (!expert) return {};
    const {
      conversation_rate,
      audio_call_rate,
      call_rate,
      message_rate,
      session_rates
    } = expert;

    return {
      ...((expert?.is_video_call_enabled || !!session_rates?.call_per_session_rate.length) && {
        video: {
          type: 'Rate per Minute',
          name: 'Video Consultation',
          icon: EIconName.CameraOn,
          rate: call_rate.text,
          full_free_service_enabled: !!expert?.full_free_service_enabled,
          is_enabled: !!expert?.is_video_call_enabled,
          cost: 'Cost per Session',
          list: [...session_rates?.call_per_session_rate]
            .sort((a, b) => (+a?.call_duration - +b?.call_duration))
            .map(({ call_duration, rates, full_free_service_enabled }) => <>
              <><b>{ call_duration }</b> min <b>- { full_free_service_enabled ? 'Free' : rates.text }</b></>
            </>)
        }
      }),
      ...((expert?.is_audio_call_enabled || !!session_rates?.audio_call_per_session_rate.length) && {
        voice: {
          type: 'Rate per Minute',
          name: 'Voice Consultation',
          icon: EIconName.MicOn,
          rate: audio_call_rate.text,
          full_free_service_enabled: !!expert?.full_free_service_enabled,
          is_enabled: !!expert?.is_audio_call_enabled,
          cost: 'Cost per Session',
          list: [...session_rates?.audio_call_per_session_rate]
            .sort((a, b) => (+a?.call_duration - +b?.call_duration))
            .map(({ call_duration, rates, full_free_service_enabled }) => <>
              <><b>{ call_duration }</b> min <b>- { full_free_service_enabled ? 'Free' : rates.text }</b></>
            </>)
        }
      }),
      ...((expert?.is_conversation_enabled) && {
        chat: {
          type: 'Rate per Minute',
          name: 'Text Chat',
          icon: EIconName.Chat,
          rate: conversation_rate.text,
          full_free_service_enabled: !!expert?.full_free_service_enabled,
          is_enabled: !!expert?.is_conversation_enabled,
        },
      }),
      ...((expert?.is_messages_enabled) && {
        correspondence: {
          type: 'Rate per Request',
          name: 'Correspondence',
          icon: EIconName.Message,
          rate: message_rate.text,
          full_free_service_enabled: !!expert?.full_free_service_enabled,
          is_enabled: !!expert?.is_messages_enabled,
        }
      }),
    }
  }

  const rates = useMemo(() => getRatesObject(), [expert]);
  const hasSessions = !!rates?.video?.list?.length || !!rates?.voice?.list?.length || !!rates?.chat?.list?.length;
  const isEmpty = !Object.keys(rates)?.length;

  return (
    <div className={ `expert-rates ${ isEmpty ? 'full-height-page' : '' }` }>

      <div className="expert-rates--header">
        <div className="header-title">
          Rates
        </div>
      </div>

      <div className="expert-rates--label">
        Instant Calls
      </div>

      <div className="expert-rates--list">
        {
          rates?.video && <div className="rates-list-item">
            <RatesItem rateInfo={ rates?.video }/>
          </div>
        }

        {
          rates?.voice && <div className="rates-list-item">
            <RatesItem rateInfo={ rates?.voice }/>
          </div>
        }

        {
          (rates?.chat || rates.correspondence) && <div className="rates-list-item">
            { rates?.chat && <RatesItem rateInfo={ rates?.chat }/> }
            { rates?.correspondence && <RatesItem rateInfo={ rates?.correspondence }/> }
          </div>
        }
      </div>

      { hasSessions && <div className="expert-rates--label">
        Appointments
      </div> }

      <div className="expert-rates--list">
        {
          rates?.video && <div className={ `rates-list-item ${ rates?.video ? 'has-service' : '' }` }>
            { !!rates?.video.list?.length && <RatesItem viewType={ 'pps' } rateInfo={ rates?.video }/> }
          </div>
        }

        {
          rates?.voice && <div className={ `rates-list-item ${ rates?.voice ? 'has-service' : '' }` }>
            { !!rates?.voice.list?.length && <RatesItem viewType={ 'pps' } rateInfo={ rates?.voice }/> }
          </div>
        }

        {
          (rates?.chat || rates?.correspondence) && <div className={ `rates-list-item ${ (rates?.chat || rates?.correspondence) ? 'has-service' : '' }` }>
            { !!rates?.chat?.list?.length && <RatesItem viewType={ 'pps' } rateInfo={ rates?.chat }/> }
          </div>
        }
      </div>

      { isEmpty && <div className="expert-rates--empty">
        <div className="empty-img">
          <img src="/assets/icons/big/rates-empty.svg" alt="empty-rates-icon"/>
        </div>
        <div className="empty-subtitle">
          No rates info yet
        </div>
      </div> }
    </div>
  );
};
