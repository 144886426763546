import { createAsyncThunk } from '@reduxjs/toolkit';

// Slices & Thunks
import { SidebarSliceActions } from './sidebar.slice';

// Models
import { TSidebarType } from '../../types/sidebar.types';

export enum ESidebarThunks {
  SetSidebarType = 'SIDEBAR/setSidebarType',
}

export const setSidebarType = createAsyncThunk(
  ESidebarThunks.SetSidebarType,
  async ({ type }: { type: TSidebarType }, { dispatch }) => {
    localStorage.setItem('sidebarState', type);
    dispatch(SidebarSliceActions.setSidebarType(type));
    return type;
  }
);
