import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import axios, { AxiosError, AxiosResponse, InternalAxiosRequestConfig } from 'axios';

// API
import { api } from '../configs/api/api';

// Thunks
import { userLogoutNotValidToken } from '../store/user/user.thunks';
import { openNotification } from '../store/app-notifications/app-notifications.thunks';

/**
 * Custom React Hook for adding an Axios interceptor.
 */
export const useInterceptor = () => {
  // object to store ongoing requests cancel tokens
  const pendingRequests = new Map();

  const dispatch = useDispatch<any>();
  const navigate = useNavigate();

  const requestSuccessHandler = (request: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
    // console.log('requestSuccessHandler response', request);
    // do something with request

    // generate an identifier for each request
    const requestIdentifier = `${ request.url }_${ request.method }`;

    // check if there is already a pending request with the same identifier
    if (pendingRequests.has(requestIdentifier)) {
      const cancelTokenSource = pendingRequests.get(requestIdentifier);
      // cancel the previous request
      cancelTokenSource.cancel('Cancelled due to new request');
    }

    // create a new CancelToken
    const newCancelTokenSource = axios.CancelToken.source();
    request.cancelToken = newCancelTokenSource.token;

    // store the new cancel token source in the map
    pendingRequests.set(requestIdentifier, newCancelTokenSource);

    const token = localStorage.getItem('token');
    const temporaryToken = localStorage.getItem('temporary_token');
    let authData = {};
    if (token || temporaryToken) {
      authData = {
        Authorization: `Bearer ${ token ?? temporaryToken }`
      }
    }

    return {
      ...request,
      headers: request.headers.concat(authData)
    };
  }

  const responseSuccessHandler = (response: AxiosResponse): AxiosResponse => {
    // do something with response
    return {
      ...response
    };
  }

  const requestErrorHandler = (error: AxiosError): Promise<AxiosError> => {
    return Promise.reject(error);
  }

  const responseErrorHandler = (error: AxiosError): Promise<AxiosError> => {
    if (error.code === 'ERR_CANCELED') {
      return Promise.resolve(error);
    }
    if (error.response?.status === 401) {
      if (!localStorage.getItem('tokenError')) {
        dispatch(userLogoutNotValidToken())
        // .then(() => navigate('/sign-in'));
        setTimeout(() => {
          dispatch(openNotification({
            type: 'error',
            description: 'Your account currently not available. Please try log in again',
          }));
        }, 500);
      }
      localStorage.setItem('tokenError', '1');
      return Promise.reject();
    }
    localStorage.removeItem('tokenError');
    return Promise.reject(error);
  }

  const setResponseInterceptor = (): number => {
    /**
     * Axios interceptor for handling response.
     * @param {Object} response - HTTP response object.
     * @returns {Object} - HTTP response object.
     */
    return api.interceptors.response.use(
      (response) => responseSuccessHandler(response),
      /**
       * Axios interceptor for handling errors.
       * @param {Object} error - HTTP error object.
       * @returns {Promise} - Promise object that rejects with the error object.
       */
      (error) => responseErrorHandler(error)
    )
  }

  const setRequestInterceptor = (): number => {
    /**
     * Axios interceptor for handling request.
     * @param {Object} request - HTTP request object.
     * @returns {Object} - HTTP request object.
     */
    return api.interceptors.request.use(
      (request) => requestSuccessHandler(request),
      /**
       * Axios interceptor for handling errors.
       * @param {Object} error - HTTP error object.
       * @returns {Promise} - Promise object that rejects with the error object.
       */
      (error) => requestErrorHandler(error)
    )


    // here we set up the Response Interceptor, this logic triggers
    // before each response from the server comes
    // api.interceptors.response.use(response => {
    //
    //   // remove completed request from pending map
    //   const requestIdentifier = `${ response.config.url }_${ response.config.method }`;
    //   pendingRequests.delete(requestIdentifier);
    //   return response;
    // }, error => {
    //
    //   // remove failed request from pending map
    //   if (error.config) {
    //     const requestIdentifier = `${ error.config.url }_${ error.config.method }`;
    //     pendingRequests.delete(requestIdentifier);
    //   }
    //   return Promise.reject(error);
    // });
  }

  useEffect(() => {
    /**
     Sets up the axios response interceptor.
     @function
     @returns {number} The ID of the axios response interceptor
     */
    const interceptorResponse = setResponseInterceptor();

    /**
     Sets up the axios request interceptor.
     @function
     @returns {number} The ID of the axios request interceptor
     */
    const interceptorRequest = setRequestInterceptor();

    /**
     Cleaning function to remove interceptors when component is unmounted.
     @function
     */
    return () => {
      api.interceptors.response.eject(interceptorResponse);
      api.interceptors.request.eject(interceptorRequest);
    };
  }, []);
};
