import { createAsyncThunk } from '@reduxjs/toolkit';

// Services
import { fetchStaticPages } from '../../services/static-pages.service';

// Slices
import { StaticPagesSliceActions } from './static-pages.slice';

export const getStaticPages = createAsyncThunk(
  'STATIC_PAGES/getStaticPages',
  async (_, {dispatch}: any) => {
    dispatch(StaticPagesSliceActions.getStaticPagesRequest());
    const pagesList = await fetchStaticPages();
    if (pagesList.status !== 200) {
      dispatch(StaticPagesSliceActions.getStaticPagesFailure());
    }
    dispatch(StaticPagesSliceActions.getStaticPagesSuccess(pagesList.data));
  }
);
