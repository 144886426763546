import React, { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// Styles
import './sidebar-mobile-widget.scss';

// Shared UI Library Components
import { CustomIcon, CustomTooltip, EIconName, UserAvatar } from '@ppmcore/seven-ppm-core-shared-components-react';

// Components
import { BackAction } from '../back-action/back-action';
import { StayWithUsModal } from '../../../../modals/stay-with-us-modal/stay-with-us-modal';
import { LogoBlock } from '../logo-block/logo-block';
import { RightMenu } from '../right-menu/right-menu';

// Store entities
import { getCurrentExpertState } from '../../../../store/currentExpert/currentExpert.selectors';
import { getCoreState } from '../../../../store/core/core.selectors';
import { getSidebarState } from '../../../../store/sidebar/sidebar.selectors';
import { getUserState } from '../../../../store/user/user.selectors';

// Models
import { ICoreState } from '../../../../store/core/core.slice';

interface ISidebarProps {
  coreState?: ICoreState
}

export const SidebarMobileWidget: FC<ISidebarProps> = (
  { coreState }: ISidebarProps
): JSX.Element => {

  const token = localStorage.getItem('token');

  const [mobileMenu, setMobileMenu] = useState(false);
  const [stayWithUsModalOpen, setStayWithUsModalOpen] = useState(false);

  const navigate = useNavigate();

  const { user } = useSelector(getUserState);
  const { expert } = useSelector(getCurrentExpertState);
  const { path } = useSelector(getCoreState);
  const { type } = useSelector(getSidebarState);

  const hiddenBlock = !expert;

  const toggleMobileMenu = (): void => {
    setMobileMenu(!mobileMenu);
  }

  const navigateToSignIn = (): void => {
    navigate('/sign-in');
  }

  return (
    <aside className="sidebar-mobile-widget" hidden={ hiddenBlock }>
      <div className="sidebar-mobile-widget--item item-info">
        { (token && !expert) && <LogoBlock/> }
        { expert &&
          <BackAction expert={ expert } isTemporary={ !token } sidebarType={ type } prevPath={ path.prev ?? '' }/> }
      </div>
      <div className={ `sidebar-mobile-widget--item item-menu ${ mobileMenu ? 'open-menu' : '' }` }>
        { token && <UserAvatar avatarUrl={ user?.photo } sizeClass={ 'small' }/> }
        { token && <CustomIcon name={ EIconName.ArrowDown } onClick={ toggleMobileMenu }/> }
        { (!token && expert) && <div className="stay-with-us">
          <CustomTooltip title={ 'Sign In / Sign Up' } placement={ 'bottom' } className="icon-item">
            <CustomIcon onClick={ navigateToSignIn } name={ EIconName.Login }/>
          </CustomTooltip>
        </div> }
      </div>
      <div className="sidebar-mobile-widget--menu" hidden={ !mobileMenu }>
        <RightMenu withLabels={ true } currentPath={ coreState?.path?.current || '' }
                   onClickMenuItem={ toggleMobileMenu }/>
      </div>

      <StayWithUsModal open={ stayWithUsModalOpen } onCancel={ () => setStayWithUsModalOpen(false) }/>
    </aside>
  )
};
