import React, { FC, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

// Shared UI Library Components
import {
  CropImageModal,
  CustomButton,
  CustomIcon,
  DiscardModal,
  EIconName
} from '@ppmcore/seven-ppm-core-shared-components-react';

// Styles
import './user-avatar-section.scss';

// Thunks
import { userPhotoDelete, userPhotoUpdate } from '../../../../store/user/user.thunks';
import { openNotification } from '../../../../store/app-notifications/app-notifications.thunks';

interface IUserAvatarSectionProps {
  userPhoto: string | null;
  onSuccess?: () => void;
}

export const UserAvatarSection: FC<IUserAvatarSectionProps> = ({userPhoto, onSuccess = () => {}}: IUserAvatarSectionProps) => {

  const dispatch = useDispatch<any>();
  const randomString = Math.random().toString(36);

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [cropModalOpen, setCropModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [inputKey, setInputKey] = useState<string>(randomString);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    setSelectedImage(file ? URL.createObjectURL(file) : null);
    setCropModalOpen(true);
  };

  const handleDeletePhoto = () => {
    setDeleteModalOpen(true);
    setInputKey(randomString);
  }

  const handleChangePhoto = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  }

  const updatePhoto = (photo: File) => {
    dispatch(userPhotoUpdate(photo)).then((res: any) => {
      if (res.payload.error) {
        dispatch(openNotification({
          description: res.payload.error,
          type: 'error'
        }));
        return;
      }

      dispatch(openNotification({
        description: 'Photo was successfully saved',
        type: 'success'
      }));
    });
    setCropModalOpen(false);
    setInputKey(randomString);
  }

  const handleCloseModal = () => {
    setCropModalOpen(false);
    setSelectedImage(null);
    setInputKey(randomString);
  }

  const cancelRemoveDialog = () => {
    setDeleteModalOpen(false);
  }

  const removePhoto = () => {
    dispatch(userPhotoDelete()).then((res: any) => {
      if (res.payload.error) {
        dispatch(openNotification({
          description: res.payload.error,
          type: 'error'
        }));
        return;
      }

      dispatch(openNotification({
        description: 'Photo was successfully deleted',
        type: 'success'
      }));
    });
    setDeleteModalOpen(false);
  }

  return (<div className="user-avatar-section">
    {selectedImage && cropModalOpen && <CropImageModal
      open={cropModalOpen}
      imageSrc={selectedImage}
      onSubmit={updatePhoto}
      onCancel={handleCloseModal}/>}

    <div className={'user-image-inside'}>
      {userPhoto
        ? <img src={userPhoto} alt=""/>
        : <div className={'no-image'}>
        <CustomIcon name={EIconName.PersonOff} skipSize={true} />
      </div>}

      {userPhoto
        ? <div className={'actions-section'}>
          <CustomIcon name={EIconName.Delete} onClick={handleDeletePhoto} />
          <CustomButton text={'Change'} type="text" size={'small'} onClick={() => handleChangePhoto()} />
        </div>
        : <div className={'actions-section'}>
          <CustomButton text={'Add'} type="text" size={'small'} onClick={() => handleChangePhoto()} />
        </div>}
    </div>

    <input
      type="file"
      accept="image/*"
      ref={fileInputRef}
      key={inputKey}
      style={{display: 'none'}}
      onChange={handleImageChange}
    />

    <DiscardModal open={deleteModalOpen}
                 onCancel={(value) => value
                   ? removePhoto()
                   : cancelRemoveDialog()}
                 title={'Are you sure you want to delete your Photo?'} />
  </div>);
}
