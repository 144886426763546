import React, { useEffect, useMemo, useState } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// Shared UI Library Components
import { SpinElement } from '@ppmcore/seven-ppm-core-shared-components-react';

// Styles
import './main-layout.scss';

// Components
import { Sidebar } from './components/sidebar/sidebar';
import { RightMenu } from './components/right-menu/right-menu';
import { SidebarMobile } from './components/sidebar-mobile/sidebar-mobile';
import { SidebarMenu } from './components/sidebar-menu/sidebar-menu';
import { CompanyAddedModal } from '../../modals/company-added-modal/company-added-modal';
import { SidebarWidget } from './components/sidebar-widget/sidebar-widget';
import { SidebarMobileWidget } from './components/sidebar-mobile-widget/sidebar-mobile-widget';
import { OngoingConsultation } from '../../shared/ui-components/call-rejoin';

// Store Entities
import { getCoreState } from '../../store/core/core.selectors';
import {
  assignNewCompanyThunk,
  fetchCountries, fetchLanguages, fetchSpecialities,
  fetchTempUserCompany
} from '../../store/core/core.thunks';
import { getUserProfile, updateLastCompany } from '../../store/user/user.thunks';
import { getSidebarState } from '../../store/sidebar/sidebar.selectors';
import { getCurrentExpertState } from '../../store/currentExpert/currentExpert.selectors';

// Utils
import { isWidgetAppType } from '../../utils/widget.utils';

type TMainLayoutProps = {
  hiddenBeforeLoad?: boolean;
};

export const MainLayout = ({ hiddenBeforeLoad = false }: TMainLayoutProps) => {

  const dispatch = useDispatch<any>();
  const navigate = useNavigate();

  const [companyAddedOpen, setCompanyAddedOpen] = useState<boolean>(false);
  const [sidebarOpened, setSidebarOpened] = useState<boolean>(true);
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);
  const [isIndividual, setIsIndividual] = useState<boolean>(true);
  const [hiddenLayout, setHiddenLayout] = useState<boolean>(hiddenBeforeLoad);

  const token = localStorage.getItem('token');

  const params = useParams<{ companyCode?: string, personalLink?: string }>();
  const companyCode = useMemo(() => params?.companyCode, [params?.companyCode]);

  const coreState = useSelector(getCoreState);
  const sidebarState = useSelector(getSidebarState);
  const { expert } = useSelector(getCurrentExpertState);

  const prefix = windowWidth <= 768 ? 'mobile' : 'desktop';
  const company = coreState?.companyInfo?.company;

  const isWidget: boolean = isWidgetAppType();

  const toggleCompanyAddedOpen = (): void => {
    setCompanyAddedOpen(prev => !prev);
  }

  const getCompanyByName = async (): Promise<void> => {
    if (!companyCode) return;

    const { payload } = await dispatch(fetchTempUserCompany({ company_code: companyCode }));

    if (!payload) {
      navigate('/404');
      return;
    }

    setHiddenLayout(false);

    if (!!token && payload) {
      const { payload: assignPayload } = await dispatch(assignNewCompanyThunk({ company_code: companyCode }));

      const { payload: user } = await dispatch(getUserProfile(false));

      if (user?.last_used_company !== payload?.company_profile?.id) {
        await dispatch(updateLastCompany(payload?.company_profile?.id));
      }

      if (assignPayload?.payload) {
        toggleCompanyAddedOpen();
      }
      return;
    }

    dispatch(fetchLanguages({ company_code: companyCode }));
    dispatch(fetchCountries({ company_code: companyCode }));
    dispatch(fetchSpecialities({ company_code: companyCode }));
  }

  useEffect((): void => {
    if (token) {
      setIsIndividual(false);
      setSidebarOpened(window.innerWidth > 900);
    }

    if (params?.personalLink) {
      setSidebarOpened(false);
    }
  }, []);

  useEffect(() => {
    if (
      (companyCode && !company) ||
      (companyCode !== company?.company_profile.url_code && companyCode !== company?.company_profile?.widget_settings?.widget_hash)
    ) {
      getCompanyByName();
    } else if (!token && companyCode && company) {
      setIsIndividual(company.is_individual);

      const timerId = setTimeout(() => {
        setHiddenLayout(false);
        clearTimeout(timerId);
      }, 200);
      // setSidebarOpened(!isIndividual);
    } else if (companyCode && company) {
      const timerId = setTimeout(() => {
        setHiddenLayout(false);
        clearTimeout(timerId);
      }, 200);
    }
  }, [companyCode, company]);

  useEffect(() => {
    window.addEventListener('resize', resizeHandler);

    return () => {
      window.removeEventListener('resize', resizeHandler);
    };
  }, []);

  const resizeHandler = (event: UIEvent): void => {
    const windowTarget = event.target as Window;
    setWindowWidth(windowTarget.innerWidth);

    if (token) {
      setSidebarOpened(windowTarget.innerWidth > 900);
    }
  }

  useEffect((): void => {
    setHiddenLayout(hiddenBeforeLoad);
  }, [hiddenBeforeLoad]);

  if (hiddenLayout) {
    return (<div className="app-loading-spinner">
      <SpinElement fullHeight={ true }/>
    </div>)
  }

  return (
    <div className={ `main-layout ${ expert ? 'main-layout--current-expert' : '' }` }>
      <CompanyAddedModal open={ companyAddedOpen } onClose={ toggleCompanyAddedOpen }/>
      <div className={ `main-layout--${ prefix }` }>
        <div className={ `${ prefix }-item ${ prefix }-item--left-menu` }>
          { !isWidget && <Sidebar
            coreState={ coreState }
            sidebarState={ sidebarState }
            sidebarOpened={ sidebarOpened }
            hideSidebarActionBtn={ isIndividual }
            setSidebarOpened={ setSidebarOpened }/> }

          { isWidget && <SidebarWidget
            coreState={ coreState }
            sidebarState={ sidebarState }
            sidebarOpened={ true }
            hideSidebarActionBtn={ isIndividual }
            setSidebarOpened={ setSidebarOpened }/> }
        </div>
        <div className={ `${ prefix }-item ${ prefix }-item--content` }>
          <div className="content-header">
            {/*TODO rejoin-flow comments*/ }
            <OngoingConsultation/>
          </div>
          <div className="content-container">
            <Outlet/>
          </div>
        </div>
        <div className={ `${ prefix }-item ${ prefix }-item--right-menu` }>
          <RightMenu currentPath={ coreState?.path?.current }/>
        </div>

        <div className={ `${ prefix }-item ${ prefix }-item--header-menu` }>
          { isWidget && <SidebarMobileWidget coreState={ coreState }/> }
          { !isWidget && <SidebarMobile coreState={ coreState }/> }
        </div>
        <div className={ `${ prefix }-item ${ prefix }-item--bottom-menu` }>
          <SidebarMenu sidebarState={ sidebarState } sidebarOpened={ true } currentPath={ coreState?.path?.current }/>
        </div>
      </div>
    </div>
  );
}
