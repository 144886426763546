import React, { FC } from 'react';

// Styles
import './tu-title.scss';

interface ITuTitleProps {
  currentStep: string;
  totalSteps: string;
}

export const TuTitle: FC<ITuTitleProps> = ({currentStep, totalSteps }: ITuTitleProps) => {
  return (
    <div className={'tu-title'}>
      <div className={'text'}>Start a Paid Consultation</div>
      <div className={'title-steps'}><span>{currentStep}</span>/{totalSteps}</div>
    </div>)
};
