import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Shared UI Library Components
import { VerificationCode } from '@ppmcore/seven-ppm-core-shared-components-react';

// Thunks
import {
  checkVerificationCode,
  clearProfileSettingsState,
  resendCheckCode
} from '../../../../store/profile-settings/profile-settings.thunks';

// Selectors
import { getUserState } from '../../../../store/user/user.selectors';

// Actions
import { openNotification } from '../../../../store/app-notifications/app-notifications.thunks';

// Enums
import { EVerificationCodeTypes } from '../../profile-settings.enums';

// Styles
import './ce-step-4.scss';
import { getUserProfile } from '../../../../store/user/user.thunks';

interface ICpStep2Props {
  setStep?: (value: number) => void;
}

export const CeStep4: FC<ICpStep2Props> = ({setStep = () => {}}: ICpStep2Props) => {

  const dispatch = useDispatch<any>();

  const [errorWithTimerTime, setErrorWithTimerTime] = useState<string>('');
  const [errorWithTimerText, setErrorWithTimerText] = useState<string | null>(null);
  const [errorAfter, setErrorAfter] = useState<string | null>(null);
  const [sendAgainTime, setSendAgainTime] = useState('');

  const {user} = useSelector(getUserState);

  useEffect(() => {
    const resendDeleteProfile = localStorage.getItem('resendDeleteProfile');
    if (resendDeleteProfile) {
      setSendAgainTime(resendDeleteProfile);
    }
  }, []);

  const onSendAgain = () => {
    if (user?.email) {
      dispatch(resendCheckCode({email: user.email, type: EVerificationCodeTypes.UpdateEmail}))
        .then((res: { payload: any }) => {
          if (res.payload.error) {
            dispatch(openNotification({
              description: res.payload.error,
              type: 'error'
            }));
            return;
          }

          dispatch(openNotification({
            description: 'Verification Code has been sent again, please check your email.',
            type: 'success'
          }));
        });
    }
  }

  const onCancel = () => {
    dispatch(clearProfileSettingsState());
    localStorage.removeItem('resendDeleteProfile');
    // setStep(1);
    setStep(3);
  }

  const onFinish = (code: string) => {
    setErrorAfter(null);
    dispatch(checkVerificationCode({
      code,
      type: EVerificationCodeTypes.UpdateEmail
    })).then((res: { payload: any }) => {
      if (res.payload.error && !res.payload.access_after) {
        setErrorAfter(res.payload.error);
        return;
      }

      if (res.payload.error && res.payload.access_after) {
        setErrorWithTimerTime(res.payload.access_after);
        setErrorWithTimerText(res.payload.error);
        return;
      }

      localStorage.removeItem('resendDeleteProfile');
      // setStep(1);
      setStep(3);
      dispatch(getUserProfile(false));
      dispatch(openNotification({
        description: 'Email successfully changed!',
        type: 'success'
      }));
    });
  };

  return (<div className="ce-step-2">
    <VerificationCode
      discardModalText={'Are you sure you want to discard your Change Email?'}
      textBefore={'Please check your new email for your verification code and enter it below. Note that the code is only valid for 15 minutes and usable once.'}
      errorWithTimerTime={errorWithTimerTime}
      errorWithTimerText={errorWithTimerText}
      errorAfterField={errorAfter}
      sendAgainTime={sendAgainTime}
      sendAgainAction={onSendAgain}
      finishStepAction={onFinish}
      cancelStepAction={onCancel}/>
  </div>);
}
