import React, { FC, MouseEvent, SyntheticEvent, useRef, useState } from 'react';
import { Modal } from 'antd';

// Shared UI Library Components
import { CustomButton, CustomIcon, EIconName } from '@ppmcore/seven-ppm-core-shared-components-react';

// Styles
import './static-text-modal.scss';

interface IStaticTextModalProps {
  open: boolean;
  modalTitle: string;
  showFooter?: boolean;
  children: React.ReactNode;
  onSubmit?: (value?: any) => void;
  onCancel?: (value?: any) => void;
}

export const StaticTextModal: FC<IStaticTextModalProps> = (
  {
    open = false,
    modalTitle = '',
    showFooter = true,
    children,
    onSubmit = () => {},
    onCancel = () => {}
  }: IStaticTextModalProps
) => {

  const contentRef = useRef<any>(null);
  const [agreeDisabled, setAgreeDisabled] = useState(true);

  const handleScroll = (event: SyntheticEvent) => {
    const { scrollTop, scrollHeight, offsetHeight } = event.target as HTMLDivElement;
    if ((Math.abs(scrollTop) + offsetHeight + 100 < scrollHeight)) return;
    setAgreeDisabled(false);
  };

  const handleCancel = (e: MouseEvent<HTMLButtonElement>): void => {
    onCancel();
  };

  const agreeBtn = (): void => {
    onSubmit();
  }

  return (
    <Modal
      rootClassName="static-text-modal"
      width={ '920px' }
      open={ open }
      centered
      title={ modalTitle }
      closeIcon={ <CustomIcon name={ EIconName.Close }/> }
      footer={ showFooter ? [
        <CustomButton key="apply" disabled={agreeDisabled} text={ 'Agree' } onClick={ agreeBtn }/>
      ] : []}
      onCancel={ handleCancel }
    >
      <div className="modal-body">
        <div className="modal-body-item" onScroll={handleScroll}>
          <div className={"modal-body-item-inside"} ref={contentRef}>
            { children }
          </div>
        </div>
      </div>
    </Modal>
  )
};
