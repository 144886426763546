import { FC } from 'react';
import { Modal } from 'antd';

// Shared UI Library Components
import { CustomButton } from '@ppmcore/seven-ppm-core-shared-components-react';

// Styles
import './success-checkout-modal.scss';

interface IClearNotificationsModalProps {
  open: boolean;
  onSubmit?: () => void;
  onCancel?: () => void;
  children?: string | JSX.Element | JSX.Element[]
}

export const SuccessCheckoutModal: FC<IClearNotificationsModalProps> = (
  {
    open = false,
    onSubmit = () => {},
    onCancel = () => {},
    children
  }: IClearNotificationsModalProps
) => {
  const handleCancel = (): void => {
    onCancel();
  };

  const handleSubmit = (): void => {
    onSubmit();
  }

  return (
    <Modal
      rootClassName="success-checkout-modal"
      width={ '604px' }
      open={ open }
      closeIcon={ null }
      centered={ true }
      title={ 'Appointment Request Sent!' }
      footer={ [
        <CustomButton key="submit" text={ 'Ok' } onClick={ handleSubmit }/>
      ] }
      onCancel={ handleCancel }
    >
      { children }
    </Modal>
  )
};
