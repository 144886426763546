import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import { clearInterval, setInterval } from 'worker-timers';

// Styles
import './free-minutes-countdown.scss';

// Models
import { ICurrency } from '../../../interfaces/company.interfaces';
import { INextPayment } from '../../../interfaces/call.interfaces';
import { INextConversationPayment } from '../../../interfaces/text-chat.interfaces';

type TFreeMinutesCountdownProps = {
  deadline: number;
  currency: ICurrency;
  nextPayment: INextPayment | INextConversationPayment | null;
  onTimerEnd?: (state: boolean) => void;
}

export const FreeMinutesCountdown: FC<TFreeMinutesCountdownProps> = (
  {
    deadline,
    currency,
    nextPayment,
    onTimerEnd = () => {},
  }: TFreeMinutesCountdownProps
): JSX.Element => {

  const [time, setTime] = useState<number>(0);
  const expiryTimer = useRef<number | null>(null);
  const redTime = useRef<boolean>(false);

  const clearIntervalAction = (): void => {
    if (expiryTimer.current === null) return;
    try {
      clearInterval(expiryTimer.current);
    } catch (error) {
      console.log('clearIntervalAction', error);
    }
    expiryTimer.current = null;
  }

  const onTimerEndHandler = (): void => {
    if (!nextPayment) {
      onTimerEnd(true);
      return;
    }

    const nextDateTimestamp = new Date(nextPayment.finished_at).getTime();
    const currentDateTimestamp = new Date().getTime();

    if (nextDateTimestamp <= currentDateTimestamp) {
      return;
    }

    redTime.current = true;
    setUpTimerByDeadline(nextDateTimestamp);
  }

  const setUpTimerByDeadline = (deadline: number): void => {
    clearIntervalAction();

    const endTimeStamp = dayjs(deadline).unix();
    const currTimeStamp = dayjs().unix();
    const timeDiff = endTimeStamp - currTimeStamp;

    if (timeDiff <= 0) {
      setTime(0);
      onTimerEndHandler();
      return;
    }

    setTimer(timeDiff);
    setTime(timeDiff);
  }

  const transformFormat = useMemo(() => {
    if (time >= 3600) {
      return 'HH [h] mm [min] ss [sec]';
    }
    return 'mm [min] ss [sec]';
  }, [time])

  useEffect(() => {
    if (time > 0 || expiryTimer.current === null) return;
    clearIntervalAction();
    onTimerEndHandler();
  }, [time]);

  useEffect(() => {
    if (!deadline) return;
    setUpTimerByDeadline(deadline);
  }, [deadline]);

  useEffect(() => {
    return () => {
      clearIntervalAction();
    }
  }, []);

  const setTimer = (time: number) => {
    if (time > 0) {
      clearIntervalAction();
      expiryTimer.current = setInterval(() => setTime(prev => prev - 1), 1000);
    } else {
      clearIntervalAction();
      onTimerEndHandler();
    }
  };

  dayjs.extend(duration);
  dayjs.extend(relativeTime);

  const durationFormat = dayjs.duration(time, "seconds");
  const formattedTime = durationFormat.format(transformFormat);

  return <div className={ `free-minutes-countdown ${ redTime.current ? 'red-time' : '' }` }>
    <div className="free-minutes-countdown--text">
      { formattedTime }
    </div>
    <span></span>
    <div className={ 'price' }>Free</div>
  </div>;
};
