import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { useDispatch } from 'react-redux';

// Store actions
import { clearPaymentsTypes } from '../../store/payments-types/payments-types.thunks';

// Styles
import './chat-consultation.scss';

export const ChatConsultation = () => {
  const dispatch = useDispatch<any>();

  const exitFullscreen = (): void => {
    window.parent.postMessage({
      type: 'exit-fullscreen',
      data: null
    }, '*');
  }

  useEffect(() => {
    return () => {
      exitFullscreen();
      dispatch(clearPaymentsTypes());
    }
  }, []);
  return (<div className={ `chat-section` }>
    <Outlet/>
  </div>);
}
