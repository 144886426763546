import { useLayoutEffect } from "react";
import { useSelector } from 'react-redux';

// Store entities
import { getWidgetState } from '../store/widget/widget.selectors';

// Utils
import { isWidgetAppType } from '../utils/widget.utils';

/**
 * Custom React Hook for tracking window height changes.
 * @returns {void}
 */
export const useWindowHeight = (): void => {

  const isWidget = isWidgetAppType();
  const { type: widgetType } = useSelector(getWidgetState);

  const saveWindowHeightForDocument = (height: number): void => {
    document.documentElement.style.setProperty("--app-height", `${
      height - ( isWidget && widgetType === 'popup' ? 88 : 0 ) - ( isWidget && widgetType === 'embedded' ? 56 : 0 )
    }px`);
  };

  const onWindowResize = (event: Event): void => {
    saveWindowHeightForDocument(window.innerHeight);
  };

  useLayoutEffect(() => {
    window.addEventListener("resize", onWindowResize);

    saveWindowHeightForDocument(window.innerHeight);

    return () => {
      window.removeEventListener("resize", onWindowResize);
    };
  }, []);
};
