import React, { FC } from 'react';

// Styles
import './chat-list.scss';

// Components
import { ChatItem } from '../chat-item/chat-item';

// Models
import { ICallMessage } from '../../../../interfaces/chat.interfaces';

type TChatListProps = {
  messages: ICallMessage[];
}

export const ChatList: FC<TChatListProps> = ({ messages }: TChatListProps) => {

  return (
    <div className="chat-list">
      {
        messages.map((message, idx) => (
          <ChatItem key={ idx }
                    message={ message }
          />)
        )
      }
    </div>
  );
}
