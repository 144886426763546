import { createAsyncThunk } from '@reduxjs/toolkit';

// Slices & Thunks
import { NotificationsSettingsSliceActions } from './notifications-settings.slice';
import { openNotification } from '../app-notifications/app-notifications.thunks';

// Services
import { NotificationsService } from '../../services/notifications.service';

// Models
import { INotificationsSettings } from '../../interfaces/notifications-settings.interfaces';

export enum ENotificationsSettingsThunks {
  FetchNotificationsSettings = 'NOTIFICATIONS_SETTINGS/openNotification',
}

export const fetchNotificationsSettings = createAsyncThunk(
  ENotificationsSettingsThunks.FetchNotificationsSettings,
  async (_, { dispatch }) => {
    dispatch(NotificationsSettingsSliceActions.loadNotificationsSettings());
    const { error, notification_settings } = await NotificationsService.fetchNotificationsSettings();
    if (!notification_settings) {
      return dispatch(NotificationsSettingsSliceActions.loadNotificationsSettingsError(error ?? 'Error'));
    }
    return dispatch(NotificationsSettingsSliceActions.loadNotificationsSettingsSuccess(notification_settings));
  }
);

export const updateNotificationsSettings = createAsyncThunk(
  ENotificationsSettingsThunks.FetchNotificationsSettings,
  async (settings: INotificationsSettings, { dispatch }) => {
    dispatch(NotificationsSettingsSliceActions.updateNotificationsSettings());
    const { error, notification_settings } = await NotificationsService.updateNotificationsSettings(settings);
    if (!notification_settings) {
      dispatch(openNotification({
        type: 'error',
        description: error ?? 'Failed to update notifications settings, try again!'
      }));
      return dispatch(NotificationsSettingsSliceActions.updateNotificationsSettingsError(error ?? 'Error'));
    }
    dispatch(openNotification({
      type: 'success',
      description: 'Changes Saved Successfully!',
    }));
    return dispatch(NotificationsSettingsSliceActions.updateNotificationsSettingsSuccess(notification_settings));
  }
);
