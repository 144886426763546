import { FC, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Modal, Form } from 'antd';

// Shared UI Library Components
import { CustomButton, CustomIcon, CustomTextArea, EIconName, RatingElement } from '@ppmcore/seven-ppm-core-shared-components-react';

// Styles
import './feedback-modal.scss';

// Store entities
import { sendFeedback } from '../../store/messages/messages.thunks';

// Models
import { TCallType } from '../../interfaces/call.interfaces';

interface IFeedbackModalModalProps {
  open: boolean;
  worker_id: string | number;
  consultation_type: TCallType;
  consultation_id: number;
  onCancel?: () => void;
}

export const FeedbackModal: FC<IFeedbackModalModalProps> = (
  {
    open, worker_id, consultation_type, consultation_id, onCancel = () => {
  }
  }: IFeedbackModalModalProps
): JSX.Element => {
  const temporaryToken = localStorage.getItem('temporary_token');
  const [form] = Form.useForm();
  const [isSending, setIsSending] = useState<boolean>(false);

  const [feedbackValue, setFeedbackValue] = useState<string>('');
  const [ratingValue, setRatingValue] = useState<number>(0);

  const dispatch = useDispatch<any>();

  const isDisabledSending = !feedbackValue.length || !ratingValue || isSending;

  const sendFeedBack = async (): Promise<void> => {
    const { rating, feedback: review_text }: { rating: number, feedback: string } = await form.validateFields();
    setIsSending(true);
    await dispatch(sendFeedback({
      rating, review_text, worker_id, consultation_type, consultation_id, temporary_token: temporaryToken ?? ''
    }));
    setIsSending(false);
    onCancel();
  }

  return (
    <Modal wrapClassName="feedback-modal"
           title="Please Leave Feedback"
           closeIcon={ <CustomIcon name={ EIconName.Close }/> }
           footer={ [
             <CustomButton key={ 'send' } text={ 'Send Feedback' } disabled={ isDisabledSending }
                           loading={ isSending }
                           onClick={ sendFeedBack }/>
           ] }
           onCancel={ onCancel }
           open={ open }>
      <Form className="feedback-modal--body" form={ form }>
        <div className="body-item">
          <Form.Item name="rating">
            <RatingElement onChange={ setRatingValue }/>
          </Form.Item>
        </div>
        <div className="body-item">
          <Form.Item name="feedback" rules={ [{ required: true }] }>
            <CustomTextArea showCount={ true } maxLength={ 1000 } onChange={ setFeedbackValue }
                            placeholder={ 'Are you satisfied with the correspondence?' }/>
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );
};
