import React, { FC } from 'react';

// Styles
import './chat-footer.scss';

type TChatFooterProps = {};

export const ChatFooter: FC<TChatFooterProps> = ({}: TChatFooterProps) => {
  return (<div className="chat-footer">
    <div className="chat-footer-inside"></div>
  </div>);
}
