import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

type Props = {
  isProtectedRouter?: boolean;
  hasToken: boolean;
  children?: React.ReactNode;
};

export const AuthGuard = ({isProtectedRouter = true, hasToken, children}: Props): any => {

  if (isProtectedRouter && !hasToken) {
    return <Navigate to={'/sign-in'} replace/>;
  }
  if (!isProtectedRouter && hasToken) {
    const urlSplit = window.location.href.split('#');
    if (urlSplit[1] && urlSplit[1] === 'terms-of-service') {
      return <Navigate to={'/terms-of-service'} replace />;
    }
    if (urlSplit[1] && urlSplit[1] === 'privacy-policy') {
      return <Navigate to={'/privacy-policy'} replace />;
    }
    return <Navigate to={'/home'} replace />;
  }

  return children ? children : <Outlet />;
};
