import { FC } from 'react';
import { Modal } from 'antd';

// Shared UI Library Components
import { CustomButton } from '@ppmcore/seven-ppm-core-shared-components-react';

// Styles
import './clear-notifications-modal.scss';

interface IClearNotificationsModalProps {
  open: boolean;
  onSubmit?: () => void;
  onCancel?: () => void;
}

export const ClearNotificationsModal: FC<IClearNotificationsModalProps> = (
  {
    open = false,
    onSubmit = () => {},
    onCancel = () => {}
  }: IClearNotificationsModalProps
) => {
  const handleCancel = (): void => {
    onCancel();
  };

  const handleSubmit = (): void => {
    onSubmit();
  }

  return (
    <Modal
      rootClassName="clear-notifications-modal"
      width={ '394px' }
      open={ open }
      closeIcon={ null }
      centered={ true }
      title={ 'Are you sure you want to clear all Notifications?' }
      footer={ [
        <CustomButton key="cancel" text={ 'No' } type={ 'text' } onClick={ handleCancel }/>,
        <CustomButton key="submit" text={ 'Yes' } onClick={ handleSubmit }/>
      ] }
      onCancel={ handleCancel }
    />
  )
};
