import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Models
import { IWebSocketDataEvent } from '../../interfaces/web-socket.interfaces';

export interface ISocketState {
  socketToken: string | null;
  lastMessage: IWebSocketDataEvent<any> | null;
  loading: boolean;
  error: string | null;
}

const initialState: ISocketState = {
  socketToken: null,
  lastMessage: null,
  loading: false,
  error: null,
};
export const socketSlice = createSlice({
  name: 'socket',
  initialState,
  reducers: {
    setSocketLastMessage: (state, { payload }: PayloadAction<{ message: IWebSocketDataEvent<any> }>) => {
      state.lastMessage = payload.message;
    },
    loadSocketToken: (state) => {
      state.loading = true;
      state.error = null;
    },
    loadSocketTokenSuccess: (state, { payload }: PayloadAction<{ token: string }>) => {
      state.socketToken = payload.token;
      state.loading = false;
      state.error = null;
    },
    loadSocketTokenFailure: (state, { payload: error }: PayloadAction<string>) => {
      state.loading = false;
      state.error = error;
    },
    clearSocketState: (state) => {
      state.socketToken = null;
      state.lastMessage = null;
      state.loading = false;
      state.error = null;
    },
  }
});

export const SocketSliceActions = socketSlice.actions;
