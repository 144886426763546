import { FC } from 'react';

// Shared UI Library Components
import { TElementMode } from '@ppmcore/seven-ppm-core-shared-components-react';

// Styles
import './stripe-footer.scss';

export interface IStripeFooterProps {
  mode?: TElementMode;
}

export const StripeFooter: FC<IStripeFooterProps> = (
  { mode = 'white' }: IStripeFooterProps
) => {

  return (
    <div className={ `stripe-footer stripe-footer-${ mode }` }>
      <div className="stripe-footer-item">Powered by <b className="bold-text">stripe</b></div>
      <div className="stripe-footer-item stripe-footer-item--divider"></div>
      <div className="stripe-footer-item">
        <a href="https://stripe.com/legal" target="_blank">Terms</a>
      </div>
      <div className="stripe-footer-item">
        <a href="https://stripe.com/privacy" target="_blank">Privacy</a>
      </div>
    </div>
  );
};
