import { minBrowsersSupport } from '../constantes/global.constants';

export const isSupportedBrowser = (): boolean => {
  const userAgent = navigator.userAgent;
  let browserName = '';
  let browserVersion = 0;

  if (/Chrome|chromium|crios/i.test(userAgent) && /Google Inc|Apple Computer/i.test(navigator.vendor)) {
    const versionMatch = userAgent.match(/(?:Chrome|chromium|crios)\/(\d+)/i);
    if (versionMatch) {
      browserVersion = parseInt(versionMatch[1], 10);
      browserName = 'Chrome';
    }
  }

  // Firefox
  if (/Firefox/.test(userAgent)) {
    const versionMatch = userAgent.match(/Firefox\/(\d+)/);
    if (versionMatch) {
      browserVersion = parseInt(versionMatch[1], 10);
      browserName = 'Firefox';
    }
  }

  // Safari
  if (/Safari/.test(userAgent) && !/Chrome/.test(userAgent)) {
    const versionMatch = userAgent.match(/Version\/(\d+)/);
    if (versionMatch) {
      browserVersion = parseInt(versionMatch[1], 10);
      browserName = 'Safari';
    }
  }

  // Opera
  if (/Opera/.test(userAgent) || /OPR/.test(userAgent)) {
    const versionMatch = userAgent.match(/(?:Opera|OPR)\/(\d+)/);
    if (versionMatch) {
      browserVersion = parseInt(versionMatch[1], 10);
      browserName = 'Opera';
    }
  }

  // Edge
  if (/Edg/.test(userAgent)) {
    const versionMatch = userAgent.match(/Edg\/(\d+)/);
    if (versionMatch) {
      browserVersion = parseInt(versionMatch[1], 10);
      browserName = 'Edge';
    }
  }

  return (browserName === 'Chrome' && browserVersion >= minBrowsersSupport.chrome)
    || (browserName === 'Firefox' && browserVersion >= minBrowsersSupport.firefox)
    || (browserName === 'Safari' && browserVersion >= minBrowsersSupport.safari)
    || (browserName === 'Opera' && browserVersion >= minBrowsersSupport.opera)
    || (browserName === 'Edge' && browserVersion >= minBrowsersSupport.edge);
};
