import React, { useState } from 'react';
import { Form } from 'antd';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// Shared UI Library Components
import {
  CustomButton,
  CustomIcon,
  CustomInput,
  defaultValidateMessages,
  DiscardModal,
  EIconName,
  InputPassword,
  passwordPattern,
  PasswordValidationTooltip, ServerErrors
} from '@ppmcore/seven-ppm-core-shared-components-react';

// Components
import { AuthLayout } from '../auth-modules/auth-layout/auth-layout';

// Thunks
import { clearAuthState, signUpUser } from '../../../store/auth/auth.thunks';

// Interfaces
import { IAuthData } from '../../../interfaces/auth.interfaces';

// Styles
import './sign-up-confirm.scss';

export const SignUpConfirm = () => {
  const [confirmForm] = Form.useForm();
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();

  const [btnDisabled, setBtnDisabled] = useState(true);
  const [discardModalOpen, setDiscardModalOpen] = useState(false);
  const [showPasswordError, setShowPasswordError] = useState(false);
  const [serverError, setServerError] = useState<string | null>(null);

  const onCancel = (value: boolean) => {
    if (value) {
      confirmForm.resetFields();
      dispatch(clearAuthState());
      localStorage.clear();
      navigate('/sign-up');
    }
    setDiscardModalOpen(false);
  }

  const onValuesChange = (changedValues: { password: string } | { password_confirmation: string } | { username: string },
                          allFormValues: { password: string | undefined, password_confirmation: string | undefined, username: string | undefined }) => {
    if (allFormValues.password !== undefined
      && allFormValues.password_confirmation !== undefined
      // && allFormValues.username !== undefined
      && allFormValues.password !== ''
      && allFormValues.password_confirmation !== ''
      // && allFormValues.username !== ''
      && new RegExp(passwordPattern).test(allFormValues.password)
      && allFormValues.password_confirmation === allFormValues.password) {
      setBtnDisabled(false);
    } else {
      setBtnDisabled(true);
    }
  };

  const onFinish = () => {
    confirmForm.validateFields().then((values) => {
      const {username, password, password_confirmation} = values;
      const userData = localStorage.getItem('signUp');
      if (userData) {
        const userDataParsed = JSON.parse(userData);
        const temporaryToken = localStorage.getItem('temporary_token');
        dispatch(signUpUser({
          first_name: userDataParsed.first_name,
          last_name: userDataParsed.last_name,
          // username: username,
          email: userDataParsed.email,
          is_anonymous: false,
          invitation_code: null,
          password, password_confirmation,
          temporary_token: temporaryToken ?? ''
        })).then((res: any) => {
          setServerError(null);
          if (res.payload?.error) {
            setBtnDisabled(true);
            setServerError(res.payload.error);
            return;
          }

          if (res.payload) {
            localStorage.removeItem('temporary_token');
            localStorage.setItem('temporaryToken', res.payload.temporary_token);
            localStorage.setItem('authVerificationResendAccess', res.payload?.resend_access);
            navigate('/account-verification');
          }
        });
      }
    }).catch((error) => {
      console.error('Form validation failed', error);
    });
  };

  return (<AuthLayout cardTitle="Sign Up" rightBarIcon="auth" cardCurrentStep={'2'} cardTotalSteps={'2'}>
    {serverError && <ServerErrors serverError={serverError} />}
    <Form
      form={confirmForm}
      className="sign-up-confirm-password"
      name="sign-up-confirm-password"
      onFinish={onFinish}
      onValuesChange={onValuesChange}
      autoComplete="off"
      validateMessages={defaultValidateMessages}>

      {/*<Form.Item*/}
      {/*  name="username"*/}
      {/*  messageVariables={{fieldLabel: 'Nickname'}}*/}
      {/*  rules={[{required: true, max: 60}]}>*/}
      {/*  <CustomInput type={'text'} placeholder={'Nickname*'} suffix={<CustomIcon name={EIconName.PersonOff}/>}/>*/}
      {/*</Form.Item>*/}

      <Form.Item
        name="password"
        messageVariables={{fieldLabel: 'Password'}}
        dependencies={['password_confirmation']}
        rules={[
          {required: true},
          ({getFieldValue}) => ({
            validator(_, value) {
              if (!value || new RegExp(passwordPattern).test(getFieldValue('password'))) {
                setShowPasswordError(false);
                return Promise.resolve();
              }
              setShowPasswordError(true);
              return Promise.reject(new Error());
            }
          })
        ]}>
        <div>
          {showPasswordError && <PasswordValidationTooltip/>}
          <InputPassword placeholder={'Password*'}/>
        </div>
      </Form.Item>

      <Form.Item
        name="password_confirmation"
        dependencies={['password']}
        messageVariables={{fieldLabel: 'Confirm Password'}}
        rules={[
          {required: true},
          ({getFieldValue}) => ({
            validator(_, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
              }
              return Promise.reject(new Error(defaultValidateMessages.passwordsDontMatch));
            }
          })]}>
        <InputPassword placeholder={'Confirm Password*'}/>
      </Form.Item>

      <div className="form-bottom">
        <CustomButton text="Cancel" type={'text'} onClick={() => setDiscardModalOpen(true)}/>
        <CustomButton htmlType={'submit'} disabled={btnDisabled} text="Sign Up"/>
      </div>
    </Form>

    <DiscardModal open={discardModalOpen}
                  onCancel={(value) => onCancel(value)}
                  title={'Are you sure you want to discard your Sign Up?'} />
  </AuthLayout>);
}
