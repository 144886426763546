import React, { FC } from 'react';

// Styles
import './history-base.scss';

interface IHistoryBaseProps {
  isExistHistory: boolean;
}

export const HistoryBase: FC<IHistoryBaseProps> = (
  { isExistHistory }: IHistoryBaseProps
) => {

  return (
    <div className="history-base">
      <div className="history-base--header">
        <div className="header-title">
          History
        </div>
        <div className="header-subtitle">
          { isExistHistory ? 'Select an Counsellor to see the history with them' : 'You don’t have history yet. Start communicating!' }
        </div>
      </div>
      <div className="history-base--img">
        <img src="/assets/icons/big/history-body.svg" alt="history-icon"/>
      </div>
    </div>
  );
};
