import React, { FC, useMemo, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

// Styles
import './active-appointments-queue.scss';

// Store entities
import { getActiveAppointmentsState } from "../../../../store/active-appointments/active-appointments.selectors";
import { getCallInfo } from "../../../../store/call/call.thunks";

// Components
import { AppointmentReminder } from "../appointment-reminder/appointment-reminder";

// Models
import { IAppointmentCallStarted } from "../../../../interfaces/appointments.interfaces";
import { ELocalStorageKeys } from "../../../../enums/storage.enums";

type TActiveAppointmentsQueueProps = {};

export const ActiveAppointmentsQueue: FC<TActiveAppointmentsQueueProps> = (
  {}: TActiveAppointmentsQueueProps
): JSX.Element => {
  const [closedByUser, setClosedByUser] = useState<boolean>(false);

  const location = useLocation()
  const { appointments } = useSelector(getActiveAppointmentsState);

  const isVisible = useMemo(() => {
    return !!appointments?.length && !location.pathname.includes('/call') && !location.pathname.includes('/conversation') && !closedByUser;
  }, [appointments, location, closedByUser]);

  const dispatch = useDispatch<any>();
  const navigate = useNavigate();

  const joinToCallHandler = (appointment: IAppointmentCallStarted): void => {
    const temporary_token = localStorage.getItem(ELocalStorageKeys.TemporaryToken) || '';

    localStorage.setItem('worker_id', `${ appointment.worker.id }`);
    localStorage.setItem('call_type', appointment.call_type);

    dispatch(getCallInfo({ call_id: appointment.call_id, temporary_token }));

    navigate(`call/devices-settings/${ appointment.call_id }`);
  }

  const closeReminderHandler = (): void => {
    setClosedByUser(true);
  }

  return (
    <div className={ `active-appointments-queue ${ isVisible ? 'visible' : '' }` }>
      { appointments.map((appointment) => <AppointmentReminder appointment={ appointment }
                                                               joinToCall={ joinToCallHandler }
                                                               closeReminder={ closeReminderHandler }
      />)
      }
    </div>
  );
};
