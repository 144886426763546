import { createSelector } from '@reduxjs/toolkit';

// Models
import { IExpertsState } from './experts.slice';
import { RootState } from '../index';

/**
 A selector function to retrieve the experts data from the store
 @function
 @param {RootState} state - The root state of the Redux store
 @returns {IExpertsState} The experts state object from the Redux store
 */
const selectExpertsState = (state: RootState): IExpertsState => state.experts;

/**
 A memoized selector function to retrieve the experts state from the store
 @function
 @type {import('@reduxjs/toolkit').OutputSelector<RootState, IExpertsState, (res: IExpertsState) => IExpertsState>}
 */
export const getExpertsState = createSelector(
  [selectExpertsState],
  (state): IExpertsState => state
);
