import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

// Components
import {
  SpinElement
} from '@ppmcore/seven-ppm-core-shared-components-react';

// Selectors
import { getStaticPagesState } from '../../store/static-pages/static-pages.selectors';

// Styles
import './static-pages.scss';

interface IStaticPagesProps {
  staticPageId: number;
  pageTitle?: string;
  isDialog?: boolean;
}

export const StaticPages: FC<IStaticPagesProps> = ({staticPageId, pageTitle, isDialog = false}: IStaticPagesProps) => {

  const {pages, loading} = useSelector(getStaticPagesState);

  const [html, setHTML] = useState({__html: ''});

  useEffect(() => {
    async function createMarkup() {
      const filteredPage = pages?.filter(item => item.id === staticPageId);

      if (filteredPage?.length) {
        let response = await fetch(filteredPage[0].link);
        const backendHtmlString = await response.text();
        return {__html: backendHtmlString};
      }

      return null;
    }

    createMarkup().then(result => result ? setHTML(result) : null);
  }, [pages, staticPageId]);

  const staticPageClass = isDialog ? 'static-page dialog-static-text' : 'static-page';

  return (
    <div className={staticPageClass}>
      {loading && <SpinElement />}
      {!loading &&
        <div className={'scroll-section'}>
          {pageTitle && <div className={'static-page-title'}>{pageTitle}</div>}
          <div className={'static-page-text'} dangerouslySetInnerHTML={html}/>
        </div>}
    </div>);
}
