import React, { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';

// Shared UI Library Components
import {
  CustomIcon,
  CustomTag,
  CustomTooltip,
  EIconName,
  EUserAccountStatus,
} from '@ppmcore/seven-ppm-core-shared-components-react';

// Styles
import './expert-about.scss';

// Components
import { ExpertProfileMenu } from '../expert-profile-menu/expert-profile-menu';

// Store entities
import { getCurrentExpertState } from '../../../../store/currentExpert/currentExpert.selectors';

// Models
import { ISpeciality, ISpecialization } from '../../../../interfaces/core.interfaces';

type TExpertAboutProps = {};

export const ExpertAbout: FC<TExpertAboutProps> = ({}: TExpertAboutProps) => {

  const { expert } = useSelector(getCurrentExpertState);

  const specialtyBySpecialisationObject: Array<{
    specialty: ISpeciality,
    specialisations: ISpecialization[]
  }> = useMemo(() => {
    return (expert?.specialities || []).reduce((acc, specialty) => (
      [...acc, {
        specialty,
        specialisations: (expert?.specializations || []).filter(specialization => specialization?.speciality_id === specialty.id)
      }]
    ), [] as Array<{
      specialty: ISpeciality,
      specialisations: ISpecialization[]
    }>);
  }, [expert?.specialities, expert?.specializations]);

  const isEmpty = !expert?.country?.flag && !expert?.languages.length && !expert?.video && !expert?.about?.length && !specialtyBySpecialisationObject?.length;

  const getUserStatusIconName = (): string => {
    if (expert?.worker_status === EUserAccountStatus.Online) {
      return EIconName.OnlineStatus;
    }
    if (expert?.worker_status === EUserAccountStatus.Offline) {
      return EIconName.OfflineStatus;
    }
    if (expert?.worker_status === EUserAccountStatus.Deactivated) {
      return EIconName.OfflineStatus;
    }
    if (expert?.worker_status === EUserAccountStatus.Busy) {
      return EIconName.OnTheCallStatus;
    }
    return EIconName.OfflineStatus;
  };

  const getUserStatusTooltipTitle = (): string => {
    if (expert?.worker_status === EUserAccountStatus.Online) {
      return 'Online';
    }
    if (expert?.worker_status === EUserAccountStatus.Offline) {
      return 'Offline';
    }
    if (expert?.worker_status === EUserAccountStatus.Deactivated) {
      return 'Offline';
    }
    if (expert?.worker_status === EUserAccountStatus.Busy) {
      return 'On the Call';
    }
    return 'Offline';
  };

  return (
    <div className={ `expert-about ${ isEmpty ? 'full-height-page' : '' }` }>

      { (expert) && <div className="expert-about--item counsellor-actions">
        <ExpertProfileMenu expert={ expert }/>
      </div> }

      { !isEmpty && <div className="expert-about--item counsellor-photo">
        <div className="item-content item-content--counsellor-photo">
          { expert?.photo
            ? <img src={ expert?.photo } alt="expert-photo"/>
            : <div className={ 'no-image' }>
              <img src="/assets/images/default-avatar.svg" alt="expert-photo"/>
            </div> }
          <CustomTooltip placement={ 'bottom' } title={ getUserStatusTooltipTitle() }
                         className={ `counsellor-badge avatar-badge-${ expert?.worker_status }` }>
            <CustomIcon size={ 'size-xll' } name={ getUserStatusIconName() }/>
          </CustomTooltip>
        </div>
      </div> }

      {/*{ expert?.video && <div className="expert-about--item">*/}
      {/*  <div className="item-label">*/}
      {/*    Preview*/}
      {/*  </div>*/}
      {/*  <div className="item-content">*/}
      {/*    <Player url={ expert?.video }/>*/}
      {/*  </div>*/}
      {/*</div> }*/}

      { (expert?.country?.flag || !!expert?.languages.length) && <div className="expert-about--item split-items">
        { expert?.country?.flag && <>
          <div className="item-label">
            Country
          </div>
          <div className="item-content item-content--country">
            <img src={ expert?.country?.flag } alt={ 'country-flag' }/>{ expert?.country?.name ?? '-' }
          </div>
        </> }

        { !!expert?.languages.length && <>
          <div className="item-label">
            Language(s)
          </div>
          <div className="item-content item-content--languages">
            { expert?.languages.map((lang, idx) => <CustomTag key={ idx }>{ lang.name }</CustomTag>
            ) }
          </div>
        </>
        }
      </div> }

      { !!expert?.about?.length && <div className="expert-about--item item-about">
        <div className="item-label">
          About
        </div>
        <div className="item-content item-content--about">
          { expert?.about }
        </div>
      </div> }

      { !!specialtyBySpecialisationObject.length && <div className="expert-about--item item-specialisations">
        <div className="item-label">
          Specialisation(s)
        </div>
        <div className="item-content item-content--specializations">
          { specialtyBySpecialisationObject.map(({ specialisations, specialty }) => <div key={ specialty.id }
                                                                                         className="specializations-item">
            <div className="specializations-item--label">
              { specialty.name }
            </div>
            { !!specialisations?.length && <div className="specializations-item--list">
              { specialisations.map((specialization) => <div key={ specialization.id } className="list-item">
                <CustomIcon name={ EIconName.Bullet }/>{ specialization.name }
              </div>) }
            </div> }
          </div>) }
        </div>
      </div> }

      { isEmpty && <div className="expert-about--empty">
        <div className="empty-img">
          <img src="/assets/icons/big/about-empty.svg" alt="empty-about-icon"/>
        </div>
        <div className="empty-subtitle">
          No profile info yet
        </div>
      </div> }
    </div>
  );
};
