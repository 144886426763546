import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

// Styles
import './chat-in-progress.scss';

// Shared UI Library Components
import { SpinElement } from '@ppmcore/seven-ppm-core-shared-components-react';

// Components
import { ChatHeader } from '../modules/chat-header/chat-header';
import { ChatFooter } from '../modules/chat-footer/chat-footer';
import { ChatContent } from '../modules/chat-content/chat-content';

// Thunks
import { clearChatStore, getChatInfo } from '../../../store/chat/chat.thunks';

// Selectors
import { getChatState } from '../../../store/chat/chat.selectors';
import { getLastMessageData } from '../../../store/socket/socket.selectors';
import { getUserState } from '../../../store/user/user.selectors';
import { getCurrencyState } from '../../../store/currency/currency.selectors';

// Enums
import { EWebSocketTypes } from '../../../enums/web-socket-types.enums';
import { ECallStatus } from '../../../enums/call-status.enums';

export const ChatInProgress = () => {
  const dispatch = useDispatch<any>();
  const { id } = useParams();
  const navigate = useNavigate();

  const { chatProcess, chatState } = useSelector(getChatState);
  const { user } = useSelector(getUserState);
  const lastMessage = useSelector(getLastMessageData);
  const { currentCurrency } = useSelector(getCurrencyState);

  useEffect(() => {
    if (!chatProcess && id) {
      dispatch(getChatInfo({ conversation_id: +id, temporary_token: localStorage.getItem('temporary_token') || '' }));
    }

    if (chatProcess?.conversation_id && chatProcess?.conversation_status === ECallStatus.Finished) {
      disconnectAction();
    }
  }, [chatProcess, id]);

  const disconnectAction = () => {
    if (!chatProcess?.conversation_id) return;

    dispatch(clearChatStore(true))
      .then(() => {
        navigate(`/conversation-finished/${ chatProcess.conversation_id }`);
      });
  }

  useEffect(() => {
    if (lastMessage?.type === EWebSocketTypes.EndConversation) {
      disconnectAction();
    }
  }, [lastMessage]);

  useEffect(() => {
    return () => {
      dispatch(clearChatStore(true));
    }
  }, []);

  return (<div className={ 'chat-in-progress' }>
    { (!chatProcess) && <SpinElement/> }

    { chatProcess && <div className={ 'chat-in-progress-inside' }>
      <ChatHeader
        currency={ currentCurrency }
        chatData={ chatProcess }
        onEndChat={ disconnectAction }/>

      { user?.user_id &&
        <ChatContent
          userData={ user }
          chatState={ chatState }
          chatData={ chatProcess }/> }

      <ChatFooter/>
    </div> }
  </div>);
}
