import React from 'react';

// Components
import { ConversationNotifications } from '../modules/conversation-notifications/conversation-notifications';

// Styles
import './chat-status-unavailable-no-payment.scss';

export const ChatStatusUnavailableNoPayment = () => {

  const workerId = localStorage.getItem('worker_id');
  const returnPathname = localStorage.getItem('return_pathname') ?? `/expert/${workerId}`;

  return (<ConversationNotifications
    title={'Availability Issue'}
    text={'The Counsellor is no longer available. You may try to contact them later.'}
    navigateAction={returnPathname} />);
}
