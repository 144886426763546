import { createSelector } from '@reduxjs/toolkit';

// Models
import { RootState } from '../index';
import { IHistoryState } from './history.slice';

/**
 A selector function to retrieve the expert data from the store
 @function
 @param {RootState} state - The root state of the Redux store
 @returns {IHistoryState} The expert state object from the Redux store
 */
const selectHistoryState = (state: RootState): IHistoryState => state.history;

/**
 A memoized selector function to retrieve the expert data from the store
 @function
 @type {import('@reduxjs/toolkit').OutputSelector<RootState, IHistoryState, (res: IHistoryState) => IHistoryState>}
 */
export const getHistoryState = createSelector(
  [selectHistoryState],
  (state): IHistoryState => state
);
