import React, { FC } from 'react';
import { useSelector } from 'react-redux';

// Styles
import './single-logo-block.scss';

// Store
import { getCoreState } from '../../../../store/core/core.selectors';
import { CustomIcon, EIconName } from '@ppmcore/seven-ppm-core-shared-components-react';

type TLogoBlockProps = {
  sidebarOpened?: boolean;
}

export const SingleLogoBlock: FC<TLogoBlockProps> = (
  {sidebarOpened = true}: TLogoBlockProps
): JSX.Element => {

  const {companyInfo: { company }} = useSelector(getCoreState);

  return <div className={'single-logo-block'}>
    <CustomIcon name={ EIconName.BusinessCenterFill }/>
    {sidebarOpened && <span>{company?.company_profile.company_name}</span>}
  </div>
};
