import React, { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

// Shared UI Library Components
import { VerificationCode } from '@ppmcore/seven-ppm-core-shared-components-react';
import { TuPowerBy } from '../modules/tu-power-by/tu-power-by';
import { TuTitle } from '../modules/tu-title/tu-title';

// Thunks
import { activateCode, activateTempUserCode, resendCode, resendTempUserCode } from '../../../store/auth/auth.thunks';
import { openNotification } from '../../../store/app-notifications/app-notifications.thunks';

// Models
import { EVerificationCodeTypes } from '../../../pages/profile-settings/profile-settings.enums';

// Styles
import './tu-second-step.scss';

interface ITuSecondStepProps {
  onSubmit?: () => void;
  onCancel?: () => void;
}

export const TuSecondStep: FC<ITuSecondStepProps> = (
  {onSubmit = () => {}, onCancel = () => {}}: ITuSecondStepProps
) => {

  const dispatch = useDispatch<any>();

  const [errorWithTimerTime, setErrorWithTimerTime] = useState<string>('');
  const [errorWithTimerText, setErrorWithTimerText] = useState<string | null>(null);
  const [errorAfter, setErrorAfter] = useState<string | null>(null);
  const [sendAgainTime, setSendAgainTime] = useState('');

  useEffect(() => {
    const tempSignUpAccessTime = localStorage.getItem('tempSignUpAccessTime');
    const tempSignUpAccessMessage = localStorage.getItem('tempSignUpAccessMessage');
    if (tempSignUpAccessTime && tempSignUpAccessMessage) {
      setErrorWithTimerTime(tempSignUpAccessTime);
      setErrorWithTimerText(tempSignUpAccessMessage);
    }
  }, []);

  useEffect(() => {
    const resendAccess = localStorage.getItem('tempSignUpResendAccess');
    if (resendAccess) {
      setSendAgainTime(resendAccess);
    }
  }, []);

  const onSendAgain = () => {
    const temporaryToken = localStorage.getItem('tempSignUpToken');
    if (temporaryToken) {
      dispatch(resendTempUserCode({temporary_token: temporaryToken, type: EVerificationCodeTypes.Authenticate}))
        .then((res: { payload: any }) => {
          if (res.payload && res.payload?.error) {
            localStorage.setItem('tempSignUpResendAccess', res.payload.access_after)
            setSendAgainTime(res.payload.access_after);
            dispatch(openNotification({
              description: res.payload.error,
              type: 'error'
            }));
            return;
          }

          localStorage.setItem('tempSignUpResendAccess', res.payload.resend_access)
          setSendAgainTime(res.payload.resend_access);
          dispatch(openNotification({
            description: res.payload.message,
            type: 'success'
          }));
        });
    }
  }

  const onErrorTimerEnd = () => {
    localStorage.removeItem('tempSignUpAccessTime');
    localStorage.removeItem('tempSignUpAccessMessage');
  }

  const sendAgainTimerEnd = () => {}

  const onCancelAction = () => {
    localStorage.clear();
    onCancel();
  }

  const onFinish = (code: string) => {
    const temporaryToken = localStorage.getItem('tempSignUpToken');
    if (temporaryToken) {
      dispatch(activateTempUserCode({
        temporary_token: temporaryToken,
        code,
        actionType: EVerificationCodeTypes.Authenticate
      }))
        .then((res: { payload: any }) => {
          if (res.payload.error && res.payload.access_after) {
            setErrorWithTimerTime(res.payload.access_after);
            setErrorWithTimerText(res.payload.error);
            localStorage.setItem('tempSignUpAccessTime', res.payload.access_after);
            localStorage.setItem('tempSignUpAccessMessage', res.payload.error);
            return;
          }

          if (res.payload && res.payload?.error) {
            setErrorAfter(res.payload.error);
            return;
          }

          localStorage.removeItem('tempSignUpAccessTime');
          localStorage.removeItem('tempSignUpAccessMessage');
          localStorage.removeItem('tempSignUpToken');
          localStorage.removeItem('tempSignUpResendAccess');
          onSubmit();
      });
    }
  };

  return (<div className="tu-second-step">
    <TuTitle currentStep={'2'} totalSteps={'2'}/>
    <VerificationCode
      discardModalText={'Are you sure you want to discard?'}
      submitBtnText={'Continue'}
      errorWithTimerTime={errorWithTimerTime}
      errorWithTimerText={errorWithTimerText}
      errorWithTimerEndAction={onErrorTimerEnd}
      errorAfterField={errorAfter}
      sendAgainTime={sendAgainTime}
      sendAgainTimerEnd={sendAgainTimerEnd}
      resetErrorAfterField={() => setErrorAfter(null)}
      sendAgainAction={onSendAgain}
      finishStepAction={onFinish}
      cancelStepAction={onCancelAction}/>
    <TuPowerBy />
  </div>);
}
