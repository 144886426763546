import React from 'react';
import { Outlet } from 'react-router-dom';

// Components
import { ProfileLeftMenu } from './modules/profile-left-menu/profile-left-menu';

// Styles
import './profile-settings.scss';

// Components
import {
  ExpertAuthWidgetNavigation
} from '../../shared/ui-components/expert/expert-auth-widget-navigation/expert-auth-widget-navigation';

export const ProfileSettings = () => {
  return (<div className="profile-settings">
    <div className={ 'profile-settings-title' }>
      <div className="title-text">
        Settings
      </div>
      <ExpertAuthWidgetNavigation/>
    </div>
    <div className={ 'profile-settings-content' }>
      <ProfileLeftMenu/>
      <div className={ 'profile-settings-inside' }>
        <Outlet/>
      </div>
    </div>
  </div>);
}
