// API
import { api } from '../configs/api/api';

// Models
import { ITransactions, ITransactionSettings } from '../interfaces/transactions.interfaces';

export const TransactionsService = {
  fetchTransactions: async (
    {
      offset,
      limit,
      show_calls,
      show_messages,
      show_audio_calls,
      show_conversations,
      sort_field,
      sort_direction,
      worker_name,
      worker_id,
      date_from,
      date_to,
      worker_code,
    }: ITransactionSettings
  ): Promise<ITransactions | null> => {
    try {
      const temporaryToken = localStorage.getItem('temporary_token');
      const params = {
        offset: offset ? offset : 0,
        limit: limit ? limit : 15,
        show_calls: show_calls === false ? 0 : 1,
        show_messages: show_messages === false ? 0 : 1,
        show_audio_calls: show_audio_calls === false ? 0 : 1,
        show_conversations: show_conversations === false ? 0 : 1,
        ...(sort_field && { order_by: sort_field }),
        ...(sort_direction && { order_type: sort_direction }),
        ...(worker_name && { worker_name }),
        ...(worker_id && { worker_id }),
        ...(date_from && { date_from }),
        ...(date_to && { date_to }),
        ...(worker_code && { worker_code }),
      };
      const axiosResponse = await api.get(`/${ temporaryToken ? 'temporary' : 'users' }/transactions`, {
        params
      });
      return axiosResponse.data;
    } catch (error) {
      return null;
    }
  },
  refundTransaction: async (payment_id: number, refund_comment: string): Promise<{
    message?: string,
    error?: string,
    status: string
  }> => {
    try {
      const axiosResponse = await api.post('/users/request-refund', {
        payment_id,
        refund_comment
      });
      return axiosResponse.data;
    } catch (error: any) {
      const res = error?.response?.data;
      if (error?.response.status === 500) {
        return { error: res?.message, status: 'error' };
      }
      return { ...res };
    }
  },
  getReceiptUrl: async (payment_id: number | string): Promise<{
    receipt_url?: string,
    message?: string,
    error?: string,
    status: string
  }> => {
    try {
      const temporaryToken = localStorage.getItem('temporary_token');
      const axiosResponse = await api.get(`/${ temporaryToken ? 'temporary' : 'users' }/receipt-url`, {
        params: { payment_id },
      });
      return axiosResponse.data;
    } catch (error: any) {
      const res = error?.response?.data;
      if (error?.response.status === 500) {
        return { error: res?.message, status: 'error' };
      }
      return { ...res };
    }
  }
}
