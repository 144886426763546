import React from 'react';
import { Outlet } from 'react-router-dom';

import { Footer } from '../../modules/footer/footer';

// Styles
import './auth.scss';

export const Auth = () => {
  return (<div className="auth">
    <Outlet />
    <Footer />
  </div>);
}
