import React, { FC, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

// Styles
import './transactions.scss';

// Components
import {
  TransactionsEmpty,
  TransactionsList,
  TransactionsTable
} from '../../shared/ui-components/transactions';

// Store entities
import { clearTransactions, getTransactions } from '../../store/transactions/transactions.thunks';
import { getTransactionsState } from '../../store/transactions/transactions.selectors';

// Utils
import { getPageSize } from '../../utils/transactions.utils';
import { getCurrentExpertState } from '../../store/currentExpert/currentExpert.selectors';

interface ITransactionsProps {
  expertPage?: boolean;
}

export const Transactions: FC<ITransactionsProps> = (
  { expertPage = false }: ITransactionsProps
) => {
  const [currentId, setCurrentId] = useState<string | undefined>();

  const { isFirstLoad, total_all } = useSelector(getTransactionsState);
  const { expert } = useSelector(getCurrentExpertState);

  const params = useParams<{ id?: string, personalLink?: string }>();
  const dispatch = useDispatch<any>();

  const pageSize = getPageSize(expertPage);
  const personalLink = useMemo(() => params?.personalLink, [params]);

  const loadTransactionsData = (worker_id: string | undefined): void => {
    dispatch(getTransactions({
      settings: { ...(worker_id && { worker_id }), limit: pageSize },
      isFirstLoad: true
    }));
  }

  const loadTransactionsDataByPersonalLink = (worker_code: string): void => {
    dispatch(getTransactions({
      settings: { worker_code, limit: pageSize },
      isFirstLoad: true
    }));
  }

  useEffect(() => {
    if (!params?.id) return;
    setCurrentId(params?.id);
  }, [params]);

  useEffect(() => {
    if (expertPage && !currentId) return;
    loadTransactionsData(currentId);
  }, [currentId]);

  useEffect(() => {
    if (!personalLink) return;
    loadTransactionsDataByPersonalLink(personalLink);
  }, [personalLink]);

  useEffect(() => {
    return () => {
      dispatch(clearTransactions());
    };
  }, []);

  return (
    <div className={ `transactions ${ expertPage ? 'transactions-counsellor' : '' }` }>
      <div className="transactions--desktop" hidden={ !total_all && !isFirstLoad }>
         <TransactionsTable worker_id={ expert?.id } expertPage={ expertPage }/>
      </div>
      <div className="transactions--mobile" hidden={ !total_all && !isFirstLoad }>
         <TransactionsList expertPage={ expertPage } worker_id={ expert?.id }/>
      </div>
      <div className="transactions--empty" hidden={ !!total_all }>
        <TransactionsEmpty expertPage={ expertPage }/>
      </div>
    </div>
  );
}
