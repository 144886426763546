import { FC } from 'react';
import { useSelector } from 'react-redux';

import { Radio } from 'antd';

// Shared UI Library Components
import { CustomIcon, EIconName } from '@ppmcore/seven-ppm-core-shared-components-react';

// Store actions
import { getPaymentsData } from '../../../store/payments/payments.selectors';

// Styles
import './cards-radio-group.scss';

const RadioGroup = Radio.Group;

export interface ICardsRadioGroupProps {
  payCard: () => void
}

export const CardsRadioGroup: FC<ICardsRadioGroupProps> = (
  { payCard }: ICardsRadioGroupProps
) => {

  const { cards, defaultCard } = useSelector(getPaymentsData);

  const deleteCard = async (cardId: string): Promise<void> => {}

  return (
    <RadioGroup rootClassName="cards-radio" defaultValue={ defaultCard }>
      {
        cards.map((card, index) => {
          return (
            <div key={ index } className="radio-group-item">
              <div className="radio-group-item-logo">
                <CustomIcon name={ card.card.brand }/>
              </div>
              <div className="radio-group-item-info">
                <div className="item-info-title">
                  Visa **** 1234
                  {
                    defaultCard === card.id &&
                      <span className="default-mark">
                          Default
                      </span>
                  }
                </div>
                <div className="item-info-expiration-date">
                  Expiration Date: 03/2024
                </div>
              </div>
              <div className="radio-group-item-actions">
                <Radio value={ card.id }/>
                <CustomIcon name={ EIconName.Delete }
                            onClick={ () => deleteCard(card.id) }
                />
              </div>
            </div>
          )
        })
      }
    </RadioGroup>
  );
};
