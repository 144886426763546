import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

// Components
import { CallNotifications } from '../modules/call-notifications/call-notifications';

// Shared UI Library Components
import {
  EIconColor,
  EIconName,
  CustomIcon,
  CustomButton,
  SpinElement
} from '@ppmcore/seven-ppm-core-shared-components-react';

// Thunks
import { clearCallFinishedStore, getCallFinishedInfo } from '../../../store/call-finished/call-finished.thunks';

// Selectors
import { getCallFinishedState } from '../../../store/call-finished/call-finished.selectors';

// Styles
import './call-finished.scss';

export const CallFinished = () => {

  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const { id } = useParams();
  const token = localStorage.getItem('token');
  const temporaryToken = localStorage.getItem('temporary_token');

  const [errorText, setErrorText] = useState<string | null>(null);
  const [showLoader, setShowLoader] = useState<boolean>(true);

  const { callFinished, error } = useSelector(getCallFinishedState);

  useEffect(() => {
    if (id) {
      if (!callFinished) {
        callFinishedTimer(+id);
        return;
      }

      if (callFinished?.call_status === 'finished') {
        setShowLoader(false);
        return;
      }

      if (+id === +callFinished.call_id) return;

      callFinishedTimer(+id);
    }
  }, [callFinished]);

  const callFinishedTimer = (id: number) => {
    dispatch(getCallFinishedInfo({ call_id: id, temporary_token: temporaryToken ?? '' }))
      .then((res: any) => {
        if (res.payload && res.payload.error) {
          setErrorText(res.payload.error);
          setShowLoader(true);
        }
      });
  }

  const finishCall = () => {
    if (!token) {
      dispatch(clearCallFinishedStore()).then(() => navigate(`/call/stay-with-us`));
      return;
    }
    localStorage.removeItem('return_pathname');
    if (callFinished) {
      dispatch(clearCallFinishedStore()).then(() => navigate(`/expert/${ callFinished?.worker?.id }/history`));
      return;
    }
    navigate(`/home`);
  }

  const navigateToLanding = (): void => {
    window.open('https://paypertok.com', '_blank');
  }

  const callTitle = callFinished?.call_type === 'audio_call' ? 'Voice Consultation with' : 'Video Consultation with';

  return (<div className={ `call-finished` }>
    { showLoader && <SpinElement/> }
    { error && errorText &&
      <CallNotifications title={ 'Call is not exists' } text={ errorText ?? '' } navigateAction={ `/home` }/> }
    { !showLoader && !error && callFinished && <div className={ 'call-finished-inside' }>
      <div className={ 'title' }>Consultation Finished</div>
      <div
        className={ 'call-with' }>{ callTitle } { callFinished.worker?.first_name } { callFinished.worker?.last_name }</div>
      <div className={ 'call-result-info' }>
        <CustomIcon name={ callFinished.call_type === 'video_call' ? EIconName.CameraOn : EIconName.MicOn }
                    color={ EIconColor.White }/>
        <div className={ 'call-result-text' }>{ callFinished.call_duration }</div>
        <div className={ 'call-divider' }></div>
        <div className={ 'call-result-text' }>{ callFinished.total }</div>
      </div>
      <div className={ 'actions-section' }>
        <CustomButton text="OK" mode={ 'dark' } onClick={ finishCall }/>
      </div>
    </div> }
    <div className={ 'power-by' }>
      <div className={ 'power-by-inside' } onClick={ navigateToLanding }>
        <div className={ 'text' }>powered by</div>
        <img src={ '/assets/images/footer-logo.svg' } alt={ 'footer-logo' }/>
      </div>
    </div>
  </div>);
}
