import { ChangeEvent, FC, KeyboardEvent, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Input } from 'antd';

// Shared UI Library Components
import { CustomIcon, EIconName, Loader, TElementMode } from '@ppmcore/seven-ppm-core-shared-components-react';

// Styles
import './chat-input.scss';

// Store
import { openNotification } from '../../../../store/app-notifications/app-notifications.thunks';

// Utils
import { isWidgetAppType } from '../../../../utils/widget.utils';

const TextArea = Input.TextArea;

interface IMessageInputProps {
  currValue: string;
  disabled?: boolean;
  showFiles?: boolean;
  loading?: boolean;
  mode?: TElementMode;
  placeholder?: string;
  sendMessage?: (message: string, files: File[]) => void;
}

export const ChatInput: FC<IMessageInputProps> = (
  {
    currValue,
    mode = 'white',
    showFiles = true,
    placeholder = 'Message',
    disabled = false,
    loading = false,
    sendMessage = () => {
    }
  }: IMessageInputProps
) => {
  const inputFileRef = useRef<HTMLInputElement>(null);

  const [inputValue, setInputValue] = useState<string>('');
  const [files, setFiles] = useState<File[]>([]);

  const dispatch = useDispatch<any>();

  const isWidget = isWidgetAppType();

  const onUploadFileHandler = (e: ChangeEvent<HTMLInputElement>): void => {
    if (!e.target.files) return;

    if (e.target.files.length > 3) {
      dispatch(openNotification({
        type: 'error',
        description: 'We can upload a maximum of 3 first files at a time'
      }));
    }

    const files = Array.from(e.target.files).slice(0, 3);

    const filesSize = files.reduce((size, file) => (size += file.size), 0);

    if (filesSize > 1048576) { // 10mb
      dispatch(openNotification({
        type: 'error',
        description: 'The maximum file size should not exceed 10 MB'
      }));
      return;
    }

    setFiles(files);

    // Clear files, if same without clear, input not emit uploading
    inputFileRef.current!.value = '';
  }

  const openUploadFileWindow = (): void => {
    inputFileRef.current?.click();
  }

  const onChangeHandler = (e: ChangeEvent<HTMLTextAreaElement>): void => {
    const currValue = e.target.value;
    setInputValue(currValue);
  }

  const onKeyDownHandler = (event: KeyboardEvent): void => {
    if (event.code === 'Enter' && !event.shiftKey) {
      event.preventDefault();
    }
    if (event.code !== 'Enter' || event.shiftKey || !inputValue.trim().length) return;
    onSendMessageHandler();
    // Enter -> send message
    // Shift + Enter -> next line
  }

  const onResizeHandler = (size: { width: number, height: number }): void => {
    document.documentElement.style.setProperty("--overHeight", `${ size.height - 55 }px`);
  }

  const onSendMessageHandler = (): void => {
    sendMessage(inputValue, files);
  }

  useEffect(() => {
    if (!currValue) {
      setInputValue('');
      setFiles([]);
    }
  }, [currValue]);

  useEffect(() => {
    return () => {
      document.documentElement.style.setProperty("--overHeight", '0px');
    };
  }, []);

  return (
    <div className={ `chat-input chat-input-${ mode }` }>
      <div className="chat-input--item">
        { showFiles && <CustomIcon name={ EIconName.AttachFile } onClick={ openUploadFileWindow }/> }
        <TextArea
          onChange={ onChangeHandler }
          onKeyDown={ onKeyDownHandler }
          onResize={ onResizeHandler }
          value={ inputValue }
          showCount={ true }
          maxLength={ 2500 }
          autoSize={ isWidget ? {
            maxRows: 3
          } : {
            maxRows: 10
          } }
          placeholder={ placeholder }
          disabled={ disabled }
        />

        { !loading &&
          <CustomIcon className={ `send-input ${ !inputValue.trim().length ? 'disabled' : '' }` }
                      name={ EIconName.Send }
                      onClick={ onSendMessageHandler }/> }

        { loading && <Loader/> }

        { showFiles && <div className="chat-input--item--files">Files { files.length }/3 (max size 10Mb each)</div> }
      </div>
      <input
        accept={ [
          'image/*',
          'application/pdf',
          '.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document'
        ].join(',') }
        ref={ inputFileRef }
        type={ 'file' }
        multiple={ true }
        hidden={ true }
        onChange={ onUploadFileHandler }/>
    </div>
  )
};
