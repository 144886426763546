import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Form, Radio } from 'antd';
import { RadioChangeEvent } from 'antd/es/radio/interface';

// Shared UI Library Components
import {
  CustomButton,
  CustomIcon,
  EIconName,
  InfoMessage,
  SpinElement
} from '@ppmcore/seven-ppm-core-shared-components-react';

// Thunks
import { fetchConversationsPaymentsTypes } from '../../../store/payments-types/payments-types.thunks';
import { openNotification } from '../../../store/app-notifications/app-notifications.thunks';
import { createChat, getChatInfo, startChat, updateChat } from '../../../store/chat/chat.thunks';

// Selectors
import { getPaymentsTypeData } from '../../../store/payments-types/payments-types.selectors';
import { getChatState } from '../../../store/chat/chat.selectors';

// Styles
import './chat-types.scss';

// Models
import { IChatProcess } from '../../../interfaces/text-chat.interfaces';

const RadioGroup = Radio.Group;

export const ChatTypes = () => {

  const [paymentsItemsForm] = Form.useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  const temporaryToken = localStorage.getItem('temporary_token');

  const { paymentsTypes, isLoad } = useSelector(getPaymentsTypeData);
  const { chatProcess, loading } = useSelector(getChatState);

  const [paymentsTypesCategory, setPaymentsTypesCategory] = useState('pay_per_minute');
  const [paymentsRadioTypes, setPaymentsRadioTypes] = useState([
    { value: 'pay_per_minute', label: 'Pay per Minute' },
    // { value: 'pay_per_session', label: 'Pay per Session' },
  ]);

  const [btnNextDisabled, setBtnNextDisabled] = useState(false);
  const [isSetupPage, setIsSetupPage] = useState(false);
  const [messageTime, setMessageTime] = useState<number | null>(null);

  const [payPerMinuteValue, setPayPerMinuteValue] = useState<string | null>(null);
  // const [payPerSessionValues, setPayPerSessionValues] = useState<ICallPerSessionRate[] | null>(null);

  // const payPerSessionValuesSorted = !payPerSessionValues ? [] : [...payPerSessionValues]
  //   .sort((a, b) => (+a?.call_duration - +b?.call_duration));

  const worker = chatProcess?.worker;

  useEffect(() => {
    const conversation_id = localStorage.getItem('conversation_id');
    if (!chatProcess?.conversation_prebooked_time && conversation_id) {
      dispatch(getChatInfo({
        conversation_id: +conversation_id,
        temporary_token: temporaryToken ?? ''
      }));
    }

    const workerId = localStorage.getItem('worker_id');
    if (!paymentsTypes && workerId) dispatch(fetchConversationsPaymentsTypes({
      workerId: +workerId,
      temporary_token: temporaryToken ?? ''
    }));
  }, [paymentsTypes, chatProcess]);

  useEffect(() => {
    if (paymentsTypes && chatProcess?.conversation_prebooked_time) {
      paymentsItemsForm.setFieldValue('rate_type', 'pay_per_minute');

      const payPerMinute = paymentsTypes.pay_per_minute.conversation_rate?.text;
      const payPerMinuteValue = paymentsTypes.pay_per_minute.conversation_rate?.value;

      if (!payPerMinute) return;

      if (payPerMinuteValue === 0) {
        setPaymentsRadioTypes(paymentsRadioTypes.filter(item => item.value !== 'pay_per_minute'));
        setPaymentsTypesCategory('pay_per_session');
        paymentsItemsForm.setFieldValue('rate_type', 'pay_per_session');
        setBtnNextDisabled(true);
      }
      setPayPerMinuteValue(payPerMinute);

      // const payPerSession = paymentsTypes.pay_per_session.audio_call_per_session_rate.length ? paymentsTypes.pay_per_session.audio_call_per_session_rate : [];
      //
      // if (payPerSession.length === 0) {
      //   setPaymentsRadioTypes(paymentsRadioTypes.filter(item => item.value !== 'pay_per_session'));
      // }

      setMessageTime(chatProcess.conversation_prebooked_time || 10);
      // setPayPerSessionValues(payPerSession);
      setIsSetupPage(true);
    }
  }, [paymentsTypes, chatProcess]);

  const onChangePayments = (value: RadioChangeEvent): void => {
    setPaymentsTypesCategory(value.target.value);
  };

  // const selectPayPerSession = (value: string): void => {
  //   paymentsItemsForm.setFieldValue('call_duration', value);
  //   setBtnNextDisabled(false);
  // }

  const onValuesChange = (changedValues: { rate_type: string },
    allFormValues: { rate_type: string | undefined, call_duration: string | undefined }): void => {
    if (allFormValues.rate_type !== undefined && allFormValues.rate_type !== '') {
      const callDuration = paymentsItemsForm.getFieldValue('call_duration');
      if (changedValues.rate_type === 'pay_per_session'
        && (callDuration === undefined || callDuration === '')) {
        setBtnNextDisabled(true);
      } else {
        setBtnNextDisabled(false);
      }
    } else {
      setBtnNextDisabled(true);
    }
  };

  const startConversationAction = async (conversation_id: number): Promise<void> => {
    const { payload } = await dispatch(startChat({
      conversation_id,
      temporary_token: temporaryToken ?? ''
    }));

    if (!!payload?.error) {
      return;
    }

    navigate(`/conversation/outgoing`);
  }

  const nextStep = (): void => {
    if (btnNextDisabled) return;

    const conversation_id = localStorage.getItem('conversation_id');

    if (!conversation_id) {
      dispatch(openNotification({
        description: 'Call ID or Call Type are not correct',
        type: 'error'
      }));
      return;
    }

    dispatch(updateChat({
      conversation_id: +conversation_id,
      temporary_token: temporaryToken || ''
    })).then((res: { payload: { conversation?: IChatProcess, error?: any } }) => {
      if (res.payload.error) {
        // dispatch(openNotification({
        //   description: res.payload.error?.error || res.payload.error?.message,
        //   type: 'error'
        // }));
        return;
      }

      const free_status = res.payload.conversation?.free_status;
      const is_required_prepay = res.payload.conversation?.is_required_prepay;

      if ((free_status === 'partly_free' && !is_required_prepay) || free_status === 'full_free') {
        startConversationAction(+conversation_id);
        return;
      }

      dispatch(getChatInfo({ conversation_id: +conversation_id, temporary_token: temporaryToken ?? '' }))
        .then(() => navigate(`/conversation/checkout`));
    });
  }

  const cancelAction = (): void => {
    const workerId = localStorage.getItem('worker_id');
    const returnPathname = localStorage.getItem('return_pathname') ?? `/expert/${ workerId }`;
    localStorage.removeItem('worker_id');
    localStorage.removeItem('conversation_id');
    localStorage.removeItem('return_pathname');
    navigate(returnPathname);
  }

  const onBack = (): void => {
    navigate('/');
  }

  const createTextChat = (): void => {
    const workerId = localStorage.getItem('worker_id');

    if (!workerId) return;

    dispatch(createChat({
      worker_id: +workerId,
      temporary_token: temporaryToken || '',
    })).then((res: any) => {
      if (res?.payload?.error) {
        dispatch(openNotification({
          description: res?.payload?.error,
          type: 'error'
        }));
        return;
      }

      localStorage.setItem('conversation_id', res.payload.conversation_id);

      dispatch(fetchConversationsPaymentsTypes({ workerId: +workerId, temporary_token: temporaryToken || '' }));
    });
  }

  useEffect(() => {
    createTextChat();
  }, []);

  return (<div className={ 'chat-types' }>
    <div className={ 'title' }><CustomIcon className={ 'back-icon' } name={ EIconName.ArrowLeft2 } onClick={ onBack }/>Select
      Payment Type
    </div>
    <div className={ 'inside-section' }>
      <div className={ 'chat-types-list' }>
        <div className={ 'chat-types-items' }>
          <Form
            form={ paymentsItemsForm }
            name="chat-types-items-form"
            onValuesChange={ onValuesChange }>

            { isSetupPage && <Form.Item
              name="rate_type">
              <RadioGroup className="item-category" onChange={ onChangePayments } options={ paymentsRadioTypes }/>
            </Form.Item> }

            { !isSetupPage && <SpinElement/> }

            { isSetupPage && paymentsTypesCategory === 'pay_per_minute' && <div>
              <div className={ 'item-per-minute' }>
                { (!worker?.full_free_service_enabled && !worker?.free_minutes_enabled) && <>
                  <span>{ payPerMinuteValue }</span>/min</> }
                { (!!worker?.full_free_service_enabled) && <span>Free</span> }
                { (!!worker?.free_minutes_enabled && !worker?.full_free_service_enabled) && <>
                  <span>{ worker.free_service_minutes }</span>min
                  for <span>Free</span>next <span>{ payPerMinuteValue }</span>/min</> }
              </div>
              { (!worker?.full_free_service_enabled) && <InfoMessage
                infoMessage={ `Please note that a pre-authorization of funds will be applied for a <b>${ messageTime }-minutes</b> connection
              duration. If the connection is shorter, the funds will be refunded automatically.` }/> }
            </div> }

            {/*{ isSetupPage && paymentsTypesCategory === 'pay_per_session' && payPerSessionValues*/ }
            {/*  &&*/ }
            {/*  <Form.Item*/ }
            {/*    name="call_duration">*/ }
            {/*    <RadioGroup className="item-per-session">*/ }
            {/*      {*/ }
            {/*        payPerSessionValuesSorted.map((item: ICallPerSessionRate, index: number) => {*/ }
            {/*          return (*/ }
            {/*            <div key={ index } className="radio-group-item"*/ }
            {/*                 onClick={ () => selectPayPerSession(item.call_duration) }>*/ }
            {/*              <div className="radio-group-item-icon">*/ }
            {/*                <CustomIcon size={ 'size-xll' } name={ EIconName.Schedule } color={ EIconColor.White }/>*/ }
            {/*              </div>*/ }
            {/*              <div className="radio-group-item-info">*/ }
            {/*                <span>{ item.call_duration }</span> min <span*/ }
            {/*                className={ 'dash-space' }>&ndash;</span><span> { item.rates.text }</span>*/ }
            {/*              </div>*/ }
            {/*              <div className="radio-group-item-actions">*/ }
            {/*                <Radio key={ index } value={ item.call_duration }/>*/ }
            {/*              </div>*/ }
            {/*            </div>*/ }
            {/*          )*/ }
            {/*        })*/ }
            {/*      }*/ }
            {/*    </RadioGroup>*/ }
            {/*  </Form.Item> }*/ }
          </Form>
        </div>

        <div className={ 'actions-section' }>
          <CustomButton type="primary" size={ 'medium' } onClick={ cancelAction }>
            Cancel
          </CustomButton>
          <CustomButton type="default" size={ 'medium' } onClick={ nextStep }
                        disabled={ btnNextDisabled || isLoad } loading={ loading }>
            { (!!worker?.full_free_service_enabled) ? 'Start' : 'Next' }
          </CustomButton>
        </div>
      </div>
    </div>
  </div>);
}
