import React, { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Components
import { ChatInput, ChatList } from '../../../../shared/ui-components/call-chat';

// Thunks & Selectors
import { getChatState } from '../../../../store/chat/chat.selectors';
import { sendConversationMessage } from '../../../../store/chat/chat.thunks';

// Styles
import './conversation-chat.scss';

type TConversationChatProps = {
  conversation_id: string | number;
};

export const ConversationChat: FC<TConversationChatProps> = ({ conversation_id }: TConversationChatProps) => {

  const dispatch = useDispatch<any>();

  const [message, setMessage] = useState<string>('');
  const { messages, loadingMessage } = useSelector(getChatState);

  const sendMessageHandler = async (message: string, files: File[]): Promise<void> => {
    setMessage(message);
    const res = await dispatch(sendConversationMessage({
      conversation_id,
      message,
      files,
      temporary_token: localStorage.getItem('temporary_token') ?? ''
    }));

    const payload = res?.payload?.payload;

    if (payload?.error) {
      return;
    }

    setMessage('');
  }

  return (<div className={ 'conversation-chat-inside' }>
    {/*<div className="conversation-chat-inside--header">*/}
    {/*  <div className="header-item header-item--title">*/}
    {/*    Chat*/}
    {/*  </div>*/}
    {/*</div>*/}
    <div className="conversation-chat-inside--body">
      <ChatList messages={ messages }/>
    </div>
    <div className="conversation-chat-inside--send">
      <ChatInput currValue={ message }
                 showFiles={ false }
                 loading={ loadingMessage }
                 sendMessage={ sendMessageHandler }
      />
    </div>
  </div>);
}
