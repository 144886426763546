import React, { FC } from 'react';

// Shared UI Library Components
import {
  CustomIcon, CustomTooltip,
  EIconColor,
  EIconName,
  InfoMessage,
  TElementMode
} from '@ppmcore/seven-ppm-core-shared-components-react';

// Styles
import './consultation-info.scss';

// Components
import { StripeFooter } from '../stripe-footer/stripe-footer';

// Models
import { TCallType, TFreeStatus, TRateType } from '../../../interfaces/call.interfaces';

export interface IConsultationInfoProps {
  rate: string;
  call_session_type?: TRateType;
  rate_time?: number | null;
  name?: string;
  type?: TCallType;
  prebooked_time?: number;
  correspondence_time?: number;
  mode?: TElementMode;
  free_status?: TFreeStatus;
  free_service_minutes?: number;
  isPrepay?: boolean,
}

export const ConsultationInfo: FC<IConsultationInfoProps> = (
  {
    rate,
    call_session_type,
    rate_time,
    name = 'Counsellor',
    type = 'video_call',
    prebooked_time = 10,
    correspondence_time = 72,
    mode = 'white',
    free_status = 'not_free',
    free_service_minutes = 0,
    isPrepay = false
  }: IConsultationInfoProps
) => {

  const getTitleType = (): { icon: string, text: string } => {
    const types = {
      conversation: { icon: EIconName.Chat, text: 'Text Chat' },
      video_call: { icon: EIconName.CameraOn, text: 'Video Consultation' },
      audio_call: { icon: EIconName.Call, text: 'Voice Consultation' },
    };

    return types[type as keyof typeof types] || { icon: EIconName.Message, text: 'Correspondence' };
  }

  const getInfoMessage = (): string => {
    if (call_session_type === 'pay_per_session' && isPrepay) {
      return `Please note that a pre-authorization of funds will be applied for a call duration <b>${ rate_time }-minutes</b>. If the call is rejected, the funds will be refunded automatically.`;
    }
    if (call_session_type === 'pay_per_session' && !isPrepay) {
      return `Please note that a pre-authorization of funds will be applied for a call duration <b>${ rate_time }-minutes</b>. The funds will be charged immediately after payment.`;
    }
    if (type === 'video_call' || type === 'audio_call') {
      return `Please note that a pre-authorization of funds will be applied for a <b>${ prebooked_time }-minutes</b> call duration. If the call is shorter, the funds will be refunded automatically.`;
    }
    if (type === 'conversation') {
      return `Please note that a pre-authorization of funds will be applied for a <b>${ prebooked_time }-minutes</b> connection duration. If the connection is shorter, the funds will be refunded automatically.`;
    }
    return `The Counsellor has a period of <b>${ correspondence_time } hours</b> to provide you with an answer. If the answer is not provided within this timeframe, the full amount of money you spent on the message will be refunded to you.`;
  }

  return (
    <div className={ `consultation-info consultation-info-${ mode }` }>
      <div className="consultation-info--title">
        <CustomIcon name={ getTitleType().icon }/> { getTitleType().text }
      </div>
      <div className="consultation-info--subtitle">
        with { name }
      </div>
      { (!rate_time && free_status === 'not_free') && <div className="consultation-info--price">
        <b className="bold-text">{ rate }</b>/{ type === 'message' ? 'request' : 'min' }
      </div> }
      { (!rate_time && free_status === 'partly_free') && <div className="consultation-info--price">
        <b className="bold-text">{ free_service_minutes }</b>min for <b className="bold-text">Free</b> next <b
        className="bold-text">{ rate }</b> /min
      </div> }
      { (!rate_time && free_status === 'full_free') && <div className="consultation-info--price">
        <b className="bold-text">Free</b>
      </div> }

      { (rate_time && free_status === 'not_free') && <div className="consultation-info--price-per-session">
        <div className="price-per-session-icon">
          <CustomIcon size={ 'size-xll' } name={ EIconName.Schedule } color={ EIconColor.White }/>
        </div>
        <div className="price-per-session-info">
          <span>{ rate_time }</span> min <span className={ 'dash-space' }>&ndash;</span><span> { rate }</span>
        </div>
      </div> }

      { (rate_time && free_status === 'partly_free') && <div className="consultation-info--price-per-session">
        <div className="price-per-session-icon">
          <CustomIcon size={ 'size-xll' } name={ EIconName.Schedule } color={ EIconColor.White }/>
        </div>
        <div className="price-per-session-info">
          <span>{ rate_time }</span> min <span
          className="tooltip-span">+ { free_service_minutes }<CustomTooltip
          title={ `Session has ${ free_service_minutes } minutes for Free` }
          placement={ 'top' } overlayClassName={ 'white-tooltip' }>
                              <CustomIcon name={ EIconName.Info } size={ 'size-sm' }/>
                            </CustomTooltip></span>
          <span className={ 'dash-space' }>&ndash;</span><span> { rate }</span>
        </div>
      </div> }

      { (rate_time && free_status === 'full_free') && <div className="consultation-info--price-per-session">
        <div className="price-per-session-icon">
          <CustomIcon size={ 'size-xll' } name={ EIconName.Schedule } color={ EIconColor.White }/>
        </div>
        <div className="price-per-session-info">
          <span>{ rate_time }</span> min <span className={ 'dash-space' }>&ndash;</span><span>Free</span>
        </div>
      </div> }

      <div className="consultation-info--reminder">
        <InfoMessage infoMessage={ getInfoMessage() } mode={ mode }/>
      </div>
      <div className="consultation-info--footer">
        <StripeFooter mode={ mode }/>
      </div>
    </div>
  );
};
