import { createAsyncThunk } from '@reduxjs/toolkit';

// Services
import { ProfileSettingsService } from '../../services/profile-settings.service';

// Slices
import { profileSettingsSliceActions } from './profile-settings.slice';

export enum EProfileSettingsThunks {
  CheckOldPassword = 'PROFILE_SETTINGS/checkOldPassword',
  ChangePassword = 'PROFILE_SETTINGS/changePassword',
  CheckCurrentEmail = 'PROFILE_SETTINGS/checkCurrentEmail',
  CheckVerificationCode = 'PROFILE_SETTINGS/checkVerificationCode',
  ResendCheckCode = 'PROFILE_SETTINGS/resendCheckCode',
  UpdateEmail = 'PROFILE_SETTINGS/updateEmail',
  SetInProgressState = 'PROFILE_SETTINGS/setInProgressState',
  ClearProfileSettingsState = 'PROFILE_SETTINGS/clearProfileSettingsState',
}

export const checkOldPassword = createAsyncThunk(
  EProfileSettingsThunks.CheckOldPassword,
  async (old_password: string, {dispatch}: any) => {
    dispatch(profileSettingsSliceActions.checkOldPasswordRequest());
    const res = await ProfileSettingsService.verifyOldPassword(old_password);
    if (res.response && res.response.status !== 200) {
      dispatch(profileSettingsSliceActions.checkOldPasswordFailure());
      return res.response.data;
    }
    dispatch(profileSettingsSliceActions.checkOldPasswordSuccess());
    return res.data;
  }
);

export const changePassword = createAsyncThunk(
  EProfileSettingsThunks.ChangePassword,
  async ({new_password, new_password_confirmation}: { new_password: string; new_password_confirmation: string; }, {dispatch}: any) => {
    dispatch(profileSettingsSliceActions.changePasswordRequest());
    const res = await ProfileSettingsService.updatePassword(new_password, new_password_confirmation);
    if (res.response && res.response.status !== 200) {
      dispatch(profileSettingsSliceActions.changePasswordFailure());
      return res.response.data;
    }
    dispatch(profileSettingsSliceActions.changePasswordSuccess());
    return res.data;
  }
);

export const checkCurrentEmail = createAsyncThunk(
  EProfileSettingsThunks.CheckCurrentEmail,
  async (email: string, {dispatch}: any) => {
    dispatch(profileSettingsSliceActions.checkCurrentRequest());
    const res = await ProfileSettingsService.checkCurrentEmail(email);
    if (res.response && res.response.status !== 200) {
      dispatch(profileSettingsSliceActions.checkCurrentFailure());
      return res.response.data;
    }
    dispatch(profileSettingsSliceActions.checkCurrentSuccess());
    return res.data;
  }
);

export const checkVerificationCode = createAsyncThunk(
  EProfileSettingsThunks.CheckVerificationCode,
  async ({code, type}: {code: string, type: string}, {dispatch}: any) => {
    dispatch(profileSettingsSliceActions.checkCodeRequest());
    const res = await ProfileSettingsService.checkVerificationCode(code, type);
    if (res.response && res.response.status !== 200) {
      dispatch(profileSettingsSliceActions.checkCodeFailure(res.response.data.error));
      return res.response.data;
    }
    dispatch(profileSettingsSliceActions.checkCodeSuccess());
    return res.data;
  }
);

export const resendCheckCode = createAsyncThunk(
  EProfileSettingsThunks.ResendCheckCode,
  async ({email, type}: {email: string, type: string}, {dispatch}: any) => {
    dispatch(profileSettingsSliceActions.resendCheckCodeRequest());
    const res = await ProfileSettingsService.resendVerificationCode(email, type);
    if (res.response && res.response.status !== 200) {
      dispatch(profileSettingsSliceActions.resendCheckCodeFailure(res.response.data.error));
      return res.response.data;
    }
    dispatch(profileSettingsSliceActions.resendCheckCodeSuccess(res.data));
    return res.data;
  }
);

export const updateEmail = createAsyncThunk(
  EProfileSettingsThunks.UpdateEmail,
  async ({email, type}: {email: string, type: string}, {dispatch}: any) => {
    dispatch(profileSettingsSliceActions.updateEmailRequest());
    const res = await ProfileSettingsService.updateEmail(email, type);
    if (res.response && res.response.status !== 200) {
      dispatch(profileSettingsSliceActions.updateEmailFailure(res.response.data.error));
      return res.response.data;
    }
    dispatch(profileSettingsSliceActions.updateEmailSuccess(res.data));
    return res.data;
  }
);

export const setInProgressState = createAsyncThunk(
  EProfileSettingsThunks.SetInProgressState,
  async (status: boolean, {dispatch}: any) => {
    dispatch(profileSettingsSliceActions.setInProgress({inProgress: status}));
  }
);

export const clearProfileSettingsState = createAsyncThunk(
  EProfileSettingsThunks.ClearProfileSettingsState,
  async (_, {dispatch}: any) => {
    dispatch(profileSettingsSliceActions.clearProfileSettingsState());
  }
);
