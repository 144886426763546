// API
import { api } from '../configs/api/api';

// Models
import { ICallMessage, IChatInfo, IFeedbackInfo, IMessageItem } from '../interfaces/chat.interfaces';
import { IHistory } from '../interfaces/history.interfaces';

export const ChatsService = {
  fetchChatByWorkerId: async (worker_id: string | number, temporary_token: string): Promise<{
    data?: { chat: IChatInfo, message: string, status: string }, error?: any
  }> => {
    try {
      const axiosResponse = await api.get(`/${ temporary_token ? 'temporary' : 'users' }/chats/info`, {
        params: { worker_id }
      });
      return { data: axiosResponse.data };
    } catch (error: any) {
      const res = error?.response?.data;
      if (error?.response.status === 500) {
        return { error: res?.message };
      }
      return { ...res };
    }
  },
  fetchMessagesList: async (chat_id: string | number, offset: number = 0, limit: number = 20, temporary_token: string): Promise<{
    data?: { chat_messages: IMessageItem[], total: number }, error?: any
  }> => {
    try {
      const axiosResponse = await api.get(`/${ temporary_token ? 'temporary' : 'users' }/chats/messages`, {
        params: { chat_id, offset, limit }
      });
      return { data: axiosResponse.data };
    } catch (error: any) {
      const res = error?.response?.data;
      if (error?.response.status === 500) {
        return { error: res?.message };
      }
      return { ...res };
    }
  },
  sendPaidMessage: async (
    chat_id: number | string,
    message: string,
    files: Array<File | string>,
    temporary_token: string,
    payment_method_nonce?: string,
    route_type?: 'chat' | 'history',
    resent_message_id?: number
  ): Promise<{
    data?: { chat: IChatInfo, chat_messages: IMessageItem },
    history?: IHistory,
    error?: any
  }> => {
    try {
      const axiosResponse = await api.post(`/${ temporary_token ? 'temporary' : 'users' }/chats/replay`, {
        chat_id,
        message, ...(payment_method_nonce && { payment_method_nonce }),
        route_type, ...(resent_message_id && { resent_message_id })
      });
      return { data: axiosResponse.data, history: axiosResponse.data?.history };
    } catch (error: any) {
      const res = error?.response?.data;
      if (error?.response.status === 500) {
        return { error: res?.message };
      }
      return { ...res };
    }
  },
  sendCallMessage: async (call_id: string | number, message: string, files: File[], temporary_token: string): Promise<{
    data?: ICallMessage,
    error?: any
  }> => {
    try {
      const axiosResponse = await api.post(`/${ temporary_token ? 'temporary' : 'users' }/chat-message`, {
        call_id, message
      });
      return { data: axiosResponse.data };
    } catch (error: any) {
      const res = error?.response?.data;
      if (error?.response.status === 500) {
        return { error: res?.message };
      }
      return { ...res };
    }
  },
  sendConversationMessage: async (conversation_id: string | number, message: string, files: File[], temporary_token: string): Promise<{
    data?: ICallMessage,
    error?: any
  }> => {
    try {
      const axiosResponse = await api.post(`/${ temporary_token ? 'temporary' : 'users' }/conversation-message`, {
        conversation_id, message
      });
      return { data: axiosResponse.data };
    } catch (error: any) {
      const res = error?.response?.data;
      if (error?.response.status === 500) {
        return { error: res?.message };
      }
      return { ...res };
    }
  },
  markAsReadMessage: async (chat_id: string | number, temporary_token: string): Promise<{
    message?: string,
    error?: string
  }> => {
    try {
      const axiosResponse = await api.post(`/${ temporary_token ? 'temporary' : 'users' }/chats/mark-read`, {
        chat_id
      });
      return axiosResponse.data;
    } catch (error: any) {
      const res = error?.response?.data;
      if (error?.response.status === 500) {
        return { error: res?.message };
      }
      return { ...res };
    }
  },
  fetchUnreadCount: async (temporary_token: string): Promise<{ count?: number, error?: string }> => {
    try {
      const axiosResponse = await api.get(`/${ temporary_token ? 'temporary' : 'users' }/chats/unread-count`);
      return axiosResponse.data;
    } catch (error: any) {
      const res = error?.response?.data;
      if (error?.response.status === 500) {
        return { error: res?.message };
      }
      return { ...res };
    }
  },
  sendFeedback: async (feedbackInfo: IFeedbackInfo): Promise<{
    data?: { message: string, status: string },
    error?: any
  }> => {
    try {
      const axiosResponse = await api.post(`/${ feedbackInfo.temporary_token ? 'temporary' : 'users' }/send-review`, feedbackInfo);
      return { data: axiosResponse.data };
    } catch (error: any) {
      const res = error?.response?.data;
      if (error?.response.status === 500) {
        return { error: res?.message };
      }
      return { ...res };
    }
  }
}


