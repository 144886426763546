import React, { FC } from 'react';

// Shared UI Library Components
import {
  CustomIconBig,
  EIconBigName,
} from '@ppmcore/seven-ppm-core-shared-components-react';

// Styles
import './subscriber.scss';

// Interfaces
import { IWorker } from '../../../../interfaces/call.interfaces';

interface ISubscriberProps {
  workerData?: IWorker;
}

export const Subscriber: FC<ISubscriberProps> = (
  { workerData }: ISubscriberProps
) => {

  return (<div className={ 'subscriber-audio' }>
    <div className={ 'subscriber-section' }/>
    <div
      className={ 'subscriber-image' }>
      { workerData?.photo
        ? <img src={ workerData?.photo } alt=""/>
        : <div className={ 'default-image' }>
          <CustomIconBig name={ EIconBigName.NoVideoAvatar }/>
        </div> }
    </div>
    <div className={ 'subscriber-fio' }>
      <div className="subscriber-fio--name">
        { workerData?.first_name } { workerData?.last_name }
      </div>
    </div>
  </div>);
}
