import { createAsyncThunk } from '@reduxjs/toolkit';

// Services
import { ExpertsService } from '../../services/experts.service';

// Slices & Thunks
import { ExpertsSliceActions, TExpertsList } from './experts.slice';
import { CurrentExpertSliceActions } from '../currentExpert/currentExpert.slice';

// Models
import { IExpertFilters, IExpertProfile } from '../../interfaces/experts.interfaces';

export enum EExpertsThunks {
  FetchExpertsList = 'EXPERTS/fetchExpertsList',
  SetExpertsFilters = 'EXPERTS/setExpertsFilters',
  ChangeBookmarkTotal = 'EXPERTS/changeBookmarkTotal',
  UpdateExpertStatusFromSocket = 'EXPERTS/updateExpertStatusFromSocket',
  DeleteExpertStatusFromSocket = 'EXPERTS/deleteExpertStatusFromSocket',
  UpdateExpertMessagesHasUnreadState = 'EXPERTS/updateExpertMessagesHasUnreadState',
}

export const fetchExpertsList = createAsyncThunk(
  EExpertsThunks.FetchExpertsList,
  async ({ filters, type, isLoadMore = false }: {
    filters: IExpertFilters, type: TExpertsList, isLoadMore?: boolean
  }, { dispatch }) => {
    dispatch(ExpertsSliceActions.loadExpertsList({ type }));
    const experts = await ExpertsService.fetchExpertsList(filters, type);
    if (!experts) {
      return dispatch(ExpertsSliceActions.loadExpertsListError({ type }));
    }
    return dispatch(ExpertsSliceActions.loadExpertsListSuccess({
      list: experts.experts,
      total: experts.total,
      total_all: experts.total_all,
      type,
      isLoadMore
    }));
  }
);

export const fetchExpertsListTempUser = createAsyncThunk(
  EExpertsThunks.FetchExpertsList,
  async ({ filters, type, company_code, isLoadMore = false }: {
    filters: IExpertFilters, type: TExpertsList, company_code: string, isLoadMore?: boolean
  }, { dispatch }) => {
    dispatch(ExpertsSliceActions.loadExpertsList({ type }));
    const experts = await ExpertsService.fetchExpertsListTempUser(filters, company_code);
    if (!experts) {
      return dispatch(ExpertsSliceActions.loadExpertsListError({ type }));
    }
    return dispatch(ExpertsSliceActions.loadExpertsListSuccess({
      list: experts.experts,
      total: experts.total,
      total_all: experts.total_all,
      type,
      isLoadMore
    }));
  }
);

export const updateExpertStatusFromSocket = createAsyncThunk(
  EExpertsThunks.UpdateExpertStatusFromSocket,
  async (data: IExpertProfile, { dispatch }) => {
    dispatch(CurrentExpertSliceActions.updateExpertStatusFromSocket(data));
    return dispatch(ExpertsSliceActions.updateExpertStatusFromSocket(data as any));
  }
);

export const deleteExpertStatusFromSocket = createAsyncThunk(
  EExpertsThunks.DeleteExpertStatusFromSocket,
  async (data: IExpertProfile, { dispatch, getState }) => {
    const currentState = getState() as any;
    const currentCounsellor = currentState?.currentExpert;
    const isExistCounsellor = currentCounsellor?.expert?.id === data.id;
    if (isExistCounsellor) {
      dispatch(CurrentExpertSliceActions.deleteExpertStatusFromSocket());
    }
    dispatch(ExpertsSliceActions.deleteExpertStatusFromSocket(data as any));
    return isExistCounsellor;
  }
);

export const updateExpertMessagesHasUnreadState = createAsyncThunk(
  EExpertsThunks.UpdateExpertMessagesHasUnreadState,
  async ({ expertId, has_unread_messages = false }: {
    expertId: number | string,
    has_unread_messages?: boolean
  }, { dispatch }) => {
    return dispatch(ExpertsSliceActions.updateExpertMessagesHasUnreadState({ expertId, has_unread_messages }));
  }
);

export const setExpertsFilters = createAsyncThunk(
  EExpertsThunks.SetExpertsFilters,
  ({ filters, type, company_code, isLoadMore }: {
    filters: IExpertFilters,
    type: TExpertsList,
    company_code: string | null,
    isLoadMore?: boolean,
  }, { dispatch }) => {
    if (company_code) {
      dispatch(fetchExpertsListTempUser({ filters: { ...filters, offset: 0 }, type, company_code, isLoadMore }));
    } else {
      dispatch(fetchExpertsList({ filters: { ...filters, offset: 0 }, type, isLoadMore }));
    }

    return dispatch(ExpertsSliceActions.setExpertsFilters({ ...filters, offset: 0 }));
  }
);

export const changeBookmarkTotal = createAsyncThunk(
  EExpertsThunks.ChangeBookmarkTotal,
  (bookmarked: boolean, { dispatch }) => {
    return dispatch(ExpertsSliceActions.changeBookmarkTotal(bookmarked));
  }
);
