import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Models
import {
  ICardItem,
  ICreatedPaymentCard,
  IPaymentsInfo
} from '../../interfaces/payments.interfaces';

export interface IPaymentsState {
  cards: ICardItem[],
  defaultCard: string | null,
  isLoad: boolean,
  isError: boolean,
}

const initialState: IPaymentsState = {
  cards: [],
  defaultCard: null,
  isLoad: false,
  isError: false,
};

export const paymentsSlice = createSlice({
  initialState,
  name: 'payments',
  reducers: {
    loadPaymentsRequest: (state): void => {
      state.isLoad = true;
      state.isError = false;
    },
    loadPaymentsRequestSuccess: (state, { payload: { cards, selected_card } }: PayloadAction<IPaymentsInfo>): void => {
      state.cards = cards;
      state.defaultCard = selected_card ?? '';
      state.isLoad = false;
      state.isError = false;
    },
    loadPaymentsRequestError: (state): void => {
      state.isLoad = false;
      state.isError = true;
    },
    markDefault: (state, { payload }: PayloadAction<{ selected_card: string }>): void => {
      state.defaultCard = payload.selected_card;
    },
    addNewCard: (state, { payload: { card } }: PayloadAction<{ card: ICreatedPaymentCard }>): void => {
      if (!state.cards.length) {
        state.defaultCard = card.card.id;
      }
      state.cards = [...state.cards, card.card];
    },
    deleteCard: (state, { payload }: PayloadAction<{ selected_card?: string, deleted_card: string }>): void => {
      state.cards = state.cards.filter(card => card.id !== payload.deleted_card);
      state.defaultCard = payload.selected_card ?? null;
    }
  }
});

export const PaymentsSliceActions = paymentsSlice.actions;
