// API
import { api } from '../configs/api/api';

// Models
import { IExpertFilters, IExpertItem, IExpertProfile } from '../interfaces/experts.interfaces';
import { TExpertsList } from '../store/experts/experts.slice';

export const ExpertsService = {
  fetchExpertData: async (
    worker_id: number | string
  ): Promise<{ worker_profile?: IExpertProfile, error?: any }> => {
    try {
      const axiosResponse = await api.get('/users/worker-profile', { params: { worker_id } });
      return { worker_profile: axiosResponse.data };
    } catch (error) {
      return { error };
    }
  },
  fetchExpertTempUserData: async (
    company_code: string, worker_code: string
  ): Promise<{ worker_profile?: IExpertProfile, error?: any }> => {
    try {
      const axiosResponse = await api.get(`/non-registered/${ company_code }/${ worker_code }`);
      return axiosResponse.data;
    } catch (error: any) {
      return { error };
    }
  },
  bookmarkWorker: async (
    worker_id: number | string,
    is_bookmarked: boolean
  ): Promise<IExpertProfile | null> => {
    try {
      const axiosResponse = await api.post('/users/bookmark-worker', { worker_id, is_bookmarked });
      return axiosResponse.data;
    } catch (error) {
      return null;
    }
  },
  fetchExpertsList: async (
    {
      offset,
      limit,
      search,
      languages,
      countries,
      specialisations,
      speciality,
      is_bookmarked,
      rate_from,
      rate_to,
      is_online
    }: IExpertFilters = {
      offset: 0,
      limit: 20,
      search: '',
      languages: [],
      countries: [],
      specialisations: [],
      speciality: '',
      is_bookmarked: 0,
      is_online: 0
    },
    type: TExpertsList
  ): Promise<{ experts: IExpertItem[], total: number, total_all: number } | null> => {
    try {
      const axiosResponse = await api.get(type === 'history' ? '/users/history-workers' : '/users/workers', {
        params: {
          offset,
          limit,
          languages,
          countries,
          search: search || '',
          specializations: specialisations,
          ...(speciality && { specialities: speciality !== 'all' ? [speciality]: [] }),
          ...(rate_from && { rate_from }),
          ...(rate_to && { rate_to }),
          is_online,
          is_bookmarked,
        }
      });
      return axiosResponse.data;
    } catch (error) {
      return null;
    }
  },
  fetchExpertsListTempUser: async (
    {
      offset,
      limit,
      search,
      languages,
      countries,
      specialisations,
      speciality,
      is_bookmarked,
      rate_from,
      rate_to,
      is_online
    }: IExpertFilters = {
      offset: 0,
      limit: 20,
      search: '',
      languages: [],
      countries: [],
      specialisations: [],
      speciality: '',
      is_bookmarked: 0,
      is_online: 0
    },
    company_code: string
  ): Promise<{ experts: IExpertItem[], total: number, total_all: number } | null> => {
    try {
      const axiosResponse = await api.get(`/non-registered/${ company_code }/workers`, {
        params: {
          offset,
          limit,
          languages,
          countries,
          search: search || '',
          specializations: specialisations,
          ...(speciality && { specialities: speciality !== 'all' ? [speciality]: [] }),
          ...(rate_from && { rate_from }),
          ...(rate_to && { rate_to }),
          is_online,
          is_bookmarked
        }
      });
      return axiosResponse.data;
    } catch (error) {
      return null;
    }
  }
}
