import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../index';
import { ICurrencyState } from './currency.slice';

/**
 A selector function to retrieve the currency data from the store
 @function
 @param {RootState} state - The root state of the Redux store
 @returns {ICurrencyState} The currency state object from the Redux store
 */
const selectCurrencyState = (state: RootState): ICurrencyState => state.currency;

/**
 A memoized selector function to retrieve the currency data from the store
 @function
 @type {import('@reduxjs/toolkit').OutputSelector<RootState, ICurrencyState, (res: ICurrencyState) => ICurrencyState>}
 */
export const getCurrencyState = createSelector(
  [selectCurrencyState],
  (state): ICurrencyState => state
);
