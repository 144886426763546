/* eslint-disable no-restricted-globals */
const workercode = () => {
    let timerInterval;
    self.onmessage = function ({ data: { turn, initialSeconds } }) {
        let time = initialSeconds;
        if (turn === "off" || timerInterval) {
            clearInterval(timerInterval);
            time = 0;
        }
        if (turn === "on") {
            timerInterval = setInterval(() => {
                time += 1;
                self.postMessage({ time });
            }, 1000);
        }
    };
};

let code = workercode.toString();
code = code.substring(code.indexOf("{") + 1, code.lastIndexOf("}"));

const blob = new Blob([code], { type: "application/javascript" });
const worker_script = URL.createObjectURL(blob);

module.exports = worker_script;
