import React, { FC } from 'react';
import { Modal } from 'antd';
import { useNavigate } from 'react-router-dom';

// Shared UI Library Components
import { CustomButton, CustomIcon, EIconName } from '@ppmcore/seven-ppm-core-shared-components-react';

// Styles
import './stay-with-us-modal.scss';

interface IStayWithUsModalModalProps {
  open: boolean;
  onCancel?: () => void;
}

export const StayWithUsModal: FC<IStayWithUsModalModalProps> = (
  { open = false, onCancel = () => {} }: IStayWithUsModalModalProps
) => {

  const navigate = useNavigate();

  const handleCancel = (): void => {
    onCancel();
  };

  const handleSubmit = async (link: string): Promise<void> => {
    onCancel();
    navigate(link);
  }

  return (
    <Modal
      key={'stay-with-us'}
      centered
      rootClassName="stay-with-us-modal"
      width={'394px'}
      open={open}
      closeIcon={<CustomIcon name={EIconName.Close}/>}
      title={'Stay with Us!'}
      footer={[
        // <CustomButton key={'sign-in'} type={'text'} text={'Log In'} onClick={() => handleSubmit('/sign-in')}/>,
        // <CustomButton key={'sign-up'} type={'primary'} text={'Sign Up'} onClick={() => handleSubmit('/sign-up')}/>
        <CustomButton key={'sign-up'} type={'primary'} text={'Set Up Profile'} onClick={() => handleSubmit('/sign-up')}/>
      ]}
      onCancel={handleCancel}
    >
      <div className="modal-body">
        Create an account to stay on top of your history and make the most of our user-friendly features.
      </div>
    </Modal>
  )
};
