import { createAsyncThunk } from '@reduxjs/toolkit';

// Services
import { ExpertsService } from '../../services/experts.service';

// Slices & Thunks
import { CurrentExpertSliceActions } from './currentExpert.slice';
import { ExpertsSliceActions } from '../experts/experts.slice';
import { openNotification } from '../app-notifications/app-notifications.thunks';
import { fetchExpertsList } from '../experts/experts.thunks';

export enum ECurrentExpertThunks {
  FetchExpertData = 'CURRENT_EXPERT/fetchExpertData',
  FetchExpertDataByLink = 'CURRENT_EXPERT/fetchExpertDataByLink',
  ClearCurrentExpertState = 'CURRENT_EXPERT/clearCurrentExpertState',
  BookmarkWorker = 'CURRENT_EXPERT/bookmarkWorker',
}

export const fetchExpertData = createAsyncThunk(
  ECurrentExpertThunks.FetchExpertData,
  async (id: string | number, { dispatch }) => {
    dispatch(CurrentExpertSliceActions.loadExpertData());
    const { worker_profile, error } = await ExpertsService.fetchExpertData(id);
    if (!worker_profile) {
      dispatch(CurrentExpertSliceActions.loadExpertDataError('Error'))
      return error;
    }
    return dispatch(CurrentExpertSliceActions.loadExpertDataSuccess(worker_profile));
  }
);

export const fetchExpertTempUserData = createAsyncThunk(
  ECurrentExpertThunks.FetchExpertData,
  async ({ company_code, worker_code }: { company_code: string, worker_code: string }, { dispatch }) => {
    dispatch(CurrentExpertSliceActions.loadExpertData());
    const { worker_profile, error } = await ExpertsService.fetchExpertTempUserData(company_code, worker_code);
    if (!worker_profile) {
      dispatch(CurrentExpertSliceActions.loadExpertDataError('Error'));
      return error;
    }
    dispatch(CurrentExpertSliceActions.loadExpertDataSuccess(worker_profile));
    return worker_profile;
  }
);

export const clearCurrentExpertState = createAsyncThunk(
  ECurrentExpertThunks.ClearCurrentExpertState,
  async (_, { dispatch }) => {
    return dispatch(CurrentExpertSliceActions.clearExpert());
  }
);

export const bookmarkWorker = createAsyncThunk(
  ECurrentExpertThunks.BookmarkWorker,
  async ({ id, bookmark, isFavoritesSidebarState = false }: {
    id: string | number,
    bookmark: boolean,
    isFavoritesSidebarState?: boolean
  }, { dispatch }) => {
    const bookmarkData = await ExpertsService.bookmarkWorker(id, bookmark);
    if (!bookmarkData) {
      return dispatch(openNotification({
        type: 'error',
        description: `Failed to ${ bookmark ? 'saved' : 'removed' } the user, try again!`,
      }));
    }
    dispatch(openNotification({
      type: 'success',
      description: `User successfully ${ bookmark ? 'saved' : 'removed' }!`,
    }));
    dispatch(ExpertsSliceActions.changeBookmarkTotal(bookmark));
    if (isFavoritesSidebarState) {
      dispatch(fetchExpertsList({
        filters: { offset: 0, limit: 20, is_bookmarked: 1 },
        type: 'favorites',
        isLoadMore: false
      }));
    }
    return dispatch(CurrentExpertSliceActions.toggleBookmarkStatus(bookmark));
  }
);
