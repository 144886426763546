import { createAsyncThunk } from '@reduxjs/toolkit';

// Slices & Thunks
import { AppNotificationsSliceActions } from './app-notifications.slice';

// Models
import { INotificationsData } from '../../interfaces/notifications.interfaces';

// Utils
import { isWidgetAppType } from '../../utils/widget.utils';

export enum EAppNotificationsThunks {
  OpenNotification = 'Notifications/openNotification',
  ClearNotification = 'Notifications/clearNotification',
  CloseNotification = 'Notifications/closeNotification',
}

export const openNotification = createAsyncThunk(
  EAppNotificationsThunks.OpenNotification,
  async (data: INotificationsData, { dispatch }) => {
    const isWidget: boolean = isWidgetAppType();
    if (isWidget && data.type !== 'info') return;
    return dispatch(AppNotificationsSliceActions.openNotification(data));
  }
);

export const clearNotification = createAsyncThunk(
  EAppNotificationsThunks.ClearNotification,
  async (_, { dispatch }) => {
    return dispatch(AppNotificationsSliceActions.clearNotification());
  }
);

export const closeNotification = createAsyncThunk(
  EAppNotificationsThunks.CloseNotification,
  async (_, { dispatch }) => {
    return dispatch(AppNotificationsSliceActions.closeNotification());
  }
);
