import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Shared UI Library Components
import { ITimezoneOption } from '@ppmcore/seven-ppm-core-shared-components-react';

// Models
import { ELocalStorageKeys } from "../../enums/storage.enums";

export interface IDateState {
  timezonesOptions: ITimezoneOption[],
  localTimezone: string | null,
  selectedTimezone: string | null,
}

const initialState: IDateState = {
  timezonesOptions: [],
  localTimezone: null,
  selectedTimezone: sessionStorage.getItem(ELocalStorageKeys.Timezone),
};

export const dateSlice = createSlice({
  name: 'date',
  initialState,
  reducers: {
    setSelectedTimezone(state, { payload: timezone }: PayloadAction<string>) {
      state.selectedTimezone = timezone;
    },
    setLocalTimezone(state, { payload: timezone }: PayloadAction<string>) {
      state.localTimezone = timezone;
    },
    setTimezonesOptions(state, { payload: timezonesOptions }: PayloadAction<ITimezoneOption[]>) {
      state.timezonesOptions = timezonesOptions;
    },
  }
});

export const DateSliceActions = dateSlice.actions;
