import { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// Shared UI Library Components
import {
  CustomIcon,
  CustomIconBig,
  CustomTooltip,
  EIconBigName,
  EIconName,
  UserAvatar
} from '@ppmcore/seven-ppm-core-shared-components-react';

// Styles
import './right-menu.scss';

// Components
import { MenuList } from './children/menu-list/menu-list';
import { StayWithUsModal } from '../../../../modals/stay-with-us-modal/stay-with-us-modal';

// Store entities
import { getNotificatiosState } from '../../../../store/notifications/notifications.selectors';

// Thunks
import { getUserState } from '../../../../store/user/user.selectors';

// Models
import { ERightMenuType } from './right-menu.enums';

interface IRightMenuProps {
  currentPath: string;
  menuType?: ERightMenuType;
  withLabels?: boolean;
  onClickMenuItem?: () => void;
}

export const RightMenu: FC<IRightMenuProps> = (
  { currentPath, menuType = ERightMenuType.Client, withLabels = false, onClickMenuItem = () => {} }: IRightMenuProps
): JSX.Element => {

  const token = localStorage.getItem('token');
  const { user, loading } = useSelector(getUserState);
  const { unread_count } = useSelector(getNotificatiosState);

  const [stayWithUsModalOpen, setStayWithUsModalOpen] = useState(false);

  const navigate = useNavigate();

  const navigateToPersonalSettings = (): void => {
    navigate('/profile-settings');
  }

  const navigateToSignIn = (): void => {
    navigate('/sign-in');
  }

  const navigateToLanding = (): void => {
    window.open('https://paypertok.com', '_blank');
  }

  return (
    <aside className="right-menu">
      { token && !withLabels && <div className="right-menu-header" onClick={ navigateToPersonalSettings }>
        <UserAvatar avatarUrl={ user?.photo } loading={ loading }/>
      </div> }
      { !token && <div className="right-menu-header stay-with-us">
          <CustomTooltip title={ 'Sign In / Sign Up' } placement={ 'left' }>
            <span className="icon-item">
                <CustomIcon onClick={ navigateToSignIn } name={ EIconName.Login }/>
            </span>
          </CustomTooltip>
      </div> }
      <div className="right-menu-body">
        { token && <MenuList unreadCount={ unread_count } menuType={ menuType } withLabels={ withLabels }
                             currentPath={ currentPath }
                             onClickMenuItem={ onClickMenuItem }/> }
      </div>
      <div className="right-menu-footer">
        <CustomIconBig onClick={ navigateToLanding } name={ EIconBigName.PoweredByPPTLogoForMenu }/>
      </div>

      <StayWithUsModal open={ stayWithUsModalOpen } onCancel={ () => setStayWithUsModalOpen(false) }/>
    </aside>
  )
};
