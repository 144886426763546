import { createAsyncThunk } from '@reduxjs/toolkit';

// Shared UI Library Components
import { CTimezones } from '@ppmcore/seven-ppm-core-shared-components-react';

// Services
import { CoreService } from '../../services/core.service';

// Slices & Thunks
import { DateSliceActions } from './date.slice';

export enum EDateThunks {
  SetLocalTimeZone = 'DATE/setLocalTimeZone',
  SetSelectedTimeZone = 'DATE/setSelectedTimeZone',
  FetchTimezonesList = 'DATE/fetchTimezonesList',
}

export const setLocalTimeZone = createAsyncThunk(
  EDateThunks.SetLocalTimeZone,
  async ({ timeZone }: { timeZone: string }, { dispatch }) => {
    return dispatch(DateSliceActions.setLocalTimezone(timeZone));
  }
);

export const setSelectedTimeZone = createAsyncThunk(
  EDateThunks.SetSelectedTimeZone,
  async ({ timeZone }: { timeZone: string }, { dispatch }) => {
    return dispatch(DateSliceActions.setSelectedTimezone(timeZone));
  }
);

export const fetchTimezonesList = createAsyncThunk(
  EDateThunks.FetchTimezonesList,
  async (_, { dispatch }) => {
    const { timezones, error } = await CoreService.fetchTimezonesList();
    if (!timezones || error || !timezones?.length) {
      return dispatch(DateSliceActions.setTimezonesOptions(CTimezones.map((timezone) => ({
        ...timezone,
        label: timezone.name.replace(/_/g, ' ')
      }))));
    }
    return dispatch(DateSliceActions.setTimezonesOptions(timezones.map((timezone) => ({
      ...timezone,
      label: timezone.name.replace(/_/g, ' ')
    }))));
  }
);
