import { createAsyncThunk } from '@reduxjs/toolkit';

// Store actions
import { openNotification } from '../app-notifications/app-notifications.thunks';

// Services
import { ChatsService } from '../../services/chats.service';

// Slices & Thunks
import { addHistoryFromResend } from '../history/history.thunks';
import { MessagesSliceActions } from './messages.slice';

// Models
import { IFeedbackInfo, IMessageItem, IMessageNotification } from '../../interfaces/chat.interfaces';
import { updateExpertMessagesHasUnreadState } from '../experts/experts.thunks';

export enum EMessagesThunks {
  GetChatInfo = 'MESSAGES/getChatInfo',
  GetMessagesList = 'MESSAGES/getMessagesList',
  AddPaidMessageFromSocket = 'MESSAGES/addPaidMessageFromSocket',
  SendPaidMessage = 'MESSAGES/sendPaidMessage',
  MarkAsReadMessages = 'MESSAGES/markAsReadMessages',
  FetchUnreadCount = 'MESSAGES/fetchUnreadCount',
  SendFeedback = 'MESSAGES/sendFeedback',
  ClearMessages = 'MESSAGES/clearMessages',
}

export const fetchChatInfo = createAsyncThunk(
  EMessagesThunks.GetChatInfo,
  async ({ worker_id, temporary_token, limit }: {
    worker_id: string | number,
    temporary_token: string,
    limit?: number
  }, { dispatch }) => {
    dispatch(MessagesSliceActions.getChatInfoRequest());
    const { data } = await ChatsService.fetchChatByWorkerId(worker_id, temporary_token);
    if (!data) {
      return dispatch(MessagesSliceActions.getChatInfoRequestFailure('Error'));
    }
    dispatch(fetchMessagesList({ chat_id: data?.chat?.id, temporary_token, limit }));
    return dispatch(MessagesSliceActions.getChatInfoRequestSuccess(data?.chat));
  }
);

export const fetchMessagesList = createAsyncThunk(
  EMessagesThunks.GetMessagesList,
  async ({ chat_id, temporary_token, offset, isLoadMore, limit }: {
    chat_id: string | number,
    temporary_token: string,
    offset?: number,
    isLoadMore?: boolean,
    limit?: number,
  }, { dispatch }) => {
    dispatch(MessagesSliceActions.getMessagesListRequest());
    const { data, error } = await ChatsService.fetchMessagesList(chat_id, offset, limit, temporary_token);
    if (!data) {
      return dispatch(MessagesSliceActions.getMessagesListRequestFailure(error));
    }
    return dispatch(MessagesSliceActions.getMessagesListRequestSuccess({
      messages: data.chat_messages,
      isLoadMore,
      total: data.total
    }));
  }
);

export const addPaidMessageFromSocket = createAsyncThunk(
  EMessagesThunks.AddPaidMessageFromSocket,
  async ({ message }: { message: IMessageItem }, { dispatch }) => {
    return dispatch(MessagesSliceActions.addPaidMessageFromSocket(message));
  }
);

export const addPaidMessageNotificationFromSocket = createAsyncThunk(
  EMessagesThunks.AddPaidMessageFromSocket,
  async ({ notification }: { notification: IMessageNotification }, { dispatch }) => {
    return dispatch(MessagesSliceActions.addPaidMessageNotificationFromSocket(notification));
  }
);

export const sendPaidMessage = createAsyncThunk(
  EMessagesThunks.SendPaidMessage,
  async ({ id, message, files, pi, temporary_token, route_type = 'chat', resent_message_id }: {
    id: number | string,
    message: string,
    files: Array<File | string>,
    pi?: string,
    temporary_token: string,
    route_type?: 'chat' | 'history',
    resent_message_id?: number
  }, { dispatch }) => {
    dispatch(MessagesSliceActions.sendPaidMessageRequest());
    const {
      error,
      data,
      history
    } = await ChatsService.sendPaidMessage(id, message, files, temporary_token, pi, route_type, resent_message_id);
    if (!data || (route_type === 'history' && !history)) {
      dispatch(openNotification({
        type: 'error',
        description: error?.error ?? 'Failed to send message, reload the page and try again!'
      }));
      dispatch(MessagesSliceActions.sendPaidMessageRequestFailure(error?.error ?? 'Failed to send message, reload the page and try again!'))
      return null;
    }
    if (route_type === 'history' && history && resent_message_id) {
      dispatch(addHistoryFromResend({ history, prevId: resent_message_id }));
      return history;
    }
    dispatch(MessagesSliceActions.sendPaidMessageRequestSuccess({ data, resent_message_id }));
    return data;
  }
);

export const markAsReadMessages = createAsyncThunk(
  EMessagesThunks.MarkAsReadMessages,
  async ({ chat_id, workerId, temporary_token }: {
    chat_id: number | string,
    workerId: number | string,
    temporary_token: string
  }, { dispatch }) => {
    const { error } = await ChatsService.markAsReadMessage(chat_id, temporary_token);
    if (error) {
      dispatch(openNotification({
        type: 'error',
        description: error ?? 'Failed to mark as read messages!'
      }));
      return false;
    }
    dispatch(updateExpertMessagesHasUnreadState({ expertId: workerId }));
    return true;
  }
);

export const fetchUnreadCount = createAsyncThunk(
  EMessagesThunks.FetchUnreadCount,
  async (temporary_token: string, { dispatch }) => {
    const { error, count } = await ChatsService.fetchUnreadCount(temporary_token);
    if (error) {
      return dispatch(openNotification({
        type: 'error',
        description: error ?? 'Failed to get unread messages count!'
      }));
    }
    return count;
  }
);

export const sendFeedback = createAsyncThunk(
  EMessagesThunks.SendFeedback,
  async (feedbackInfo: IFeedbackInfo, { dispatch }) => {
    const { error, data } = await ChatsService.sendFeedback(feedbackInfo);
    if (error) {
      return dispatch(openNotification({
        type: 'error',
        description: error?.error ?? 'Failed to send feedback, reload the page and try again!'
      }));
    }
    return dispatch(openNotification({
      type: 'success',
      description: 'Feedback successfully sent'
    }));
  }
);

export const clearMessages = createAsyncThunk(
  EMessagesThunks.ClearMessages,
  async (_, { dispatch }) => {
    return dispatch(MessagesSliceActions.clearMessages());
  }
);
