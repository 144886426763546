import i18n from 'i18next';
import { initReactI18next } from "react-i18next";

// Import translations for the 'en' language.
import en from './locales/en/translation.json';

/**
 * Initialize the i18next instance for internationalization and localization.
 *
 * @memberof external:i18n
 * @namespace i18nInit
 *
 * @property {Object} interpolation - Interpolation configuration.
 * @property {boolean} interpolation.escapeValue - Set to false as React already escapes values.
 *
 * @property {string} lng - The default language.
 *
 * @property {Object} resources - Translations resources for each supported language.
 * @property {Object} resources.en - Translations for the 'en' language.
 * @property {Object} resources.en.translation - The translation object for the 'en' language.
 */
i18n.init({
  interpolation: { escapeValue: false }, // React already does escaping
  lng: 'en', // default language
  resources: {
    en: { translation: en },
  },
});

/**
 * Use the initReactI18next module to integrate i18next with React.
 *
 * @memberof external:i18n
 * @namespace initReactI18next
 */
i18n.use(initReactI18next).init();

/**
 * Export the initialized i18n instance for internationalization and localization.
 *
 * @memberof external:i18n
 * @type {external:i18n}
 */
export default i18n;
