import { createSelector } from '@reduxjs/toolkit';

// Models
import { IWidgetState } from './widget.slice';
import { RootState } from '../index';

/**
 A selector function to retrieve the widget data from the store
 @function
 @param {RootState} state - The root state of the Redux store
 @returns {IWidgetState} The widget state object from the Redux store
 */
const selectWidgetState = (state: RootState): IWidgetState => state.widget;

/**
 A memoized selector function to retrieve the widget data from the store
 @function
 @type {import('@reduxjs/toolkit').OutputSelector<RootState, IWidgetState, (res: IWidgetState) => IWidgetState>}
 */
export const getWidgetState = createSelector(
  [selectWidgetState],
  (state) => state
);
