import { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import dayjs from 'dayjs';

import { Drawer, Form, TimeRangePickerProps } from 'antd';

// Shared UI Library Components
import {
  CheckboxGroup,
  CustomButton,
  CustomIcon,
  EIconName,
  DateRangeElement
} from '@ppmcore/seven-ppm-core-shared-components-react';

// Styles
import './transactions-filters-modal.scss';

// Store
import { saveTransactionsSetting } from '../../store/transactions/transactions.thunks';

// Models
import { ITransactionSettings } from '../../interfaces/transactions.interfaces';

type TTransactionsFiltersModalProps = {
  open: boolean;
  settings: ITransactionSettings;
  userCreatedAt: string;
  onCancel?: () => void;
  onSubmit?: () => void;
  worker_id?: number;
}

type TFormValues = {
  types?: {
    show_audio_calls: boolean,
    show_calls: boolean,
    show_messages: boolean,
    show_conversations: boolean,
  },
  range?: [dayjs.Dayjs, dayjs.Dayjs],
};

export const TransactionsFiltersModal: FC<TTransactionsFiltersModalProps> = (
  { open, settings, userCreatedAt, onCancel = () => {}, onSubmit = () => {}, worker_id }: TTransactionsFiltersModalProps
): JSX.Element => {
  const [form] = Form.useForm<TFormValues>();

  const [types, setTypes] = useState<TFormValues['types']>({
    show_audio_calls: true,
    show_calls: true,
    show_messages: true,
    show_conversations: true,
  });
  const [dates, setDates] = useState<[string, string]>(['', '']);
  const [hasChanges, setHasChanges] = useState<boolean>(false);

  const dispatch = useDispatch<any>();

  const disabledDate: TimeRangePickerProps['disabledDate'] = (current) => {
    // Can not select months before company created_at
    return current < dayjs(userCreatedAt).startOf('day') || current > dayjs().endOf('day');
  };

  const typeChangeHandler = (types: any): void => {
    setTypes(types);
  }

  const rangeChangeHandler = (_: any, dates: [string, string]): void => {
    setDates(dates);
  }

  const saveSettings = (transactionsSettings: ITransactionSettings, isReset: boolean = false): void => {
    dispatch(saveTransactionsSetting({
      settings: {
        ...settings,
        ...(!isReset && transactionsSettings),
        ...(isReset && {
          show_calls: true,
          show_audio_calls: true,
          show_messages: true,
          show_conversations: true,
          date_from: undefined,
          date_to: undefined,
        }),
        ...(worker_id && { worker_id })
      }
    }));
  }

  const applyFilters = (): void => {
    saveSettings({
      ...types,
      ...(dates[0] && { date_from: dates[0] }),
      ...(dates[1] && { date_to: dates[1] }),
    });
    onSubmit();
  }

  const resetFilters = (): void => {
    saveSettings({
      ...(!!settings?.worker_name && { worker_name: settings.worker_name })
    }, true);
    form.resetFields();
    setTypes({
      show_calls: true,
      show_audio_calls: true,
      show_messages: true,
      show_conversations: true,
    });
    setHasChanges(false);
    onSubmit();
  }

  const checkIsTypeHasChanges = (typesCurrent?: TFormValues['types']): boolean => {
    if (!typesCurrent || !types) return typesCurrent === types;
    return Object.keys(typesCurrent).some(key => types[key as keyof TFormValues['types']] !== typesCurrent[key as keyof TFormValues['types']]);
  }

  const checkIsRangeHasChanges = (range?: [from: dayjs.Dayjs, to: dayjs.Dayjs]): boolean => {
    if (!range) return range === dates;
    const [from, to] = range;
    return !dayjs(from).isSame(dates[0]) || !dayjs(to).isSame(dates[1]);
  }

  const onValuesChange = (_: { [key in keyof TFormValues]: any }, { range, types }: TFormValues): void => {
    const isTypeHasChange = checkIsTypeHasChanges(types);
    const isRangeHasChange = checkIsRangeHasChanges(range);
    setHasChanges(isTypeHasChange || isRangeHasChange);
  }

  return (
    <Drawer rootClassName="transactions-filters-modal"
            title="Filters"
            closeIcon={ <CustomIcon size={ 'size-xl' } name={ EIconName.Close }/> }
            footer={ [
              <CustomButton key={ 'reset' }
                            text={ 'Reset' }
                            disabled={ !hasChanges }
                            type={ 'text' }
                            onClick={ resetFilters }/>,
              <CustomButton key={ 'apply' }
                            text={ 'Apply' }
                            disabled={ !hasChanges }
                            onClick={ applyFilters }/>
            ] }
            onClose={ onCancel }
            open={ open }>
      <Form className="transactions-filters-modal--body"
            form={ form }
            onValuesChange={ onValuesChange }
      >
        <div className="body-item">
          <div className="body-item--label">
            Consultation Type
          </div>
          <Form.Item name="types">
            <CheckboxGroup allOption={ true } onChange={ typeChangeHandler } options={ [
              { value: 'show_calls', label: 'Video Consultation' },
              { value: 'show_audio_calls', label: 'Voice Consultation' },
              { value: 'show_conversations', label: 'Text Chat' },
              { value: 'show_messages', label: 'Correspondence' },
            ] }/>
          </Form.Item>
        </div>
        <div className="body-item">
          <div className="body-item--label">
            Date Range
          </div>
          <Form.Item name="range">
            <DateRangeElement disabledDate={ disabledDate } onChange={ rangeChangeHandler }/>
          </Form.Item>
        </div>
      </Form>
    </Drawer>
  );
};
