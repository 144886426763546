import React, { FC, useState } from 'react';
import { Button, Form } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

// Shared UI Library Components
import {
  CustomIcon,
  CustomInput,
  defaultValidateMessages, EIconName, emailPattern,
  ServerErrors,
  ServerErrorsTimer
} from '@ppmcore/seven-ppm-core-shared-components-react';

// Selectors
import {
  getProfileSettings
} from '../../../../store/profile-settings/profile-settings.selectors';

// Thunks
import {
  updateEmail
} from '../../../../store/profile-settings/profile-settings.thunks';

// Enums
import { EVerificationCodeTypes } from '../../profile-settings.enums';

// Styles
import './ce-step-3.scss';

interface ICpStep3Props {
  setStep?: (value: number) => void;
}

export const CeStep3: FC<ICpStep3Props> = ({setStep = () => {}}: ICpStep3Props) => {

  const dispatch = useDispatch<any>();
  const [cpStep3] = Form.useForm();

  const [btnNextDisabled, setBtnNextDisabled] = useState(true);
  const [formDisabled, setFormDisabled] = useState(false);

  const [serverError, setServerError] = useState<string | null>(null);
  const [errorTime, setErrorTime] = useState<string | null>(null);
  const [errorTimeMessage, setErrorTimeMessage] = useState<string | null>(null);

  const {loading} = useSelector(getProfileSettings);

  const onFinish = () => {
    if (btnNextDisabled) return;
    cpStep3.validateFields().then((values) => {
      const {email} = values;
      dispatch(updateEmail({email, type: EVerificationCodeTypes.VerifyEmail})).then((res: { payload: any }) => {
        if (res.payload.error && res.payload.access_after) {
          cpStep3.resetFields();
          setBtnNextDisabled(true);
          setFormDisabled(true);
          setServerError(null);

          setErrorTime(res.payload.access_after);
          setErrorTimeMessage(res.payload.error);
          return;
        }
        if (res.payload && res.payload.error) {
          setBtnNextDisabled(true);
          setServerError(res.payload.error);
          return;
        }

        localStorage.setItem('resendDeleteProfile', res.payload?.resend_access);
        setStep(4);
      });
    }).catch((error) => {
      console.error('Form validation failed', error);
    });
  };

  const cancelAction = () => {
    cpStep3.resetFields();
    setServerError(null);
    setBtnNextDisabled(true);
    localStorage.removeItem('resendDeleteProfile');
    // setStep(1);
  }

  const onValuesChange = (changedValues: { email: string },
                          allFormValues: { email: string | undefined }) => {
    if (allFormValues.email !== undefined
      && allFormValues.email !== ''
      && new RegExp(emailPattern).test(allFormValues.email)) {
      setBtnNextDisabled(false);
    } else {
      setBtnNextDisabled(true);
    }
  };

  const onTimeEnd = () => {
    setErrorTimeMessage(null);
    setErrorTime(null);
    setFormDisabled(false);
  }

  return (<div className="ce-step-3">
    {serverError && <ServerErrors serverError={serverError}/>}
    {errorTimeMessage && errorTime &&
      <ServerErrorsTimer serverError={errorTimeMessage} errorTime={errorTime} onTimeEnd={onTimeEnd}/>}

    <Form
      form={cpStep3}
      disabled={formDisabled}
      name="form-ce-step-3"
      onFinish={onFinish}
      autoComplete="off"
      onValuesChange={onValuesChange}
      validateMessages={defaultValidateMessages}>
      <Form.Item
        name="email"
        messageVariables={{fieldLabel: 'New Email'}}
        rules={[
          {required: true},
          ({getFieldValue}) => ({
            validator(_, value) {
              if (!value || new RegExp(emailPattern).test(getFieldValue('email'))) {
                return Promise.resolve();
              }
              return Promise.reject(new Error(defaultValidateMessages.invalidNewEmailFormat));
            }
          })]}>
        <CustomInput disabled={formDisabled} shouldTrim={true} type={'text'} placeholder={'New Email*'} suffix={<CustomIcon name={EIconName.Mail}/>}/>
      </Form.Item>

      <div className="form-bottom">
        <Button type="default" size={'large'} onClick={cancelAction}
                disabled={btnNextDisabled || loading}>Cancel</Button>
        <Button type="primary" size={'large'} htmlType={'submit'}
                disabled={btnNextDisabled || loading}>Continue</Button>
      </div>
    </Form>
  </div>);
}
