import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ProfileSettingsState {
  loading: boolean;
  error: boolean;
  inProgress: boolean;
}

const initialState: ProfileSettingsState = {
  loading: false,
  error: false,
  inProgress: false,
};

export const profileSettingsSlice = createSlice({
  name: 'profileSettings',
  initialState,
  reducers: {
    checkOldPasswordRequest(state) {
      state.loading = true;
      state.error = false;
    },
    checkOldPasswordSuccess: (state) => {
      state.loading = false;
      state.error = false;
    },
    checkOldPasswordFailure: (state) => {
      state.loading = false;
      state.error = true;
    },
    changePasswordRequest(state) {
      state.loading = true;
      state.error = false;
    },
    changePasswordSuccess: (state) => {
      state.loading = false;
      state.error = false;
    },
    changePasswordFailure: (state) => {
      state.loading = false;
      state.error = true;
    },
    checkCurrentRequest(state) {
      state.loading = true;
      state.error = false;
    },
    checkCurrentSuccess: (state) => {
      state.loading = false;
      state.error = false;
    },
    checkCurrentFailure: (state) => {
      state.loading = false;
      state.error = true;
    },
    checkCodeRequest(state) {
      state.loading = true;
      state.error = false;
    },
    checkCodeSuccess: (state) => {
      state.loading = false;
      state.error = false;
    },
    checkCodeFailure: (state) => {
      state.loading = false;
      state.error = true;
    },
    resendCheckCodeRequest(state) {
      state.loading = true;
      state.error = false;
    },
    resendCheckCodeSuccess: (state) => {
      state.loading = false;
      state.error = false;
    },
    resendCheckCodeFailure: (state) => {
      state.loading = false;
      state.error = true;
    },
    updateEmailRequest(state) {
      state.loading = true;
      state.error = false;
    },
    updateEmailSuccess: (state) => {
      state.loading = false;
      state.error = false;
    },
    updateEmailFailure: (state) => {
      state.loading = false;
      state.error = true;
    },
    setInProgress(state, action: PayloadAction<{ inProgress: boolean }>) {
      state.inProgress = action.payload.inProgress;
      state.loading = false;
      state.error = false;
    },
    clearProfileSettingsState: (state) => {
      state.loading = false;
      state.error = false;
    },
  }
});

export const profileSettingsSliceActions = profileSettingsSlice.actions;
