import { FC, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

// Shared UI Library Components
import { CustomTooltip } from '@ppmcore/seven-ppm-core-shared-components-react';

// Store entities
import { getCoreState } from '../../../../store/core/core.selectors';
import { getExpertsState } from '../../../../store/experts/experts.selectors';

// Models
import { CSidebarFooterMenuList } from '../../../../configs/components/sidebar.config';
import { ISidebarState } from '../../../../store/sidebar/sidebar.slice';

// Styles
import './sidebar-menu.scss';

type TSidebarMenuProps = {
  sidebarOpened: boolean;
  currentPath: string;
  sidebarState: ISidebarState;
}

export const SidebarMenu: FC<TSidebarMenuProps> = (
  { sidebarOpened, currentPath, sidebarState }: TSidebarMenuProps
): JSX.Element => {
  const navigate = useNavigate();
  const params = useParams();

  const token = localStorage.getItem('token');

  const companyCode = useMemo(() => params?.companyCode, [params?.companyCode]);

  const [menu, setMenu] = useState(CSidebarFooterMenuList);

  const { companyInfo: { company } } = useSelector(getCoreState);
  const { experts: { list } } = useSelector(getExpertsState);

  useEffect(() => {
    const updatedMenu = CSidebarFooterMenuList.map(item => {
      item.isDisabled = company?.company_profile.is_blocked === 1 && item.path !== 'home';
      return item;
    });
    setMenu(updatedMenu);
  }, [company]);

  const changeMenuHandler = (path: string, isDisabled: boolean): void => {
    if (isDisabled) return;

    if (!token && companyCode && path === 'business-information') {
      navigate(`/${ companyCode }/business-information`);
      return;
    }
    if (!token && companyCode && path === 'home' && company?.is_individual) {
      navigate(`/${ companyCode }/${ list[0]?.personal_link || '' }`);
      return;
    }
    if (!token && companyCode && path === 'home') {
      navigate(`/${ companyCode }/${ path }`);
      return;
    }
    navigate(`/${ path }`);
  }

  const isActivePatch = (path: string): boolean => {
    return (sidebarState.type === path && !!token) ||
      (currentPath.includes('business-information') && path === 'business-information') ||
      (currentPath.includes('home') && path === 'home' && sidebarState.type === 'home') ||
      (currentPath.includes(companyCode as string) && path === 'home' && sidebarState.type === 'home' && !currentPath.includes('business-information'));
  }

  return (
    <div className={ `sidebar-menu ${ !token ? 'temp-sidebar-menu' : '' } ${ sidebarOpened ? '' : ' menu-vertical' }` }>
      {
        menu.map(({ icon, path, activeIcon, secureLink, isDisabled, label }, index) => {
          const showTempUser = !token ? !secureLink : true;
          return (showTempUser &&
            <CustomTooltip className={ `sidebar-menu-item-tooltip ` } key={ index } placement={ `${sidebarOpened ? 'top' : 'right'}` } title={ label }>
              <div className={ `sidebar-menu-item ${isDisabled ? 'disabled-item' : ''} ${ isActivePatch(path) ? ' active-item' : '' }` }
                   key={ path }
                   onClick={ () => changeMenuHandler(path, isDisabled) }>
                    { isActivePatch(path) ? activeIcon : icon }
              </div>
            </CustomTooltip>)
        })
      }
    </div>
  )
};
