import React, { FC } from 'react';

// Shared UI Library Components
import {
  CustomButton, CustomIcon, EIconColor, EIconName,
  UserAvatar
} from '@ppmcore/seven-ppm-core-shared-components-react';

// Styles
import './appointment-reminder.scss';

// Models
import { IAppointmentCallStarted } from "../../../../interfaces/appointments.interfaces";

type TAppointmentReminderProps = {
  appointment: IAppointmentCallStarted;
  joinToCall?: (appointment: IAppointmentCallStarted) => void;
  closeReminder?: () => void;
};

export const AppointmentReminder: FC<TAppointmentReminderProps> = (
  {
    appointment,
    joinToCall = () => {},
    closeReminder = () => {},
  }: TAppointmentReminderProps
): JSX.Element => {

  const closeReminderHandler = (): void => {
    closeReminder();
  }

  const joinToCallHandler = (): void => {
    joinToCall(appointment);
  }

  return (
    <div className="appointment-reminder">
      <div className="appointment-reminder--close" onClick={ closeReminderHandler }>
       <CustomIcon name={ EIconName.Close } size={'size-xl-30'} color={ 'white' } />
      </div>
      <div className="appointment-reminder--avatar">
        <UserAvatar avatarUrl={ appointment.worker?.photo || '' } sizeClass={ 'extra-large' }/>
      </div>
      <div className="appointment-reminder--reminder">
        You have an active appointment with { appointment.worker.first_name } { appointment.worker.last_name }, please
        join it
      </div>
      <div className="appointment-reminder--type">
        { appointment.call_type === 'video_call' &&
          <CustomIcon name={ EIconName.CameraOn } color={ EIconColor.White }/> }
        { appointment.call_type === 'audio_call' && <CustomIcon name={ EIconName.MicOn } color={ EIconColor.White }/> }
      </div>
      <div className="appointment-reminder--actions">
        <CustomButton mode={ "dark" } type={ 'primary' } onClick={ joinToCallHandler }>Join</CustomButton>
      </div>
    </div>
  );
};
