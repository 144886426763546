import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNetworkState } from '@uidotdev/usehooks';

// Components
import { CallNotifications } from '../modules/call-notifications/call-notifications';

// Thunks
import { fetchOngoingCalls } from '../../../store/ongoing-consultation/ongoing-consultation.thunks';
import { getCallInfoClearRequest } from '../../../store/call/call.thunks';

// Interfaces
import { ICallInfo } from '../../../interfaces/call.interfaces';

// Styles
import './call-lost-connection.scss';

export const CallLostConnection = () => {

  const dispatch = useDispatch<any>();
  const { online } = useNetworkState();

  const workerId = localStorage.getItem('worker_id');
  const callId = localStorage.getItem('call_id');
  const temporaryToken = localStorage.getItem('temporary_token');

  const [showBtn, setShowBtn] = useState<boolean>(false);
  const [additionActionLink, setAdditionActionLink] = useState<string>(localStorage.getItem('return_pathname') ?? `/expert/${workerId}`);

  const skipRejoin = () => {
    dispatch(fetchOngoingCalls());
  }

  useEffect(() => {
    if (!online) {
      setShowBtn(false);
      return;
    }

    if (callId) {
      dispatch(getCallInfoClearRequest({ call_id: +callId, temporary_token: temporaryToken ?? '' })).then((res: {payload: ICallInfo}) => {
        if (res.payload.call.call_status === 'finished') setAdditionActionLink(`/call-finished/${ callId }`);
        setShowBtn(true);
      });
      return;
    }

    setShowBtn(true);
  }, [online, callId]);

  return (<CallNotifications
    title={'Oops, something went wrong on your side'}
    text={'Your Internet connection was lost. Please check your internet connection. After successfully connecting to the network, buttons will appear below, use them to try to join the consultation. You will be connected to the same consultation.'}
    navigateAction={`/call/${callId}`}
    navigateActionText={'Rejoin'}
    additionAction={showBtn ? additionActionLink : ''}
    additionActionText={'Skip'}
    showPrimaryBtn={showBtn}
    onAdditionAction={skipRejoin} />);
}
