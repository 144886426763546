import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Dropdown } from 'antd';

// Shared UI Library Components
import { CustomIcon, EIconName, Loader, CustomTooltip } from '@ppmcore/seven-ppm-core-shared-components-react';

// Styles
import './logo-block.scss';

// Store
import { getCoreState } from '../../../../store/core/core.selectors';
import { getUserState } from '../../../../store/user/user.selectors';
import { updateLastCompany } from '../../../../store/user/user.thunks';

// Models
import { ICompany } from '../../../../interfaces/core.interfaces';

type TLogoBlockProps = {
  sidebarOpened?: boolean;
}

export const LogoBlock: FC<TLogoBlockProps> = (
  { sidebarOpened = true }: TLogoBlockProps
): JSX.Element => {

  const [company, setCompany] = useState<ICompany | null>(null);
  const [dropdownOpened, setDropdownOpened] = useState<boolean>(false);

  const { companies: { list, isLoad }, companyInfo } = useSelector(getCoreState);
  const { user } = useSelector(getUserState);

  const dispatch = useDispatch<any>();
  const navigate = useNavigate();

  const setCurrentCompany = (): void => {
    const companyId = user?.last_used_company;
    if (!companyId) return;
    const company = list.find(({ company_id }) => company_id === +companyId) ?? null;
    setCompany(company);
  }

  const changeCompany = (id: number): void => {
    setDropdownOpened(false);
    dispatch(updateLastCompany(id));
    navigate('/home', { replace: true });
  }

  const items = list.map(({ company_id, company_name }) => ({
    key: company_id,
    label: <><span className="company-name">{ company_name }</span>{ company_id === company?.company_id &&
      <CustomIcon name={ EIconName.SelectCheck }/> }</>,
    icon: <CustomIcon name={ EIconName.BusinessCenterFill }/>,
    onClick: () => changeCompany(company_id)
  }));

  const viewDropdown = <div className="logo-block--trigger">
    <CustomTooltip className="view-tooltip" title={ company?.company_name }>
      <CustomIcon name={ EIconName.BusinessCenterFill }/>
      { sidebarOpened && <span>{ company?.company_name }</span> }
    </CustomTooltip>
    <CustomIcon className={ `view-arrow ${ dropdownOpened ? 'rotate-icon' : '' }` } name={ EIconName.ArrowDown }/>
  </div>;

  useEffect(() => {
    setCurrentCompany();
  }, [user, list]);

  return (
    <div className="logo-block">
      <Dropdown rootClassName="logo-block--dropdown" onOpenChange={ setDropdownOpened }
                trigger={ isLoad ? [] : ['click'] }
                menu={ { items } }>
        { isLoad ? <Loader size={ 'small' }/> : viewDropdown }
      </Dropdown>
    </div>
  )
};
