// API
import { api } from '../configs/api/api';

// Models
import { IHistory } from '../interfaces/history.interfaces';
import { ICallNotification, IMessageNotification } from '../interfaces/chat.interfaces';

export const HistoryService = {
  fetchHistory: async (worker_id?: string | number, worker_code?: string, offset: number = 0, limit: number = 20, temporary_token?: string): Promise<{
    history?: Array<IHistory | IMessageNotification | ICallNotification>,
    total?: number,
    total_all?: number,
    error?: string
  }> => {
    try {
      const axiosResponse = await api.get(`/${ temporary_token ? 'temporary' : 'users' }/history`, {
        params: {
          ...(worker_id && { worker_id }),
          ...(worker_code && { worker_code }),
          offset,
          limit
        }
      });
      return axiosResponse.data;
    } catch (error: any) {
      const res = error?.response?.data;
      if (error?.response.status === 500) {
        return { error: res?.message };
      }
      return { ...res };
    }
  },
}
