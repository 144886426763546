import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

// Components
import { CeStep1 } from './ce-step-1/ce-step-1';
import { CeStep2 } from './ce-step-2/ce-step-2';
import { CeStep3 } from './ce-step-3/ce-step-3';
import { CeStep4 } from './ce-step-4/ce-step-4';

// Thunks
import { setInProgressState } from '../../../store/profile-settings/profile-settings.thunks';

// Styles
import './change-email.scss';

export const ChangeEmail = () => {

  const dispatch = useDispatch<any>();
  const [activeStep, setActiveStep] = useState(3);

  const onSetStep = (value: number) => {
    setActiveStep(value);
    if (value === 1) {
      dispatch(setInProgressState(false));
    } else {
      dispatch(setInProgressState(true));
    }
  }

  return (<div className="change-email">
    <div className={'content-title'}>Change Email</div>
    <div className={'content-title-line'}></div>
    <div className={'content-inside'}>
      {/*{activeStep === 1 && <CeStep1 setStep={onSetStep}/>}*/}
      {/*{activeStep === 2 && <CeStep2 setStep={onSetStep} />}*/}
      {activeStep === 3 && <CeStep3 setStep={onSetStep} />}
      {activeStep === 4 && <CeStep4 setStep={onSetStep} />}
    </div>
  </div>);
}
