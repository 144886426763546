import { createSelector } from '@reduxjs/toolkit';

// Models
import { IPaymentsState } from './payments.slice';
import { RootState } from '../index';

/**
 A selector function to retrieve the payments data from the store
 @function
 @param {RootState} state - The root state of the Redux store
 @returns {IPaymentsState} The payments state object from the Redux store
 */
const selectPaymentsState = (state: RootState): IPaymentsState => state.payments;

/**
 A memoized selector function to retrieve the payments data from the store
 @function
 @type {import('@reduxjs/toolkit').OutputSelector<RootState, IPaymentsState, (res: IPaymentsState) => IPaymentsState>}
 */
export const getPaymentsData = createSelector(
  [selectPaymentsState],
  (state): IPaymentsState => state
);
