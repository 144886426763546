import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Interfaces
import { ICallProcess, IOngoingCall } from '../../interfaces/call.interfaces';

export interface IOngoingConsultationState {
  call: IOngoingCall | null,
  soon_appointment_call: ICallProcess | null,
  loading: boolean,
  error: string | null,
}

const initialState: IOngoingConsultationState = {
  call: null,
  soon_appointment_call: null,
  loading: true,
  error: null,
};

export const ongoingConsultationSlice = createSlice({
  name: 'ongoing-consultation',
  initialState,
  reducers: {
    loadOngoingConsultationRequest(state) {
      state.loading = true;
      state.error = null;
    },
    loadOngoingConsultationRequestSuccess: (state, { payload: { call, soon_appointment_call } }: PayloadAction<{
      call?: IOngoingCall,
      soon_appointment_call?: ICallProcess
    }>) => {
      state.call = call || null;
      state.soon_appointment_call = soon_appointment_call || null;
      state.loading = false;
      state.error = null;
    },
    loadOngoingConsultationRequestFailure: (state, { payload: error }: PayloadAction<string>) => {
      state.call = null;
      state.soon_appointment_call = null;
      state.loading = false;
      state.error = error;
    },
    updateSoonAppointmentCall: (state, { payload: soon_appointment_call }: PayloadAction<ICallProcess>) => {
      state.soon_appointment_call = soon_appointment_call;
    },
  }
});

export const OngoingConsultationSliceActions = ongoingConsultationSlice.actions;
