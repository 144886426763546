import { createAsyncThunk } from '@reduxjs/toolkit';

// Services
import { AuthService } from '../../services/auth.service';

// Slices
import { AuthSliceActions } from './auth.slice';
import { UserSliceActions } from '../user/user.slice';

// Interface
import { IUserSignUpRequest } from '../../interfaces/user.interfaces';
import { EVerificationCodeTypes } from '../../pages/profile-settings/profile-settings.enums';

// Utils
import { isWidgetAppType } from '../../utils/widget.utils';

export enum EAuthThunks {
  SignInUser = 'AUTH/signInUser',
  SignUpUser = 'AUTH/signUpUser',
  SignUpTempUser = 'AUTH/signUpTempUser',
  ActivateCode = 'AUTH/activateCode',
  ActivateTempUserCode = 'AUTH/activateTempUserCode',
  ResendCode = 'AUTH/resendCode',
  ResendTempUserCode = 'AUTH/resendTempUserCode',
  ForgotPassword = 'AUTH/forgotPassword',
  ResendResetEmail = 'AUTH/resendResetEmail',
  CheckResetPasswordTokenThunks = 'AUTH/checkResetPasswordTokenThunks',
  ResetPassword = 'AUTH/resetPassword',
  ClearAuthState = 'AUTH/clearAuthState',
}

export const signInUser = createAsyncThunk(
  EAuthThunks.SignInUser,
  async ({ email, password, temporary_token, device_UDID }: {
    email: string;
    password: string;
    temporary_token: string;
    device_UDID: string | null;
  }, { dispatch }: any) => {
    dispatch(AuthSliceActions.signInRequest());
    const isWidget = isWidgetAppType();
    const invitation_code = isWidget ? localStorage.getItem('companyHash') : null;
    const res = await AuthService.signIn(email, password, temporary_token, device_UDID, invitation_code);
    if (res.status !== 200) {
      dispatch(AuthSliceActions.signInFailure(res.data.error));
      return res.data;
    }
    dispatch(AuthSliceActions.signInSuccess(res.data));
    return res.data;
  }
);

export const signUpUser = createAsyncThunk(
  EAuthThunks.SignUpUser,
  async (userData: IUserSignUpRequest, { dispatch }: any) => {
    dispatch(AuthSliceActions.signUpRequest());
    const res = await AuthService.signUp(userData);
    if (res.status !== 200) {
      dispatch(AuthSliceActions.signUpFailure(res.data.error));
      return res.data;
    }
    dispatch(AuthSliceActions.signUpSuccess(res.data));
    return res.data;
  }
);

export const activateCode = createAsyncThunk(
  EAuthThunks.ActivateCode,
  async ({ temporary_token, code, actionType, device_UDID }: {
    temporary_token: string,
    code: string,
    actionType: string,
    device_UDID: string | null
  }, { dispatch }: any) => {
    dispatch(AuthSliceActions.activateCodeRequest());
    const isWidget = isWidgetAppType();
    const invitation_code = isWidget ? localStorage.getItem('companyHash') : null;
    const res = await AuthService.activateCode(temporary_token, code, actionType, device_UDID, invitation_code);
    if (res.data.code === 401 && actionType === EVerificationCodeTypes.ProfileDelete) {
      return res.data;
    }
    if (res.status !== 200) {
      dispatch(AuthSliceActions.activateCodeFailure(res.data.error));
      return res.data;
    }
    dispatch(AuthSliceActions.activateCodeSuccess());
    localStorage.setItem('token', res.data.token);
    dispatch(UserSliceActions.profileSuccess(res.data.user));
    return res.data.user;
  }
);

export const resendCode = createAsyncThunk(
  EAuthThunks.ResendCode,
  async ({ temporary_token, type }: { temporary_token: string, type: string }, { dispatch }: any) => {
    dispatch(AuthSliceActions.resendCodeRequest());
    const res = await AuthService.resendCode(temporary_token, type);
    if (res.status !== 200) {
      dispatch(AuthSliceActions.resendCodeFailure(res.data.error));
      return res.data;
    }
    dispatch(AuthSliceActions.resendCodeSuccess(res.data));
    return res.data;
  }
);

export const forgotPasswordThunks = createAsyncThunk(
  EAuthThunks.ForgotPassword,
  async ({ email }: { email: string; }, { dispatch }) => {
    dispatch(AuthSliceActions.forgotPasswordRequest());
    const res = await AuthService.forgotPassword(email);
    if (res.status !== 200) {
      dispatch(AuthSliceActions.forgotPasswordFailure(res.data.error));
      return res.data;
    }
    dispatch(AuthSliceActions.forgotPasswordSuccess(res.data));
  }
);

export const resendEmailThunks = createAsyncThunk(
  EAuthThunks.ResendResetEmail,
  async ({ email }: { email: string; }, { dispatch }) => {
    dispatch(AuthSliceActions.resendEmailRequest());
    const res = await AuthService.resendResetEmail(email);
    if (res.status !== 200) {
      dispatch(AuthSliceActions.resendEmailFailure(res.data.error));
      return res.data;
    }
    dispatch(AuthSliceActions.resendEmailSuccess(res.data));
    return res.data;
  }
);

export const checkResetPasswordTokenThunks = createAsyncThunk(
  EAuthThunks.CheckResetPasswordTokenThunks,
  async ({ reset_token }: { reset_token: string }, { dispatch }) => {
    const res = await AuthService.checkResetPasswordToken(reset_token);
    if (res.status !== 200) {
      return res.data;
    }
    return res.data;
  }
);

export const resetPasswordThunks = createAsyncThunk(
  EAuthThunks.ResetPassword,
  async ({ reset_token, password, password_confirmation }: {
    reset_token: string,
    password: string,
    password_confirmation: string;
  }, { dispatch }) => {
    dispatch(AuthSliceActions.resetPasswordRequest());
    const res = await AuthService.resetPassword(reset_token, password, password_confirmation);
    if (res.status !== 200) {
      dispatch(AuthSliceActions.resetPasswordFailure(res.data.error));
      return res.data;
    }
    dispatch(AuthSliceActions.clearAuthState());
    return res.data;
  }
);

export const signUpTempUser = createAsyncThunk(
  EAuthThunks.SignUpTempUser,
  async ({ first_name, last_name, email, company_code }: {
    first_name: string,
    last_name: string,
    email: string,
    company_code: string
  }, { dispatch }: any) => {
    dispatch(AuthSliceActions.signUpRequest());
    const res = await AuthService.signUpTemp({ first_name, last_name, email, company_code });
    if (res.status !== 200) {
      dispatch(AuthSliceActions.signUpFailure(res.data.error));
      return res.data;
    }
    dispatch(AuthSliceActions.signUpSuccess());
    return res.data;
  }
);

export const activateTempUserCode = createAsyncThunk(
  EAuthThunks.ActivateTempUserCode,
  async ({ temporary_token, code, actionType }: {
    temporary_token: string,
    code: string,
    actionType: string
  }, { dispatch }: any) => {
    dispatch(AuthSliceActions.activateCodeRequest());
    const res = await AuthService.activateCodeTempUser(temporary_token, code, actionType);
    if (res.status !== 200) {
      dispatch(AuthSliceActions.activateCodeFailure(res.data.error));
      return res.data;
    }
    dispatch(AuthSliceActions.activateCodeSuccess());
    localStorage.setItem('temporary_token', res.data.token);
    dispatch(UserSliceActions.profileSuccess(res.data.user));
    return res.data.user;
  }
);

export const resendTempUserCode = createAsyncThunk(
  EAuthThunks.ResendTempUserCode,
  async ({ temporary_token, type }: { temporary_token: string, type: string }, { dispatch }: any) => {
    dispatch(AuthSliceActions.resendCodeRequest());
    const res = await AuthService.resendCodeTempUser(temporary_token, type);
    if (res.status !== 200) {
      dispatch(AuthSliceActions.resendCodeFailure(res.data.error));
      return res.data;
    }
    dispatch(AuthSliceActions.resendCodeSuccess(res.data));
    return res.data;
  }
);

export const clearAuthState = createAsyncThunk(
  EAuthThunks.ClearAuthState,
  async (_, { dispatch }: any) => {
    dispatch(AuthSliceActions.clearAuthState());
  }
);
