import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IStaticPages, IStaticPagesItem } from '../../interfaces/static-pages.interface';

export interface IStaticPagesState {
  pages: IStaticPagesItem[] | null;
  loading: boolean;
  error: boolean;
}

const initialState: IStaticPagesState = {
  pages: null,
  loading: false,
  error: false
};

export const staticPagesSlice = createSlice({
  name: 'static_pages',
  initialState,
  reducers: {
    getStaticPagesRequest(state) {
      state.loading = true;
      state.error = false;
    },
    getStaticPagesSuccess: (state, action: PayloadAction<IStaticPages | null>) => {
      state.pages = action.payload?.pages ?? null;
      state.loading = false;
      state.error = false;
    },
    getStaticPagesFailure: (state) => {
      state.loading = false;
      state.error = true;
    }
  }
});

export const StaticPagesSliceActions = staticPagesSlice.actions;
