import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Models
import { IChatProcess } from '../../interfaces/text-chat.interfaces';

export interface IChatFinishedState {
  chatFinished: IChatProcess | null;
  loading: boolean;
  error: boolean;
}

const initialState: IChatFinishedState = {
  chatFinished: null,
  loading: false,
  error: false
};

export const chatFinishedSlice = createSlice({
  name: 'chat-finished',
  initialState,
  reducers: {
    getChatFinishedRequest(state) {
      state.loading = true;
      state.error = false;
    },
    getChatFinishedSuccess: (state, action: PayloadAction<IChatProcess>) => {
      state.chatFinished = action.payload ?? null;
      state.loading = false;
      state.error = false;
    },
    getChatFinishedFailure: (state) => {
      state.loading = false;
      state.error = true;
    },
    clearChatFinishedState: (state) => {
      state.chatFinished = null;
      state.loading = false;
      state.error = false;
    },
  }
});

export const ChatFinishedSliceActions = chatFinishedSlice.actions;
