import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Models
import { INotificationsData } from '../../interfaces/notifications.interfaces';

export interface IAppNotificationsState {
  data: INotificationsData,
  isOpened: boolean,
}

const initialState: IAppNotificationsState = {
  data: {
    type: 'success',
    message: '',
    description: '',
    icon: null,
    placement: 'topRight'
  },
  isOpened: false,
};

export const appNotificationsSlice = createSlice({
  name: 'app-notifications',
  initialState,
  reducers: {
    openNotification: (state, { payload }: PayloadAction<INotificationsData>) => {
      state.isOpened = true;
      state.data = payload;
    },
    clearNotification: (state) => {
      state.isOpened = false;
      state.data = {
        type: 'success',
        message: '',
        description: '',
        icon: null,
        placement: 'topRight'
      };
    },
    closeNotification: (state) => {
      state.isOpened = false;
    },
  }
});

export const AppNotificationsSliceActions = appNotificationsSlice.actions;
