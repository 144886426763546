import React, { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";

// Shared UI Library Components
import { Paginator, SpinElement } from '@ppmcore/seven-ppm-core-shared-components-react';

// Styles
import './appointments-mobile.scss';

// Components
import { AppointmentsCard } from '../appointments-card/appointments-card';
import { CancelingAppointmentModal } from "../../../../modals/canceling-appointment-modal/canceling-appointment-modal";

// Store
import {
  cancelAppointment,
  fetchAppointments
} from "../../../../store/appointments/appointments.thunks";
import { getCallInfo } from "../../../../store/call/call.thunks";

// Models
import { IAppointment, IAppointmentsSettings } from "../../../../interfaces/appointments.interfaces";
import { ELocalStorageKeys } from "../../../../enums/storage.enums";

interface IAppointmentsMobileProps {
  appointments: IAppointment[];
  appointmentsSettings: IAppointmentsSettings;
  loading?: boolean;
  total?: number;
  total_all?: number;
}

export const AppointmentsMobile: FC<IAppointmentsMobileProps> = (
  { appointments, appointmentsSettings, total = 0, total_all = 0, loading = false }: IAppointmentsMobileProps
): JSX.Element => {
  const [cancelAppointmentModalOpen, setCancelAppointmentModalOpen] = useState<boolean>(false);
  const [actionAppointment, setActionAppointment] = useState<IAppointment | null>(null);
  const [page, setPage] = useState<number>(1);

  const dispatch = useDispatch<any>();
  const navigate = useNavigate();

  const jointAppointmentHandler = (call_id: number, call_type: string, workerId: number): void => {
    localStorage.setItem('call_type', call_type);
    localStorage.setItem('call_id', `${ call_id }`);
    localStorage.setItem('worker_id', `${ workerId }`);

    const temporary_token = localStorage.getItem(ELocalStorageKeys.TemporaryToken) || '';

    dispatch(getCallInfo({ call_id: +call_id, temporary_token }));

    navigate(`/call/devices-settings/${ call_id }`);
  }

  const toggleCancelAppointmentModal = (): void => {
    setCancelAppointmentModalOpen((prev) => !prev);
  }

  const cancelAppointmentHandler = (appointment: IAppointment | null): void => {
    setActionAppointment(appointment);
    toggleCancelAppointmentModal();
  }

  const submitCancelAppointmentHandler = async (appointment: IAppointment | null, cancel_notes: string): Promise<void> => {
    const appointment_id = appointment?.id;
    if (!appointment_id) return;

    setActionAppointment(appointment);
    const { payload } = await dispatch(cancelAppointment({
      appointment_id,
      cancel_notes
    }));
    if (!payload) return;
    setActionAppointment(null);
    toggleCancelAppointmentModal();
  }

  const onPageChange = (currentPage: number) => {
    if (page !== currentPage) {
      setPage(currentPage);
      dispatch(fetchAppointments({
        offset: (currentPage - 1) * 10,
        limit: 10,
        settings: {
          ...appointmentsSettings,
        }
      }));
      return;
    }
    setPage(1);
  };

  return (
    <div className="appointments-mobile">

      <CancelingAppointmentModal open={ cancelAppointmentModalOpen }
                                 payload={ actionAppointment }
                                 loading={ loading }
                                 onCancel={ toggleCancelAppointmentModal }
                                 onSubmit={ submitCancelAppointmentHandler }
      />

      { loading && <div className="appointments-mobile--loader">
        <SpinElement/>
      </div> }

      { !total && <div className="appointments-mobile--empty">
        No found items
      </div> }

      { total > 10 && <div className="appointments-mobile--pagination">
        <Paginator totalItems={ total } pageChange={ onPageChange }/>
      </div> }

      <div className="appointments-mobile--list">
        {
          appointments.map(appointment =>
            <AppointmentsCard key={ appointment.id }
                              loading={ appointment.id === actionAppointment?.id && loading }
                              appointment={ appointment }
                              cancelAppointment={ cancelAppointmentHandler }
                              jointAppointment={ jointAppointmentHandler }
            />)
        }
      </div>
    </div>
  );
}
